import {
    Menu,
    MenuItem,
    ProSidebarProvider,
    Sidebar,
    SubMenu,
} from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setRoute } from "../../redux/route/routeSlice";

import dashboard from "../../assets/images/icon1.svg";
import report from "../../assets/images/icon8.svg";
import template from "../../assets/images/icon5.svg";
import entity from "../../assets/images/icon9.svg";
import scheduler from "../../assets/images/scheduler.svg";
import announcement from "../../assets/images/icon6.svg";
import activityLog from "../../assets/images/activityLog.svg";
import bell from "../../assets/images/bell.svg";
import productList from "../../assets/images/productList.svg";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import folderIcon from "../../assets/images/sidebarFolderIcon.svg";

export default function CaseManagerSidebar(props) {
    const [activePage, setActivePage] = useState(null);
    const [sidebarOpen, setSidebarOpen] = useState("");
    const reduxData = useSelector((state) => state);
    const dispatch = useDispatch();

    const getFolderName = () => {
        if (reduxData?.folders?.folderStructure?.length) {
            let name = reduxData?.folders?.folderStructure[0]?.name;
            return name;
        }
    };

    function handleActive(event, heading) {
        let activeA = document.querySelector("a.active");
        if (activeA) {
            activeA.classList.remove("active");
        }
        dispatch(setRoute(heading));
        if (!event.target.classList.value.includes("active")) {
            event.target.classList.toggle("active");
            if (activePage) activePage.classList.remove("active");
            setActivePage(event.target);
        }
    }

    useEffect(() => {
        if (reduxData?.heading?.value?.includes("Cases")) {
            setSidebarOpen("Cases");
        }
    }, [reduxData?.heading?.value]);

    return (
        <ProSidebarProvider>
            <Sidebar>
                <Menu>
                    <MenuItem
                        onClick={(e) => {
                            handleActive(e, "Dashboard");
                            setSidebarOpen("");
                        }}
                        routerLink={
                            <Link
                                onClick={props.menuClick}
                                to="/case-manager"
                            />
                        }
                    >
                        <img src={dashboard} alt="dashboard" /> Dashboard
                    </MenuItem>

                    {/* <MenuItem
                        onClick={(e) => {
                            handleActive(e, "Entities");
                        }}
                        routerLink={
                            <Link
                                onClick={props.menuClick}
                                to="/case-manager/entities"
                            />
                        }
                    >
                        
                        <img src={entity} alt="Entities" /> Entities
                    </MenuItem> */}

                    {reduxData?.auth?.user?.addCorporateLeads && (
                        <SubMenu
                            icon={<img src={template} alt="setting" />}
                            className="settings"
                            label="Leads"
                            open={sidebarOpen === "Leads"}
                            onClick={() => {
                                let activeSpan =
                                    document.querySelector("span.active");
                                if (activeSpan) {
                                    activeSpan.classList.remove("active");
                                }
                                setSidebarOpen(
                                    sidebarOpen === "Leads" ? null : "Leads"
                                );
                            }}
                            // onClick={leadLink}
                        >
                            <MenuItem
                                onClick={(e) => {
                                    handleActive(e, "Leads");
                                }}
                                routerLink={
                                    <Link
                                        onClick={props.menuClick}
                                        to="/case-manager/leads/all"
                                    />
                                }
                            >
                                All Leads
                            </MenuItem>
                            <MenuItem
                                onClick={(e) => {
                                    handleActive(e, "Leads");
                                }}
                                routerLink={
                                    <Link
                                        onClick={props.menuClick}
                                        to="/case-manager/leads/assigned"
                                    />
                                }
                            >
                                Assigned Leads
                            </MenuItem>
                            <MenuItem
                                onClick={(e) => {
                                    handleActive(e, "Leads");
                                }}
                                routerLink={
                                    <Link
                                        onClick={props.menuClick}
                                        to="/case-manager/leads/unassigned"
                                    />
                                }
                            >
                                Own Leads
                            </MenuItem>
                        </SubMenu>
                    )}

                    <SubMenu
                        icon={<img src={template} alt="setting" />}
                        className="settings"
                        label="Case"
                        open={sidebarOpen === "Cases"}
                        onClick={() => {
                            let activeSpan =
                                document.querySelector("span.active");
                            if (activeSpan) {
                                activeSpan.classList.remove("active");
                            }
                            setSidebarOpen(
                                sidebarOpen === "Cases" ? null : "Cases"
                            );
                        }}
                    >
                        <MenuItem
                            onClick={(e) => {
                                handleActive(e, "Cases");
                            }}
                            routerLink={
                                <Link
                                    onClick={props.menuClick}
                                    to="/case-manager/all-cases"
                                />
                            }
                        >
                            All Case
                        </MenuItem>
                        <MenuItem
                            onClick={(e) => {
                                handleActive(e, "Cases");
                            }}
                            routerLink={
                                <Link
                                    onClick={props.menuClick}
                                    to="/case-manager/verified-cases"
                                />
                            }
                        >
                            Case Verified
                        </MenuItem>
                        <MenuItem
                            onClick={(e) => {
                                handleActive(e, "Cases");
                            }}
                            routerLink={
                                <Link
                                    onClick={props.menuClick}
                                    to="/case-manager/unverified-cases"
                                />
                            }
                        >
                            Case Need to Verified
                        </MenuItem>
                    </SubMenu>

                    <MenuItem
                        onClick={(e) => {
                            handleActive(e, "Reports");
                        }}
                        routerLink={
                            <Link
                                onClick={props.menuClick}
                                to="/case-manager/report"
                            />
                        }
                    >
                        <img src={report} alt="Form Template" /> Reports
                    </MenuItem>
                    <MenuItem
                        onClick={(e) => {
                            handleActive(e, "Scheduler");
                        }}
                        routerLink={
                            <Link onClick={props.menuClick} to="/schedule" />
                        }
                    >
                        <img src={scheduler} alt="Form Template" />
                        Scheduler
                    </MenuItem>
                    {/* <MenuItem
                        onClick={(e) => {
                            handleActive(e, "Notification");
                        }}
                        routerLink={
                            <Link
                                onClick={props.menuClick}
                                to="/notification"
                            />
                        }
                    >
                        <img src={bell} alt="Form Template" />
                        Notification
                    </MenuItem> */}
                    <MenuItem
                        onClick={(e) => {
                            handleActive(e, "Activity Logs");
                        }}
                        routerLink={
                            <Link
                                onClick={props.menuClick}
                                to="/activity-log"
                            />
                        }
                    >
                        <img src={activityLog} alt="Form Template" />
                        Activity Logs
                    </MenuItem>
                    <MenuItem
                        onClick={(e) => {
                            handleActive(e, "PDPA");
                        }}
                        routerLink={
                            <Link
                                onClick={props.menuClick}
                                to="/announcements-list"
                            />
                        }
                    >
                        <img src={announcement} alt="Form Template" />
                        PDPA
                    </MenuItem>
                    <MenuItem
                        onClick={(e) => {
                            handleActive(e, "Product");
                        }}
                        routerLink={
                            <Link
                                onClick={props.menuClick}
                                to="/product-list"
                            />
                        }
                    >
                        <img src={productList} alt="Form Template" />
                        Product
                    </MenuItem>
                    <MenuItem
                        onClick={(e) => {
                            handleActive(e, getFolderName());
                        }}
                        routerLink={
                            <Link
                                onClick={props.menuClick}
                                to="/folder-structure"
                            />
                        }
                    >
                        <img src={folderIcon} alt="Folder" />
                        {getFolderName()}
                    </MenuItem>
                </Menu>
            </Sidebar>
        </ProSidebarProvider>
    );
}
