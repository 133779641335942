import "./leadDetailModal.scss";
import crossIcon from "../../../../assets/images/cross.svg";
import fileIcon from "../../../../assets/images/fileIcon.svg";
import { useEffect, useState } from "react";
import CaseInformation from "./CaseInformation";
import BusinessInformation from "./BusinessInformation";
import { PENDING, PROCESSING } from "../../../../config/leadStatus";
import {
    APPROVED,
    COMPLETED,
    PENDING_1,
    PENDING_2,
    PROCESSING_1,
} from "../../../../config/casesStatus";
import { api } from "../../../../helpers/apiHelper/requestHelper";
import { toastify } from "../../../../helpers/toast/toastify";
import NotesInformation from "./NotesInformation";

export default function LeadDetailsModal(props) {
    const [currentCase, setCurrentCase] = useState();

    useEffect(() => {
        setCurrentCase(props?.case);
    }, [props?.case]);

    const [activeTab, setActiveTab] = useState(
        props?.case ? "caseTab" : "individualTab"
    );

    const getSubStatus = (status, reason, rmPdpa, cmPdpa, fundsDisbursed) => {
        if (cmPdpa) {
            return "Case Manager PDPA Deletion";
        } else if (rmPdpa) {
            return "RM PDPA Deletion";
        } else {
            if (fundsDisbursed) {
                return "Disbursed By Bank";
            } else if (status?.toLowerCase() === "pending") {
                return PENDING_1;
            } else if (status?.toLowerCase() === "submitted") {
                return PENDING_2;
            } else if (status?.toLowerCase() === "processing") {
                return PROCESSING_1;
            } else if (status?.toLowerCase() === "approved") {
                return APPROVED;
            } else if (status?.toLowerCase() === "completed") {
                return COMPLETED;
            } else if (status?.toLowerCase() === "rejected") {
                return reason;
            } else {
                return null;
            }
        }
    };

    return (
        <div className={`modal-wrapper add-product-form lead-details-modal`}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-body">
                        <img
                            src={crossIcon}
                            className="cross-btn"
                            onClick={props.setModalClick}
                            alt="crossIcon"
                        />
                        <div className="modal-forms pb-50">
                            <h2>View Details</h2>
                            <div className="load-modal-detail">
                                <div className="flex top-strip">
                                    <h3>
                                        Lead{" "}
                                        <span>
                                            {props?.lead?.leadType ||
                                                currentCase?.lead?.leadType}
                                        </span>
                                        <h3>
                                            {currentCase
                                                ? currentCase?.lead
                                                      ?.businessEntity?.name ||
                                                  currentCase?.lead
                                                      ?.individualEntity
                                                      ?.firstName +
                                                      " " +
                                                      currentCase?.lead
                                                          ?.individualEntity
                                                          ?.lastName
                                                : props?.lead?.businessEntity
                                                      ?.name ||
                                                  props?.lead?.individualEntity
                                                      ?.firstName +
                                                      " " +
                                                      props?.lead
                                                          ?.individualEntity
                                                          ?.lastName}
                                        </h3>
                                    </h3>
                                    <div className="status-wrap ml-auto">
                                        <span
                                            className={`status ${
                                                props?.lead?.status?.toLowerCase() ||
                                                currentCase?.status?.toLowerCase()
                                            }`}
                                        >
                                            {props?.lead?.status ||
                                                currentCase?.status}
                                        </span>
                                        <small>
                                            {getSubStatus(
                                                props?.lead?.status ||
                                                    currentCase?.status,
                                                currentCase?.decisionReason,
                                                currentCase?.rmPdpa,
                                                currentCase?.cmPdpa,
                                                currentCase?.institutes?.find(
                                                    (e) => e.fundsDisbursed
                                                ) || false
                                            )}
                                        </small>
                                    </div>
                                </div>
                                <div className="entity-type-tab">
                                    {currentCase ? (
                                        <ul className="list-style-none flex">
                                            <li>
                                                <button
                                                    onClick={() => {
                                                        setActiveTab("caseTab");
                                                    }}
                                                    className={`${
                                                        activeTab === "caseTab"
                                                            ? "active"
                                                            : ""
                                                    }`}
                                                >
                                                    Case Information
                                                </button>
                                            </li>
                                            <li>
                                                <button
                                                    onClick={() => {
                                                        setActiveTab(
                                                            "individualTab"
                                                        );
                                                    }}
                                                    className={`${
                                                        activeTab ===
                                                        "individualTab"
                                                            ? "active"
                                                            : ""
                                                    }`}
                                                >
                                                    {props?.lead?.entityType ||
                                                        currentCase?.lead
                                                            ?.entityType}{" "}
                                                    information
                                                </button>
                                            </li>
                                        </ul>
                                    ) : (
                                        <ul className="list-style-none flex">
                                            <li>
                                                <button
                                                    onClick={() => {
                                                        setActiveTab(
                                                            "individualTab"
                                                        );
                                                    }}
                                                    className={`${
                                                        activeTab ===
                                                        "individualTab"
                                                            ? "active"
                                                            : ""
                                                    }`}
                                                >
                                                    {props?.lead?.entityType ||
                                                        currentCase?.lead
                                                            ?.entityType}{" "}
                                                    information
                                                </button>
                                            </li>
                                            <li>
                                                <button
                                                    onClick={() => {
                                                        setActiveTab("caseTab");
                                                    }}
                                                    className={`no-br ${
                                                        activeTab === "caseTab"
                                                            ? "active"
                                                            : ""
                                                    }`}
                                                >
                                                    Case Information
                                                </button>
                                            </li>
                                            <li>
                                                <button
                                                    onClick={() => {
                                                        setActiveTab("noteTab");
                                                    }}
                                                    className={`${
                                                        activeTab === "noteTab"
                                                            ? "active"
                                                            : ""
                                                    }`}
                                                >
                                                    Note
                                                </button>
                                            </li>
                                        </ul>
                                    )}
                                </div>
                                {activeTab === "caseTab" ? (
                                    <CaseInformation
                                        lead={props.lead}
                                        case={props.case}
                                        setOpenDetailModal={
                                            props?.setOpenDetailModal
                                        }
                                        setModalClick={props?.setModalClick}
                                        getLeads={props?.getLeads}
                                        getCases={props?.getCases}
                                        setCurrentCase={setCurrentCase}
                                    />
                                ) : activeTab === "individualTab" ? (
                                    <BusinessInformation
                                        lead={props.lead}
                                        case={props.case}
                                    />
                                ) : (
                                    <NotesInformation
                                        lead={props.lead}
                                        getLeads={props?.getLeads}
                                        setShow={props?.setModalClick}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
