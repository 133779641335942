import Search from "../../../components/search/Search";
import { Link, useNavigate } from "react-router-dom";
import update from "../../../assets/images/update.svg";
import del from "../../../assets/images/del.svg";
import EntriesSelection from "../../../components/pagination/EntriesSelection";
import Pagination from "../../../components/pagination/Pagination";
import { useState } from "react";
import { useEffect } from "react";
import { api } from "../../../helpers/apiHelper/requestHelper";
import { toastify } from "../../../helpers/toast/toastify";
import { AlertModal } from "../../../components/modals/AlertModal";
import { setLoading } from "../../../redux/loader/loadingSlice";
import { useDispatch } from "react-redux";
import { setSidebar } from "../../../helpers/sidebar/setSidebar";
import { shortFormatDate } from "../../../helpers/dataHelper/dateFormatter";

export default function BankList() {
    const [banks, setBanks] = useState([]);
    const [selectedBank, setSelectedBank] = useState();
    const [show, setShow] = useState(false);
    const [filtered, setFiltered] = useState([]);
    const [count, setCount] = useState(5);
    const [page, setPage] = useState(1);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        setFiltered(banks);
    }, [banks]);

    useEffect(() => {
        setSidebar("Bank List");
        populateData();
    }, []);

    const populateData = async () => {
        const response = await api("get", "banks/index");

        if (response?.status) {
            setBanks(response?.data?.reverse());
        } else {
            toastify(response?.message);
        }
    };

    const confirmAction = async () => {
        dispatch(setLoading(true));
        const response = await api("post", "banks/update", {
            id: selectedBank?._id,
            isDeleted: true,
        });
        dispatch(setLoading(false));
        if (response?.data?.status) {
            toastify("Product Deleted Successfully.", "success");
            populateData();
        } else {
            toastify(response?.message, "error");
        }
        setShow(false);
        setSelectedBank();
    };

    const handleDelete = (data) => {
        setShow(true);
        setSelectedBank(data);
    };

    return (
        <>
            {show && (
                <AlertModal
                    confirmAction={confirmAction}
                    setModalClick={() => setShow(false)}
                    confirmBtn={true}
                    modalTitle={[
                        <h2>
                            Do you really want to delete{" "}
                            {selectedBank?.name || "N/A"}
                            <br /> Bank permanently?
                        </h2>,
                    ]}
                    modalDescription={[]}
                />
            )}
            <h4 className="flex column-direction mini-title">Bank List</h4>
            <Search
                userSearch={true}
                filedText="Search Bank"
                teamDropdown={false}
                filters={false}
                addButton={true}
                addButtonText="Add Bank"
                buttonRoute={"/add-bank"}
                setBanks={setFiltered}
                banks={banks}
                component={"Banks"}
            />
            <div className="responsive-table bank-table w-100">
                <h3>Bank List</h3>
                <table className="table">
                    <thead>
                        <tr
                            className="no-shadow"
                            style={{ background: "#f6f6f6" }}
                        >
                            <td colSpan="3" height="16"></td>
                        </tr>
                        <tr>
                            <th>Bank Name</th>
                            <th>Created At</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filtered
                            ?.slice(count * page - count, count * page)
                            ?.map((bank, index) => {
                                return (
                                    <>
                                        <tr className="no-shadow">
                                            <td colSpan="3" height="16"></td>
                                        </tr>
                                        <tr key={index}>
                                            <td>{bank?.name}</td>
                                            <td>
                                                {shortFormatDate(
                                                    bank?.created_at
                                                )}
                                            </td>
                                            <td>
                                                <div className="flex items-center">
                                                    <div
                                                        onClick={() =>
                                                            navigate(
                                                                "/add-bank",
                                                                {
                                                                    state: {
                                                                        bank,
                                                                    },
                                                                }
                                                            )
                                                        }
                                                        className="table-action update cursor-pointer"
                                                    >
                                                        <img
                                                            src={update}
                                                            alt="update"
                                                        />
                                                        <span>
                                                            <small>
                                                                Update
                                                            </small>
                                                        </span>
                                                    </div>
                                                    <div
                                                        onClick={() =>
                                                            handleDelete(bank)
                                                        }
                                                        className="table-action del cursor-pointer"
                                                    >
                                                        <img
                                                            src={del}
                                                            alt="del"
                                                        />
                                                        <span>
                                                            <small>
                                                                Delete
                                                            </small>
                                                        </span>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </>
                                );
                            })}
                    </tbody>
                </table>
                <div className="flex pagination-combine">
                    <EntriesSelection
                        setCount={setCount}
                        page={page}
                        count={count}
                        data={filtered}
                    />
                    <Pagination
                        count={count}
                        data={filtered}
                        setPage={setPage}
                        page={page}
                    />
                </div>
            </div>
        </>
    );
}
