import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    value: [],
};

export const productsSlice = createSlice({
    name: "productsSlice",
    initialState,
    reducers: {
        setProductForms: (state, action) => {
            state.value = action.payload;
        },
    },
});

export const { setProductForms } = productsSlice.actions;

export default productsSlice.reducer;
