import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    currentRole: null,
    roles: [],
};

export const rolesSlice = createSlice({
    name: "rolesSlice",
    initialState,
    reducers: {
        setActiveRole: (state, action) => {
            state.currentRole = action.payload;
        },
        setUserRoles: (state, action) => {
            state.roles = action.payload;
        },
    },
});

export const { setActiveRole, setUserRoles } = rolesSlice.actions;

export default rolesSlice.reducer;
