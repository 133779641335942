import EntriesSelection from "../../../components/pagination/EntriesSelection";
import Pagination from "../../../components/pagination/Pagination";
import view from "../../../assets/images/view.svg";
import update from "../../../assets/images/update.svg";
import del from "../../../assets/images/del.svg";
import { Link, useNavigate } from "react-router-dom";
import Search from "../../../components/search/Search";
import { useEffect, useState } from "react";
import { AlertModal } from "../../../components/modals/AlertModal";
import { api } from "../../../helpers/apiHelper/requestHelper";
import { toastify } from "../../../helpers/toast/toastify";
import { setLoading } from "../../../redux/loader/loadingSlice";
import { useDispatch } from "react-redux";
import { shortFormatDate } from "../../../helpers/dataHelper/dateFormatter";

export default function AnnouncementList({ announcements, refresh }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [filtered, setFiltered] = useState([]);
    const [show, setShow] = useState(false);
    const [ann, setAnn] = useState();
    const [count, setCount] = useState(5);
    const [page, setPage] = useState(1);

    useEffect(() => {
        setFiltered(announcements);
    }, [announcements]);

    const checkExpiry = (exp) => {
        let date1 = new Date(exp).getTime();
        let date2 = new Date().getTime();

        if (date1 > date2) {
            return true;
        } else {
            return false;
        }
    };

    const deleteAnnouncement = async (data) => {
        setShow(true);
        setAnn(data);
    };

    const confirmAction = async () => {
        dispatch(setLoading(true));
        const response = await api("post", "announcements/updateAnnouncement", {
            isDeleted: true,
            id: ann._id,
        });
        dispatch(setLoading(false));
        if (response?.data?.status) {
            refresh();
            toastify("PDPA deleted successfully", "success");
            setShow(false);
        } else {
            toastify(response?.message, "error");
        }
    };

    return (
        <>
            <Search
                userSearch={true}
                filedText="Search PDPA"
                teamDropdown={false}
                filters={false}
                addButton={true}
                addButtonText="Add PDPA"
                buttonRoute={"/add-announcement"}
                setAnnouncements={setFiltered}
                announcements={announcements}
                component={"Announcements"}
            />
            {show && (
                <AlertModal
                    confirmAction={confirmAction}
                    setModalClick={() => setShow(false)}
                    confirmBtn={true}
                    modalTitle={[
                        <h2>
                            Do you really want to delete {ann?.title || "N/A"}
                            <br /> pdpa permanently?
                        </h2>,
                    ]}
                    modalDescription={[]}
                />
            )}
            <div className="responsive-table w-100">
                <h3>PDPA</h3>
                <table className="table">
                    <thead>
                        <tr
                            className="no-shadow"
                            style={{ background: "#f6f6f6" }}
                        >
                            <td colSpan="6" height="16"></td>
                        </tr>
                        <tr>
                            <th>Created Date</th>
                            <th>Title</th>
                            <th className="description">Description</th>
                            <th>From Date</th>
                            <th>To Date</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filtered
                            ?.slice(count * page - count, count * page)
                            ?.map((data, index) => {
                                if (index <= count - 1) {
                                    return (
                                        <>
                                            <tr className="no-shadow">
                                                <td
                                                    colSpan="6"
                                                    height="16"
                                                ></td>
                                            </tr>
                                            <tr key={index}>
                                                <td>
                                                    {shortFormatDate(
                                                        data?.created_at
                                                    )}
                                                </td>
                                                <td>{data?.title}</td>
                                                <td>{data?.description}</td>
                                                <td>
                                                    {shortFormatDate(
                                                        data?.fromDate
                                                    )}
                                                </td>
                                                <td>
                                                    {shortFormatDate(
                                                        data?.toDate
                                                    )}
                                                </td>
                                                <td>
                                                    <div className="flex items-center">
                                                        <div
                                                            onClick={() =>
                                                                navigate(
                                                                    "/announcement-details",
                                                                    {
                                                                        state: {
                                                                            announcement:
                                                                                data,
                                                                        },
                                                                    }
                                                                )
                                                            }
                                                            className="table-action view cursor-pointer"
                                                        >
                                                            <img
                                                                src={view}
                                                                alt="view"
                                                            />
                                                            <span>
                                                                <small>
                                                                    View
                                                                </small>
                                                            </span>
                                                        </div>
                                                        {checkExpiry(
                                                            data?.toDate
                                                        ) && (
                                                            <>
                                                                <div
                                                                    onClick={() =>
                                                                        navigate(
                                                                            "/add-announcement",
                                                                            {
                                                                                state: {
                                                                                    announcement:
                                                                                        data,
                                                                                },
                                                                            }
                                                                        )
                                                                    }
                                                                    className="table-action update cursor-pointer"
                                                                >
                                                                    <img
                                                                        src={
                                                                            update
                                                                        }
                                                                        alt="update"
                                                                    />
                                                                    <span>
                                                                        <small>
                                                                            Update
                                                                        </small>
                                                                    </span>
                                                                </div>
                                                                <div
                                                                    onClick={() =>
                                                                        deleteAnnouncement(
                                                                            data
                                                                        )
                                                                    }
                                                                    className="table-action del cursor-pointer"
                                                                >
                                                                    <img
                                                                        src={
                                                                            del
                                                                        }
                                                                        alt="del"
                                                                    />
                                                                    <span>
                                                                        <small>
                                                                            Delete
                                                                        </small>
                                                                    </span>
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                </td>
                                            </tr>
                                        </>
                                    );
                                }
                            })}
                    </tbody>
                </table>
                <div className="flex pagination-combine">
                    <EntriesSelection
                        setCount={setCount}
                        page={page}
                        count={count}
                        data={filtered}
                    />
                    <Pagination
                        count={count}
                        data={filtered}
                        setPage={setPage}
                        page={page}
                    />
                </div>
            </div>
        </>
    );
}
