import crossIcon from "../../assets/images/cross.svg";
import file from "../../assets/images/fileUpload.svg";
import { useState } from "react";
import { toastify } from "../../helpers/toast/toastify";
import { convertBytesToMbsKbs } from "../../helpers/sizeConverter/sizeConverter";

export const UploadFile = (props) => {
    const [selectedImage, setSelectedImage] = useState(null);
    const submit = () => {
        if (!selectedImage) toastify("Select a file", "error");
        else {
            props.submit(selectedImage);
        }
    };

    return (
        <div className={`modal-wrapper`}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-body">
                        <img
                            src={crossIcon}
                            className="cross-btn"
                            onClick={props.setModalClick}
                            alt="crossIcon"
                        />
                        <div className="modal-forms">
                            <h2>Upload File</h2>
                            <ul className="list-style-none">
                                <li>
                                    <div className="form-group">
                                        <div className="file-upload-modal">
                                            <input
                                                type="file"
                                                name="fileUpload"
                                                onChange={(event) => {
                                                    setSelectedImage(
                                                        event.target.files[0]
                                                    );
                                                }}
                                            />
                                            <section className="flex column-direction items-center content-justify-center">
                                                <img src={file} alt="file" />
                                                {selectedImage && (
                                                    <span>
                                                        {selectedImage.name}{" "}
                                                        <br /> (
                                                        {convertBytesToMbsKbs(
                                                            selectedImage.size
                                                        )}
                                                        )
                                                    </span>
                                                )}
                                                {!selectedImage && (
                                                    <span>
                                                        Click to Upload File
                                                    </span>
                                                )}
                                            </section>
                                        </div>
                                    </div>
                                </li>
                                <li className="flex content-justify-center button-wrapper">
                                    <button
                                        onClick={props.setModalClick}
                                        className="simple-btn cancel"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="simple-btn"
                                        onClick={submit}
                                    >
                                        Upload
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
