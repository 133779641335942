import React from "react";
import { useState } from "react";
import { api } from "../../../helpers/apiHelper/requestHelper";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../redux/loader/loadingSlice";
import { toastify } from "../../../helpers/toast/toastify";
import DatePicker from "react-date-picker";

export default function DateSettings() {
    const [size, setSize] = useState("");
    const [startDate, setStartDate] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        getSettings();
    }, []);

    const getSettings = async () => {
        dispatch(setLoading(true));
        const response = await api("get", "settings/getDateSettings");
        if (response?.status) {
            setStartDate(
                response?.data?.startDate
                    ? new Date(response?.data?.startDate)
                    : null
            );
        } else {
            toastify(response?.message);
        }
        dispatch(setLoading(false));
    };

    const submit = async () => {
        dispatch(setLoading(true));
        const response = await api("post", "settings/update", {
            startDate,
        });
        if (response?.status) {
            toastify("Start Date changed successfully", "success");
        } else {
            toastify(response?.message);
        }
        dispatch(setLoading(false));
    };

    return (
        <>
            <h2 className="flex flex-wrap mini-title items-center">
                Settings / Date Settings
                <button className="simple-btn ml-auto" onClick={submit}>
                    Save
                </button>
            </h2>
            <div className="card">
                <div className="card-title"></div>
                <div className="card-body">
                    <div className="edit-form-wrapper">
                        <ul className="list-style-none flex flex-wrap column-direction">
                            <li>
                                <div className="form-group">
                                    <label htmlFor="">Start Date</label>
                                    <DatePicker
                                        onChange={(date) => setStartDate(date)}
                                        maxDate={
                                            new Date(
                                                new Date().setDate(
                                                    new Date().getDate() - 1
                                                )
                                            )
                                        }
                                        className="form-control"
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                        value={startDate}
                                        format="dd/MM/y"
                                    />
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}
