import "./editUserDetails.scss";
import { useEffect, useState } from "react";
import AddressMultipleFields from "./AddressMultipleFields";
import Multiselect from "multiselect-react-dropdown";
import { missingPopup } from "../../../../helpers/dataHelper/missingData";
import DatePicker from "react-date-picker";
import { Country, State, City } from "country-state-city";
import { api } from "../../../../helpers/apiHelper/requestHelper";
import { toastify } from "../../../../helpers/toast/toastify";
import { useLocation, useNavigate } from "react-router-dom";
import passwordIcon from "../../../../assets/images/password-eye.png";
import addIcon from "../../../../assets/images/add-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../../../redux/auth/authSlice";
import { UNAVAILABLE_COUNTRIES } from "../../../../utils/constants";
import PasswordChecklist from "react-password-checklist";
import { setLoading } from "../../../../redux/loader/loadingSlice";
import ProfileSVG from "../../../../assets/images/profile.svg";

export default function EditUserDetails() {
    const navigate = useNavigate();
    const { state } = useLocation();
    const dispatch = useDispatch();
    const [birthData, setBirthData] = useState();
    const [userName, setUserName] = useState("");
    const [email, setEmail] = useState("");
    const [accountPassword, setAccountPassword] = useState("");
    const [gender, setGender] = useState();
    const [contactNumber, setContactNumber] = useState("");
    const [postal, setPostal] = useState("");
    const [city, setCity] = useState();
    const [country, setCountry] = useState({
        isoCode: "SG",
        name: "Singapore",
    });
    const [address, setAddress] = useState("");
    const [region, setRegion] = useState();
    const [items, setItems] = useState([]);
    const [status, setStatus] = useState();
    const [isDeleted, setIsDeleted] = useState(state?.user?.isDeleted || false);
    const [reAssignLeads, setReAssignLeads] = useState(false);
    const [addCorporateLeads, setAddCorporateLeads] = useState(false);
    const [profilePicture, setProfilePicture] = useState("");
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [showPassword, setShowPassword] = useState(false);
    const [inputFields, setInputFields] = useState([]);
    const [isValidated, setIsValidated] = useState(false);
    const [files, setFiles] = useState(null);
    const [previewImg, setPreviewImg] = useState("");
    const userRoles = useSelector((state) => state.userRoles);
    const u = useSelector((state) => state.auth.user);
    const numberRegex = new RegExp(`^((0|([1-9][0-9]*))([.]|[.][0-9]{1,2})?)?$`);

    useEffect(() => {
        setCountries(
            Country.getAllCountries()
                ?.filter((e) => !UNAVAILABLE_COUNTRIES.includes(e.isoCode))
                ?.map((e) => {
                    return { isoCode: e.isoCode, name: e.name };
                })
        );
    }, []);

    useEffect(() => {
        if (country) {
            setStates(State.getStatesOfCountry(country?.isoCode));
        }
    }, [country]);

    useEffect(() => {
        if (region && country) {
            let nonFiltered = City.getCitiesOfState(
                country?.isoCode,
                region?.isoCode
            );

            const uniqueNames = nonFiltered.reduce((acc, obj) => {
                if (!acc[obj.name]) {
                    acc[obj.name] = true;
                }
                return acc;
            }, {});

            const filteredArray = nonFiltered.filter((obj) => {
                if (uniqueNames[obj.name]) {
                    uniqueNames[obj.name] = false;
                    return true;
                }
                return false;
            });

            setCities(filteredArray);
        }
    }, [region]);

    useEffect(() => {
        if (state?.user) {
            let data = state.user;
            setUserName(data?.username);
            setEmail(data?.email);
            // setAccountPassword(data?.password);
            setGender(data?.gender);
            setBirthData(data?.dob ? new Date(data?.dob) : null);
            setContactNumber(data?.contact);
            setCountryStateCity(data);
            setProfilePicture(data?.profilePicture);
            setAddress(
                data?.addresses && data?.addresses?.length
                    ? data?.addresses[0]
                    : []
            );
            setInputFields(getInputFields(data?.addresses));
            setAddCorporateLeads(data?.addCorporateLeads);
            setReAssignLeads(data?.reAssignLeads);
            setStatus(data?.isActive);
            setIsDeleted(data?.isDeleted);
            setPostal(data?.postalCode);
            setItems(data?.roles);
            setPreviewImg(data?.profile);
        }
    }, [state]);

    const setCountryStateCity = (data) => {
        let allCount = Country.getAllCountries();
        let selectedCount = allCount.find((e) => e.name === data.country);
        setCountry(selectedCount);

        let allCities = City.getCitiesOfCountry(selectedCount?.isoCode);
        let selectedCity = allCities.find((e) => e.name === data.city);
        setCity(selectedCity);

        let allRegions = State.getStatesOfCountry(selectedCount?.isoCode);
        let selectedRegion = allRegions.find((e) => e.name === data.state);
        setRegion(selectedRegion);
    };

    const getInputFields = (data) => {
        if (data?.length > 1) {
            let newData = data.filter((e, i) => i > 0);
            return newData;
        } else {
            return [];
        }
    };

    const showPasswordFun = () => {
        setShowPassword(!showPassword);
    };

    const submit = async () => {
        if (!userName) missingPopup("Username");
        else if (!email) missingPopup("Email");
        else if (!accountPassword && !state?.user) missingPopup("Password");
        else if (items?.length === 0) missingPopup("Role");
        else if (accountPassword && !isValidated)
            toastify(
                "Password must be at least 8 alphanumeric characters including a special character."
            );
        else if (!items) missingPopup("Role");
        else {
            if (inputFields?.length) {
                var allAddresses = inputFields
                    // ?.map((e) => e.address)
                    ?.filter((e) => e !== "");
                allAddresses?.push(address);
            }

            const formData = new FormData();
            formData.append("email", email);
            formData.append("username", userName);
            formData.append("gender", gender ? gender : "");
            formData.append("dob", birthData ? birthData : "");
            formData.append("contact", contactNumber ? contactNumber : "");
            formData.append("country", country?.name ?? "");
            formData.append("city", city?.name ?? "");
            formData.append("postalCode", postal ? postal : "");
            formData.append("state", region?.name ?? "");
            formData.append(
                "addresses",
                JSON.stringify(inputFields?.length ? allAddresses : [address])
            );
            formData.append("roles", JSON.stringify(items));
            formData.append("reAssignLeads", JSON.stringify(reAssignLeads));
            formData.append(
                "addCorporateLeads",
                JSON.stringify(addCorporateLeads)
            );
            formData.append("isActive", status);
            formData.append("password", accountPassword);
            if (state?.user) {
                formData.append("isDeleted", isDeleted);
            }
            if (state?.user) {
                formData.append("id", state?.user?._id);
            }
            if (files) {
                formData.append("profile", files);
            }

            dispatch(setLoading(true));
            const responseExist = await api(
                "get",
                `users/checkIfUserIsDeleted/${email}/${userName}`
            );
            if (responseExist?.data?.isDeleted === true || state?.user) {
                let msg = "User Modified Successfully";
                if (responseExist?.data?.isDeleted === true) {
                    msg = "User Created Successfully";
                    if (!formData.get("id")) {
                        formData.append("id", responseExist.data?._id);
                        formData.append("isDeleted", false);
                        formData.append("updateCreated", true);
                    }
                }

                // dispatch(setLoading(true));
                const response = await api(
                    "postFormData",
                    "users/updateUser",
                    formData
                );
                dispatch(setLoading(false));
                if (response?.status) {
                    toastify(msg, "success");
                    navigate("/users");
                    if (state?.isMe) {
                        navigate("/");
                        dispatch(login(response?.data?.data));
                    }
                } else {
                    toastify(response?.message, "error");
                }
            } else {
                // dispatch(setLoading(true));
                const response = await api(
                    "postFormData",
                    "users/createUser",
                    formData
                );
                dispatch(setLoading(false));
                if (response?.status) {
                    toastify("User Created Successfully", "success");
                    await api("post", "activity/create", {
                        activity: `New User ${userName} has been created.`,
                        user: u._id,
                    });
                    navigate("/users");
                } else {
                    toastify(response?.message, "error");
                }
            }
        }
    };

    const options = [
        "Admin",
        "Lead Coordinator",
        "RM Team Leader",
        "RM Member",
        "Case Manager",
    ];

    const handleSelect = (selectedList) => {
        setItems(selectedList);
        // if ([...selectedList].pop() === "RM Team Leader") setReAssignLeads(true);
    };

    const handleRemove = (selectedList) => {
        setItems(selectedList);
    };

    const uploadFileHandler = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onloadend = (e) => {
            setPreviewImg(reader.result);
        };

        setFiles(file);
    };

    return (
        <>
            <h2 className="flex flex-wrap mini-title">
                <span onClick={() => navigate("/users")}>Users</span> /{" "}
                {state?.user ? "Edit" : "Add"} User
            </h2>
            <div className="card">
                <div className="card-title">
                    <h2>Personal Information</h2>
                </div>
                <div className="card-body">
                    <div className="profile-wrapper">
                        <img
                            src={previewImg ? previewImg : ProfileSVG}
                            className="profileImg"
                            alt=""
                        />
                        <br />
                        <input type="file" onChange={uploadFileHandler}></input>
                    </div>
                    <div className="edit-form-wrapper">
                        <ul className="list-style-none flex flex-wrap">
                            <li>
                                <div className="form-group">
                                    <label htmlFor="">Username *</label>
                                    <input
                                        type="text"
                                        value={userName}
                                        onChange={(e) =>
                                            setUserName(e.target.value)
                                        }
                                        className="form-control mandatory"
                                        placeholder="Username"
                                    />
                                </div>
                            </li>
                            <li>
                                <div className="form-group">
                                    <label htmlFor="">Email *</label>
                                    <input
                                        type="email"
                                        value={email}
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                        className="form-control mandatory"
                                        placeholder="Email"
                                        id="email"
                                    />
                                </div>
                            </li>
                            <li>
                                <div className="form-group">
                                    <label htmlFor="">Account Password *</label>
                                    <div
                                        className={`password ${
                                            showPassword ? "passwordHide" : ""
                                        }`}
                                    >
                                        <input
                                            type={
                                                showPassword
                                                    ? "text"
                                                    : "password"
                                            }
                                            value={accountPassword}
                                            // disabled={
                                            //     state?.user && !showPassword
                                            // }
                                            onChange={(e) => {
                                                setAccountPassword(
                                                    e.target.value
                                                );
                                                // const numberRegex = new RegExp(
                                                //     `[\W_]`
                                                // );

                                                // console.log(
                                                //     "Regex",
                                                //     numberRegex.test(
                                                //         e.target.value
                                                //     )
                                                // );
                                            }}
                                            className="form-control mandatory"
                                            placeholder="Account Password"
                                        />
                                        <img
                                            src={passwordIcon}
                                            onClick={showPasswordFun}
                                            alt="password"
                                        />
                                    </div>
                                    {accountPassword?.length > 0 && (
                                        <PasswordChecklist
                                            // style={{ display: "none" }}
                                            className="mt-10 validator"
                                            rules={[
                                                "minLength",
                                                "specialChar",
                                                "number",
                                                "letter",
                                            ]}
                                            minLength={8}
                                            value={accountPassword}
                                            onChange={(isValid) => {
                                                if (isValid)
                                                    setIsValidated(true);
                                                else setIsValidated(false);
                                            }}
                                        />
                                    )}
                                </div>
                            </li>
                            <li>
                                <div className="form-group">
                                    <label htmlFor="">Gender</label>
                                    <select
                                        name=""
                                        id=""
                                        className="form-control"
                                        value={gender}
                                        onChange={(e) =>
                                            setGender(e.target.value)
                                        }
                                    >
                                        <option value="">Select</option>
                                        <option value="male">Male</option>
                                        <option value="female">Female</option>
                                    </select>
                                </div>
                            </li>
                            <li>
                                <div className="form-group">
                                    <label htmlFor="">Date of Birth</label>
                                    <DatePicker
                                        format="dd/MM/y"
                                        onChange={(e) => {
                                            setBirthData(e);
                                        }}
                                        className="form-control"
                                        value={birthData}
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                    />
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-title">
                    <h2>Contact & Address Information</h2>
                </div>
                <div className="card-body">
                    <div className="edit-form-wrapper">
                        <ul className="list-style-none flex flex-wrap">
                            <li>
                                <div className="form-group">
                                    <label htmlFor="">Contact Number</label>
                                    <input
                                        type="text"
                                        value={contactNumber}
                                        maxLength={15}
                                        onChange={(e) => {
                                            if (
                                                numberRegex.test(
                                                    e.target.value
                                                ) ||
                                                e.target.value === ""
                                            ) {
                                                setContactNumber(
                                                    e.target.value
                                                );
                                            }
                                        }}
                                        className="form-control"
                                        placeholder="Contact no."
                                    />
                                </div>
                            </li>
                            <li>
                                <div className="form-group">
                                    <label htmlFor="">Country</label>
                                    <select
                                        className="form-control"
                                        value={JSON.stringify(country)}
                                        onChange={(e) => {
                                            setCountry(
                                                JSON.parse(e.target.value)
                                            );
                                        }}
                                    >
                                        <option
                                            value={null}
                                            label={"Select a Country"}
                                        >
                                            Select a Country
                                        </option>
                                        {countries?.map((e) => (
                                            <option
                                                value={JSON.stringify(e)}
                                                label={e.name}
                                            >
                                                {e.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </li>
                            <li>
                                <div className="form-group">
                                    <label htmlFor="">State/Region</label>
                                    <select
                                        disabled={country?.isoCode === "SG"}
                                        className="form-control"
                                        value={JSON.stringify(region)}
                                        onChange={(e) => {
                                            setRegion(
                                                JSON.parse(e.target.value)
                                            );
                                        }}
                                    >
                                        <option
                                            value={null}
                                            label={"Select a State"}
                                        >
                                            Select a State
                                        </option>
                                        {states?.map((e) => (
                                            <option
                                                value={JSON.stringify(e)}
                                                label={e.name}
                                            >
                                                {e.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </li>
                            <li>
                                <div className="form-group">
                                    <label htmlFor="">City</label>
                                    <select
                                        disabled={country?.isoCode === "SG"}
                                        className="form-control"
                                        value={JSON.stringify(city)}
                                        onChange={(e) => {
                                            setCity(JSON.parse(e.target.value));
                                        }}
                                    >
                                        <option
                                            value={null}
                                            label={"Select a City"}
                                        >
                                            Select a City
                                        </option>
                                        {cities?.map((e) => (
                                            <option
                                                value={JSON.stringify(e)}
                                                label={e.name}
                                            >
                                                {e.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </li>
                            <li>
                                <div className="form-group">
                                    <label htmlFor="">Postal Code</label>
                                    <input
                                        type="text"
                                        value={postal}
                                        onChange={(e) => {
                                            if (
                                                numberRegex.test(
                                                    e.target.value
                                                ) ||
                                                e.target.value === ""
                                            ) {
                                                setPostal(e.target.value);
                                            }
                                        }}
                                        className="form-control"
                                        placeholder="Postal Code"
                                    />
                                </div>
                            </li>

                            <li>
                                <div className="form-group">
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                        }}
                                    >
                                        <label htmlFor="" className="flex">
                                            Address 1
                                            {inputFields.length === 0 && (
                                                <button
                                                    className="add-address-btn"
                                                    onClick={() => {
                                                        setInputFields([
                                                            ...inputFields,
                                                            "",
                                                        ]);
                                                    }}
                                                >
                                                    <img
                                                        src={addIcon}
                                                        alt="addIcon"
                                                    />
                                                </button>
                                            )}
                                        </label>
                                    </div>
                                    <input
                                        type="text"
                                        value={address}
                                        onChange={(e) =>
                                            setAddress(e.target.value)
                                        }
                                        className="form-control"
                                        placeholder="Address 1"
                                    />
                                </div>
                            </li>

                            <li className="w-100">
                                <AddressMultipleFields
                                    inputFields={inputFields}
                                    setInputFields={setInputFields}
                                />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            {userRoles?.currentRole === "Admin" && (
                <div className="card">
                    <div className="card-title">
                        <h2>Role</h2>
                    </div>
                    <div className="card-body">
                        <div className="edit-form-wrapper">
                            <ul className="list-style-none flex flex-wrap">
                                <li>
                                    <div className="form-group">
                                        <label htmlFor="">Roles *</label>
                                        <Multiselect
                                            className="mandatory-multi"
                                            isObject={false}
                                            showCheckbox
                                            options={options}
                                            selectedValues={items}
                                            onSelect={handleSelect}
                                            onRemove={handleRemove}
                                            displayValue="name"
                                            selectionLimit={10}
                                        />
                                    </div>
                                </li>
                                <li>
                                    <div className="form-group flex column-direction">
                                        <label
                                            className="check-box"
                                            htmlFor="add-corporate"
                                        >
                                            <input
                                                id="add-corporate"
                                                type="checkbox"
                                                value={addCorporateLeads}
                                                checked={addCorporateLeads}
                                                onChange={(e) =>
                                                    setAddCorporateLeads(
                                                        e.target.checked
                                                    )
                                                }
                                            />
                                            <span>
                                                Power to add Corporate <br />
                                                Leads
                                            </span>
                                        </label>
                                        <label
                                            className="check-box"
                                            htmlFor="re-assign"
                                        >
                                            <input
                                                id="re-assign"
                                                type="checkbox"
                                                checked={reAssignLeads}
                                                value={reAssignLeads}
                                                onChange={(e) =>
                                                    setReAssignLeads(
                                                        e.target.checked
                                                    )
                                                }
                                            />

                                            <span>
                                                Power re-assign ALL leads
                                            </span>
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <div className="form-group">
                                        <label>Status</label>
                                        <label
                                            // htmlFor="status"
                                            className="switch-wrap"
                                        >
                                            <input
                                                onChange={(e) => {
                                                    setStatus(e.target.checked);
                                                }}
                                                checked={status}
                                                // defaultChecked={status || false}
                                                type="checkbox"
                                                // id="status"
                                            />
                                            <span></span>
                                        </label>
                                    </div>
                                </li>
                                {state?.user?.isDeleted && (
                                    <li>
                                        <div className="form-group">
                                            <label htmlFor="">Deleted</label>
                                            <label
                                                htmlFor="isDeleted"
                                                className="switch-wrap"
                                            >
                                                <input
                                                    onChange={(e) => {
                                                        setIsDeleted(
                                                            e.target.checked
                                                        );
                                                    }}
                                                    value={isDeleted}
                                                    defaultChecked={isDeleted}
                                                    type="checkbox"
                                                    id="isDeleted"
                                                />
                                                <span></span>
                                            </label>
                                        </div>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            )}
            <div className="flex items-start content-justify-center">
                <button onClick={submit} className="simple-btn submit">
                    Submit
                </button>
            </div>
        </>
    );
}
