import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { api } from "../../helpers/apiHelper/requestHelper";
import { toastify } from "../../helpers/toast/toastify";
import { shortFormatDate } from "../../helpers/dataHelper/dateFormatter";
import fileIcon from "../../assets/images/file-icon.svg";

export default function AnnouncementDetails() {
    const { state } = useLocation();
    console.log({ state });
    const navigate = useNavigate();
    const role = useSelector((state) => state.userRoles.currentRole);

    const acknowledge = async () => {
        const response = await api("post", "announcements/acknowledge", {
            announcementId: state?.announcement?._id,
        });

        if (response?.data?.status) {
            toastify("Acknowledged!", "success");
            navigate("/announcements-list");
        } else {
            toastify(response?.messgae, "error");
            navigate("/announcements-list");
        }
    };

    return (
        <>
            <h4 className="flex flex-wrap mini-title">
                <span onClick={() => navigate("/announcements-list")}>
                    PDPA
                </span>{" "}
                / PDPA Details
            </h4>
            <div className="responsive-table detail-table announcement">
                <table className="table">
                    <tr>
                        <th>PDPA Title:</th>
                        <td>{state?.announcement?.title}</td>
                    </tr>
                    <tr>
                        <th>From Date: </th>
                        <td>
                            {shortFormatDate(state?.announcement?.fromDate)}
                        </td>
                    </tr>
                    <tr>
                        <th>To Date: </th>
                        <td>{shortFormatDate(state?.announcement?.toDate)}</td>
                    </tr>
                    <tr>
                        <th>Description: </th>
                        <td>{state?.announcement?.description}</td>
                    </tr>
                    <tr>
                        <th className="flex">Attachments: </th>
                        <td>
                            {state?.announcement?.attachments?.map((att) => (
                                <div
                                    className="cases-doc-list "
                                    style={{ padding: 0 }}
                                >
                                    <ul className="p-0 m-0 mb-0">
                                        <div className="flex column-direction case_input_des ">
                                            <li className="p-0">
                                                <span
                                                    style={{
                                                        padding:
                                                            "20px 40px 20px 20px",
                                                    }}
                                                >
                                                    <img
                                                        src={fileIcon}
                                                        className="cursor-pointer"
                                                        alt="pdfIcon"
                                                        onClick={() => {
                                                            window.open(
                                                                att.url
                                                            );
                                                        }}
                                                    />
                                                    <div
                                                        className="file_name cursor-pointer"
                                                        onClick={() => {
                                                            window.open(
                                                                att.url
                                                            );
                                                        }}
                                                    >
                                                        {att?.filename}
                                                    </div>
                                                </span>
                                            </li>
                                        </div>
                                    </ul>
                                </div>
                            ))}
                        </td>
                    </tr>
                    <tr>
                        <th></th>
                        <td>
                            <button
                                className="simple-btn"
                                onClick={acknowledge}
                            >
                                Acknowledgement
                            </button>
                        </td>
                    </tr>
                </table>
            </div>
        </>
    );
}
