import React from "react";
import { useState } from "react";
import { api } from "../../../helpers/apiHelper/requestHelper";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../redux/loader/loadingSlice";
import { toastify } from "../../../helpers/toast/toastify";

export default function UploadSize() {
    const [size, setSize] = useState("");
    const [space, setSpace] = useState(0);
    const dispatch = useDispatch();

    useEffect(() => {
        getSettings();
        getStorage();
    }, []);

    const getSettings = async () => {
        dispatch(setLoading(true));
        const response = await api("get", "settings/index");
        if (response?.status) {
            setSize(response?.data?.fileSize);
        } else {
            toastify(response?.message);
        }
        dispatch(setLoading(false));
    };

    const getStorage = async () => {
        dispatch(setLoading(true));
        const response = await api("get", "uploads/calculateSpace");

        if (response?.status) {
            if (response?.data?.space > 0) {
                setSpace(
                    ((response?.data?.space || 0) / (1024 * 1024)).toFixed(2)
                );
            } else {
                setSpace(0);
            }
        } else {
            toastify(response?.message);
        }
        dispatch(setLoading(false));
    };

    const submit = async () => {
        dispatch(setLoading(true));
        const response = await api("post", "settings/update", {
            fileSize: size,
        });
        if (response?.status) {
            toastify("File size changed successfully", "success");
        } else {
            toastify(response?.message);
        }
        dispatch(setLoading(false));
    };

    const clearS3 = async () => {
        dispatch(setLoading(true));
        const response = await api("get", "cases/deleteS3Data");
        console.log(response);
        if (response?.status) {
            getStorage();
            toastify("Files deleted successfully.", "success");
        } else {
            toastify(response?.message);
        }
        dispatch(setLoading(false));
    };

    return (
        <>
            <h2 className="flex flex-wrap mini-title items-center">
                Settings / File Uploads
                <button className="simple-btn ml-auto" onClick={submit}>
                    Save
                </button>
            </h2>
            <div className="card">
                <div className="card-title"></div>
                <div className="card-body">
                    <div className="edit-form-wrapper">
                        <ul className="list-style-none flex flex-wrap column-direction">
                            <li>
                                <div className="form-group">
                                    <label htmlFor="">
                                        Set file size limit when user upload a
                                        file
                                    </label>
                                    <div className="flex items-center">
                                        <input
                                            type="text"
                                            value={size}
                                            onChange={(e) =>
                                                setSize(e.target.value)
                                            }
                                            className="form-control mandatory"
                                            placeholder="Enter size in MBs"
                                        />
                                        <div className="flex w-50 ml-10 bold">
                                            MB
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="form-group">
                                    <label htmlFor="">Total Storage Used</label>
                                    <div className="flex items-center">
                                        <input
                                            type="text"
                                            value={space}
                                            disabled
                                            className="form-control mandatory"
                                            placeholder="Enter size in MBs"
                                        />
                                        <div className="flex w-50 ml-10 bold">
                                            MB
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="mt-20">
                                <button
                                    className="simple-btn"
                                    onClick={() => clearS3()}
                                >
                                    Perform CRON Job
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}
