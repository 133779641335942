import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    value: [],
};

export const bEntitySlice = createSlice({
    name: "bEntitySlice",
    initialState,
    reducers: {
        setBusinessEntities: (state, action) => {
            state.value = action.payload;
        },
    },
});

export const { setBusinessEntities } = bEntitySlice.actions;

export default bEntitySlice.reducer;
