import React, { useEffect, useState } from "react";
import update from "../../../../assets/images/update.svg";
import del from "../../../../assets/images/del.svg";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import EntriesSelection from "../../../../components/pagination/EntriesSelection";
import Pagination from "../../../../components/pagination/Pagination";
import { AlertModal } from "../../../../components/modals/AlertModal";
import { setLoading } from "../../../../redux/loader/loadingSlice";
import { toastify } from "../../../../helpers/toast/toastify";
import { useDispatch } from "react-redux";
import { api } from "../../../../helpers/apiHelper/requestHelper";
import { shortFormatDate } from "../../../../helpers/dataHelper/dateFormatter";

export default function IndividualEntityList(props) {
    const reduxData = useSelector((state) => state);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [count, setCount] = useState(5);
    const [page, setPage] = useState(1);
    const [selectedEntity, setSelectedEntity] = useState();
    const [show, setShow] = useState(false);
    const [filtered, setFiltered] = useState([]);

    useEffect(() => {
        setFiltered(reduxData?.individualEntities?.value);
    }, [reduxData?.individualEntities?.value]);

    const deleteEntity = async (data) => {
        setShow(true);
        setSelectedEntity(data);
    };

    const confirmAction = async () => {
        dispatch(setLoading(true));
        const response = await api("post", "individualEntity/update", {
            isDeleted: true,
            id: selectedEntity._id,
        });
        dispatch(setLoading(false));
        if (response?.data?.status) {
            props?.getEntities();
            toastify("Entity deleted successfully", "success");
            setShow(false);
        } else {
            toastify(response?.message, "error");
        }
    };

    const getHeadings = () => {
        if (reduxData?.userRoles.currentRole === "Lead Coordinator") {
            return (
                <>
                    <th>Name</th>
                    <th>Contact Number</th>
                    <th>Email</th>
                    <th>Companies</th>
                    <th>Action</th>
                </>
            );
        } else {
            return (
                <>
                    <th>Name</th>
                    <th>Contact</th>
                    <th>Email</th>
                    <th>NRIC</th>
                    <th>Gender</th>
                    <th>Date of birth</th>
                    <th>Postal Code</th>
                    <th>City</th>
                    <th>State/Region</th>
                    <th>Country</th>
                    <th>Address</th>
                    <th>Action</th>
                </>
            );
        }
    };

    const getTableData = (data) => {
        if (reduxData?.userRoles.currentRole === "Lead Coordinator") {
            return (
                <>
                    <td>{data?.firstName + " " + data?.lastName}</td>
                    <td>{data?.contact}</td>
                    <td>{data?.email}</td>
                    <td>
                        {[...reduxData?.businessEntities?.value]
                            ?.filter(
                                (e) => e?.representative?._id === data?._id
                            )
                            ?.map((e) => (
                                <div>{e?.name}</div>
                            ))}
                    </td>
                </>
            );
        } else {
            return (
                <>
                    <td>{data?.firstName + " " + data?.lastName}</td>
                    <td>{data?.contact}</td>
                    <td>{data?.email}</td>
                    <td>{data?.nric}</td>
                    <td>{data?.gender}</td>
                    <td>{shortFormatDate(data?.dob)}</td>
                    <td>{data?.postalCode}</td>
                    <td>{data?.city}</td>
                    <td>{data?.state}</td>
                    <td>{data?.country}</td>
                    <td>
                        {data?.addresses?.map((e) => (
                            <div>{e}</div>
                        ))}
                    </td>
                </>
            );
        }
    };

    return (
        <>
            <div
                className={`responsive-table individuallist ${
                    reduxData?.userRoles?.currentRole !== "Lead Coordinator"
                        ? " accordion-list "
                        : ""
                } w-100`}
            >
                {show && (
                    <AlertModal
                        confirmAction={confirmAction}
                        setModalClick={() => setShow(false)}
                        confirmBtn={true}
                        modalTitle={[
                            <h2>
                                Do you really want to delete{" "}
                                {selectedEntity?.firstName +
                                    " " +
                                    selectedEntity?.lastName || "N/A"}
                                <br /> Entity permanently?
                            </h2>,
                        ]}
                        modalDescription={[]}
                    />
                )}
                <table className="table">
                    <thead>
                        <tr
                            className="no-shadow"
                            style={{ background: "#f6f6f6" }}
                        >
                            <td colSpan="12" height="16"></td>
                        </tr>
                        <tr>{getHeadings()}</tr>
                    </thead>
                    <tbody>
                        {filtered
                            ?.filter((e) => {
                                if (props.search !== "") {
                                    if (
                                        e?.firstName
                                            ?.toLowerCase()
                                            ?.includes(props.search) ||
                                        e?.lastName
                                            ?.toLowerCase()
                                            ?.includes(props.search) ||
                                        e?.contact
                                            ?.toLowerCase()
                                            ?.includes(props.search) ||
                                        e?.email
                                            ?.toLowerCase()
                                            ?.includes(props.search) ||
                                        e?.nric
                                            ?.toLowerCase()
                                            ?.includes(props.search)
                                    ) {
                                        return e;
                                    }
                                } else {
                                    return e;
                                }
                            })
                            ?.filter((e) => {
                                if (props?.dateFilter) {
                                    let d1 = new Date(props.dateFilter);
                                    let d2 = new Date(e?.created_at);
                                    if (
                                        d1.getFullYear() === d2.getFullYear() &&
                                        d1.getMonth() === d2.getMonth() &&
                                        d1.getDate() === d2.getDate()
                                    ) {
                                        return e;
                                    }
                                } else {
                                    return e;
                                }
                            })
                            ?.slice(count * page - count, count * page)
                            ?.map((data) => {
                                return (
                                    <>
                                        <tr className="no-shadow">
                                            <td colSpan="12" height="16"></td>
                                        </tr>
                                        <tr>
                                            {getTableData(data)}
                                            <td>
                                                <div className="flex items-center">
                                                    <button
                                                        className="table-action update cursor-pointer"
                                                        onClick={() => {
                                                            if (
                                                                reduxData
                                                                    ?.userRoles
                                                                    .currentRole ===
                                                                "RM Team Leader"
                                                            ) {
                                                                navigate(
                                                                    "/rm-lead/individual-entity",
                                                                    {
                                                                        state: {
                                                                            entity: data,
                                                                        },
                                                                    }
                                                                );
                                                            } else if (
                                                                reduxData
                                                                    ?.userRoles
                                                                    .currentRole ===
                                                                "RM Member"
                                                            ) {
                                                                navigate(
                                                                    "/rm-member/individual-entity",
                                                                    {
                                                                        state: {
                                                                            entity: data,
                                                                        },
                                                                    }
                                                                );
                                                            } else if (
                                                                reduxData
                                                                    ?.userRoles
                                                                    .currentRole ===
                                                                "Lead Coordinator"
                                                            ) {
                                                                navigate(
                                                                    "/lead-coordinator/individual-entity",
                                                                    {
                                                                        state: {
                                                                            entity: data,
                                                                        },
                                                                    }
                                                                );
                                                            }
                                                        }}
                                                    >
                                                        <img
                                                            src={update}
                                                            alt="update"
                                                        />
                                                        <span>
                                                            <small>
                                                                Update
                                                            </small>
                                                        </span>
                                                    </button>
                                                    <button
                                                        className="table-action del"
                                                        onClick={() =>
                                                            deleteEntity(data)
                                                        }
                                                    >
                                                        <img
                                                            src={del}
                                                            alt="del"
                                                        />
                                                        <span>
                                                            <small>
                                                                Delete
                                                            </small>
                                                        </span>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    </>
                                );
                            })}
                    </tbody>
                </table>
                <div className="flex pagination-combine">
                    <EntriesSelection
                        setCount={setCount}
                        page={page}
                        count={count}
                        data={filtered}
                    />
                    <Pagination
                        count={count}
                        data={filtered}
                        setPage={setPage}
                        page={page}
                    />
                </div>
            </div>
        </>
    );
}
