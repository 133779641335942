import { api } from "../apiHelper/requestHelper";

export const filterDataByDate = async (date, unfilter, role) => {
    let allLeads = [];
    let allCases = [];

    let cResponse;
    if (unfilter) {
        cResponse = await api("get", "cases/unfilteredCases");
    } else {
        cResponse = await api("post", "cases/index");
    }
    if (cResponse?.status) {
        allCases = cResponse?.data;
    }
    if (cResponse?.data?.status) {
        allCases = cResponse?.data?.data;
    }

    let lResponse = await api("post", "leads/index", {
        assignedFromRole: role,
    });
    if (lResponse?.status) {
        allLeads = lResponse?.data?.data;
    }

    let _allLeads = [...allLeads].filter((e) => {
        let d1 = new Date(date);
        let d2 = new Date(e.created_at);

        if (
            d1.getFullYear() === d2.getFullYear() &&
            d1.getMonth() === d2.getMonth() &&
            d1.getDate() === d2.getDate()
        ) {
            return e;
        }
    });

    let _allCases = [...allCases].filter((e) => {
        let d1 = new Date(date);
        let d2 = new Date(e.created_at);
        if (
            d1.getFullYear() === d2.getFullYear() &&
            d1.getMonth() === d2.getMonth() &&
            d1.getDate() === d2.getDate()
        ) {
            return e;
        }
    });

    return { filteredCases: _allCases, filteredLeads: _allLeads };
};
