import { useEffect } from "react";
import { useState } from "react";

export default function HyperlinkModalModal(props) {
    const [name, setName] = useState("");
    const [link, setLink] = useState("");

    useEffect(() => {
        if (props?.hyperlink) {
            setName(props?.hyperlink?.name);
            setLink(props?.hyperlink?.link);
        }
    }, [props?.hyperlink]);

    return (
        <div className={`modal-wrapper inner_modal`}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-body ">
                        <div className="modal-forms">
                            <ul className="list-style-none">
                                <li>
                                    <div className="form-group">
                                        <label htmlFor="">
                                            Text to Display *
                                        </label>
                                        <input
                                            autoFocus
                                            type="text"
                                            value={name}
                                            onChange={(e) =>
                                                setName(e.target.value)
                                            }
                                            className="form-control mandatory"
                                            placeholder="Enter text to display"
                                        />
                                    </div>
                                </li>
                                <li>
                                    <div className="form-group">
                                        <label htmlFor="">Web Address *</label>
                                        <input
                                            type="text"
                                            value={link}
                                            onChange={(e) =>
                                                setLink(e.target.value)
                                            }
                                            className="form-control mandatory"
                                            placeholder="To which URL should this link refer to?"
                                        />
                                    </div>
                                </li>

                                <li>
                                    <div className="flex content-justify-center">
                                        <button
                                            onClick={() => props?.cancel()}
                                            className="modal-btn cancel"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            onClick={() => {
                                                props?.confirm(name, link);
                                            }}
                                            className="modal-btn"
                                        >
                                            Confirm
                                        </button>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
