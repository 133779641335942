import React, { useEffect, useRef, useState } from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel,
} from "react-accessible-accordion";
import CaseDocument from "./CaseDocument";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import addIcon from "../../../assets/images/add-icon.svg";
import AddressMultipleFields from "../../../pages/admin/users/edit-user-details/AddressMultipleFields";
import { api } from "../../../helpers/apiHelper/requestHelper";
import { toastify } from "../../../helpers/toast/toastify";
import { storeLeads } from "../../../redux/leads/leadsSlice";
import { useDispatch } from "react-redux";
import { setRoute } from "../../../redux/route/routeSlice";
import { setUserNotifications } from "../../../redux/notifications/notificationsSlice";
import { getCaseUsers } from "../../../helpers/dataHelper/getCaseUsers";
import { setLoading } from "../../../redux/loader/loadingSlice";
import { formatCurrency } from "../../../helpers/dataHelper/currentFormatter";

export default function EditCase() {
    const { state } = useLocation();
    const navigate = useNavigate();
    const reduxData = useSelector((state) => state);
    const dispatch = useDispatch();
    const [lead, setLead] = useState();
    const [inputFields, setInputFields] = useState([]);
    const [address, setAddress] = useState("");
    const [bEntity, setBEntity] = useState();
    const [iEntity, setIEntity] = useState();
    const [product, setProduct] = useState();
    const numberRegex = new RegExp(`^((0|([1-9][0-9]*))([.]|[.][0-9]{1,2})?)?$`);
    const [loanAmount, setLoanAmount] = useState("0");
    const [advisoryFee, setAdvisoryFee] = useState({
        amount: "0",
        type: "fixed",
    });
    const _product = reduxData?.productForms?.value.find(
        (el) => el._id === product
    );

    useEffect(() => {
        if (lead) {
            if (lead?.entityType === "Business") {
                let data = lead?.businessEntity;
                setBEntity({
                    name: data?.name,
                    email: data?.email,
                    uen: data?.uen,
                });
                setAddress(
                    data?.addresses && data?.addresses?.length
                        ? data?.addresses[0]
                        : []
                );
                setInputFields(getInputFields(data?.addresses));
                setIEntity({ ...lead?.businessEntity?.representative });
            } else {
                let data = { ...lead?.individualEntity };

                setIEntity({ ...lead?.individualEntity });
                setAddress(
                    data?.addresses && data?.addresses?.length
                        ? data?.addresses[0]
                        : []
                );
                setInputFields(getInputFields(data?.addresses));
            }
        }
    }, [lead]);

    useEffect(() => {
        if (state?.case) {
            setLead(state?.case?.lead);
            setProduct(state?.case?.product?._id);
            setLoanAmount(state?.case?.loanAmount || "0");
            if (state?.case?.advisoryFee) {
                setAdvisoryFee(state?.case?.advisoryFee);
            } else {
                setAdvisoryFee({
                    amount: state?.case?.product?.price,
                    type: state?.case?.product?.advisoryFee,
                });
            }
        }
    }, [state?.case]);

    const getInputFields = (data) => {
        if (data?.length > 1) {
            let newData = data.filter((e, i) => i > 0);
            return newData;
        } else {
            return [];
        }
    };

    const getLeads = async () => {
        let response = await api("post", "leads/index", {
            assignedFromRole: reduxData?.userRoles?.currentRole,
        });
        if (response?.status) {
            // if(response?.data?.length>0){
            //     let data = response?.data?.filter(e=>{
            //         if(e.entityType==="Business"){

            //         }
            //     })

            // }

            dispatch(storeLeads(response?.data?.data?.reverse()));
        } else {
            toastify(response?.message, "error");
        }
    };

    const setSidebar = () => {
        //SIDEBAR start
        //Remove Active class
        dispatch(setRoute("Leads"));
        let activeSpan = document.querySelector("a.active");
        if (activeSpan) {
            activeSpan.classList.remove("active");
        }
        //Add Active class
        let sidebar = document.querySelector(".sidebar-wrapper");
        const spanElements = sidebar.querySelectorAll("span");
        let targetSpan;
        spanElements.forEach((span) => {
            if (span.innerText === "All Leads") {
                targetSpan = span;
                return;
            }
        });
        if (targetSpan) {
            targetSpan.classList.toggle("active");
        }
        //SIDEBAR end
    };

    useEffect(() => {
        getLeads();
    }, []);

    const submit = async (docs, comments) => {
        if (state?.case) {
            let data = {
                lead: lead?._id,
                cmPdpa: false,
                rmPdpa: false,
                product: product,
                decision: state?.case?.decision,
                comments,
                caseDocuments: docs,
                loanAmount: loanAmount.replace(",", ""),
                advisoryFee,
                id: state?.case?._id,
            };

            dispatch(setLoading(true));
            const response = await api("post", "cases/update", data);

            if (response?.data?.status) {
                let allUsers = getCaseUsers(state?.case);
                if (!allUsers.includes(reduxData?.auth?.user?._id)) {
                    allUsers.push(reduxData?.auth?.user?._id);
                }
                await api("post", "activity/create", {
                    activity: `${response?.data?.data?.identifier} - has been updated.`,
                    users: allUsers,
                });
                dispatch(setLoading(false));

                toastify("Case updated successfully", "success");
                if (state?.samePage) {
                    navigate(-1);
                } else {
                    navigate("/all-cases");
                }
            } else {
                dispatch(setLoading(false));
                toastify(response?.message, "error");
            }
        } else {
            let data = {
                lead: lead?._id,
                status: "Draft",
                product: product,
                comments,
                caseDocuments: docs,
                loanAmount: loanAmount.replace(",", ""),
                advisoryFee,
                logs: [
                    {
                        date: new Date(),
                        operator: reduxData?.auth?.user?._id,
                        comment: "Created a case.",
                    },
                ],
            };
            dispatch(setLoading(true));
            const response = await api("post", "cases/create", data);
            dispatch(setLoading(false));

            if (response?.data?.status) {
                let allUsers = getCaseUsers(state?.case);
                if (!allUsers.includes(reduxData?.auth?.user?._id)) {
                    allUsers.push(reduxData?.auth?.user?._id);
                }
                await api("post", "activity/create", {
                    activity: `${response?.data?.data?.identifier} - Created a case.`,
                    users: allUsers,
                });
                const response2 = await api(
                    "post",
                    "notifications/getNotificationsById"
                );

                if (response2?.status) {
                    dispatch(
                        setUserNotifications(response2?.data?.data?.reverse())
                    );
                }
                toastify("Case created successfully", "success");
                navigate(-1);
            } else {
                toastify(response?.message, "error");
            }
        }
    };

    const setAmount = (e) => {
        let value = e.target.value;
        if (value !== "00") {
            if (advisoryFee.type === "percentage") {
                if (
                    (numberRegex.test(value) &&
                        parseFloat(value) <= 100 &&
                        parseFloat(value) >= 0) ||
                    value === ""
                ) {
                    if (
                        (value.length === 3 &&
                            value.charAt(1) === "." &&
                            value.charAt(2) === ".") ||
                        (value.length === 2 &&
                            value.charAt(0) === "0" &&
                            value.charAt(1) !== "0" &&
                            value.charAt(1) !== ".") ||
                        value?.match(/\./g)?.length === 2
                    ) {
                        return;
                    } else {
                        setAdvisoryFee({
                            type: advisoryFee?.type,
                            amount: value,
                        });
                    }
                }
            } else {
                if ((numberRegex.test(value))) {
                    setAdvisoryFee({
                        type: advisoryFee?.type,
                        amount: value,
                    });
                }
            }
        }
    };

    return (
        <>
            <h4 className="flex flex-wrap mini-title">
                <span
                    onClick={() =>
                        navigate(
                            "/" +
                                window.location.pathname.split("/")[1] +
                                "/all-cases"
                        )
                    }
                >
                    Cases /{" "}
                </span>{" "}
                {state?.case ? "Edit" : "Add"} Case
            </h4>
            <div className="card">
                <div className="card-body">
                    {state?.case ? (
                        <div className="edit-form-wrapper disable_input">
                            <ul className="list-style-none flex flex-wrap">
                                <li>
                                    <div className="form-group">
                                        <label htmlFor="">Lead</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            disabled
                                            readOnly
                                            value={state?.case?.lead?.leadType}
                                        />
                                    </div>
                                </li>
                            </ul>
                        </div>
                    ) : (
                        <div className="single-filed items-center flex">
                            <h3>Lead</h3>
                            <select
                                className="form-control"
                                value={JSON.stringify(lead)}
                                onChange={(e) =>
                                    setLead(JSON.parse(e.target.value))
                                }
                            >
                                <option value="" style={{ display: "none" }}>
                                    Select Lead
                                </option>
                                {reduxData?.leads?.value?.map((e) => (
                                    <option value={JSON.stringify(e)}>
                                        {e?.identifier}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}
                    {lead?.entityType === "Business" && (
                        <div className="edit-form-wrapper">
                            <ul className="list-style-none flex flex-wrap">
                                <li>
                                    <div className="form-group">
                                        <label htmlFor="">Company Name</label>
                                        <input
                                            type="text"
                                            placeholder="Company Name"
                                            className="form-control"
                                            disabled
                                            value={bEntity?.name}
                                        />
                                    </div>
                                </li>
                                <li>
                                    <div className="form-group">
                                        <label htmlFor="">Email *</label>
                                        <input
                                            disabled
                                            type="text"
                                            placeholder="Email"
                                            className="form-control"
                                            value={bEntity?.email}
                                        />
                                    </div>
                                </li>
                                <li>
                                    <div className="form-group">
                                        <label htmlFor="">Company UEN</label>
                                        <input
                                            disabled
                                            type="text"
                                            placeholder="Company UEN"
                                            className="form-control"
                                            value={bEntity?.uen}
                                        />
                                    </div>
                                </li>
                            </ul>
                        </div>
                    )}
                    <h2 className="inner-form-title">
                        Contact & Address Information
                    </h2>
                    <div className="edit-form-wrapper">
                        <ul className="list-style-none flex flex-wrap">
                            <li>
                                <div className="form-group">
                                    <label htmlFor="">Representative</label>
                                    <input
                                        type="text"
                                        placeholder="Representative"
                                        className="form-control"
                                        disabled
                                        value={
                                            iEntity?.firstName
                                                ? iEntity?.firstName +
                                                  " " +
                                                  iEntity?.lastName
                                                : "Representative"
                                        }
                                    />
                                </div>
                            </li>
                            <li>
                                <div className="form-group">
                                    <label htmlFor="">Assigned to</label>
                                    <select disabled className="form-control">
                                        <option value="">
                                            {lead?.assignedTo?.username ||
                                                "Assigned To"}
                                        </option>
                                    </select>
                                </div>
                            </li>
                            <li>
                                <div className="form-group">
                                    <label htmlFor="">Postal Code</label>
                                    <input
                                        type="text"
                                        placeholder="Postal Code"
                                        className="form-control"
                                        disabled
                                        value={iEntity?.postalCode}
                                    />
                                </div>
                            </li>
                            <li>
                                <div className="form-group">
                                    <label htmlFor="">Country</label>
                                    <select disabled className="form-control">
                                        <option value="">
                                            {iEntity?.country || "Country"}
                                        </option>
                                    </select>
                                </div>
                            </li>
                            <li>
                                <div className="form-group">
                                    <label htmlFor="">City</label>
                                    <select disabled className="form-control">
                                        <option value="">
                                            {iEntity?.city || "City"}
                                        </option>
                                    </select>
                                </div>
                            </li>
                            <li>
                                <div className="form-group">
                                    <label htmlFor="">State/Region</label>
                                    <select disabled className="form-control">
                                        <option value="">
                                            {iEntity?.state || "State/Region"}
                                        </option>
                                    </select>
                                </div>
                            </li>
                            <li>
                                <div className="form-group">
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                        }}
                                    >
                                        <label htmlFor="" className="flex">
                                            Address 1
                                        </label>
                                    </div>
                                    <input
                                        type="text"
                                        value={address}
                                        className="form-control"
                                        placeholder="Address 1"
                                        disabled
                                    />
                                </div>
                            </li>

                            <li className="w-100">
                                <AddressMultipleFields
                                    inputFields={inputFields}
                                    setInputFields={setInputFields}
                                    hideIcon={true}
                                    hideDeleteIcon={true}
                                    disabled={true}
                                />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="case-accordion">
                <Accordion
                    allowZeroExpanded
                    allowMultipleExpanded={true}
                    preExpanded={["0", "1"]}
                >
                    <AccordionItem uuid="0">
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Choose Product
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <div className="edit-form-wrapper">
                                <ul className="list-style-none flex flex-wrap">
                                    <li>
                                        <div className="form-group">
                                            {state?.case && (
                                                <div className="caseId">
                                                    <div>Case ID: </div>
                                                    <p>
                                                        {
                                                            state?.case
                                                                ?.identifier
                                                        }
                                                    </p>
                                                </div>
                                            )}
                                            <label htmlFor="">
                                                Case Product
                                            </label>
                                            <select
                                                className="form-control"
                                                value={product}
                                                onChange={(e) => {
                                                    setProduct(e.target.value);
                                                    let pr =
                                                        reduxData?.productForms?.value.find(
                                                            (el) =>
                                                                el._id ===
                                                                e.target.value
                                                        );
                                                    setAdvisoryFee({
                                                        amount: pr?.price,
                                                        type: pr?.advisoryFee,
                                                    });
                                                }}
                                            >
                                                <option
                                                    value=""
                                                    style={{ display: "none" }}
                                                >
                                                    Select Case Products
                                                </option>
                                                {reduxData?.productForms?.value?.map(
                                                    (e) => (
                                                        <option value={e._id}>
                                                            {e?.name}
                                                        </option>
                                                    )
                                                )}
                                            </select>
                                        </div>
                                        <div className="form-group mt-20">
                                            <label htmlFor="">
                                                Loan Amount
                                            </label>

                                            <input
                                                placeholder="Input Amount"
                                                className="form-control"
                                                value={loanAmount}
                                                onChange={(e) => {
                                                    if (numberRegex.test(e.target.value)) {
                                                        setLoanAmount(
                                                            e.target.value
                                                        );
                                                    }
                                                }}
                                                onBlur={()=>{
                                                    if(loanAmount?.slice(-1) === "."){
                                                        setLoanAmount(loanAmount.slice(0, -1));
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div className="form-group mt-20">
                                            <label>Advisory Fee *</label>

                                            <label
                                                id="advisory"
                                                className="check-radio"
                                            >
                                                <input
                                                    type="radio"
                                                    name="advisory"
                                                    checked={
                                                        advisoryFee?.type ===
                                                        "fixed"
                                                    }
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setAdvisoryFee({
                                                                amount: "0",
                                                                type: "fixed",
                                                            });
                                                        }
                                                    }}
                                                />
                                                <span>Fixed</span>
                                            </label>
                                            <label
                                                id="advisory"
                                                className="check-radio"
                                            >
                                                <input
                                                    type="radio"
                                                    name="advisory"
                                                    checked={
                                                        advisoryFee?.type ===
                                                        "percentage"
                                                    }
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setAdvisoryFee({
                                                                amount: "0",
                                                                type: "percentage",
                                                            });
                                                        }
                                                    }}
                                                />
                                                <span>Percentage</span>
                                            </label>
                                            <input
                                                type="text"
                                                placeholder="Enter Value"
                                                className="form-control mandatory"
                                                value={advisoryFee?.amount}
                                                onChange={setAmount}
                                                onBlur={()=>{
                                                    if(advisoryFee?.amount.slice(-1) === "."){
                                                        setAdvisoryFee({
                                                            ...advisoryFee,
                                                            amount: advisoryFee?.amount.slice(0,-1)
                                                        })
                                                    }
                                                }}
                                                // maxLength={10}
                                            />
                                        </div>
                                    </li>

                                    <li>
                                        <div className="form-group">
                                            <label htmlFor="">
                                                Product Specification
                                            </label>
                                            <textarea
                                                className="form-control"
                                                placeholder="Product Specification"
                                                disabled
                                                value={_product?.description}
                                            ></textarea>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </AccordionItemPanel>
                    </AccordionItem>
                    {product && (lead || state?.case) && (
                        <CaseDocument
                            selectedCase={state?.case}
                            submit={submit}
                            product={_product}
                            setSidebar={setSidebar}
                        />
                    )}
                </Accordion>
            </div>
        </>
    );
}
