import { useState } from "react";
import { missingPopup } from "../../../helpers/dataHelper/missingData";
import { toastify } from "../../../helpers/toast/toastify";
import addIcon from "../../../assets/images/add-icon.svg";
import crossIcon from "../../../assets/images/cross.svg";

export const InputModal = (props) => {
    const [placeholder, setPlaceholder] = useState("");
    const [label, setLabel] = useState("");
    const [text, setText] = useState("");
    const [options, setOptions] = useState([""]);

    const confirm = () => {
        if (props.type === "input" || props.type === "textarea") {
            if (!label) missingPopup("Label");
            else {
                let data = {
                    label,
                    placeholder,
                };
                props.confirmAction(data);
            }
        } else if (props.type === "file" || props.type === "date") {
            if (!label) {
                missingPopup("Label");
            } else {
                let data = {
                    label,
                };
                props.confirmAction(data);
            }
        } else if (props.type === "heading") {
            if (!text) {
                missingPopup("Text");
            } else {
                let data = {
                    text,
                };
                props.confirmAction(data);
            }
        } else if (props.type === "button") {
            if (!text) missingPopup("Text");
            else {
                let data = {
                    text,
                };
                props.confirmAction(data);
            }
        } else if (
            props.type === "checkbox" ||
            props.type === "radio" ||
            props.type === "select"
        ) {
            if (options.length === 0)
                toastify("Please Enter at least 1 option");
            else {
                let data = {
                    options: options.filter((e) => e !== ""),
                    label,
                };
                props.confirmAction(data);
            }
        }
    };

    return (
        <div className={`modal-wrapper`}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-body">
                        <img
                            src={crossIcon}
                            className="cross-btn"
                            onClick={props.closeModal}
                            alt="crossIcon"
                        />
                        <div className="modal-forms">
                            <h2>{props?.title || "Field Parameters"}</h2>
                            <ul className="list-style-none">
                                {(props.type === "input" ||
                                    props.type === "textarea") && (
                                    <>
                                        <li>
                                            <input
                                                placeholder="Enter Label"
                                                value={label}
                                                onChange={(e) =>
                                                    setLabel(e.target.value)
                                                }
                                                className="form-control"
                                            />
                                        </li>
                                        <li>
                                            <input
                                                placeholder="Enter Placeholder"
                                                value={placeholder}
                                                onChange={(e) =>
                                                    setPlaceholder(
                                                        e.target.value
                                                    )
                                                }
                                                className="form-control"
                                            />
                                        </li>
                                    </>
                                )}
                                {props.type === "heading" && (
                                    <li>
                                        <input
                                            placeholder="Enter Text"
                                            value={text}
                                            onChange={(e) =>
                                                setText(e.target.value)
                                            }
                                            className="form-control"
                                        />
                                    </li>
                                )}

                                {(props.type === "date" ||
                                    props.type === "file" ||
                                    props.type === "checkbox" ||
                                    props.type === "radio" ||
                                    props.type === "select") && (
                                    <li>
                                        <input
                                            placeholder={"Enter Label"}
                                            value={label}
                                            onChange={(e) =>
                                                setLabel(e.target.value)
                                            }
                                            className="form-control"
                                        />
                                    </li>
                                )}
                                {props.type === "button" && (
                                    <li>
                                        <input
                                            placeholder="Enter Button Text"
                                            value={text}
                                            onChange={(e) =>
                                                setText(e.target.value)
                                            }
                                            className="form-control"
                                        />
                                    </li>
                                )}
                                {(props.type === "checkbox" ||
                                    props.type === "radio" ||
                                    props.type === "select") && (
                                    <li>
                                        {options.map((e, index) => (
                                            <div className="form-group flex items-center">
                                                <input
                                                    placeholder="Enter Option"
                                                    value={options[index]}
                                                    onChange={(e) => {
                                                        let arr = [...options];
                                                        arr[index] =
                                                            e.target.value;
                                                        setOptions(arr);
                                                    }}
                                                    className="form-control"
                                                />

                                                {index ===
                                                options?.length - 1 ? (
                                                    <img
                                                        className="add-icon"
                                                        src={addIcon}
                                                        alt="addIcon"
                                                        onClick={() => {
                                                            let arr = [
                                                                ...options,
                                                            ];

                                                            if (
                                                                arr[
                                                                    options.length -
                                                                        1
                                                                ] !== ""
                                                            ) {
                                                                arr.push("");
                                                                setOptions(arr);
                                                            }
                                                        }}
                                                    />
                                                ) : (
                                                    <img
                                                        className="add-icon"
                                                        src={crossIcon}
                                                        alt="crossIcon"
                                                        onClick={() => {
                                                            let arr = [
                                                                ...options,
                                                            ].filter(
                                                                (e, i) =>
                                                                    i !== index
                                                            );

                                                            setOptions(arr);
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        ))}
                                    </li>
                                )}

                                <div className="flex justify-content-center">
                                    <button
                                        onClick={props.closeModal}
                                        className="modal-btn cancel"
                                    >
                                        Cancel
                                    </button>

                                    <button
                                        onClick={confirm}
                                        className="modal-btn"
                                    >
                                        Confirm
                                    </button>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
