import { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { api } from "../../../../helpers/apiHelper/requestHelper";
import AcknowledgementReports from "./AcknowledgementReports";
import { shortFormatDate } from "../../../../helpers/dataHelper/dateFormatter";
import fileIcon from "../../../../assets/images/file-icon.svg";

export default function AnnouncementDetail() {
    const { state } = useLocation();
    const navigate = useNavigate();

    return (
        <>
            <h2 className="flex flex-wrap mini-title">
                <span onClick={() => navigate("/announcements")}>PDPA</span> /
                PDPA Detail
            </h2>
            <div className="card">
                <div className="card-body">
                    <div className="detail-table">
                        <table className="table">
                            <tr>
                                <th>PDPA Title :</th>
                                <td>{state?.announcement?.title}</td>
                            </tr>
                            <tr>
                                <th>From Date: </th>
                                <td>
                                    {shortFormatDate(
                                        state?.announcement?.fromDate
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <th>To Date: </th>
                                <td>
                                    {shortFormatDate(
                                        state?.announcement?.toDate
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <th>Description: </th>
                                <td>{state?.announcement?.description}</td>
                            </tr>
                            <tr>
                                <th className="flex">Attachments: </th>
                                <td>
                                    {state?.announcement?.attachments?.map(
                                        (att) => (
                                            <div
                                                className="cases-doc-list "
                                                style={{ padding: 0 }}
                                            >
                                                <ul className="p-0 m-0 mb-0">
                                                    <div className="flex column-direction case_input_des ">
                                                        <li className="p-0">
                                                            <span
                                                                style={{
                                                                    padding:
                                                                        "20px 40px 20px 20px",
                                                                }}
                                                            >
                                                                <img
                                                                    src={
                                                                        fileIcon
                                                                    }
                                                                    className="cursor-pointer"
                                                                    alt="pdfIcon"
                                                                    onClick={() => {
                                                                        window.open(
                                                                            att.url
                                                                        );
                                                                    }}
                                                                />
                                                                <div
                                                                    className="file_name cursor-pointer"
                                                                    onClick={() => {
                                                                        window.open(
                                                                            att.url
                                                                        );
                                                                    }}
                                                                >
                                                                    {
                                                                        att?.filename
                                                                    }
                                                                </div>
                                                            </span>
                                                        </li>
                                                    </div>
                                                </ul>
                                            </div>
                                        )
                                    )}
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            <AcknowledgementReports users={state?.announcement?.users} />
        </>
    );
}
