import view from "../../../assets/images/view.svg";
import update from "../../../assets/images/update.svg";
import del from "../../../assets/images/del.svg";
import { AlertModal } from "../../../components/modals/AlertModal";
import { useEffect, useState } from "react";
import { UserDetailModal } from "../../../components/modals/UserDetailModal";
import { useNavigate } from "react-router-dom";
import Pagination from "../../../components/pagination/Pagination";
import EntriesSelection from "../../../components/pagination/EntriesSelection";
import Search from "../../../components/search/Search";
import { api } from "../../../helpers/apiHelper/requestHelper";
import { toastify } from "../../../helpers/toast/toastify";
import { setLoading } from "../../../redux/loader/loadingSlice";
import { useDispatch, useSelector } from "react-redux";

export default function UsersList({ users, refresh }) {
    const navigate = useNavigate();
    const [user, setUser] = useState();
    const [filteredUsers, setFilteredUsers] = useState(users);
    const [openAlertModal, setOpenAlertModal] = useState(false);
    const [openUserDetailModal, setOpenUserDetailModal] = useState(false);
    const [deletedId, setDeletedId] = useState("");
    const [count, setCount] = useState(5);
    const [page, setPage] = useState(1);
    const dispatch = useDispatch();
    const u = useSelector((state) => state.auth.user);

    const showUserDetailModal = (data) => {
        setUser(data);
        setOpenUserDetailModal(!openUserDetailModal);
    };

    const confirmAction = async () => {
        dispatch(setLoading(true));
        const response = await api("delete", `users/deleteUser/${deletedId}`);
        if (response?.data?.status) {
            refresh();
            dispatch(setLoading(false));
            toastify("User deleted successfully", "success");
            await api("post", "activity/create", {
                activity: `A user ${user?.username} has been deleted.`,
                user: u._id,
            });
            setOpenAlertModal(false);
        } else {
            toastify(response?.message, "error");
            dispatch(setLoading(false));
        }
    };

    const handleDelete = async (myUser) => {
        setOpenAlertModal(true);
        setDeletedId(myUser.userId);
        setUser(myUser);
    };

    useEffect(() => {
        setFilteredUsers(users);
    }, [users]);

    return (
        <>
            <Search
                userSearch={true}
                teamDropdown={false}
                filters={true}
                addButton={true}
                filedText="Username, email, phone no."
                addButtonText="Add User"
                buttonRoute={"/edit-user-details"}
                setUsers={setFilteredUsers}
                users={users}
                component={"Users"}
            />
            <div className="responsive-table w-100">
                <table className="table">
                    <thead>
                        <tr
                            className="no-shadow"
                            style={{ background: "#f6f6f6" }}
                        >
                            <td colSpan="5" height="16"></td>
                        </tr>
                        <tr>
                            <th>Username</th>
                            <th>Role</th>
                            <th>Email</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredUsers
                            ?.slice(count * page - count, count * page)
                            ?.map((user, index) => {
                                if (index <= count - 1) {
                                    return (
                                        <>
                                            <tr className="no-shadow">
                                                <td
                                                    colSpan="5"
                                                    height="16"
                                                ></td>
                                            </tr>
                                            <tr key={index}>
                                                <td>{user.username}</td>
                                                <td>{user.roles.join(", ")}</td>
                                                <td>{user.email}</td>
                                                <td>
                                                    <span
                                                        className={`status ${
                                                            user.isActive
                                                                ? "active"
                                                                : "nonactive"
                                                        }`}
                                                    >
                                                        {user.isActive
                                                            ? "Active"
                                                            : "Inactive"}
                                                    </span>
                                                </td>
                                                <td>
                                                    <div className="flex items-center">
                                                        <button
                                                            className="table-action view"
                                                            onClick={() =>
                                                                showUserDetailModal(
                                                                    user
                                                                )
                                                            }
                                                        >
                                                            <img
                                                                src={view}
                                                                alt="view"
                                                            />
                                                            <span>
                                                                <small>
                                                                    View
                                                                </small>
                                                            </span>
                                                        </button>
                                                        <div
                                                            onClick={() => {
                                                                navigate(
                                                                    "/edit-user-details",
                                                                    {
                                                                        state: {
                                                                            user,
                                                                        },
                                                                    }
                                                                );
                                                            }}
                                                            className="table-action update cursor-pointer"
                                                        >
                                                            <img
                                                                src={update}
                                                                alt="update"
                                                            />
                                                            <span>
                                                                <small>
                                                                    Update
                                                                </small>
                                                            </span>
                                                        </div>
                                                        <button
                                                            className="table-action del"
                                                            onClick={() =>
                                                                handleDelete(
                                                                    user
                                                                )
                                                            }
                                                        >
                                                            <img
                                                                src={del}
                                                                alt="del"
                                                            />
                                                            <span>
                                                                <small>
                                                                    Delete
                                                                </small>
                                                            </span>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </>
                                    );
                                }
                            })}
                    </tbody>
                </table>
                <div className="flex pagination-combine">
                    <EntriesSelection
                        setCount={setCount}
                        page={page}
                        count={count}
                        data={filteredUsers}
                    />

                    <Pagination
                        count={count}
                        data={filteredUsers}
                        setPage={setPage}
                        page={page}
                    />
                </div>
                {openAlertModal && (
                    <AlertModal
                        confirmAction={confirmAction}
                        setModalClick={() => setOpenAlertModal(false)}
                        confirmBtn={true}
                        modalTitle={[
                            <h2>
                                Do you really want to delete{" "}
                                {"@" + user?.username || "N/A"}
                                {"  "}
                                permanently?
                            </h2>,
                        ]}
                        modalDescription={[]}
                    />
                )}
                {openUserDetailModal && (
                    <UserDetailModal
                        setModalClick={() => setOpenUserDetailModal(false)}
                        confirmBtn={true}
                        user={user}
                        refresh={refresh}
                        turnOff={setOpenUserDetailModal}
                    />
                )}
            </div>
        </>
    );
}
