import "./modals.scss";
import crossIcon from "../../assets/images/cross.svg";
import { useState } from "react";
import { api } from "../../helpers/apiHelper/requestHelper";
import { toastify } from "../../helpers/toast/toastify";
import { useEffect } from "react";
import { missingPopup } from "../../helpers/dataHelper/missingData";
import { useNavigate } from "react-router-dom";
import { setLoading } from "../../redux/loader/loadingSlice";
import { useDispatch } from "react-redux";

export const AddInternalForm = (props) => {
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [temp, setTemp] = useState();
    const [isActive, setIsActive] = useState(true);
    const [pdpa, setPdpa] = useState(true);
    const [templates, setTemplates] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const resetData = () => {
        setName("");
        setDescription("");
        setTemp();
        setIsActive(true);
        setPdpa(true);
        props.setForm();
    };

    const submit = async () => {
        let data = { name, description, template: temp, isActive, pdpa };
        if (!name) missingPopup("Form Name");
        else if (!temp) missingPopup("Form Template");
        else {
            if (props.form) {
                dispatch(setLoading(true));

                const response = await api("post", "forms/update", {
                    ...data,
                    id: props.form._id,
                });
                dispatch(setLoading(false));
                if (response?.data?.status) {
                    toastify("Internal Form Updated Successfully.", "success");
                    resetData();
                    props.setModalClick();
                    props.populateData();
                } else {
                    toastify(response?.message);
                }
            } else {
                dispatch(setLoading(true));
                const response = await api("post", "forms/create", data);
                dispatch(setLoading(false));
                if (response?.data?.status) {
                    toastify("Internal Form Added Successfully.", "success");
                    resetData();
                    props.setModalClick();
                    props.populateData();
                } else {
                    toastify(response?.message);
                }
            }
        }
    };

    const populateData = () => {
        let data = props.form;
        setName(data?.name);
        setDescription(data?.description);
        setTemp(data?.template);
        setPdpa(data?.pdpa);
        setIsActive(data?.isActive);
    };

    useEffect(() => {
        if (props.form) populateData();
    }, [props.form]);

    useEffect(() => {
        getTemplates();
    }, []);

    const getTemplates = async () => {
        const response = await api("get", "templates/index");
        if (response?.status) {
            setTemplates(response?.data?.reverse());
        } else {
            toastify(response?.message);
        }
    };

    return (
        <div className={`modal-wrapper add-product-form`}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-body">
                        <img
                            src={crossIcon}
                            className="cross-btn"
                            onClick={() => {
                                resetData();
                                props.setModalClick();
                            }}
                            alt="crossIcon"
                        />
                        <div className="modal-forms">
                            <h2>Add internal Form</h2>
                            <ul className="list-style-none">
                                <li>
                                    <div className="form-group">
                                        <label htmlFor="">Form Name *</label>
                                        <input
                                            type="text"
                                            placeholder="Form Name"
                                            className="form-control mandatory"
                                            value={name}
                                            onChange={(e) =>
                                                setName(e.target.value)
                                            }
                                        />
                                    </div>
                                </li>
                                <li>
                                    <div className="form-group">
                                        <label htmlFor="">Description</label>
                                        <textarea
                                            placeholder="Description"
                                            className="form-control"
                                            value={description}
                                            onChange={(e) =>
                                                setDescription(e.target.value)
                                            }
                                        />
                                    </div>
                                </li>
                                <li>
                                    <div className="form-group">
                                        <label htmlFor="">
                                            Form Template *
                                        </label>
                                        <select
                                            className="form-control mandatory"
                                            value={temp}
                                            onChange={(e) =>
                                                setTemp(e.target.value)
                                            }
                                        >
                                            <option value="">
                                                Select Template
                                            </option>
                                            {templates
                                                ?.filter((e) => e.isActive)
                                                .map((e) => (
                                                    <option value={e._id}>
                                                        {e.name}
                                                    </option>
                                                ))}
                                        </select>
                                    </div>
                                </li>
                                <li>
                                    <div className="form-group">
                                        <label htmlFor="">Status *</label>
                                        <select
                                            className="form-control mandatory"
                                            value={isActive}
                                            onChange={(e) =>
                                                setIsActive(e.target.value)
                                            }
                                        >
                                            <option value={true}>Active</option>
                                            <option value={false}>
                                                Inactive
                                            </option>
                                        </select>
                                    </div>
                                </li>
                                <li>
                                    <div className="form-group">
                                        <label>PDPA Deletion</label>
                                        <label
                                            htmlFor={"pdpa-id"}
                                            className="switch-wrap"
                                        >
                                            <input
                                                id={"pdpa-id"}
                                                checked={pdpa}
                                                onChange={(e) => {
                                                    setPdpa(e.target.checked);
                                                }}
                                                type="checkbox"
                                            />
                                            <span></span>
                                        </label>
                                    </div>

                                    {/* <label
                                            className="check-box"
                                            htmlFor={"pdpa-id"}
                                        >
                                            <input
                                                id={"pdpa-id"}
                                                type="checkbox"
                                                checked={pdpa}
                                                onChange={(e) => {
                                                    setPdpa(e.target.checked);
                                                }}
                                            />
                                            <span>PDPA Deletion</span>
                                        </label> */}
                                </li>
                                <li className="flex content-justify-center button-wrapper">
                                    <button
                                        onClick={() => {
                                            resetData();
                                            props.setModalClick();
                                        }}
                                        className="simple-btn cancel"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="simple-btn"
                                        onClick={submit}
                                    >
                                        Submit
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
