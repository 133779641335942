import React, { useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { api } from "../../helpers/apiHelper/requestHelper";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../redux/loader/loadingSlice";
import { formatCurrency } from "../../helpers/dataHelper/currentFormatter";
import { shortFormatDate } from "../../helpers/dataHelper/dateFormatter";
import TableComponent from "./TableComponent";
import { useNavigate } from "react-router-dom";

export default function ReportTables({ startDate, endDate }) {
    const [data, setData] = useState();
    const [previousData, setPreviousData] = useState();
    const [quarter, setQuarter] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const role = useSelector((state) => state?.userRoles?.currentRole);
    const reduxData = useSelector((state) => state);

    useEffect(() => {
        dispatch(setLoading(true));
        getData();
    }, []);

    useEffect(() => {
        dispatch(setLoading(true));
        getData();
    }, [endDate]);

    const getYears = () => {
        if (endDate) {
            let currentYear = new Date(endDate).getFullYear();
            let prevYear = currentYear - 1;

            return { currentYear, prevYear };
        } else {
            let currentYear = new Date().getFullYear();
            let prevYear = currentYear - 1;
            return { currentYear, prevYear };
        }
    };

    const getData = async () => {
        const response = await api("post", "cases/getReports", getYears());
        if (response?.data?.status) {
            setData(response?.data?.data);
            setPreviousData(response?.data?.data?.previous);
        }
        dispatch(setLoading(false));
    };

    const getFilterCase = (cases) => {
        return cases?.filter(
            (f) =>
                f.status === "Pending" ||
                f.status === "Submitted" ||
                f.status === "Processing" ||
                f.status === "Rejected" ||
                f.status === "Approved" ||
                f.status === "Completed"
        );
    };

    const getMonthlyCases = (cases, month) => {
        const filteredCases = getFilterCase(cases)?.filter((caseItem) => {
            const caseDate = new Date(caseItem.created_at);
            return caseDate.getMonth() + 1 === month;
        });

        return filteredCases.length || 0;
    };

    const getPercentage = (cases) => {
        let rejections =
            cases?.filter(
                (f) =>
                    f.status === "Rejected" ||
                    (f.status === "Completed" &&
                        f?.financialInstitutes?.length === 0) ||
                    (f.status === "Completed" &&
                        f?.institutes?.find((i) => i.amount && !i.decision)) ||
                    (f.status === "Completed" &&
                        f?.institutes?.find(
                            (i) =>
                                i.amount &&
                                i.approvedAmount &&
                                !i.clientDecision
                        ))
            )?.length || 0;
        let submissions =
            (cases?.filter(
                (f) =>
                    f.status === "Rejected" ||
                    (f.status === "Completed" &&
                        f?.financialInstitutes?.length === 0) ||
                    (f.status === "Completed" &&
                        f?.institutes?.find((i) => i.amount && !i.decision)) ||
                    (f.status === "Completed" &&
                        f?.institutes?.find(
                            (i) =>
                                i.amount &&
                                i.approvedAmount &&
                                !i.clientDecision
                        ))
            )?.length || 0) +
            (cases?.filter(
                (f) =>
                    f.status === "Pending" ||
                    f.status === "Submitted" ||
                    f.status === "Processing" ||
                    f.status === "Approved"
            )?.length || 0) +
            (cases?.filter(
                (f) =>
                    f.status === "Completed" &&
                    !(
                        f.status === "Completed" &&
                        f?.financialInstitutes?.length === 0
                    ) &&
                    !(
                        f.status === "Completed" &&
                        f?.institutes?.find((i) => i.amount && !i.decision)
                    ) &&
                    !(
                        f.status === "Completed" &&
                        f?.institutes?.find(
                            (i) =>
                                i.amount &&
                                i.approvedAmount &&
                                !i.clientDecision
                        )
                    )
            )?.length || 0);
        const percentageVal = (rejections / submissions) * 100 || 0;

        return percentageVal === 0
            ? 0
            : ((rejections / submissions) * 100)?.toFixed(2);
    };

    const getPendingPercentage = (cases) => {
        function formatNumber(num) {
            if (Number.isInteger(num)) {
                return num;
            } else {
                return num.toFixed(2);
            }
        }

        let pending =
            cases?.filter(
                (f) =>
                    f.status === "Pending" ||
                    f.status === "Submitted" ||
                    f.status === "Approved" ||
                    f.status === "Processing"
            )?.length || 0;
        let submissions = cases?.length || 0;
        return formatNumber((pending / submissions) * 100 || 0);
    };

    const getPercentageRm = (team, list) => {
        let a = calculateAdvisoryFee(list);
        let b = calculateAdvisoryFeeTotal(team?.members);
        if (a !== 0 && b !== 0) {
            return `${((a / b) * 100 || 0).toFixed() || 0}%`;
        } else {
            return `0%`;
        }
    };

    const getQuarterlyAdvisory = (cases, q) => {
        let myCases = [];
        if (q === 1) {
            myCases = [...cases]?.filter((e) => {
                const month = new Date(e.created_at).getMonth();
                return month >= 0 && month <= 2;
            });
        } else if (q === 2) {
            myCases = [...cases]?.filter((e) => {
                const month = new Date(e.created_at).getMonth();
                return month >= 3 && month <= 5;
            });
        } else if (q === 3) {
            myCases = [...cases]?.filter((e) => {
                const month = new Date(e.created_at).getMonth();
                return month >= 6 && month <= 8;
            });
        } else if (q === 4) {
            myCases = [...cases]?.filter((e) => {
                const month = new Date(e.created_at).getMonth();
                return month >= 9 && month <= 11;
            });
        }
        let advisoryFee = 0;
        for (let index = 0; index < myCases.length; index++) {
            const cs = myCases[index];
            if (cs?.status === "Completed") {
                if (cs?.advisoryFee?.type === "fixed") {
                    const advFee = parseFloat((cs?.advisoryFee?.amount && cs?.advisoryFee?.amount !== "") ? cs?.advisoryFee?.amount : 0);
                    advisoryFee += advFee;
                } else if (cs?.advisoryFee?.type === "percentage") {
                    let total = 0;
                    cs?.institutes?.forEach(
                        (b) => (total += parseFloat(b.cashOut || "0"))
                    );
                    advisoryFee +=
                        (total * parseFloat((cs?.advisoryFee?.amount && cs?.advisoryFee?.amount !== "") ? cs?.advisoryFee?.amount : 0)) / 100;
                }
            }
        }
        return advisoryFee;
    };

    const getQuarterlyAdvisoryCases = (cases, q) => {
        let myCases = [];
        let filteredCases = [];
        if (q === 1) {
            myCases = [...cases]?.filter((e) => {
                const month = new Date(e.created_at).getMonth();
                return month >= 0 && month <= 2;
            });
        } else if (q === 2) {
            myCases = [...cases]?.filter((e) => {
                const month = new Date(e.created_at).getMonth();
                return month >= 3 && month <= 5;
            });
        } else if (q === 3) {
            myCases = [...cases]?.filter((e) => {
                const month = new Date(e.created_at).getMonth();
                return month >= 6 && month <= 8;
            });
        } else if (q === 4) {
            myCases = [...cases]?.filter((e) => {
                const month = new Date(e.created_at).getMonth();
                return month >= 9 && month <= 11;
            });
        }

        for (let index = 0; index < myCases.length; index++) {
            const cs = myCases[index];
            if (cs?.status === "Completed") {
                filteredCases.push(cs);
            }
        }

        filteredCases = filteredCases?.map((e) => {
            return e._id;
        });

        navigateToCases(filteredCases);
    };

    const calculateAdvisoryFeeCases = (cases) => {
        let filteredCases = [];

        let myCases = [...cases]?.filter((e) => {
            if (quarter) {
                if (quarter) {
                    if (
                        quarter === "1" &&
                        new Date(e.created_at).getMonth() >= 0 &&
                        new Date(e.created_at).getMonth() <= 2
                    ) {
                        return e;
                    } else if (
                        quarter === "2" &&
                        new Date(e.created_at).getMonth() >= 3 &&
                        new Date(e.created_at).getMonth() <= 5
                    ) {
                        return e;
                    } else if (
                        quarter === "3" &&
                        new Date(e.created_at).getMonth() >= 6 &&
                        new Date(e.created_at).getMonth() <= 8
                    ) {
                        return e;
                    } else if (
                        quarter === "4" &&
                        new Date(e.created_at).getMonth() >= 9 &&
                        new Date(e.created_at).getMonth() <= 11
                    ) {
                        return e;
                    }
                }
            } else {
                if (
                    new Date(e?.created_at) >= new Date(startDate) &&
                    new Date(e?.created_at) <= new Date(endDate)
                ) {
                    return { ...e };
                }
            }
        });

        for (let index = 0; index < myCases.length; index++) {
            const cs = myCases[index];
            if (cs?.status === "Completed") {
                filteredCases.push(cs);
            }
        }

        filteredCases = filteredCases?.map((e) => {
            return e._id;
        });

        navigateToCases(filteredCases);
    };

    const calculateAdvisoryFeeYtdCases = (cases) => {
        let myCases = [];

        myCases = [...cases];
        let filteredCases = [];

        for (let index = 0; index < myCases.length; index++) {
            const cs = myCases[index];
            if (cs?.status === "Completed") {
                filteredCases.push(cs);
            }
        }
        filteredCases = filteredCases?.map((e) => {
            return e._id;
        });

        console.log({ filteredCases });

        navigateToCases(filteredCases);
    };

    const calculateAdvisoryFeeTotalCases = (members) => {
        let myMembers = [...members];
        let filteredCases = [];

        for (let index = 0; index < myMembers.length; index++) {
            let memberCases = [];

            memberCases = myMembers[index].cases?.filter((e) => {
                if (quarter) {
                    if (
                        quarter === "1" &&
                        new Date(e.created_at).getMonth() >= 0 &&
                        new Date(e.created_at).getMonth() <= 2
                    ) {
                        return e;
                    } else if (
                        quarter === "2" &&
                        new Date(e.created_at).getMonth() >= 3 &&
                        new Date(e.created_at).getMonth() <= 5
                    ) {
                        return e;
                    } else if (
                        quarter === "3" &&
                        new Date(e.created_at).getMonth() >= 6 &&
                        new Date(e.created_at).getMonth() <= 8
                    ) {
                        return e;
                    } else if (
                        quarter === "4" &&
                        new Date(e.created_at).getMonth() >= 9 &&
                        new Date(e.created_at).getMonth() <= 11
                    ) {
                        return e;
                    }
                } else {
                    if (
                        new Date(e?.created_at) >= new Date(startDate) &&
                        new Date(e?.created_at) <= new Date(endDate)
                    ) {
                        return { ...e };
                    }
                }
            });
            for (let index = 0; index < memberCases.length; index++) {
                const cs = memberCases[index];
                if (cs?.status === "Completed") {
                    filteredCases.push(cs);
                }
            }
        }

        filteredCases = filteredCases?.map((e) => {
            return e._id;
        });

        navigateToCases(filteredCases);
    };

    const redirectMonthlyCases = (cases, month, param) => {
        let filteredCases = [];
        if (param === "total") {
            filteredCases = getFilterCase(cases)?.map((f) => {
                return f._id;
            });
        } else if (param === "totalTeam") {
            cases.forEach((f) => {
                let arr = getFilterCase(f?.cases);
                arr.forEach((element) => {
                    filteredCases.push(element);
                });
            });
            filteredCases = filteredCases?.map((f) => {
                return f._id;
            });
        } else if (param === "quarter") {
            filteredCases = getFilterCase(cases)
                ?.filter((f) => {
                    const currentDate = new Date();
                    const currentQuarterStart = new Date(
                        currentDate.getFullYear(),
                        Math.floor(currentDate.getMonth() / 3) * 3,
                        1
                    );
                    const currentQuarterEnd = new Date(
                        currentDate.getFullYear(),
                        Math.floor(currentDate.getMonth() / 3) * 3 + 3,
                        0
                    );

                    if (
                        // [4, 5, 6].includes(
                        //     new Date(f.created_at).getMonth() + 1
                        // )
                        new Date(f.created_at) >= currentQuarterStart &&
                        new Date(f.created_at) <= currentQuarterEnd
                    ) {
                        return true;
                    } else {
                        return false;
                    }
                })
                ?.map((f) => {
                    return f._id;
                });
        } else if (param === "teamQuarter") {
            cases.forEach((f) => {
                const currentDate = new Date();
                const currentQuarterStart = new Date(
                    currentDate.getFullYear(),
                    Math.floor(currentDate.getMonth() / 3) * 3,
                    1
                );
                const currentQuarterEnd = new Date(
                    currentDate.getFullYear(),
                    Math.floor(currentDate.getMonth() / 3) * 3 + 3,
                    0
                );

                let arr = getFilterCase(f?.cases)?.filter((f) => {
                    if (
                        new Date(f.created_at) >= currentQuarterStart &&
                        new Date(f.created_at) <= currentQuarterEnd
                    ) {
                        return true;
                    } else {
                        return false;
                    }
                });
                arr.forEach((element) => {
                    filteredCases.push(element);
                });
            });
            filteredCases = filteredCases?.map((f) => {
                return f._id;
            });
        } else {
            filteredCases = getFilterCase(cases)
                ?.filter((caseItem) => {
                    const caseDate = new Date(caseItem.created_at);
                    return caseDate.getMonth() + 1 === month;
                })
                ?.map((f) => {
                    return f._id;
                });
        }

        navigateToCases(filteredCases);
    };

    const redirectCases = (cs, status) => {
        let allCases = [];
        if (status.includes("approved")) {
            allCases = cs
                ?.filter(
                    (f) =>
                        f.status === "Completed" &&
                        !(
                            f.status === "Completed" &&
                            f?.financialInstitutes?.length === 0
                        ) &&
                        !(
                            f.status === "Completed" &&
                            f?.institutes?.find((i) => i.amount && !i.decision)
                        ) &&
                        !(
                            f.status === "Completed" &&
                            f?.institutes?.find(
                                (i) =>
                                    i.amount &&
                                    i.approvedAmount &&
                                    !i.clientDecision
                            )
                        )
                )
                ?.map((f) => {
                    return f._id;
                });
        }
        if (status.includes("pending")) {
            allCases = cs
                ?.filter(
                    (f) =>
                        f.status === "Pending" ||
                        f.status === "Submitted" ||
                        f.status === "Processing" ||
                        f.status === "Approved"
                )
                ?.map((f) => {
                    return f._id;
                });
        }
        if (status.includes("rejected")) {
            allCases = cs
                ?.filter(
                    (f) =>
                        f.status === "Rejected" ||
                        (f.status === "Completed" &&
                            f?.financialInstitutes?.length === 0) ||
                        (f.status === "Completed" &&
                            f?.institutes?.find(
                                (i) => i.amount && !i.decision
                            )) ||
                        (f.status === "Completed" &&
                            f?.institutes?.find(
                                (i) =>
                                    i.amount &&
                                    i.approvedAmount &&
                                    !i.clientDecision
                            ))
                )
                ?.map((f) => {
                    return f._id;
                });
        }
        if (status.includes("total")) {
            allCases = cs
                ?.filter(
                    (f) =>
                        f.status === "Rejected" ||
                        (f.status === "Completed" &&
                            f?.financialInstitutes?.length === 0) ||
                        (f.status === "Completed" &&
                            f?.institutes?.find(
                                (i) => i.amount && !i.decision
                            )) ||
                        (f.status === "Completed" &&
                            f?.institutes?.find(
                                (i) =>
                                    i.amount &&
                                    i.approvedAmount &&
                                    !i.clientDecision
                            )) ||
                        (f.status === "Completed" &&
                            !(
                                f.status === "Completed" &&
                                f?.financialInstitutes?.length === 0
                            ) &&
                            !(
                                f.status === "Completed" &&
                                f?.institutes?.find(
                                    (i) => i.amount && !i.decision
                                )
                            ) &&
                            !(
                                f.status === "Completed" &&
                                f?.institutes?.find(
                                    (i) =>
                                        i.amount &&
                                        i.approvedAmount &&
                                        !i.clientDecision
                                )
                            )) ||
                        f.status === "Pending" ||
                        f.status === "Submitted" ||
                        f.status === "Processing" ||
                        f.status === "Approved"
                )
                ?.map((f) => {
                    return f._id;
                });
        }
        if (status.includes("prev_")) {
            navigateToCases(allCases, true);
        } else {
            navigateToCases(allCases);
        }
    };

    const calculateAdvisoryFee = (cases) => {
        let myCases = [];

        myCases = [...cases]?.filter((e) => {
            if (quarter) {
                if (quarter) {
                    if (
                        quarter === "1" &&
                        new Date(e.created_at).getMonth() >= 0 &&
                        new Date(e.created_at).getMonth() <= 2
                    ) {
                        return e;
                    } else if (
                        quarter === "2" &&
                        new Date(e.created_at).getMonth() >= 3 &&
                        new Date(e.created_at).getMonth() <= 5
                    ) {
                        return e;
                    } else if (
                        quarter === "3" &&
                        new Date(e.created_at).getMonth() >= 6 &&
                        new Date(e.created_at).getMonth() <= 8
                    ) {
                        return e;
                    } else if (
                        quarter === "4" &&
                        new Date(e.created_at).getMonth() >= 9 &&
                        new Date(e.created_at).getMonth() <= 11
                    ) {
                        return e;
                    }
                }
            } else {
                if (
                    new Date(e?.created_at) >= new Date(startDate) &&
                    new Date(e?.created_at) <= new Date(endDate)
                ) {
                    return { ...e };
                }
            }
        });

        let advisoryFee = 0;
        for (let index = 0; index < myCases.length; index++) {
            const cs = myCases[index];
            if (cs?.status === "Completed") {
                if (cs?.advisoryFee?.type === "fixed") {
                    const advFee = parseFloat((cs?.advisoryFee?.amount && cs?.advisoryFee?.amount !== "") ? cs?.advisoryFee?.amount : 0);
                    advisoryFee += advFee;
                } else if (cs?.advisoryFee?.type === "percentage") {
                    let total = 0;
                    cs?.institutes?.forEach(
                        (b) => (total += parseFloat(b.cashOut || "0"))
                    );
                    advisoryFee +=
                        (total * parseFloat((cs?.advisoryFee?.amount && cs?.advisoryFee?.amount !== "") ? cs?.advisoryFee?.amount : 0)) / 100;
                }
            }
        }
        
        return advisoryFee;
    };

    const calculateAdvisoryFeeYtd = (cases) => {
        let myCases = [];

        myCases = [...cases];

        let advisoryFee = 0;
        for (let index = 0; index < myCases.length; index++) {
            const cs = myCases[index];
            if (cs?.status === "Completed") {
                if (cs?.advisoryFee?.type === "fixed") {
                    const advFee = parseFloat((cs?.advisoryFee?.amount && cs?.advisoryFee?.amount !== "") ? cs?.advisoryFee?.amount : 0);
                    advisoryFee += advFee;
                } else if (cs?.advisoryFee?.type === "percentage") {
                    let total = 0;
                    cs?.institutes?.forEach(
                        (b) => (total += parseFloat(b.cashOut || "0"))
                    );
                    advisoryFee +=
                        (total * parseFloat((cs?.advisoryFee?.amount && cs?.advisoryFee?.amount !== "") ? cs?.advisoryFee?.amount : 0)) / 100;
                }
            }
        }
        return advisoryFee;
    };

    const navigateToCases = (cases, previous) => {
        let obj = { cases };
        if (previous) {
            obj = { cases, isPrevious: true };
        }
        if (role === "RM Team Leader") {
            navigate(`/rm-lead/all-cases`, {
                state: obj,
            });
        } else if (role === "Admin") {
            navigate(`/all-cases`, {
                state: obj,
            });
        } else {
            navigate(
                `/${role?.toLowerCase()?.split(" ")?.join("-")}/all-cases`,
                {
                    state: obj,
                }
            );
        }
    };

    const checkTeamLead = (teamName, memberId, hideMember) => {
        let found;
        let id = reduxData?.auth?.user?._id;
        reduxData?.allTeams?.value?.teams?.forEach((element) => {
            element?.members?.forEach((e) => {
                if (e?._id === id) {
                    found = element;
                }
            });
        });

        if (
            (found &&
                found?.teamLead?._id === id &&
                teamName === found?.name &&
                role === "RM Team Leader") ||
            role === "Admin" ||
            role === "Case Manager" ||
            (role === "RM Member" &&
                reduxData?.auth?.user?._id === memberId &&
                !hideMember)
        ) {
            return true;
        } else {
            return false;
        }
    };

    const calculateAdvisoryFeeTotal = (members) => {
        let myMembers = [...members];
        let advisoryFee = 0;
        for (let index = 0; index < myMembers.length; index++) {
            let memberCases = [];

            memberCases = myMembers[index].cases?.filter((e) => {
                if (quarter) {
                    if (
                        quarter === "1" &&
                        new Date(e.created_at).getMonth() >= 0 &&
                        new Date(e.created_at).getMonth() <= 2
                    ) {
                        return e;
                    } else if (
                        quarter === "2" &&
                        new Date(e.created_at).getMonth() >= 3 &&
                        new Date(e.created_at).getMonth() <= 5
                    ) {
                        return e;
                    } else if (
                        quarter === "3" &&
                        new Date(e.created_at).getMonth() >= 6 &&
                        new Date(e.created_at).getMonth() <= 8
                    ) {
                        return e;
                    } else if (
                        quarter === "4" &&
                        new Date(e.created_at).getMonth() >= 9 &&
                        new Date(e.created_at).getMonth() <= 11
                    ) {
                        return e;
                    }
                } else {
                    if (
                        new Date(e?.created_at) >= new Date(startDate) &&
                        new Date(e?.created_at) <= new Date(endDate)
                    ) {
                        return { ...e };
                    }
                }
            });
            for (let index = 0; index < memberCases.length; index++) {
                const cs = memberCases[index];
                if (cs?.status === "Completed") {
                    if (cs?.advisoryFee?.type === "fixed") {
                        const advFee = parseFloat((cs?.advisoryFee?.amount && cs?.advisoryFee?.amount !== "") ? cs?.advisoryFee?.amount : 0);
                        advisoryFee += advFee;
                    } else if (cs?.advisoryFee?.type === "percentage") {
                        let total = 0;
                        cs?.institutes?.forEach(
                            (b) => (total += parseFloat(b.cashOut || "0"))
                        );
                        advisoryFee +=
                            (total * parseFloat((cs?.advisoryFee?.amount && cs?.advisoryFee?.amount !== "") ? cs?.advisoryFee?.amount : 0)) / 100;
                    }
                }
            }
        }
        return advisoryFee;
    };

    const totalAdvisoryFee = () => {
        let total = 0;
        for (let i = 0; i < data?.teams.length; i++) {
            const members = data?.teams[i].members;
            total += calculateAdvisoryFeeTotal(members);
        }
        return total;
    };

    const getPercentageTeam = (members) => {
        let a = calculateAdvisoryFeeTotal(members);
        let b = totalAdvisoryFee();

        return `${((a / b) * 100 || 0).toFixed()}%`;
    };

    const getTeamRank = (teamAmounts, teamAmountToFind) => {
        teamAmounts.sort((a, b) => b.amount - a.amount);
        const teamIndex = teamAmounts.findIndex(
            (team) => team.amount === teamAmountToFind
        );
        const rank = teamIndex + 1;
        return rank;
    };

    const getTeamRanking = (team) => {
        let teamAmounts = [];
        for (let index = 0; index < data?.teams?.length; index++) {
            const element = data?.teams[index];
            let amount = calculateAdvisoryFeeTotal(element?.members);
            teamAmounts.push({ name: element.team, amount });
        }
        let thisTeam = teamAmounts?.find((e) => e.name === team.team)?.amount;
        let rank = getTeamRank(teamAmounts, thisTeam);

        return rank;
    };

    const getRmRanking = (id) => {
        let arr = [];
        for (let index = 0; index < data?.teams?.length; index++) {
            const element = data?.teams[index];

            let myMembers = [...element.members];

            for (let index = 0; index < myMembers.length; index++) {
                let memberCases = myMembers[index].cases;

                let advisoryFee = 0;

                memberCases = memberCases?.filter((e) => {
                    if (quarter) {
                        if (quarter) {
                            if (
                                quarter === "1" &&
                                new Date(e.created_at).getMonth() >= 0 &&
                                new Date(e.created_at).getMonth() <= 2
                            ) {
                                return e;
                            } else if (
                                quarter === "2" &&
                                new Date(e.created_at).getMonth() >= 3 &&
                                new Date(e.created_at).getMonth() <= 5
                            ) {
                                return e;
                            } else if (
                                quarter === "3" &&
                                new Date(e.created_at).getMonth() >= 6 &&
                                new Date(e.created_at).getMonth() <= 8
                            ) {
                                return e;
                            } else if (
                                quarter === "4" &&
                                new Date(e.created_at).getMonth() >= 9 &&
                                new Date(e.created_at).getMonth() <= 11
                            ) {
                                return e;
                            }
                        }
                    } else {
                        if (
                            new Date(e?.created_at) >= new Date(startDate) &&
                            new Date(e?.created_at) <= new Date(endDate)
                        ) {
                            return e;
                        }
                    }
                });

                for (let index = 0; index < memberCases.length; index++) {
                    const cs = memberCases[index];

                    if (cs?.status === "Completed") {
                        if (cs?.advisoryFee?.type === "fixed") {
                            const advFee = parseFloat(cs?.advisoryFee?.amount);
                            advisoryFee += advFee;
                        } else if (cs?.advisoryFee?.type === "percentage") {
                            let total = 0;
                            cs?.institutes?.forEach(
                                (b) => (total += parseFloat(b.cashOut || "0"))
                            );
                            advisoryFee +=
                                (total * parseFloat(cs?.advisoryFee?.amount)) /
                                100;
                        }
                    }
                }
                arr.push({
                    amount: advisoryFee,
                    member: myMembers[index]?.member?._id,
                });
            }
        }
        arr.sort((a, b) => b.amount - a.amount);

        let index = arr.findIndex((e) => e.member === id);

        return index + 1;
    };

    const getTotalSubmissions = (members) => {
        let total = 0;
        members.forEach((f) => {
            // total += f.cases.length || 0;
            total += getFilterCase(f?.cases)?.length || 0;
        });
        return total;
    };

    const getTotalSubmissions2 = (members) => {
        let total = 0;
        members.forEach((f) => {
            const currentDate = new Date();
            const currentQuarterStart = new Date(
                currentDate.getFullYear(),
                Math.floor(currentDate.getMonth() / 3) * 3,
                1
            );
            const currentQuarterEnd = new Date(
                currentDate.getFullYear(),
                Math.floor(currentDate.getMonth() / 3) * 3 + 3,
                0
            );

            // let filteredCases = f.cases?.filter((f) => {
            let filteredCases = getFilterCase(f?.cases)?.filter((f) => {
                if (
                    new Date(f.created_at) >= currentQuarterStart &&
                    new Date(f.created_at) <= currentQuarterEnd
                ) {
                    return true;
                } else {
                    return false;
                }
            });
            // let filteredCases = f.cases?.filter((f) => {
            total += filteredCases?.length || 0;
        });
        return total;
    };

    const getT1Total = (type, year) => {
        let total = 0;
        if (type === "approved") {
            total = (year ? previousData : data)?.cases?.filter(
                (f) =>
                    f.status === "Completed" &&
                    !(
                        f.status === "Completed" &&
                        f?.financialInstitutes?.length === 0
                    ) &&
                    !(
                        f.status === "Completed" &&
                        f?.institutes?.find((i) => i.amount && !i.decision)
                    ) &&
                    !(
                        f.status === "Completed" &&
                        f?.institutes?.find(
                            (i) =>
                                i.amount &&
                                i.approvedAmount &&
                                !i.clientDecision
                        )
                    )
            )?.length;
        } else if (type === "rejected") {
            total = (year ? previousData : data)?.cases?.filter(
                (f) =>
                    f.status === "Rejected" ||
                    (f.status === "Completed" &&
                        f?.financialInstitutes?.length === 0) ||
                    (f.status === "Completed" &&
                        f?.institutes?.find((i) => i.amount && !i.decision)) ||
                    (f.status === "Completed" &&
                        f?.institutes?.find(
                            (i) =>
                                i.amount &&
                                i.approvedAmount &&
                                !i.clientDecision
                        ))
            )?.length;
        } else if (type === "pending") {
            total = (year ? previousData : data)?.cases?.filter(
                (f) =>
                    f.status === "Pending" ||
                    f.status === "Submitted" ||
                    f.status === "Processing" ||
                    f.status === "Approved"
            )?.length;
        } else if (type === "total") {
            // total = (year ? previousData : data)?.cases?.filter(
            //     (e) => e.status !== "Draft"
            // )?.length;
            let app = (total =
                (year ? previousData : data)?.cases?.filter(
                    (f) =>
                        f.status === "Completed" &&
                        !(
                            f.status === "Completed" &&
                            f?.financialInstitutes?.length === 0
                        ) &&
                        !(
                            f.status === "Completed" &&
                            f?.institutes?.find((i) => i.amount && !i.decision)
                        ) &&
                        !(
                            f.status === "Completed" &&
                            f?.institutes?.find(
                                (i) =>
                                    i.amount &&
                                    i.approvedAmount &&
                                    !i.clientDecision
                            )
                        )
                )?.length || 0);
            let rej =
                (year ? previousData : data)?.cases?.filter(
                    (f) =>
                        f.status === "Rejected" ||
                        (f.status === "Completed" &&
                            f?.financialInstitutes?.length === 0) ||
                        (f.status === "Completed" &&
                            f?.institutes?.find(
                                (i) => i.amount && !i.decision
                            )) ||
                        (f.status === "Completed" &&
                            f?.institutes?.find(
                                (i) =>
                                    i.amount &&
                                    i.approvedAmount &&
                                    !i.clientDecision
                            ))
                )?.length || 0;
            let pend =
                (year ? previousData : data)?.cases?.filter(
                    (f) =>
                        f.status === "Pending" ||
                        f.status === "Submitted" ||
                        f.status === "Processing" ||
                        f.status === "Approved"
                )?.length || 0;

            total = app + rej + pend;
        }
        return total || 0;
    };

    const getT1TotalPercent = (type, year) => {
        let total = 0;
        let app = (total =
            (year ? previousData : data)?.cases?.filter(
                (f) =>
                    f.status === "Completed" &&
                    !(
                        f.status === "Completed" &&
                        f?.financialInstitutes?.length === 0
                    ) &&
                    !(
                        f.status === "Completed" &&
                        f?.institutes?.find((i) => i.amount && !i.decision)
                    ) &&
                    !(
                        f.status === "Completed" &&
                        f?.institutes?.find(
                            (i) =>
                                i.amount &&
                                i.approvedAmount &&
                                !i.clientDecision
                        )
                    )
            )?.length || 0);
        let rej =
            (year ? previousData : data)?.cases?.filter(
                (f) =>
                    f.status === "Rejected" ||
                    (f.status === "Completed" &&
                        f?.financialInstitutes?.length === 0) ||
                    (f.status === "Completed" &&
                        f?.institutes?.find((i) => i.amount && !i.decision)) ||
                    (f.status === "Completed" &&
                        f?.institutes?.find(
                            (i) =>
                                i.amount &&
                                i.approvedAmount &&
                                !i.clientDecision
                        ))
            )?.length || 0;
        let pend =
            (year ? previousData : data)?.cases?.filter(
                (f) =>
                    f.status === "Pending" ||
                    f.status === "Submitted" ||
                    f.status === "Processing" ||
                    f.status === "Approved"
            )?.length || 0;
        let casePercent = app + rej + pend;

        if (type === "approved") {
            total = app;
        } else if (type === "rejected") {
            total = rej;
        } else if (type === "pending") {
            total = pend;
        } else if (type === "total") {
            total = app + rej + pend;
        } else if (type === "totalPending") {
            total = pend;
        }
        return Math.round((total / casePercent) * 100 * 10) / 10 || 0;
    };

    const getT3Totals = (prop) => {
        let total = 0;
        const reportedCases = getFilterCase(data?.cases);
        if (prop === "1") {
            total = reportedCases?.filter((e) =>
                [1, 2, 3].includes(new Date(e.created_at).getMonth() + 1)
            )?.length;
        } else if (prop === "2") {
            total = reportedCases?.filter((e) =>
                [4, 5, 6].includes(new Date(e.created_at).getMonth() + 1)
            )?.length;
        } else if (prop === "3") {
            total = reportedCases?.filter((e) =>
                [7, 8, 9].includes(new Date(e.created_at).getMonth() + 1)
            )?.length;
        } else if (prop === "4") {
            total = reportedCases?.filter((e) =>
                [10, 11, 12].includes(new Date(e.created_at).getMonth() + 1)
            )?.length;
        } else if (prop === "iTotal") {
            total = reportedCases?.length;
        } else if (prop === "iTotal2") {
            total =
                reportedCases?.filter((f) => {
                    const currentDate = new Date();
                    const currentQuarterStart = new Date(
                        currentDate.getFullYear(),
                        Math.floor(currentDate.getMonth() / 3) * 3,
                        1
                    );
                    const currentQuarterEnd = new Date(
                        currentDate.getFullYear(),
                        Math.floor(currentDate.getMonth() / 3) * 3 + 3,
                        0
                    );

                    if (
                        // [4, 5, 6].includes(
                        //     new Date(f.created_at).getMonth() + 1
                        // )
                        new Date(f.created_at) >= currentQuarterStart &&
                        new Date(f.created_at) <= currentQuarterEnd
                    ) {
                        return true;
                    } else {
                        return false;
                    }
                })?.length || 0;
        } else if (prop === "iTotal3") {
            total = getTotalSubmissions2([{ cases: reportedCases }]);
        }
        return total || 0;
    };

    const columnsT1 = [
        {
            name: "Team",
            wrap: true,
            width: "100px",
            maxWidth: "100px",
            minWidth: "100px",
            selector: (row) => row.team,
            dataField: "team",
        },
        {
            name: "RM",
            wrap: true,
            width: "100px",
            maxWidth: "100px",
            minWidth: "100px",
            selector: (row) => row.rm,
            dataField: "rm",
        },
        {
            name: "Approved/ Invoiced",
            wrap: true,
            width: "100px",
            maxWidth: "100px",
            minWidth: "100px",
            selector: (row) => row.approved,
            dataField: "approved",
        },
        {
            name: "Rejected/ Closed",
            wrap: true,
            width: "100px",
            maxWidth: "100px",
            minWidth: "100px",
            selector: (row) => row.rejected,
            dataField: "rejected",
        },
        {
            name: "Pending/ Processing",
            wrap: true,
            width: "100px",
            maxWidth: "100px",
            minWidth: "100px",
            selector: (row) => row.pending,
            dataField: "pending",
        },
        {
            name: "Total Submission",
            wrap: true,
            width: "100px",
            maxWidth: "100px",
            minWidth: "100px",
            selector: (row) => row.total,
            dataField: "total",
        },
        {
            name: "% Rejection vs Submission",
            wrap: true,
            width: "100px",
            maxWidth: "100px",
            minWidth: "100px",
            selector: (row) => row.percent,
            dataField: "percent",
        },
    ];

    const columnsT2 = [
        {
            name: "Team",
            wrap: true,
            width: "100px",
            maxWidth: "100px",
            minWidth: "100px",
            selector: (row) => row.team,
            dataField: "team",
        },
        {
            name: "RM",
            wrap: true,
            width: "100px",
            maxWidth: "100px",
            minWidth: "100px",
            selector: (row) => row.rm,
            dataField: "rm",
        },
        {
            name: "Approved/Invoiced",
            wrap: true,
            width: "100px",
            maxWidth: "100px",
            minWidth: "100px",
            selector: (row) => row.approved,
            dataField: "approved",
        },
        {
            name: "Rejected/Closed",
            wrap: true,
            width: "100px",
            maxWidth: "100px",
            minWidth: "100px",
            selector: (row) => row.rejected,
            dataField: "rejected",
        },
        {
            name: "Pending/Processing",
            wrap: true,
            width: "100px",
            maxWidth: "100px",
            minWidth: "100px",
            selector: (row) => row.pending,
            dataField: "pending",
        },
        {
            name: "Total Submission",
            wrap: true,
            width: "100px",
            maxWidth: "100px",
            minWidth: "100px",
            selector: (row) => row.total,
            dataField: "total",
        },
        {
            name: "% Rejection vs Submission",
            wrap: true,
            width: "100px",
            maxWidth: "100px",
            minWidth: "100px",
            selector: (row) => row.percent,
            dataField: "percent",
        },
        {
            name: "% Pending (RM)",
            wrap: true,
            width: "100px",
            maxWidth: "100px",
            minWidth: "100px",
            selector: (row) => row.percentRm,
            dataField: "percentRm",
        },
    ];

    const columnsT3 = [
        {
            name: "Team",
            wrap: true,
            width: "100px",
            maxWidth: "100px",
            minWidth: "100px",
            selector: (row) => row.team,
            dataField: "team",
        },
        {
            name: "RM",
            wrap: true,
            width: "100px",
            maxWidth: "100px",
            minWidth: "100px",
            selector: (row) => row.rm,
            dataField: "rm",
        },
        {
            name: "Jan",
            wrap: true,
            width: "100px",
            maxWidth: "100px",
            minWidth: "100px",
            selector: (row) => row.m1,
            dataField: "m1",
        },
        {
            name: "Feb",
            wrap: true,
            width: "100px",
            maxWidth: "100px",
            minWidth: "100px",
            selector: (row) => row.m2,
            dataField: "m2",
        },
        {
            name: "Mar",
            wrap: true,
            width: "100px",
            maxWidth: "100px",
            minWidth: "100px",
            selector: (row) => row.m3,
            dataField: "m3",
        },
        {
            name: "Apr",
            wrap: true,
            width: "100px",
            maxWidth: "100px",
            minWidth: "100px",
            selector: (row) => row.m4,
            dataField: "m4",
        },
        {
            name: "May",
            wrap: true,
            width: "100px",
            maxWidth: "100px",
            minWidth: "100px",
            selector: (row) => row.m5,
            dataField: "m5",
        },
        {
            name: "Jun",
            wrap: true,
            width: "100px",
            maxWidth: "100px",
            minWidth: "100px",
            selector: (row) => row.m6,
            dataField: "m6",
        },
        {
            name: "Jul",
            wrap: true,
            width: "100px",
            maxWidth: "100px",
            minWidth: "100px",
            selector: (row) => row.m7,
            dataField: "m7",
        },
        {
            name: "Aug",
            wrap: true,
            width: "100px",
            maxWidth: "100px",
            minWidth: "100px",
            selector: (row) => row.m8,
            dataField: "m8",
        },
        {
            name: "Sep",
            wrap: true,
            width: "100px",
            maxWidth: "100px",
            minWidth: "100px",
            selector: (row) => row.m9,
            dataField: "m9",
        },
        {
            name: "Oct",
            wrap: true,
            width: "100px",
            maxWidth: "100px",
            minWidth: "100px",
            selector: (row) => row.m10,
            dataField: "m10",
        },
        {
            name: "Nov",
            wrap: true,
            width: "100px",
            maxWidth: "100px",
            minWidth: "100px",
            selector: (row) => row.m11,
            dataField: "m11",
        },
        {
            name: "Dec",
            wrap: true,
            width: "100px",
            maxWidth: "100px",
            minWidth: "100px",
            selector: (row) => row.m12,
            dataField: "m12",
        },
        {
            name: "YTD Individual Submission",
            wrap: true,
            width: "100px",
            maxWidth: "100px",
            minWidth: "100px",
            selector: (row) => row.iSubmission,
            dataField: "iSubmission",
        },
        {
            name: "YTD Team Submission",
            wrap: true,
            width: "100px",
            maxWidth: "100px",
            minWidth: "100px",
            selector: (row) => row.tSubmission,
            dataField: "tSubmission",
        },
        {
            name: `Individual Submission (Current Qtr ${
                getYears().currentYear
            })`,
            wrap: true,
            width: "100px",
            maxWidth: "100px",
            minWidth: "100px",
            selector: (row) => row.iSubmission2,
            dataField: "iSubmission2",
        },
        {
            name: `Team Submission (Current Qtr ${getYears().currentYear})`,
            wrap: true,
            width: "100px",
            maxWidth: "100px",
            minWidth: "100px",
            selector: (row) => row.tSubmission2,
            dataField: "tSubmission2",
        },
    ];

    const columnsT4 = [
        {
            name: "Team",
            wrap: true,
            width: "100px",
            maxWidth: "100px",
            minWidth: "100px",
            selector: (row) => row.team,
            dataField: "team",
        },
        {
            name: "RM",
            wrap: true,
            width: "100px",
            maxWidth: "100px",
            minWidth: "100px",
            selector: (row) => row.rm,
            dataField: "rm",
        },
        {
            name: "$ by RM",
            wrap: true,
            width: "100px",
            maxWidth: "100px",
            minWidth: "100px",
            selector: (row) => row.m1,
            dataField: "m1",
        },
        {
            name: "$ by Team",
            wrap: true,
            width: "100px",
            maxWidth: "100px",
            minWidth: "100px",
            selector: (row) => row.m2,
            dataField: "m2",
        },
        {
            name: "% by RM",
            wrap: true,
            width: "100px",
            maxWidth: "100px",
            minWidth: "100px",
            selector: (row) => row.m3,
            dataField: "m3",
        },
        {
            name: "% by Team",
            wrap: true,
            width: "100px",
            maxWidth: "100px",
            minWidth: "100px",
            selector: (row) => row.m4,
            dataField: "m4",
        },
        {
            name: "Team Ranking",
            wrap: true,
            width: "100px",
            maxWidth: "100px",
            minWidth: "100px",
            selector: (row) => row.m5,
            dataField: "m5",
        },
        {
            name: "RM Ranking",
            wrap: true,
            width: "100px",
            maxWidth: "100px",
            minWidth: "100px",
            selector: (row) => row.m6,
            dataField: "m6",
        },
        {
            name: `1st Qtr ${getYears().currentYear} Revenue`,
            wrap: true,
            width: "100px",
            maxWidth: "100px",
            minWidth: "100px",
            selector: (row) => row.m7,
            dataField: "m7",
        },
        {
            name: `2nd Qtr ${getYears().currentYear} Revenue`,
            wrap: true,
            width: "100px",
            maxWidth: "100px",
            minWidth: "100px",
            selector: (row) => row.m8,
            dataField: "m8",
        },
        {
            name: `3rd Qtr ${getYears().currentYear} Revenue`,
            wrap: true,
            width: "100px",
            maxWidth: "100px",
            minWidth: "100px",
            selector: (row) => row.m9,
            dataField: "m9",
        },
        {
            name: `4th Qtr ${getYears().currentYear} Revenue`,
            wrap: true,
            width: "100px",
            maxWidth: "100px",
            minWidth: "100px",
            selector: (row) => row.m10,
            dataField: "m10",
        },
        {
            name: `YTD ${getYears().currentYear}`,
            wrap: true,
            width: "100px",
            maxWidth: "100px",
            minWidth: "100px",
            selector: (row) => row.m11,
            dataField: "m11",
        },
    ];

    const dataT1 = data?.teams?.map((e) => {
        return {
            team: <div>{e.team}</div>,
            rm: (
                <div>
                    {e.members.map((m) => (
                        <div>{m.member.username}</div>
                    ))}
                </div>
            ),
            approved: (
                <div>
                    {e.members.map((m) => (
                        <div
                            onClick={() => {
                                redirectCases(m?.cases, "approved");
                            }}
                            className={`cursor-pointer ${
                                !checkTeamLead(e.team, m.member?._id) &&
                                "none-pointer-events"
                            }`}
                        >
                            {m?.cases?.filter(
                                (f) =>
                                    f.status === "Completed" &&
                                    !(
                                        f.status === "Completed" &&
                                        f?.financialInstitutes?.length === 0
                                    ) &&
                                    !(
                                        f.status === "Completed" &&
                                        f?.institutes?.find(
                                            (i) => i.amount && !i.decision
                                        )
                                    ) &&
                                    !(
                                        f.status === "Completed" &&
                                        f?.institutes?.find(
                                            (i) =>
                                                i.amount &&
                                                i.approvedAmount &&
                                                !i.clientDecision
                                        )
                                    )
                            )?.length || 0}
                        </div>
                    ))}
                </div>
            ),

            rejected: (
                <div>
                    {e.members.map((m) => (
                        <div
                            onClick={() => {
                                redirectCases(m?.cases, "rejected");
                            }}
                            className={`cursor-pointer ${
                                !checkTeamLead(e.team, m.member?._id) &&
                                "none-pointer-events"
                            }`}
                        >
                            {m?.cases?.filter(
                                (f) =>
                                    f.status === "Rejected" ||
                                    (f.status === "Completed" &&
                                        f?.financialInstitutes?.length === 0) ||
                                    (f.status === "Completed" &&
                                        f?.institutes?.find(
                                            (i) => i.amount && !i.decision
                                        )) ||
                                    (f.status === "Completed" &&
                                        f?.institutes?.find(
                                            (i) =>
                                                i.amount &&
                                                i.approvedAmount &&
                                                !i.clientDecision
                                        ))
                            )?.length || 0}
                        </div>
                    ))}
                </div>
            ),

            pending: (
                <div>
                    {e.members.map((m) => (
                        <div
                            onClick={() => {
                                redirectCases(m?.cases, "pending");
                            }}
                            className={`cursor-pointer ${
                                !checkTeamLead(e.team, m.member?._id) &&
                                "none-pointer-events"
                            }`}
                        >
                            {m?.cases?.filter(
                                (f) =>
                                    f.status === "Pending" ||
                                    f.status === "Submitted" ||
                                    f.status === "Processing" ||
                                    f.status === "Approved"
                            )?.length || 0}
                        </div>
                    ))}
                </div>
            ),
            total: (
                <div>
                    {e.members.map((m) => (
                        <div
                            onClick={() => {
                                redirectCases(m?.cases, "total");
                            }}
                            className={`cursor-pointer ${
                                !checkTeamLead(e.team, m.member?._id) &&
                                "none-pointer-events"
                            }`}
                        >
                            {(m?.cases?.filter(
                                (f) =>
                                    f.status === "Rejected" ||
                                    (f.status === "Completed" &&
                                        f?.financialInstitutes?.length === 0) ||
                                    (f.status === "Completed" &&
                                        f?.institutes?.find(
                                            (i) => i.amount && !i.decision
                                        )) ||
                                    (f.status === "Completed" &&
                                        f?.institutes?.find(
                                            (i) =>
                                                i.amount &&
                                                i.approvedAmount &&
                                                !i.clientDecision
                                        ))
                            )?.length || 0) +
                                (m?.cases?.filter(
                                    (f) =>
                                        f.status === "Pending" ||
                                        f.status === "Submitted" ||
                                        f.status === "Processing" ||
                                        f.status === "Approved"
                                )?.length || 0) +
                                (m?.cases?.filter(
                                    (f) =>
                                        f.status === "Completed" &&
                                        !(
                                            f.status === "Completed" &&
                                            f?.financialInstitutes?.length === 0
                                        ) &&
                                        !(
                                            f.status === "Completed" &&
                                            f?.institutes?.find(
                                                (i) => i.amount && !i.decision
                                            )
                                        ) &&
                                        !(
                                            f.status === "Completed" &&
                                            f?.institutes?.find(
                                                (i) =>
                                                    i.amount &&
                                                    i.approvedAmount &&
                                                    !i.clientDecision
                                            )
                                        )
                                )?.length || 0)}
                        </div>
                    ))}
                </div>
            ),
            percent: (
                <div>
                    {e.members.map((m) => (
                        <div>{getPercentage(m?.cases)}</div>
                    ))}
                </div>
            ),
        };
    });

    const dataT2 = previousData?.teams?.map((e) => {
        return {
            team: <div>{e.team}</div>,
            rm: (
                <div>
                    {e.members.map((m) => (
                        <div>{m.member.username}</div>
                    ))}
                </div>
            ),

            approved: (
                <div>
                    {e.members.map((m) => (
                        <div
                            onClick={() => {
                                redirectCases(m?.cases, "prev_approved");
                            }}
                            className={`cursor-pointer ${
                                !checkTeamLead(e.team, m.member?._id) &&
                                "none-pointer-events"
                            }`}
                        >
                            {m?.cases?.filter(
                                (f) =>
                                    f.status === "Completed" &&
                                    !(
                                        f.status === "Completed" &&
                                        f?.financialInstitutes?.length === 0
                                    ) &&
                                    !(
                                        f.status === "Completed" &&
                                        f?.institutes?.find(
                                            (i) => i.amount && !i.decision
                                        )
                                    ) &&
                                    !(
                                        f.status === "Completed" &&
                                        f?.institutes?.find(
                                            (i) =>
                                                i.amount &&
                                                i.approvedAmount &&
                                                !i.clientDecision
                                        )
                                    )
                            )?.length || 0}
                        </div>
                    ))}
                </div>
            ),

            rejected: (
                <div>
                    {e.members.map((m) => (
                        <div
                            onClick={() => {
                                redirectCases(m?.cases, "prev_rejected");
                            }}
                            className={`cursor-pointer ${
                                !checkTeamLead(e.team, m.member?._id) &&
                                "none-pointer-events"
                            }`}
                        >
                            {m?.cases?.filter(
                                (f) =>
                                    f.status === "Rejected" ||
                                    (f.status === "Completed" &&
                                        f?.financialInstitutes?.length === 0) ||
                                    (f.status === "Completed" &&
                                        f?.institutes?.find(
                                            (i) => i.amount && !i.decision
                                        )) ||
                                    (f.status === "Completed" &&
                                        f?.institutes?.find(
                                            (i) =>
                                                i.amount &&
                                                i.approvedAmount &&
                                                !i.clientDecision
                                        ))
                            )?.length || 0}
                        </div>
                    ))}
                </div>
            ),

            pending: (
                <div>
                    {e.members.map((m) => (
                        <div
                            onClick={() => {
                                redirectCases(m?.cases, "prev_pending");
                            }}
                            className={`cursor-pointer ${
                                !checkTeamLead(e.team, m.member?._id) &&
                                "none-pointer-events"
                            }`}
                        >
                            {m?.cases?.filter(
                                (f) =>
                                    f.status === "Pending" ||
                                    f.status === "Submitted" ||
                                    f.status === "Processing" ||
                                    f.status === "Approved"
                            )?.length || 0}
                        </div>
                    ))}
                </div>
            ),
            total: (
                <div>
                    {e.members.map((m) => (
                        <div
                            onClick={() => {
                                redirectCases(m?.cases, "prev_total");
                            }}
                            className={`cursor-pointer ${
                                !checkTeamLead(e.team, m.member?._id) &&
                                "none-pointer-events"
                            }`}
                        >
                            {(m?.cases?.filter(
                                (f) =>
                                    f.status === "Rejected" ||
                                    (f.status === "Completed" &&
                                        f?.financialInstitutes?.length === 0) ||
                                    (f.status === "Completed" &&
                                        f?.institutes?.find(
                                            (i) => i.amount && !i.decision
                                        )) ||
                                    (f.status === "Completed" &&
                                        f?.institutes?.find(
                                            (i) =>
                                                i.amount &&
                                                i.approvedAmount &&
                                                !i.clientDecision
                                        ))
                            )?.length || 0) +
                                (m?.cases?.filter(
                                    (f) =>
                                        f.status === "Pending" ||
                                        f.status === "Submitted" ||
                                        f.status === "Processing" ||
                                        f.status === "Approved"
                                )?.length || 0) +
                                (m?.cases?.filter(
                                    (f) =>
                                        f.status === "Completed" &&
                                        !(
                                            f.status === "Completed" &&
                                            f?.financialInstitutes?.length === 0
                                        ) &&
                                        !(
                                            f.status === "Completed" &&
                                            f?.institutes?.find(
                                                (i) => i.amount && !i.decision
                                            )
                                        ) &&
                                        !(
                                            f.status === "Completed" &&
                                            f?.institutes?.find(
                                                (i) =>
                                                    i.amount &&
                                                    i.approvedAmount &&
                                                    !i.clientDecision
                                            )
                                        )
                                )?.length || 0)}
                        </div>
                    ))}
                </div>
            ),
            percent: (
                <div>
                    {e.members.map((m) => (
                        <div>{getPercentage(m?.cases)}</div>
                    ))}
                </div>
            ),
            percentRm: (
                <div>
                    {e.members.map((m) => (
                        <div>{getPendingPercentage(m?.cases)}</div>
                    ))}
                </div>
            ),
        };
    });

    const dataT3 = data?.teams?.map((e) => {
        return {
            team: <div>{e.team}</div>,
            rm: (
                <div>
                    {e.members.map((m) => (
                        <div>{m.member.username}</div>
                    ))}
                </div>
            ),
            m1: (
                <div>
                    {e.members.map((m) => (
                        <div
                            className={`cursor-pointer ${
                                !checkTeamLead(e.team, m.member?._id) &&
                                "none-pointer-events"
                            }`}
                            onClick={() => redirectMonthlyCases(m.cases, 1)}
                        >
                            {getMonthlyCases(m.cases, 1)}
                        </div>
                    ))}
                </div>
            ),
            m2: (
                <div>
                    {e.members.map((m) => (
                        <div
                            className={`cursor-pointer ${
                                !checkTeamLead(e.team, m.member?._id) &&
                                "none-pointer-events"
                            }`}
                            onClick={() => redirectMonthlyCases(m.cases, 2)}
                        >
                            {getMonthlyCases(m.cases, 2)}
                        </div>
                    ))}
                </div>
            ),
            m3: (
                <div>
                    {e.members.map((m) => (
                        <div
                            className={`cursor-pointer ${
                                !checkTeamLead(e.team, m.member?._id) &&
                                "none-pointer-events"
                            }`}
                            onClick={() => redirectMonthlyCases(m.cases, 3)}
                        >
                            {getMonthlyCases(m.cases, 3)}
                        </div>
                    ))}
                </div>
            ),
            m4: (
                <div>
                    {e.members.map((m) => (
                        <div
                            className={`cursor-pointer ${
                                !checkTeamLead(e.team, m.member?._id) &&
                                "none-pointer-events"
                            }`}
                            onClick={() => redirectMonthlyCases(m.cases, 4)}
                        >
                            {getMonthlyCases(m.cases, 4)}
                        </div>
                    ))}
                </div>
            ),
            m5: (
                <div>
                    {e.members.map((m) => (
                        <div
                            className={`cursor-pointer ${
                                !checkTeamLead(e.team, m.member?._id) &&
                                "none-pointer-events"
                            }`}
                            onClick={() => redirectMonthlyCases(m.cases, 5)}
                        >
                            {getMonthlyCases(m.cases, 5)}
                        </div>
                    ))}
                </div>
            ),
            m6: (
                <div>
                    {e.members.map((m) => (
                        <div
                            className={`cursor-pointer ${
                                !checkTeamLead(e.team, m.member?._id) &&
                                "none-pointer-events"
                            }`}
                            onClick={() => redirectMonthlyCases(m.cases, 6)}
                        >
                            {getMonthlyCases(m.cases, 6)}
                        </div>
                    ))}
                </div>
            ),
            m7: (
                <div>
                    {e.members.map((m) => (
                        <div
                            className={`cursor-pointer ${
                                !checkTeamLead(e.team, m.member?._id) &&
                                "none-pointer-events"
                            }`}
                            onClick={() => redirectMonthlyCases(m.cases, 7)}
                        >
                            {getMonthlyCases(m.cases, 7)}
                        </div>
                    ))}
                </div>
            ),
            m8: (
                <div>
                    {e.members.map((m) => (
                        <div
                            className={`cursor-pointer ${
                                !checkTeamLead(e.team, m.member?._id) &&
                                "none-pointer-events"
                            }`}
                            onClick={() => redirectMonthlyCases(m.cases, 8)}
                        >
                            {getMonthlyCases(m.cases, 8)}
                        </div>
                    ))}
                </div>
            ),
            m9: (
                <div>
                    {e.members.map((m) => (
                        <div
                            className={`cursor-pointer ${
                                !checkTeamLead(e.team, m.member?._id) &&
                                "none-pointer-events"
                            }`}
                            onClick={() => redirectMonthlyCases(m.cases, 9)}
                        >
                            {getMonthlyCases(m.cases, 9)}
                        </div>
                    ))}
                </div>
            ),
            m10: (
                <div>
                    {e.members.map((m) => (
                        <div
                            className={`cursor-pointer ${
                                !checkTeamLead(e.team, m.member?._id) &&
                                "none-pointer-events"
                            }`}
                            onClick={() => redirectMonthlyCases(m.cases, 10)}
                        >
                            {getMonthlyCases(m.cases, 10)}
                        </div>
                    ))}
                </div>
            ),
            m11: (
                <div>
                    {e.members.map((m) => (
                        <div
                            className={`cursor-pointer ${
                                !checkTeamLead(e.team, m.member?._id) &&
                                "none-pointer-events"
                            }`}
                            onClick={() => redirectMonthlyCases(m.cases, 11)}
                        >
                            {getMonthlyCases(m.cases, 11)}
                        </div>
                    ))}
                </div>
            ),
            m12: (
                <div>
                    {e.members.map((m) => (
                        <div
                            className={`cursor-pointer ${
                                !checkTeamLead(e.team, m.member?._id) &&
                                "none-pointer-events"
                            }`}
                            onClick={() => redirectMonthlyCases(m.cases, 12)}
                        >
                            {getMonthlyCases(m.cases, 12)}
                        </div>
                    ))}
                </div>
            ),
            iSubmission: (
                <div>
                    {e.members.map((m) => (
                        // <div>{m?.cases?.length || 0}</div>
                        <div
                            className={`cursor-pointer ${
                                !checkTeamLead(e.team, m.member?._id) &&
                                "none-pointer-events"
                            }`}
                            onClick={() =>
                                redirectMonthlyCases(m.cases, null, "total")
                            }
                        >
                            {getFilterCase(m?.cases)?.length || 0}
                        </div>
                    ))}
                </div>
            ),
            tSubmission: (
                <div
                    className={`cursor-pointer ${
                        !checkTeamLead(e.team, null, true) &&
                        "none-pointer-events"
                    }`}
                    onClick={() =>
                        redirectMonthlyCases(e.members, null, "totalTeam")
                    }
                >
                    {getTotalSubmissions(e.members)}
                </div>
            ),
            iSubmission2: (
                <div>
                    {e.members.map((m) => (
                        <div
                            className={`cursor-pointer ${
                                !checkTeamLead(e.team, m.member?._id) &&
                                "none-pointer-events"
                            }`}
                            onClick={() =>
                                redirectMonthlyCases(m.cases, null, "quarter")
                            }
                        >
                            {/* {m?.cases?.filter((f) => { */}
                            {getFilterCase(m?.cases)?.filter((f) => {
                                const currentDate = new Date();
                                const currentQuarterStart = new Date(
                                    currentDate.getFullYear(),
                                    Math.floor(currentDate.getMonth() / 3) * 3,
                                    1
                                );
                                const currentQuarterEnd = new Date(
                                    currentDate.getFullYear(),
                                    Math.floor(currentDate.getMonth() / 3) * 3 +
                                        3,
                                    0
                                );

                                if (
                                    // [4, 5, 6].includes(
                                    //     new Date(f.created_at).getMonth() + 1
                                    // )
                                    new Date(f.created_at) >=
                                        currentQuarterStart &&
                                    new Date(f.created_at) <= currentQuarterEnd
                                ) {
                                    return true;
                                } else {
                                    return false;
                                }
                            })?.length || 0}
                        </div>
                    ))}
                </div>
            ),
            tSubmission2: (
                <div
                    className={`cursor-pointer ${
                        !checkTeamLead(e.team, null, true) &&
                        "none-pointer-events"
                    }`}
                    onClick={() =>
                        redirectMonthlyCases(e.members, null, "teamQuarter")
                    }
                >
                    {getTotalSubmissions2(e.members)}
                </div>
            ),
        };
    });

    const dataT4 = data?.teams?.map((e) => {
        return {
            team: <div>{e.team}</div>,
            rm: (
                <div>
                    {e.members.map((m) => (
                        <div>{m.member.username}</div>
                    ))}
                </div>
            ),
            m1: (
                <div>
                    {e.members.map((m) => (
                        <div
                            className={`cursor-pointer ${
                                !checkTeamLead(e.team, m.member?._id) &&
                                "none-pointer-events"
                            }`}
                            onClick={() => calculateAdvisoryFeeCases(m.cases)}
                        >
                            {formatCurrency(
                                calculateAdvisoryFee(m.cases),
                                true
                            )}
                        </div>
                    ))}
                </div>
            ),
            m2: (
                <div>
                    <div
                        className={`cursor-pointer ${
                            !checkTeamLead(e.team, null, true) &&
                            "none-pointer-events"
                        }`}
                        onClick={() =>
                            calculateAdvisoryFeeTotalCases(e.members)
                        }
                    >
                        {formatCurrency(
                            calculateAdvisoryFeeTotal(e.members),
                            true
                        )}
                    </div>
                </div>
            ),
            m3: (
                <div>
                    {e.members.map((m) => (
                        <div>{getPercentageRm(e, m.cases)}</div>
                    ))}
                </div>
            ),
            m4: (
                <div>
                    <div>{getPercentageTeam(e.members)}</div>
                </div>
            ),
            m5: (
                <div>
                    <div>{getTeamRanking(e)}</div>
                </div>
            ),
            m6: (
                <div>
                    {e.members.map((m) => (
                        <div>{getRmRanking(m?.member?._id)}</div>
                    ))}
                </div>
            ),
            m7: (
                <div>
                    {e.members.map((m) => (
                        <div
                            className={`cursor-pointer ${
                                !checkTeamLead(e.team, m.member?._id) &&
                                "none-pointer-events"
                            }`}
                            onClick={() =>
                                getQuarterlyAdvisoryCases(m.cases, 1)
                            }
                        >
                            {formatCurrency(
                                getQuarterlyAdvisory(m.cases, 1),
                                true
                            )}
                        </div>
                    ))}
                </div>
            ),
            m8: (
                <div>
                    {e.members.map((m) => (
                        <div
                            className={`cursor-pointer ${
                                !checkTeamLead(e.team, m.member?._id) &&
                                "none-pointer-events"
                            }`}
                            onClick={() =>
                                getQuarterlyAdvisoryCases(m.cases, 2)
                            }
                        >
                            {formatCurrency(
                                getQuarterlyAdvisory(m.cases, 2),
                                true
                            )}
                        </div>
                    ))}
                </div>
            ),
            m9: (
                <div>
                    {e.members.map((m) => (
                        <div
                            className={`cursor-pointer ${
                                !checkTeamLead(e.team, m.member?._id) &&
                                "none-pointer-events"
                            }`}
                            onClick={() =>
                                getQuarterlyAdvisoryCases(m.cases, 3)
                            }
                        >
                            {formatCurrency(
                                getQuarterlyAdvisory(m.cases, 3),
                                true
                            )}
                        </div>
                    ))}
                </div>
            ),
            m10: (
                <div>
                    {e.members.map((m) => (
                        <div
                            className={`cursor-pointer ${
                                !checkTeamLead(e.team, m.member?._id) &&
                                "none-pointer-events"
                            }`}
                            onClick={() =>
                                getQuarterlyAdvisoryCases(m.cases, 4)
                            }
                        >
                            {formatCurrency(
                                getQuarterlyAdvisory(m.cases, 4),
                                true
                            )}
                        </div>
                    ))}
                </div>
            ),
            m11: (
                <div>
                    {e.members.map((m) => (
                        <div
                            className={`cursor-pointer ${
                                !checkTeamLead(e.team, m.member?._id) &&
                                "none-pointer-events"
                            }`}
                            onClick={() =>
                                calculateAdvisoryFeeYtdCases(m.cases)
                            }
                        >
                            {formatCurrency(
                                calculateAdvisoryFeeYtd(m.cases),
                                true
                            )}
                        </div>
                    ))}
                </div>
            ),
        };
    });

    return (
        <div className="report-table-wrapper">
            <div id="table-1">
                <h4>{getYears().currentYear} YTD Total Submission</h4>
                <div className="datatable_wrap table-1">
                    <div className="responsive_table table_1">
                        <TableComponent data={dataT1} columns={columnsT1} />
                    </div>
                    <div
                        className="listing_table_wrapper"
                        style={{ width: 700 }}
                    >
                        <ul className="list-style-none flex">
                            <li className="fixed-footer-1">Total</li>
                            <li></li>
                            <li></li>
                            <li>{getT1Total("approved")}</li>
                            <li>{getT1Total("rejected")}</li>
                            <li>{getT1Total("pending")}</li>
                            <li>{getT1Total("total")}</li>
                            <li></li>
                        </ul>
                        <ul className="list-style-none flex">
                            <li className="fixed-footer-2">Total %</li>
                            <li></li>
                            <li></li>
                            <li>{getT1TotalPercent("approved")} %</li>
                            <li>{getT1TotalPercent("rejected")} %</li>
                            <li>{getT1TotalPercent("pending")} %</li>
                            <li></li>
                            <li>{getT1TotalPercent("total")} %</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div id="table-2">
                <h4>{getYears().prevYear} Submissions</h4>
                <div className="uper_title content-justify-center flex">
                    {getYears().prevYear} Team & Individual Sales Submission
                </div>
                <div className="datatable_wrap table-2">
                    <div className="responsive_table">
                        <TableComponent data={dataT2} columns={columnsT2} />
                    </div>
                    <div
                        className="listing_table_wrapper table_2"
                        style={{ width: 800 }}
                    >
                        <ul className="list-style-none flex">
                            <li className="">Total</li>
                            <li>{getT1Total("approved", "pre")}</li>
                            <li>{getT1Total("rejected", "pre")}</li>
                            <li>{getT1Total("pending", "pre")}</li>
                            <li>{getT1Total("total", "pre")}</li>
                            <li></li>
                            <li></li>
                        </ul>
                        <ul className="list-style-none flex">
                            <li className="">Total %</li>
                            <li>{getT1TotalPercent("approved", "pre")} %</li>
                            <li>{getT1TotalPercent("rejected", "pre")} %</li>
                            <li>{getT1TotalPercent("pending", "pre")} %</li>
                            <li></li>
                            <li>{getT1TotalPercent("total", "pre")} %</li>
                            <li>
                                {getT1TotalPercent("totalPending", "pre")} %
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div id="table-3">
                <h4>{getYears().currentYear} Submissions</h4>
                <div className="datatable_wrap table-3">
                    <div className="responsive_table">
                        <TableComponent data={dataT3} columns={columnsT3} />
                        <div className="listing_table_wrapper table_3">
                            <ul className="list-style-none flex">
                                <li className="fixed-footer-3">
                                    Quarterly Total
                                </li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li
                                    style={{ borderRight: "1px solid #999999" }}
                                >
                                    {getT3Totals("1")}
                                </li>
                                <li></li>
                                <li></li>
                                <li
                                    style={{ borderRight: "1px solid #999999" }}
                                >
                                    {getT3Totals("2")}
                                </li>
                                <li></li>
                                <li></li>
                                <li
                                    style={{ borderRight: "1px solid #999999" }}
                                >
                                    {getT3Totals("3")}
                                </li>
                                <li></li>
                                <li></li>
                                <li
                                    style={{ borderRight: "1px solid #999999" }}
                                >
                                    {getT3Totals("4")}
                                </li>
                                <li
                                    style={{ borderRight: "1px solid #999999" }}
                                >
                                    {getT3Totals("iTotal")}
                                </li>
                                <li
                                    style={{ borderRight: "1px solid #999999" }}
                                >
                                    {getT3Totals("iTotal")}
                                </li>
                                <li
                                    style={{ borderRight: "1px solid #999999" }}
                                >
                                    {getT3Totals("iTotal2")}
                                </li>
                                <li>{getT3Totals("iTotal3")}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div id="table-4">
                <h4>{getYears().currentYear} Submissions</h4>
                <div className="flex">
                    <div className="quarter-selector">
                        <span
                            className={quarter === "1" && "active"}
                            onClick={() =>
                                quarter === "1"
                                    ? setQuarter("")
                                    : setQuarter("1")
                            }
                        >
                            Q1
                        </span>
                        <span
                            className={quarter === "2" && "active"}
                            onClick={() =>
                                quarter === "2"
                                    ? setQuarter("")
                                    : setQuarter("2")
                            }
                        >
                            Q2
                        </span>
                        <span
                            className={quarter === "3" && "active"}
                            onClick={() =>
                                quarter === "3"
                                    ? setQuarter("")
                                    : setQuarter("3")
                            }
                        >
                            Q3
                        </span>
                        <span
                            className={quarter === "4" && "active"}
                            onClick={() =>
                                quarter === "4"
                                    ? setQuarter("")
                                    : setQuarter("4")
                            }
                        >
                            Q4
                        </span>
                    </div>
                    {!quarter && (
                        <div className="ml-20 bold">
                            <span>
                                {shortFormatDate(new Date(startDate))} to{" "}
                                {shortFormatDate(new Date(endDate))}
                            </span>
                        </div>
                    )}
                </div>
                <div className="datatable_wrap table-4">
                    <div className="responsive_table">
                        <span className="quarter-table">
                            {quarter ? (
                                <span>Quarter {quarter} </span>
                            ) : (
                                <span>
                                    {shortFormatDate(new Date(startDate))} to{" "}
                                    {shortFormatDate(new Date(endDate))}
                                </span>
                            )}
                            <span>Yearly</span>
                        </span>
                        <TableComponent data={dataT4} columns={columnsT4} />
                    </div>
                </div>
            </div>
        </div>
    );
}
