import { Link } from "react-router-dom";
import copy from "../../../../assets/images/copy-icon.svg";
import update from "../../../../assets/images/update.svg";
import del from "../../../../assets/images/del.svg";
import EntriesSelection from "../../../../components/pagination/EntriesSelection";
import Pagination from "../../../../components/pagination/Pagination";
import Search from "../../../../components/search/Search";
import { AddProduct } from "../../../../components/modals/AddProduct";
import "./productForm.scss";
import { useState } from "react";
import { api } from "../../../../helpers/apiHelper/requestHelper";
import { toastify } from "../../../../helpers/toast/toastify";
import { useEffect } from "react";
import { AlertModal } from "../../../../components/modals/AlertModal";
import { setLoading } from "../../../../redux/loader/loadingSlice";
import { useDispatch } from "react-redux";
import { setSidebar } from "../../../../helpers/sidebar/setSidebar";

export default function ProductForm() {
    const [openAddModal, setOpenAddModal] = useState(false);
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState();
    const [show, setShow] = useState(false);
    const [filtered, setFiltered] = useState([]);
    const [count, setCount] = useState(5);
    const [page, setPage] = useState(1);
    const dispatch = useDispatch();

    useEffect(() => {
        setFiltered(products);
    }, [products]);

    useEffect(() => {
        setSidebar("Product Form");
        populateData();
    }, []);

    const populateData = async () => {
        const response = await api("get", "products/index");

        if (response?.status) {
            setProducts(response?.data?.reverse());
        } else {
            toastify(response?.message);
        }
    };

    const confirmAction = async () => {
        dispatch(setLoading(true));
        const response = await api("post", "products/update", {
            id: selectedProduct?._id,
            isDeleted: true,
        });
        dispatch(setLoading(false));
        if (response?.data?.status) {
            toastify("Product Deleted Successfully.", "success");
            populateData();
        } else {
            toastify(response?.message, "error");
        }
        setShow(false);
        setSelectedProduct();
    };

    const handleDelete = (data) => {
        setShow(true);
        setSelectedProduct(data);
    };

    return (
        <>
            {openAddModal === true && (
                <AddProduct
                    setModalClick={() => {
                        setOpenAddModal(!openAddModal);
                        setSelectedProduct();
                    }}
                    populateData={populateData}
                    product={selectedProduct}
                    setProduct={setSelectedProduct}
                />
            )}
            {show && (
                <AlertModal
                    confirmAction={confirmAction}
                    setModalClick={() => setShow(false)}
                    confirmBtn={true}
                    modalTitle={[
                        <h2>
                            Do you really want to delete{" "}
                            {selectedProduct?.name || "N/A"}
                            <br /> Product permanently?
                        </h2>,
                    ]}
                    modalDescription={[]}
                />
            )}
            <h4 className="flex flex-wrap mini-title">Product Forms</h4>
            <Search
                userSearch={true}
                filedText="Search here"
                teamDropdown={false}
                filters={false}
                addButton={false}
                modalButton={true}
                modalButtonText="Add Form"
                setModalClick={() => setOpenAddModal(!openAddModal)}
                setProducts={setFiltered}
                products={products}
                component={"Products"}
            />
            <div className="responsive-table last-child-table w-100">
                <table className="table">
                    <thead>
                        <tr
                            className="no-shadow"
                            style={{ background: "#f6f6f6" }}
                        >
                            <td colSpan="6" height="16"></td>
                        </tr>
                        <tr>
                            <th>Products</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filtered
                            ?.slice(count * page - count, count * page)
                            .map((prod, index) => {
                                return (
                                    <>
                                        <tr className="no-shadow">
                                            <td colSpan="6" height="16"></td>
                                        </tr>
                                        <tr key={index}>
                                            <td>{prod.name}</td>
                                            <td>
                                                <span
                                                    className={`status ${
                                                        prod.isActive
                                                            ? "active"
                                                            : "nonactive"
                                                    }`}
                                                >
                                                    {prod.isActive
                                                        ? "Active"
                                                        : "Inactive"}
                                                </span>
                                            </td>
                                            <td>
                                                <div className="flex items-center">
                                                    <div
                                                        onClick={() => {
                                                            setSelectedProduct(
                                                                prod
                                                            );
                                                            setOpenAddModal(
                                                                true
                                                            );
                                                        }}
                                                        className="table-action update cursor-pointer"
                                                    >
                                                        <img
                                                            src={update}
                                                            alt="update"
                                                        />
                                                        <span>
                                                            <small>
                                                                Update
                                                            </small>
                                                        </span>
                                                    </div>
                                                    {/* <div className="table-action copy cursor-pointer">
                                                        <img
                                                            src={copy}
                                                            alt="copy"
                                                        />
                                                        <span>
                                                            <small>Copy</small>
                                                        </span>
                                                    </div> */}
                                                    <div
                                                        className="table-action del cursor-pointer"
                                                        onClick={() =>
                                                            handleDelete(prod)
                                                        }
                                                    >
                                                        <img
                                                            src={del}
                                                            alt="del"
                                                        />
                                                        <span>
                                                            <small>
                                                                Delete
                                                            </small>
                                                        </span>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </>
                                );
                            })}
                    </tbody>
                </table>
                <div className="flex pagination-combine">
                    <EntriesSelection
                        setCount={setCount}
                        page={page}
                        count={count}
                        data={filtered}
                    />
                    <Pagination
                        count={count}
                        data={filtered}
                        setPage={setPage}
                        page={page}
                    />
                </div>
            </div>
        </>
    );
}
