import "./search.scss";
import { Link, useNavigate } from "react-router-dom";
import filterIcon from "../../assets/images/filter-icon.svg";
import calenderIcon from "../../assets/images/calender.svg";
import searchIcon from "../../assets/images/searchIcon.png";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AlertModal } from "../modals/AlertModal";
import { api } from "../../helpers/apiHelper/requestHelper";
import { toastify } from "../../helpers/toast/toastify";
import {
    selectTeam,
    storeTeams,
    storeUnassignedMembers,
} from "../../redux/teams/teamsSlice";
import { setLoading } from "../../redux/loader/loadingSlice";
import DatePicker from "react-date-picker";

export default function Search(props) {
    const teams = useSelector((state) => state.allTeams);
    const reduxCases = useSelector((state) => state.cases.value);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [lead, setLead] = useState();
    const [value, setValue] = useState("");
    const [show, setShow] = useState(false);
    const [filterDate, setFilterDate] = useState();
    const reduxData = useSelector((state) => state);

    const [filters, setFilters] = useState({
        leadType: "",
        date: null,
        status: "",
    });

    const getUsers = async () => {
        let response = await api("get", "teams/index");
        if (response?.status) {
            dispatch(storeTeams(response?.data));
            dispatch(storeUnassignedMembers(response?.data?.unassignedUsers));
        } else {
            toastify(response?.message, "error");
        }
    };

    useEffect(() => {
        if (teams?.selectedTeam) {
            setLead(teams?.selectedTeam?.teamLead?._id);
        }
    }, [teams?.selectedTeam]);

    const confirmAction = async () => {
        if (teams?.selectedTeam && lead) {
            let data = { userId: lead, teamId: teams?.selectedTeam?._id };
            dispatch(setLoading(true));
            const response = await api("post", "teams/assignLead", data);
            dispatch(setLoading(false));

            if (response?.data?.status) {
                toastify("Lead assigned successfully.", "success");
                getUsers();
                dispatch(selectTeam(response?.data?.data));
            } else {
                toastify(response?.message, "error");
            }
            setShow(false);
        } else if (teams?.selectedTeam && !lead) {
            let data = { userId: lead, teamId: teams?.selectedTeam?._id };
            dispatch(setLoading(true));
            const response = await api("post", "teams/assignLead", data);
            dispatch(setLoading(false));

            if (response?.data?.status) {
                toastify("Lead assigned successfully.", "success");
                getUsers();
                dispatch(selectTeam(response?.data?.data));
            } else {
                toastify(response?.message, "error");
            }
            setShow(false);
        }
    };

    useEffect(() => {
        if (props.leads || props.cases) {
            let data =
                props.component === "Leads"
                    ? [...props?.leads]?.filter((e) => {
                          if (
                              (props?.filter === "assigned" && e.assignedTo) ||
                              (props?.filter === "unassigned" &&
                                  (props?.role === "LC" || props?.role === "RML"
                                      ? !e?.assignedTo
                                      : e?.assignedFrom?._id ===
                                        reduxData?.auth?.user?._id)) ||
                              (props?.filter === "own" &&
                                  e?.assignedFrom?._id ===
                                      reduxData?.auth?.user?._id) ||
                              props?.filter === "all" ||
                              (props?.filter === "reassigned" && e.reassigned)
                          ) {
                              return e;
                          }
                      })
                    : [...props?.cases]?.filter((e) => {
                          if (
                              (props.filter === "unverified-cases" &&
                                  e?.status?.toLowerCase() !== "completed" &&
                                  e?.status?.toLowerCase() !== "rejected") ||
                              (props.filter === "verified-cases" &&
                                  (e?.status?.toLowerCase() === "completed" ||
                                      e?.status?.toLowerCase() ===
                                          "rejected")) ||
                              props.filter === "all-cases"
                          ) {
                              return e;
                          }
                      });
            if (filters.date) {
                data = data?.filter((e) => {
                    let d1 = new Date(filters.date);
                    let d2 = new Date(e.created_at);
                    if (
                        d1.getFullYear() === d2.getFullYear() &&
                        d1.getMonth() === d2.getMonth() &&
                        d1.getDate() === d2.getDate()
                    ) {
                        return e;
                    }
                });
            }
            if (filters.leadType) {
                if (props.component === "Leads") {
                    data = data.filter((f) => f.leadType === filters.leadType);
                } else {
                    data = data.filter(
                        (f) => f?.lead.leadType === filters.leadType
                    );
                }
            }
            if (filters.status) {
                data = data.filter((f) => f.status === filters.status);
            }
            if (props.component === "Leads") {
                props.setLeads(data);
            } else {
                props.setCases(data);
            }
        }
    }, [filters]);

    useEffect(() => {
        if (props.component === "Leads") {
            props.setLeads(
                [...props?.leads]?.filter((e) => {
                    if (
                        (props?.filter === "assigned" && e.assignedTo) ||
                        (props?.filter === "unassigned" &&
                            (props?.role === "LC" || props?.role === "RML"
                                ? !e?.assignedTo
                                : e?.assignedFrom?._id ===
                                  reduxData?.auth?.user?._id)) ||
                        (props?.filter === "own" &&
                            e?.assignedFrom?._id ===
                                reduxData?.auth?.user?._id) ||
                        props?.filter === "all" ||
                        (props?.filter === "reassigned" && e.reassigned)
                    ) {
                        return e;
                    }
                })
            );
        }
    }, [props?.filter]);

    const updateData = () => {
        if (props.component === "Users") {
            props.setUsers(
                props.users.filter(
                    (f) =>
                        f?.username?.includes(value) ||
                        f?.email?.toLowerCase()?.includes(value) ||
                        f?.contact?.includes(value)
                )
            );
        } else if (props.component === "Announcements") {
            props.setAnnouncements(
                props.announcements.filter(
                    (f) =>
                        f.title.toLowerCase().includes(value) ||
                        f.description.toLowerCase().includes(value)
                )
            );
        } else if (props.component === "Banks") {
            props.setBanks(
                props.banks.filter((f) => f.name.toLowerCase().includes(value))
            );
        } else if (props.component === "Products") {
            props.setProducts(
                props.products.filter((f) =>
                    f.name.toLowerCase().includes(value)
                )
            );
        } else if (props.component === "Forms") {
            props.setForms(
                props.forms.filter(
                    (f) =>
                        f.name.toLowerCase().includes(value) ||
                        f.description.toLowerCase().includes(value)
                )
            );
        } else if (props.component === "Templates") {
            props.setTemplates(
                props.templates.filter(
                    (f) =>
                        f.name.toLowerCase().includes(value) ||
                        f.description.toLowerCase().includes(value)
                )
            );
        } else if (props.component === "Leads") {
            props.setLeads(
                props.leads
                    ?.filter((e) => {
                        if (
                            (props?.filter === "assigned" && e.assignedTo) ||
                            (props?.filter === "unassigned" &&
                                (props?.role === "LC" || props?.role === "RML"
                                    ? !e?.assignedTo
                                    : e?.assignedFrom?._id ===
                                      reduxData?.auth?.user?._id)) ||
                            (props?.filter === "own" &&
                                e?.assignedFrom?._id ===
                                    reduxData?.auth?.user?._id) ||
                            props?.filter === "all" ||
                            (props?.filter === "reassigned" && e.reassigned)
                        ) {
                            return e;
                        }
                    })
                    ?.filter((f) => {
                        if (props?.role === "LC") {
                            if (
                                f?.assignedTo?.username
                                    ?.toLowerCase()
                                    ?.includes(value?.toLowerCase()) ||
                                f?.assignedFrom?.username
                                    ?.toLowerCase()
                                    ?.includes(value?.toLowerCase()) ||
                                f?.identifier
                                    ?.toLowerCase()
                                    ?.includes(value.toLowerCase())
                            ) {
                                return f;
                            }
                            if (f?.entityType === "Business") {
                                if (
                                    f?.businessEntity?.representative?.firstName
                                        .toLowerCase()
                                        .includes(value.toLowerCase()) ||
                                    f?.businessEntity?.name
                                        .toLowerCase()
                                        .includes(value.toLowerCase()) ||
                                    f?.businessEntity?.representative?.lastName
                                        .toLowerCase()
                                        .includes(value.toLowerCase())
                                ) {
                                    return f;
                                }
                            }
                        } else {
                            if (f?.entityType === "Business") {
                                if (
                                    f?.assignedTo?.username
                                        ?.toLowerCase()
                                        ?.includes(value?.toLowerCase()) ||
                                    f?.assignedFrom?.username
                                        ?.toLowerCase()
                                        ?.includes(value?.toLowerCase()) ||
                                    f?.businessEntity?.representative?.firstName
                                        .toLowerCase()
                                        .includes(value.toLowerCase()) ||
                                    f?.businessEntity?.representative?.lastName
                                        .toLowerCase()
                                        .includes(value.toLowerCase()) ||
                                    f?.businessEntity?.name
                                        .toLowerCase()
                                        .includes(value.toLowerCase()) ||
                                    f?.businessEntity?.representative?.contact.includes(
                                        value.toLowerCase()
                                    ) ||
                                    f?.identifier
                                        .toLowerCase()
                                        .includes(value.toLowerCase())
                                ) {
                                    return f;
                                }
                            } else {
                                if (
                                    f?.assignedTo?.username
                                        ?.toLowerCase()
                                        ?.includes(value?.toLowerCase()) ||
                                    f?.assignedFrom?.username
                                        ?.toLowerCase()
                                        ?.includes(value?.toLowerCase()) ||
                                    f?.individualEntity?.firstName
                                        .toLowerCase()
                                        .includes(value.toLowerCase()) ||
                                    f?.individualEntity?.lastName
                                        .toLowerCase()
                                        .includes(value.toLowerCase()) ||
                                    f?.individualEntity?.contact.includes(
                                        value.toLowerCase()
                                    ) ||
                                    f?.identifier
                                        .toLowerCase()
                                        .includes(value.toLowerCase())
                                ) {
                                    return f;
                                }
                            }
                        }
                    })
                    ?.filter((g) => {
                        if (filters.date) {
                            const date1 = new Date(filters.date);
                            const date2 = new Date(g?.created_at);

                            const isSameDate =
                                date1.getDate() === date2.getDate() &&
                                date1.getMonth() === date2.getMonth() &&
                                date1.getFullYear() === date2.getFullYear();
                            if (isSameDate) {
                                return g;
                            }
                        } else {
                            return g;
                        }
                    })
            );
        } else if (props.component === "Cases") {
            props.setCases(
                props.cases
                    ?.filter((e) => {
                        if (props.filter === "all-cases") {
                            return e;
                        } else if (
                            props.filter === "verified-cases" &&
                            (e?.status?.toLowerCase() === "completed" ||
                                e?.status?.toLowerCase() === "rejected")
                        ) {
                            return e;
                        } else if (
                            props.filter === "unverified-cases" &&
                            e?.status?.toLowerCase() !== "completed" &&
                            e?.status?.toLowerCase() !== "rejected"
                        ) {
                            return e;
                        } else if (
                            props.filter === "pending-cases" &&
                            e?.status.includes("Pending")
                        ) {
                            return e;
                        } else if (
                            props.filter === "rejected-cases" &&
                            e?.status.includes("Rejected")
                        ) {
                            return e;
                        } else if (
                            props.filter === "completed-cases" &&
                            e?.status.includes("Completed")
                        ) {
                            return e;
                        } else if (
                            props.filter === "processing-cases" &&
                            e?.status.includes("Processing")
                        ) {
                            return e;
                        } else if (
                            props.filter === "approved-cases" &&
                            e?.status.includes("Approved")
                        ) {
                            return e;
                        } else if (
                            props.filter === "draft-cases" &&
                            e?.status.includes("Draft")
                        ) {
                            return e;
                        } else if (
                            props.filter === "submitted-cases" &&
                            e?.status.includes("Draft")
                        ) {
                            return e;
                        }
                    })
                    .filter((f) => {
                        if (f?.lead?.entityType === "Business") {
                            if (
                                f?.lead?.assignedTo?.username
                                    ?.toLowerCase()
                                    ?.includes(value?.toLowerCase()) ||
                                f?.lead?.assignedFrom?.username
                                    ?.toLowerCase()
                                    ?.includes(value?.toLowerCase()) ||
                                f?.lead?.businessEntity?.representative?.firstName
                                    ?.toLowerCase()
                                    ?.includes(value?.toLowerCase()) ||
                                f?.lead?.businessEntity?.representative?.lastName
                                    ?.toLowerCase()
                                    ?.includes(value?.toLowerCase()) ||
                                f?.lead?.businessEntity?.representative?.contact.includes(
                                    value?.toLowerCase()
                                ) ||
                                f?.lead?.identifier
                                    ?.toLowerCase()
                                    ?.includes(value?.toLowerCase()) ||
                                f?.lead?.businessEntity?.name
                                    ?.toLowerCase()
                                    ?.includes(value?.toLowerCase()) ||
                                f?.identifier
                                    ?.toLowerCase()
                                    ?.includes(value?.toLowerCase()) ||
                                f?.product?.name
                                    ?.toLowerCase()
                                    ?.includes(value?.toLowerCase())
                            ) {
                                return f;
                            }
                        } else if (f?.lead?.entityType === "Individual") {
                            if (
                                f?.lead?.assignedTo?.username
                                    ?.toLowerCase()
                                    ?.includes(value?.toLowerCase()) ||
                                f?.lead?.assignedFrom?.username
                                    ?.toLowerCase()
                                    ?.includes(value?.toLowerCase()) ||
                                f?.lead?.individualEntity?.firstName
                                    .toLowerCase()
                                    .includes(value.toLowerCase()) ||
                                f?.lead?.individualEntity?.lastName
                                    .toLowerCase()
                                    .includes(value.toLowerCase()) ||
                                f?.lead?.individualEntity?.contact.includes(
                                    value.toLowerCase()
                                ) ||
                                f?.lead?.identifier
                                    .toLowerCase()
                                    .includes(value.toLowerCase()) ||
                                f?.identifier
                                    ?.toLowerCase()
                                    ?.includes(value?.toLowerCase()) ||
                                f?.product?.name
                                    ?.toLowerCase()
                                    ?.includes(value?.toLowerCase())
                            ) {
                                return f;
                            }
                        }
                    })
                    ?.filter((g) => {
                        if (filters.date) {
                            const date1 = new Date(filters.date);
                            const date2 = new Date(g?.created_at);

                            const isSameDate =
                                date1.getDate() === date2.getDate() &&
                                date1.getMonth() === date2.getMonth() &&
                                date1.getFullYear() === date2.getFullYear();
                            if (isSameDate) {
                                return g;
                            }
                        } else {
                            return g;
                        }
                    })
            );
        } else if (props.component === "Announcement List") {
            props.setAnnouncements(
                props.announcements.filter(
                    (f) =>
                        f.title.toLowerCase().includes(value) ||
                        f.description.toLowerCase().includes(value)
                )
            );
        } else if (props.component === "Product") {
            props.setProducts(
                props.products.filter(
                    (f) =>
                        f.name.toLowerCase().includes(value) ||
                        f.description.toLowerCase().includes(value)
                )
            );
        } else if (props.component === "Entity") {
            props.setSearch(value);
        }
    };

    useEffect(() => {
        updateData();
    }, [value]);

    useEffect(() => {
        setValue("");
        updateData();
    }, [props?.cases?.length]);

    useEffect(() => {
        setValue("");
    }, [props.filter]);

    return (
        <div
            className={`flex flex-wrap items-center search-wrap ${props.extraClass}`}
        >
            {props.userSearch === true && (
                <div className="user-search flex">
                    <input
                        type="text"
                        placeholder={props.filedText}
                        value={value}
                        onChange={(e) => {
                            // let value = e.target.value.toLowerCase();
                            setValue(e.target.value.toLowerCase());
                        }}
                    />
                    {/* <button type="submit" className="search-submit-btn">
                        Search
                    </button> */}
                    <img src={searchIcon} className="search-submit-btn" />
                </div>
            )}
            {props.teamDropdown === true && (
                <div className="team-dropdown items-center flex">
                    <span>Lead</span>
                    <select
                        name=""
                        id=""
                        value={lead ? lead : ""}
                        onChange={(e) => {
                            setShow(true);
                            setLead(e.target.value);
                        }}
                    >
                        <option value="">Select a team lead</option>
                        {teams?.selectedTeam?.members?.map((e) => (
                            <option value={e._id}>{e.username}</option>
                        ))}
                    </select>
                </div>
            )}

            {show && (
                <AlertModal
                    setModalClick={() => {
                        setShow(false);
                        setLead(teams?.selectedTeam?.teamLead);
                    }}
                    confirmBtn={true}
                    modalTitle={[
                        <h2>Are you sure you want to re-assign team lead?</h2>,
                    ]}
                    confirmAction={confirmAction}
                    modalDescription={[]}
                />
            )}

            <div className="ml-auto flex">
                {props.typeFilters === true && (
                    <div className="search-filter">
                        <img src={filterIcon} alt="filterIcon" />
                        <select
                            onChange={(e) => {
                                if (props.component === "Leads") {
                                    setFilters((f) => ({
                                        ...f,
                                        leadType: e.target.value,
                                    }));
                                } else if (props.component === "Cases") {
                                    setFilters((f) => ({
                                        ...f,
                                        leadType: e.target.value,
                                    }));
                                }
                            }}
                        >
                            <option value="" style={{ display: "none" }}>
                                Filter by {props.typeFiltersTitle}
                            </option>
                            <option value="">All</option>
                            <option value="Personal">Personal</option>
                            <option value="Corporate">Corporate</option>
                        </select>
                    </div>
                )}

                {props.dateFilters === true && (
                    <div className="search-filter date">
                        <img src={calenderIcon} alt="filterIcon" />
                        <div className="form-group">
                            <DatePicker
                                onChange={(e) => {
                                    setFilterDate(e);
                                    if (props.component === "Leads") {
                                        if (e === null) {
                                            props.setLeads(props.leads);
                                            setFilters((f) => ({
                                                ...f,
                                                date: null,
                                            }));
                                        } else {
                                            setFilters((f) => ({
                                                ...f,
                                                date: e,
                                            }));
                                        }
                                    } else if (props.component === "Cases") {
                                        if (e === null) {
                                            props.setCases(props.cases);
                                            setFilters((f) => ({
                                                ...f,
                                                date: null,
                                            }));
                                        } else {
                                            setFilters((f) => ({
                                                ...f,
                                                date: e,
                                            }));
                                        }
                                    } else if (props.component === "Entity") {
                                        props.setDateFilter(e);
                                    }
                                }}
                                format="dd/MM/y"
                                className="form-control"
                                value={filterDate}
                                onKeyDown={(e) => {
                                    e.preventDefault();
                                }}
                            />
                        </div>
                    </div>
                )}

                {props.statusFilter === true && (
                    <div className="search-filter">
                        <img src={filterIcon} alt="filterIcon" />
                        <select
                            onChange={(e) => {
                                if (props.component === "Leads") {
                                    if (e.target.value === "") {
                                        props.setLeads(
                                            props.leads?.filter((g) => {
                                                if (filters.date) {
                                                    const date1 = new Date(
                                                        filters.date
                                                    );
                                                    const date2 = new Date(
                                                        g?.created_at
                                                    );

                                                    const isSameDate =
                                                        date1.getDate() ===
                                                            date2.getDate() &&
                                                        date1.getMonth() ===
                                                            date2.getMonth() &&
                                                        date1.getFullYear() ===
                                                            date2.getFullYear();
                                                    if (isSameDate) {
                                                        return g;
                                                    }
                                                } else {
                                                    return g;
                                                }
                                            })
                                        );
                                        setFilters((e) => ({
                                            ...e,
                                            status: false,
                                        }));
                                    } else {
                                        props.setLeads(
                                            props.leads.filter(
                                                (f) =>
                                                    f.status === e.target.value
                                            )
                                        );
                                    }
                                }
                            }}
                        >
                            <option value="" style={{ display: "none" }}>
                                Filter by Status
                            </option>
                            <option value="">All</option>
                            <option value="Pending">Pending</option>
                            <option value="Processing">Processing</option>
                            <option value="Completed">Completed</option>
                            <option value="Rejected">Rejected</option>
                        </select>
                    </div>
                )}

                {props.filters === true && (
                    <div className="search-filter">
                        <img src={filterIcon} alt="filterIcon" />
                        <select
                            name=""
                            id=""
                            onChange={(e) => {
                                if (props.component === "Users") {
                                    if (e.target.value !== "all") {
                                        props.setUsers(
                                            props.users.filter((f) =>
                                                f?.roles?.includes(
                                                    e.target.value
                                                )
                                            )
                                        );
                                    } else {
                                        props.setUsers(props.users);
                                    }
                                }
                            }}
                        >
                            <option value="" style={{ display: "none" }}>
                                Filter by role
                            </option>
                            <option value="all">All roles</option>
                            {[
                                "Admin",
                                "Lead Coordinator",
                                "RM Team Leader",
                                "RM Member",
                                "Case Manager",
                            ].map((e) => (
                                <option value={e}>{e}</option>
                            ))}
                        </select>
                    </div>
                )}
                {props.addButton === true && (
                    <li
                        className="search-btn cursor-pointer"
                        onClick={() =>
                            navigate(props.buttonRoute || "/", {
                                state: { samePage: true },
                            })
                        }
                    >
                        {props.addButtonText}
                    </li>
                )}
                {props.modalButton === true && (
                    <Link className="search-btn" onClick={props.setModalClick}>
                        {props.modalButtonText}
                    </Link>
                )}
            </div>
        </div>
    );
}
