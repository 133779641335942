import crossIcon from "../../../../assets/images/cross.svg";
import { useState } from "react";
import CaseInformation from "./CaseInformation";
import BusinessInformation from "./BusinessInformation";
import { toastify } from "../../../../helpers/toast/toastify";
import { api } from "../../../../helpers/apiHelper/requestHelper";
import { useEffect } from "react";
import {
    APPROVED,
    COMPLETED,
    PENDING_1,
    PENDING_2,
    PROCESSING_1,
} from "../../../../config/casesStatus";
import { storeCases } from "../../../../redux/cases/casesSlice";
import { useDispatch } from "react-redux";
import { storeLeads } from "../../../../redux/leads/leadsSlice";
import { useSelector } from "react-redux";

export default function LeadDetailsModal(props) {
    const [activeTab, setActiveTab] = useState(
        props?.case ? "caseTab" : "individualTab"
    );
    const [currentCase, setCurrentCase] = useState();
    const dispatch = useDispatch();
    const reduxData = useSelector((state) => state);

    useEffect(() => {
        setCurrentCase(props.case);
    }, [props.case]);

    const getSubStatus = (status, reason, rmPdpa, cmPdpa, fundsDisbursed) => {
        if (cmPdpa) {
            return "Case Manager PDPA Deletion";
        } else if (rmPdpa) {
            return "RM PDPA Deletion";
        } else {
            if (fundsDisbursed) {
                return "Disbursed By Bank";
            } else if (status?.toLowerCase() === "pending") {
                return PENDING_1;
            } else if (status?.toLowerCase() === "submitted") {
                return PENDING_2;
            } else if (status?.toLowerCase() === "processing") {
                return PROCESSING_1;
            } else if (status?.toLowerCase() === "approved") {
                return APPROVED;
            } else if (status?.toLowerCase() === "completed") {
                return COMPLETED;
            } else if (status?.toLowerCase() === "rejected") {
                return reason;
            } else {
                return null;
            }
        }
    };

    const getCases = async () => {
        let response1 = await api("post", "leads/index", {
            assignedFromRole: reduxData?.userRoles?.currentRole,
        });
        if (response1?.status) {
            dispatch(storeLeads(response1?.data?.data?.reverse()));
        } else {
            toastify(response1?.message, "error");
        }

        let response = await api("get", "cases/unfilteredCases");
        if (response?.status) {
            dispatch(storeCases(response?.data?.reverse()));
        } else {
            toastify(response?.message, "error");
        }
    };

    return (
        <div className={`modal-wrapper add-product-form lead-details-modal`}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-body">
                        <img
                            src={crossIcon}
                            className="cross-btn"
                            onClick={props.setModalClick}
                            alt="crossIcon"
                        />
                        <div className="modal-forms">
                            <h2>View Details</h2>
                            <div className="load-modal-detail">
                                <div className="flex top-strip">
                                    <h3>
                                        Lead{" "}
                                        <span>
                                            {props?.lead?.leadType ||
                                                props?.case?.lead?.leadType}
                                        </span>
                                        <h3>
                                            {currentCase
                                                ? currentCase?.lead
                                                      ?.businessEntity?.name ||
                                                  currentCase?.lead
                                                      ?.individualEntity
                                                      ?.firstName +
                                                      " " +
                                                      currentCase?.lead
                                                          ?.individualEntity
                                                          ?.lastName
                                                : props?.lead?.businessEntity
                                                      ?.name ||
                                                  props?.lead?.individualEntity
                                                      ?.firstName +
                                                      " " +
                                                      props?.lead
                                                          ?.individualEntity
                                                          ?.lastName}
                                        </h3>
                                    </h3>

                                    <div className="status-wrap ml-auto">
                                        <span
                                            className={`status ${
                                                props?.lead?.status?.toLowerCase() ||
                                                currentCase?.status?.toLowerCase()
                                            }`}
                                        >
                                            {props?.lead?.status ||
                                                currentCase?.status}
                                        </span>
                                        <small>
                                            {getSubStatus(
                                                props?.lead?.status ||
                                                    currentCase?.status,
                                                currentCase?.decisionReason,
                                                currentCase?.rmPdpa,
                                                currentCase?.cmPdpa,
                                                currentCase?.institutes?.find(
                                                    (e) => e.fundsDisbursed
                                                ) || false
                                            )}
                                        </small>
                                    </div>
                                </div>
                                <div className="entity-type-tab">
                                    {props.case ? (
                                        <ul className="list-style-none flex">
                                            <li>
                                                <button
                                                    onClick={() => {
                                                        setActiveTab("caseTab");
                                                    }}
                                                    className={`${
                                                        activeTab === "caseTab"
                                                            ? "active"
                                                            : ""
                                                    }`}
                                                >
                                                    Case Information
                                                </button>
                                            </li>
                                            <li>
                                                <button
                                                    onClick={() => {
                                                        setActiveTab(
                                                            "individualTab"
                                                        );
                                                    }}
                                                    className={`${
                                                        activeTab ===
                                                        "individualTab"
                                                            ? "active"
                                                            : ""
                                                    }`}
                                                >
                                                    {props?.lead?.entityType ||
                                                        props?.case?.lead
                                                            ?.entityType}{" "}
                                                    information
                                                </button>
                                            </li>
                                        </ul>
                                    ) : (
                                        <ul className="list-style-none flex">
                                            <li>
                                                <button
                                                    onClick={() => {
                                                        setActiveTab(
                                                            "individualTab"
                                                        );
                                                    }}
                                                    className={`${
                                                        activeTab ===
                                                        "individualTab"
                                                            ? "active"
                                                            : ""
                                                    }`}
                                                >
                                                    {props?.lead?.entityType ||
                                                        props?.case?.lead
                                                            ?.entityType}{" "}
                                                    information
                                                </button>
                                            </li>
                                            <li>
                                                <button
                                                    onClick={() => {
                                                        setActiveTab("caseTab");
                                                    }}
                                                    className={`no-br ${
                                                        activeTab === "caseTab"
                                                            ? "active"
                                                            : ""
                                                    }`}
                                                >
                                                    Case Information
                                                </button>
                                            </li>
                                        </ul>
                                    )}
                                </div>
                                {activeTab === "caseTab" ? (
                                    <CaseInformation
                                        lead={props.lead}
                                        case={props.case}
                                        setOpenDetailModal={
                                            props?.setOpenDetailModal
                                        }
                                        setModalClick={props?.setModalClick}
                                        getLeads={props?.getLeads}
                                        getCases={getCases}
                                        setCurrentCase={setCurrentCase}
                                    />
                                ) : (
                                    <BusinessInformation
                                        lead={props.lead}
                                        case={props.case}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
