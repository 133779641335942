import { useState } from "react";
import { useEffect } from "react";

export default function EntriesSelection({ setCount, count, page, data }) {
    const [params, setParams] = useState({ count: 0, page: 1, data: [] });

    useEffect(() => {
        setParams({ count: parseInt(count), page, data });
    }, [count, page, data]);

    const getentries = () => {
        let start =
            data?.length > 0 ? params.count * ((params?.page || 1) - 1) + 1 : 0;
        let end = start + params.count - 1;
        if (end > params?.data?.length) {
            end = params?.data?.length;
        }
        let limit = params?.data?.length;

        return `${start} - ${end} of ${limit}`;
    };
    return (
        <div className="entries-dropdown items-center flex mr-auto content-justify-start">
            <span>{getentries()}</span>
            <select
                name=""
                id=""
                value={count}
                onChange={(e) => {
                    setCount(e.target.value);
                    setParams({ ...params, count: e.target.value });
                }}
            >
                <option value={"5"}>5</option>
                <option value={"10"}>10</option>
                <option value={"20"}>20</option>
                <option value={"50"}>50</option>
                <option value={"100"}>100</option>
            </select>
        </div>
    );
}
