import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { Loader } from "../components/modals/Loader";
export const LoginLayout = () => {
    const loading = useSelector((state) => state.loading.value);
    return (
        <>
            {loading && <Loader />}
            <Outlet />
        </>
    );
};
