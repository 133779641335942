import leads from "../../../../assets/images/leads.svg";
import rLeads from "../../../../assets/images/rLeads.svg";
import gLeads from "../../../../assets/images/gLeads.svg";
import highIcon from "../../../../assets/images/highIcon.svg";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { setRoute } from "../../../../redux/route/routeSlice";
import { useDispatch } from "react-redux";
import { resetSidebar } from "../../../../helpers/sidebar/resetSidebar";
import { setSidebar } from "../../../../helpers/sidebar/setSidebar";

export default function LCStatistics() {
    const reduxData = useSelector((state) => state);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const statisticsList = [
        {
            statisticsIcon: leads,
            statisticsCount: reduxData?.leads?.value?.length || 0,
            statisticsTitle: "Total Leads",
            url: "/lead-coordinator/leads/all",
        },
        {
            statisticsIcon: gLeads,
            statisticsCount:
                reduxData?.leads?.value?.filter((e) => e.assignedTo)?.length ||
                0,
            statisticsTitle: "Total Assigned Leads",
            url: "/lead-coordinator/leads/assigned",
        },
        {
            statisticsIcon: rLeads,
            statisticsCount:
                reduxData?.leads?.value?.filter((e) => !e.assignedTo)?.length ||
                0,
            statisticsTitle: "Total Unassigned Leads",
            url: "/lead-coordinator/leads/unassigned",
        },
    ];
    return (
        <div className="statistics-wrapper flex column-direction">
            <ul className="list-style-none flex flex-wrap">
                {statisticsList.map(
                    (
                        {
                            statisticsIcon,
                            statisticsCount,
                            statisticsTitle,
                            url,
                        },
                        index
                    ) => {
                        return (
                            <li
                                key={`statistics-${index}`}
                                onClick={() => {
                                    dispatch(setRoute("Leads"));
                                    resetSidebar();

                                    if (statisticsTitle === "Total Leads") {
                                        setSidebar("All Leads");
                                    } else if (
                                        statisticsTitle ===
                                        "Total Assigned Leads"
                                    ) {
                                        setSidebar("Assigned Leads");
                                    } else if (
                                        statisticsTitle ===
                                        "Total Unassigned Leads"
                                    ) {
                                        setSidebar("Unassigned Leads");
                                    }

                                    navigate(url, {
                                        state: {
                                            isPrevious: true,
                                        },
                                    });
                                }}
                            >
                                <Link className="flex items-center">
                                    <section>
                                        <h2>{statisticsCount}</h2>
                                        <span>{statisticsTitle} </span>
                                    </section>
                                    <figure>
                                        <img
                                            src={statisticsIcon}
                                            alt="statisticsIcon"
                                        />
                                    </figure>
                                </Link>
                            </li>
                        );
                    }
                )}
            </ul>
        </div>
    );
}
