import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import arrow from "../../../assets/images/diraction-arrow.svg";
import Pagination from "../../../components/pagination/Pagination";
import { api } from "../../../helpers/apiHelper/requestHelper";
import { toastify } from "../../../helpers/toast/toastify";
import { setLoading } from "../../../redux/loader/loadingSlice";
import {
    selectTeam,
    storeTeams,
    storeUnassignedMembers,
} from "../../../redux/teams/teamsSlice";

export default function AssignedMembers() {
    const teams = useSelector((state) => state.allTeams);
    const dispatch = useDispatch();
    const [count, setCount] = useState(10);
    const [page, setPage] = useState(1);

    const dischargeMember = async (user) => {
        if (user?._id && teams?.selectedTeam?._id) {
            dispatch(setLoading(true));
            const response = await api("post", "teams/discharge", {
                userId: user?._id,
                teamId: teams?.selectedTeam?._id,
            });
            dispatch(setLoading(false));

            if (response?.data?.status) {
                getUsers();
                dispatch(selectTeam(response?.data?.data));

                toastify("User and Team updated successfully.", "success");
            } else {
                toastify(response?.message, "error");
            }
        }
    };

    const getUsers = async () => {
        let response = await api("get", "teams/index");
        if (response?.status) {
            dispatch(storeTeams(response?.data));
            dispatch(storeUnassignedMembers(response?.data?.unassignedUsers));
        } else {
            toastify(response?.message, "error");
        }
    };

    const checkTeamLead = (id) => {
        if (teams?.selectedTeam?.teamLead?._id === id) {
            return " (Team Leader) ";
        } else {
            return;
        }
    };

    return (
        <div className="responsive-table w-100">
            <table className="table">
                <thead>
                    <tr className="no-shadow" style={{ background: "#f6f6f6" }}>
                        <td colSpan="5" height="16"></td>
                    </tr>
                    <tr>
                        <th>Assigned Members</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {teams?.selectedTeam?.members?.map((data, index) => {
                        return (
                            <>
                                <tr className="no-shadow">
                                    <td colSpan="5" height="16"></td>
                                </tr>
                                <tr key={`member-${index}`}>
                                    {console.log({ data })}
                                    <td>
                                        {data?.username}
                                        {checkTeamLead(data?._id)}
                                    </td>
                                    <td>
                                        <div className="flex items-center">
                                            <button
                                                onClick={() =>
                                                    dischargeMember(data)
                                                }
                                                className="table-action update assigned"
                                            >
                                                <img
                                                    src={arrow}
                                                    alt="Unassign"
                                                />{" "}
                                                <span>
                                                    <small>Unassign</small>
                                                </span>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </>
                        );
                    })}
                </tbody>
            </table>
            <Pagination
                count={count}
                data={teams?.selectedTeam?.members}
                setPage={setPage}
                page={page}
            />
        </div>
    );
}
