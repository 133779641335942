import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { api } from "../../../helpers/apiHelper/requestHelper";
import axios from "axios";
import { toastify } from "../../../helpers/toast/toastify";
import RenderElements from "./RenderElements";
import { setLoading } from "../../../redux/loader/loadingSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { replace } from "lodash";

const config = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
});
export default function FormRenderer() {
    const { formId, caseId } = useParams();
    const dispatch = useDispatch();
    const role = useSelector((state) => state?.userRoles?.currentRole);
    const [form, setForm] = useState();
    const [answers, setAnswers] = useState([]);
    const navigate = useNavigate();
    const { state } = useLocation();

    useEffect(() => {
        if (formId && caseId) {
            getData(formId, caseId);
        }
    }, [formId, caseId]);

    const getData = async () => {
        let response = await config.post("auth/getForm", {
            formId,
            caseId,
        });
        if (response?.data?.status) {
            setForm(response?.data?.data?.form?.form);
            setAnswers(response?.data?.data?.answers);
        } else {
            toastify("Unable to fetch form.");
        }
    };

    useEffect(() => {
        dispatch(setLoading(false));
    }, []);

    const upload = async (file, id) => {
        try {
            dispatch(setLoading(true));
            let fd = new FormData();
            fd.append("file", file);
            fd.append("type", "Internal Form");
            fd.append("relatedCase", caseId);
            let response = await config.post("auth/uploadFile", fd);
            console.log({ response });
            if (response?.data?.status) {
                let url = response?.data?.data;
                let found = answers.findIndex((e) => e.questionId === id);
                if (found > -1) {
                    let arr = [...answers];
                    arr[found]["value"] = url;
                    setAnswers(arr);
                } else {
                    setAnswers([...answers, { questionId: id, value: url }]);
                }
            } else {
                toastify("Unable to upload file. Please try again later.");
            }
        } catch (error) {
            console.log({ error });
            toastify(error?.response?.data?.message);
        }
        dispatch(setLoading(false));
    };

    const submit = async () => {
        let data = answers.map((e) => {
            return { ...e, formId, caseId };
        });

        let response = await config.post("auth/submitForm", { data });
        if (response?.data?.status) {
            // setForm(response?.data?.data?.form);
            toastify("Form submitted successfully.", "success");
            // window.opener.postMessage("childTabClosed", "*");
            // window.close();
            if (state?.role === "RM Team Leader") {
                navigate(`/rm-lead/all-cases/${caseId}`, {
                    state: { caseId },
                    replace: true,
                });
            } else {
                navigate(
                    `/${state.role
                        ?.toLowerCase()
                        ?.split(" ")
                        ?.join("-")}/all-cases/${caseId}`,
                    { state: { caseId }, replace: true }
                );
            }
        } else {
            toastify(response?.data?.message);
        }
    };

    const onChange = (id, value, options) => {
        let found = answers.findIndex((e) => e.questionId === id);

        if (options) {
            if (found > -1) {
                let arr = [...answers];
                arr[found]["options"] = options;
                setAnswers(arr);
            } else {
                setAnswers([...answers, { questionId: id, options }]);
            }
        } else {
            if (found > -1) {
                let arr = [...answers];
                arr[found]["value"] = value;
                setAnswers(arr);
            } else {
                setAnswers([...answers, { questionId: id, value }]);
            }
        }
    };

    return (
        <div className="form-render">
            {form &&
                form?.map((data) => (
                    <RenderElements
                        disabled={role === "Case Manager" ? true : false}
                        type={data?.type}
                        placeholder={data?.placeholder}
                        label={data?.label}
                        text={data?.text}
                        options={data?.options}
                        id={data?.id}
                        _id={data?._id}
                        url={data?.url}
                        filename={data?.filename}
                        submit={submit}
                        onChange={onChange}
                        upload={upload}
                        value={
                            answers?.find((e) => e.questionId === data?._id)
                                ?.value || ""
                        }
                        selectedOptions={
                            answers?.find((e) => e.questionId === data?._id)
                                ?.options || []
                        }
                    />
                ))}
            {role !== "Case Manager" && (
                <button className="simple-btn mb-20 mt-20" onClick={submit}>
                    Submit Form
                </button>
            )}
        </div>
    );
}
