import { Link, useNavigate } from "react-router-dom";
import "./login.scss";
import logoImage from "../../../assets/images/logo.png";
import { useEffect, useState } from "react";
import passwordIcon from "../../../assets/images/password-eye.png";
import { useDispatch, useSelector } from "react-redux";
import { api } from "../../../helpers/apiHelper/requestHelper";
import { toastify } from "../../../helpers/toast/toastify";
import PasswordChecklist from "react-password-checklist";

export const ForgotPassword = () => {
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [email, setEmail] = useState("");
    const [error, setError] = useState(false);
    const [OTP, setOTP] = useState("");
    const [showPassword1, setShowPassword1] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [isValidated, setIsValidated] = useState(false);
    const [forgotToken, setForgotToken] = useState("");
    const Auth = useSelector((state) => state.auth);
    const navigate = useNavigate();

    const loginFun = async () => {
        if (!newPassword || !confirmPassword) {
            toastify("Please enter passwords.");
        } else if (!isValidated) {
            toastify(
                "Password must be at least 8 alphanumeric characters including a special character."
            );
        } else if (newPassword !== confirmPassword) {
            toastify("New password and confirm password must be same");
        } else {
            const userData = {
                token: forgotToken,
                otp: OTP,
                password: newPassword,
                email: email,
            };
            const response = await api("post", "auth/verifyOtp", userData);
            if (response?.status) {
                toastify("Password changes successfully.", "success");
                navigate("/login");
            } else {
                toastify(response?.message, "error");
            }
        }
    };

    const SentEmailFun = async () => {
        if (!email) toastify("Please enter email address.");
        else {
            let response = await api("post", "auth/forgetPassword", { email });
            if (response?.status) {
                toastify("OTP Send to your registered email.", "success");
                setForgotToken(response?.data?.data?.data?.data?.token);
                setEmailSent(true);
            } else {
                toastify(response?.message, "error");
            }
        }
    };

    return (
        <div className="flex column-direction items-center content-justify-center main-form-wrapper">
            <div className="container">
                <div className="form-wrapper flex flex-wrap column-direction content-justify-center">
                    <img src={logoImage} alt="logo" />
                    <h1>Forgot Password</h1>
                    <ul className="list-style-none">
                        {!emailSent ? (
                            <>
                                <li>
                                    <label htmlFor="">email</label>
                                    <div className="form-group">
                                        <input
                                            className="form-control"
                                            type="email"
                                            value={email}
                                            onChange={(e) =>
                                                setEmail(e.target.value)
                                            }
                                            placeholder="Enter Email"
                                        />
                                    </div>
                                </li>
                                <li>
                                    <div className="flex button-wrapper">
                                        <Link
                                            to="/login"
                                            className="simple-btn cancel"
                                        >
                                            Cancel
                                        </Link>
                                        <button
                                            className="simple-btn"
                                            onClick={SentEmailFun}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </li>
                            </>
                        ) : (
                            <>
                                <li>
                                    <label htmlFor="">OTP</label>
                                    <div className="form-group">
                                        <input
                                            className="form-control"
                                            type="text"
                                            value={OTP}
                                            onChange={(e) =>
                                                setOTP(e.target.value)
                                            }
                                            // disabled={true}
                                            placeholder="OTP"
                                        />
                                    </div>
                                </li>
                                <li>
                                    <label htmlFor="">New Password</label>
                                    <div
                                        className={`form-group password ${
                                            showPassword1 ? "passwordHide" : ""
                                        }`}
                                    >
                                        <input
                                            className="form-control"
                                            type={
                                                showPassword1
                                                    ? "text"
                                                    : "password"
                                            }
                                            value={newPassword}
                                            onChange={(e) =>
                                                setNewPassword(e.target.value)
                                            }
                                            placeholder="New Password"
                                        />
                                        <img
                                            src={passwordIcon}
                                            onClick={() =>
                                                setShowPassword1(!showPassword1)
                                            }
                                            alt="password"
                                        />
                                    </div>
                                    <PasswordChecklist
                                        style={{ display: "none" }}
                                        className="mt-10 validator"
                                        rules={[
                                            "minLength",
                                            "specialChar",
                                            "number",
                                            "letter",
                                        ]}
                                        minLength={8}
                                        value={newPassword}
                                        onChange={(isValid) => {
                                            // console.log({ isValid });
                                            if (isValid) setIsValidated(true);
                                            else setIsValidated(false);
                                        }}
                                    />
                                </li>
                                <li>
                                    <label htmlFor="">Confirm Password</label>
                                    <div
                                        className={`form-group password ${
                                            showPassword2 ? "passwordHide" : ""
                                        }`}
                                    >
                                        <input
                                            className="form-control"
                                            type={
                                                showPassword2
                                                    ? "text"
                                                    : "password"
                                            }
                                            value={confirmPassword}
                                            onChange={(e) =>
                                                setConfirmPassword(
                                                    e.target.value
                                                )
                                            }
                                            placeholder="Confirm Password"
                                        />
                                        <img
                                            src={passwordIcon}
                                            onClick={() =>
                                                setShowPassword2(!showPassword2)
                                            }
                                            alt="password"
                                        />
                                    </div>
                                </li>
                                <li>
                                    <div className="flex button-wrapper">
                                        <Link
                                            to="/login"
                                            className="simple-btn cancel"
                                        >
                                            Cancel
                                        </Link>
                                        <button
                                            className="simple-btn"
                                            onClick={loginFun}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </li>
                            </>
                        )}
                    </ul>
                </div>
            </div>
        </div>
    );
};
