import { useState } from "react";
import "./modals.scss";

export const RejectionModal = (props) => {
    const [reason, setReason] = useState("");

    return (
        <div className={`modal-wrapper ${props.innerClass}`}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-body alert-modals">
                        <div className="modal-forms">
                            {props.modalTitle}
                            <textarea
                                placeholder="Description"
                                className="form-control mb-20"
                                onChange={(e) => setReason(e.target.value)}
                                value={reason}
                            />
                            <div className="flex content-justify-center">
                                <button
                                    onClick={props.setModalClick}
                                    className="modal-btn cancel"
                                >
                                    Cancel
                                </button>
                                {props.confirmBtn === true && (
                                    <button
                                        onClick={() =>
                                            props?.confirmAction(reason)
                                        }
                                        className="modal-btn"
                                    >
                                        Confirm
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
