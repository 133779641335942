export const sortTable = (type, array, param) => {
    let _array = [...array];

    if (type === "Cases") {
        if (param === "Case ID") {
            let newArry = _array.sort((a, b) =>
                a.identifier.localeCompare(b.identifier, undefined, {
                    numeric: true,
                })
            );
            if (JSON.stringify(array) === JSON.stringify(newArry)) {
                newArry = [...array].reverse();
            }
            return newArry;
        }

        if (param === "Lead ID") {
            let newArry = _array.sort((a, b) =>
                a?.lead?.identifier.localeCompare(
                    b?.lead?.identifier,
                    undefined,
                    {
                        numeric: true,
                    }
                )
            );
            if (JSON.stringify(array) === JSON.stringify(newArry)) {
                newArry = [...array].reverse();
            }
            return newArry;
        }

        if (param === "Created Date") {
            let newArry = _array.sort(
                (a, b) => new Date(a.created_at) - new Date(b.created_at)
            );
            if (JSON.stringify(array) === JSON.stringify(newArry)) {
                newArry = [...array].reverse();
            }
            return newArry;
        }

        if (param === "Product Name") {
            let newArry = _array.sort((a, b) =>
                a?.product?.name?.localeCompare(b?.product?.name)
            );
            if (JSON.stringify(array) === JSON.stringify(newArry)) {
                newArry = [...array].reverse();
            }
            return newArry;
        }

        if (param === "Entities Type") {
            let newArry = _array.sort((a, b) =>
                a?.lead?.entityType?.localeCompare(b?.lead?.entityType)
            );
            if (JSON.stringify(array) === JSON.stringify(newArry)) {
                newArry = [...array].reverse();
            }
            return newArry;
        }

        if (param === "Assigned To") {
            let newArry = _array.sort((a, b) =>
                a?.lead?.assignedTo?.username?.localeCompare(
                    b?.lead?.assignedTo?.username
                )
            );
            if (JSON.stringify(array) === JSON.stringify(newArry)) {
                newArry = [...array].reverse();
            }
            return newArry;
        }

        if (param === "Name") {
            let newArry = _array.sort((a, b) => {
                let aName =
                    a?.lead?.businessEntity?.representative?.firstName ||
                    a?.lead?.individualEntity?.firstName;
                let bName =
                    b?.lead?.businessEntity?.representative?.firstName ||
                    b?.lead?.individualEntity?.firstName;

                return aName.localeCompare(bName);
            });
            if (JSON.stringify(array) === JSON.stringify(newArry)) {
                newArry = [...array].reverse();
            }
            return newArry;
        }

        if (param === "Assigned From") {
            let newArry = _array.sort((a, b) =>
                a?.lead?.assignedFrom?.username?.localeCompare(
                    b?.lead?.assignedFrom?.username
                )
            );
            if (JSON.stringify(array) === JSON.stringify(newArry)) {
                newArry = [...array].reverse();
            }
            return newArry;
        }

        if (param === "Status") {
            let newArry = _array.sort((a, b) =>
                a?.status?.localeCompare(b?.status)
            );
            if (JSON.stringify(array) === JSON.stringify(newArry)) {
                newArry = [...array].reverse();
            }
            return newArry;
        }
    }
    if (type === "Leads") {
        if (param === "ID") {
            let newArry = _array.sort((a, b) =>
                a.identifier.localeCompare(b.identifier, undefined, {
                    numeric: true,
                })
            );
            if (JSON.stringify(array) === JSON.stringify(newArry)) {
                newArry = [...array].reverse();
            }
            return newArry;
        }

        if (param === "Entities Type") {
            let newArry = _array.sort((a, b) =>
                a?.entityType?.localeCompare(b?.entityType)
            );
            if (JSON.stringify(array) === JSON.stringify(newArry)) {
                newArry = [...array].reverse();
            }
            return newArry;
        }

        if (param === "Created Date") {
            let newArry = _array.sort(
                (a, b) => new Date(a.created_at) - new Date(b.created_at)
            );
            if (JSON.stringify(array) === JSON.stringify(newArry)) {
                newArry = [...array].reverse();
            }
            return newArry;
        }

        if (param === "Lead Type") {
            let newArry = _array.sort((a, b) =>
                a?.leadType?.localeCompare(b?.leadType)
            );
            if (JSON.stringify(array) === JSON.stringify(newArry)) {
                newArry = [...array].reverse();
            }
            return newArry;
        }

        if (param === "Assign To") {
            let newArry = _array.sort((a, b) =>
                a?.assignedTo?.username?.localeCompare(b?.assignedTo?.username)
            );
            if (JSON.stringify(array) === JSON.stringify(newArry)) {
                newArry = [...array].reverse();
            }
            return newArry;
        }

        if (param === "Assign From") {
            let newArry = _array.sort((a, b) =>
                a?.assignedFrom?.username?.localeCompare(
                    b?.assignedFrom?.username
                )
            );
            if (JSON.stringify(array) === JSON.stringify(newArry)) {
                newArry = [...array].reverse();
            }
            return newArry;
        }

        if (param === "Name") {
            let newArry = _array.sort((a, b) => {
                let aName =
                    a?.businessEntity?.representative?.firstName ||
                    a?.individualEntity?.firstName ||
                    "N/A";
                let bName =
                    b?.businessEntity?.representative?.firstName ||
                    b?.individualEntity?.firstName ||
                    "N/A";

                return aName.localeCompare(bName);
            });
            if (JSON.stringify(array) === JSON.stringify(newArry)) {
                newArry = [...array].reverse();
            }
            return newArry;
        }

        if (param === "Contact") {
            let newArry = _array.sort((a, b) => {
                let aContact =
                    a?.businessEntity?.representative?.contact ||
                    a?.individualEntity?.contact;
                let bContact =
                    b?.businessEntity?.representative?.contact ||
                    b?.individualEntity?.contact;

                return aContact.localeCompare(bContact);
            });
            if (JSON.stringify(array) === JSON.stringify(newArry)) {
                newArry = [...array].reverse();
            }
            return newArry;
        }

        if (param === "Status") {
            let newArry = _array.sort((a, b) =>
                a?.status?.localeCompare(b?.status)
            );
            if (JSON.stringify(array) === JSON.stringify(newArry)) {
                newArry = [...array].reverse();
            }
            return newArry;
        }

        if (param === "Team") {
            let newArry = _array.sort((a, b) =>
                a?.team?.name?.localeCompare(b?.team?.name)
            );
            if (JSON.stringify(array) === JSON.stringify(newArry)) {
                newArry = [...array].reverse();
            }
            return newArry;
        }
    }
};
