import Multiselect from "multiselect-react-dropdown";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { api } from "../../helpers/apiHelper/requestHelper";
import { missingPopup } from "../../helpers/dataHelper/missingData";
import { toastify } from "../../helpers/toast/toastify";
import { storeCases } from "../../redux/cases/casesSlice";
import "./modals.scss";
import { useEffect } from "react";

export const EmailsModal = (props) => {
    const [mailers, setMailers] = useState([]);

    useEffect(() => {
        let arr = [];
        let cs = props?.case?.lead;
        if (cs?.businessEntity?.email) {
            arr.push({
                email: cs?.businessEntity?.email,
                type: `Send Email to Business (${cs?.businessEntity?.name})`,
                agent: "Business Entity",
            });
        }
        if (cs?.businessEntity?.representative?.email) {
            arr.push({
                email: cs?.businessEntity?.representative?.email,
                type: `Send Email to Contact Person (${
                    cs?.businessEntity?.representative?.firstName +
                    " " +
                    cs?.businessEntity?.representative?.lastName
                })`,
                agent: "Contact Person",
            });
        }
        if (cs?.individualEntity?.email) {
            arr.push({
                email: cs?.individualEntity?.email,
                type: `Send Email to Individual (${
                    cs?.individualEntity?.firstName +
                    " " +
                    cs?.individualEntity?.lastName
                })`,
                agent: "Individual Entity",
            });
        }
        if (cs?.assignedTo?.email) {
            arr.push({
                email: cs?.assignedTo?.email,
                type: `Send Email to RM (${cs?.assignedTo?.username})`,
                agent: "RM",
            });
        }
        setMailers(arr);
    }, []);

    return (
        <div className={`modal-wrapper`}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-body ">
                        <div className="modal-forms">
                            <ul className="list-style-none">
                                <li>
                                    <div className="form-group">
                                        <label>
                                            Select the email you want to send
                                            document to
                                        </label>
                                        {/* <Multiselect
                                            isObject={true}
                                            showCheckbox
                                            options={props?.case?.caseDocuments?.filter(
                                                (e) => e.url
                                            )}
                                            selectedValues={props?.documents}
                                            onSelect={(e) =>
                                                props?.setDocuments(e)
                                            }
                                            onRemove={(e) =>
                                                props?.setDocuments(e)
                                            }
                                            displayValue="name"
                                        /> */}
                                        {mailers?.map((e) => (
                                            <div>
                                                <label
                                                    id={e.email}
                                                    className="check-box"
                                                >
                                                    <input
                                                        checked={props?.email?.find(
                                                            (f) =>
                                                                f.email ===
                                                                e.email
                                                        )}
                                                        type="checkbox"
                                                        name={e.email}
                                                        onChange={(v) => {
                                                            if (
                                                                v.target.checked
                                                            ) {
                                                                let emails = [
                                                                    ...props?.email,
                                                                ];
                                                                emails.push({
                                                                    email: e.email,
                                                                    agent: e.agent,
                                                                });

                                                                props.setEmail(
                                                                    emails
                                                                );
                                                            } else {
                                                                let emails = [
                                                                    ...props?.email,
                                                                ]?.filter(
                                                                    (f) =>
                                                                        f.email !==
                                                                        e.email
                                                                );

                                                                props.setEmail(
                                                                    emails
                                                                );
                                                            }
                                                        }}
                                                    />
                                                    <span className="no-text-decoration">
                                                        {e.type} {e.email}
                                                    </span>
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </li>
                                <li>
                                    <div className="flex content-justify-center">
                                        <button
                                            onClick={() => {
                                                props?.setModal(false);
                                                props.setEmail([]);
                                            }}
                                            className="modal-btn cancel"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            onClick={() => {
                                                if (props.email?.length === 0) {
                                                    missingPopup("Email");
                                                } else {
                                                    props?.confirm();
                                                }
                                            }}
                                            className="modal-btn"
                                        >
                                            Confirm
                                        </button>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
