import { Accordion } from "react-accessible-accordion";
// import AllLeadAccordions from "../rm-dashboard/accordion-listing/lead-accordions/AllLeadAccordions";
import React from "react";
import Statistics from "../../rm-lead/leads/statistics/Statistics";
import { useSelector } from "react-redux";
import AllLeadAccordionsRmLead from "../../rm-lead/rm-dashboard/accordion-listing/lead-accordions/AllLeadAccordions";
import AllLeadAccordionsRmMember from "../../rm-member/rm-dashboard/accordion-listing/lead-accordions/AllLeadAccordions";
import AllLeadAccordions from "../rm-dashboard/accordion-listing/lead-accordions/AllLeadAccordions";

export default function Leads() {
    const userRoles = useSelector((state) => state.userRoles);

    return (
        <div className="accordion-list-wrapper">
            <Statistics />
            <br />
            <h2 className="section-title">Leads</h2>
            <Accordion allowZeroExpanded>
                {userRoles.currentRole === "Lead Coordinator" ? (
                    <>
                        <AllLeadAccordions
                            filter={"all"}
                            arrow={true}
                            arrowLink={
                                userRoles.currentRole === "RM Team Leader"
                                    ? "/rm-lead/leads/all"
                                    : userRoles.currentRole === "RM Member"
                                    ? "/rm-member/leads/all"
                                    : userRoles.currentRole ===
                                      "Lead Coordinator"
                                    ? "/lead-coordinator/leads/all"
                                    : "/leads/all"
                            }
                        />
                        <AllLeadAccordions filter={"assigned"} />
                        <AllLeadAccordions filter={"unassigned"} />
                    </>
                ) : userRoles.currentRole === "RM Team Leader" ? (
                    <>
                        <AllLeadAccordionsRmLead
                            filter={"all"}
                            arrow={true}
                            arrowLink={
                                userRoles.currentRole === "RM Team Leader"
                                    ? "/rm-lead/leads/all"
                                    : userRoles.currentRole === "RM Member"
                                    ? "/rm-member/leads/all"
                                    : userRoles.currentRole ===
                                      "Lead Coordinator"
                                    ? "/lead-coordinator/leads/all"
                                    : "/leads/all"
                            }
                        />
                        <AllLeadAccordionsRmLead filter={"assigned"} />
                        <AllLeadAccordionsRmLead filter={"unassigned"} />
                    </>
                ) : (
                    <>
                        <AllLeadAccordionsRmMember
                            filter={"all"}
                            arrow={true}
                            arrowLink={
                                userRoles.currentRole === "RM Team Leader"
                                    ? "/rm-lead/leads/all"
                                    : userRoles.currentRole === "RM Member"
                                    ? "/rm-member/leads/all"
                                    : userRoles.currentRole ===
                                      "Lead Coordinator"
                                    ? "/lead-coordinator/leads/all"
                                    : "/leads/all"
                            }
                        />
                        <AllLeadAccordionsRmMember filter={"assigned"} />
                        <AllLeadAccordionsRmMember filter={"unassigned"} />
                    </>
                )}

                {/* <AssignedLeadAccordions filter={"assigned"} /> */}
                {/* <UnAssignedLeadAccordions filter={"unassigned"} /> */}
            </Accordion>
        </div>
    );
}
