import React, { useState } from "react";
import DatePicker from "react-date-picker";
import { useSelector } from "react-redux";
import AreaReportGenerator from "../../components/reports/AreaReportGenerator";
import BarChartGenerator from "../../components/reports/BarChartGenerator";
import { api } from "../../helpers/apiHelper/requestHelper";
import Members from "../../pages/rm-lead/rm-dashboard/members/Members";
import "./styles.scss";
import lowIcon from "../../assets/images/lowIcon.svg";
import userIcon from "../../assets/images/users.svg";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import ReportTables from "./ReportTables";
import { useDispatch } from "react-redux";
import { setLoading } from "../../redux/loader/loadingSlice";
import { formatCurrency } from "../../helpers/dataHelper/currentFormatter";

export default function RMMemberReports() {
    const reduxData = useSelector((state) => state);
    const [activeTab, setActiveTab] = useState("tab2");
    const [pendingRev, setPendingRev] = useState("0");
    const [ownRev, setOwnRev] = useState("0");
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [leads, setLeads] = useState([]);
    const [cases, setCases] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        let year = new Date().getFullYear();
        setStartDate(new Date(year, 0, 1));
        setEndDate(new Date());
    }, []);

    useEffect(() => {
        if (reduxData?.auth) {
            getMembersData();
            getStartDate();
        }
    }, [reduxData?.auth]);

    const getStartDate = async () => {
        let response = await api("get", "settings/getDateSettings");

        if (response?.status) {
            setStartDate(new Date(response?.data?.startDate));
        }
    };

    const getMembersData = async () => {
        const response = await api("post", "teams/getMembersByTeam", {
            id: reduxData?.auth?.user?.team?._id,
        });
        if (response?.data?.status) {
            setPendingRev(response?.data?.data?.pendingCaseRevenue);
            // setOwnRev(response?.data?.data?.ownTotalRevenue);
        }
    };

    return (
        <>
            <div className="rml-container">
                <text>Report</text>
                <div className="flex">
                    <div>
                        <p>Start Date</p>
                        <DatePicker
                            onChange={(date) => setStartDate(date)}
                            selectsStart
                            startDate={new Date(startDate)}
                            endDate={new Date(endDate)}
                            className="form-control"
                            onKeyDown={(e) => {
                                e.preventDefault();
                            }}
                            value={startDate}
                            format="dd/MM/y"
                        />
                    </div>
                    <div className="ml-20">
                        <p>End Date</p>
                        <DatePicker
                            onChange={(date) => setEndDate(date)}
                            selectsEnd
                            startDate={startDate}
                            endDate={endDate}
                            minDate={startDate}
                            value={endDate}
                            className="form-control"
                            onKeyDown={(e) => {
                                e.preventDefault();
                            }}
                            format="dd/MM/y"
                        />
                    </div>
                </div>
            </div>

            <ReportTables startDate={startDate} endDate={endDate} />
        </>
    );
}
