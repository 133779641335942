import "./modals.scss";
import "../../pages/shared/FolderStructure.scss";

export const DeleteModal = (props) => {
    return (
        <div className={`modal-wrapper`}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div
                        className="modal-body alert-modals"
                        style={{ paddingBottom: 20, paddingTop: 30 }}
                    >
                        <div className="modal-forms">
                            <div className="bold mb-20 fs-18">
                                Delete {props?.count} Selected
                            </div>
                            <div className="mb-20">
                                Do you want to delete selected?
                            </div>
                            <div className="flex content-justify-center mt-20 custom-button-group">
                                <button
                                    onClick={() => props.cancel()}
                                    className="modal-btn cancel"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={() => props?.confirm()}
                                    className="simple-btn"
                                >
                                    Delete
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
