import Search from "../../../components/search/Search";
import { Link, useNavigate } from "react-router-dom";
import update from "../../../assets/images/update.svg";
import del from "../../../assets/images/del.svg";
import EntriesSelection from "../../../components/pagination/EntriesSelection";
import Pagination from "../../../components/pagination/Pagination";
import { api } from "../../../helpers/apiHelper/requestHelper";
import { toastify } from "../../../helpers/toast/toastify";
import { useEffect } from "react";
import { useState } from "react";
import { AlertModal } from "../../../components/modals/AlertModal";
import { setLoading } from "../../../redux/loader/loadingSlice";
import { useDispatch } from "react-redux";
import { setSidebar } from "../../../helpers/sidebar/setSidebar";

export default function FormTemplate() {
    const [templates, setTemplates] = useState([]);
    const [show, setShow] = useState(false);
    const [temp, setTemp] = useState();
    const [filtered, setFiltered] = useState([]);
    const [count, setCount] = useState(5);
    const [page, setPage] = useState(1);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        setSidebar("Form Template");
        getTemplates();
    }, []);

    useEffect(() => {
        setFiltered(templates);
    }, [templates]);

    const handleDelete = (data) => {
        setShow(true);
        setTemp(data);
    };

    const confirmAction = async () => {
        dispatch(setLoading(true));
        const response = await api("post", "templates/update", {
            id: temp?._id,
            isDeleted: true,
        });
        dispatch(setLoading(false));

        if (response?.data?.status) {
            toastify("Template Deleted Successfully.", "success");
            getTemplates();
        } else {
            toastify(response?.message, "error");
        }
        setShow(false);
    };

    const getTemplates = async () => {
        const response = await api("get", "templates/index");
        if (response?.status) {
            setTemplates(response?.data?.reverse());
        } else {
            toastify(response?.message);
        }
    };

    return (
        <>
            <h4 className="flex flex-wrap mini-title">Form Template</h4>
            <Search
                userSearch={true}
                filedText="Search here"
                teamDropdown={false}
                filters={false}
                addButton={true}
                addButtonText="Add Template"
                buttonRoute="/online-form"
                setTemplates={setFiltered}
                templates={templates}
                component={"Templates"}
            />
            {show && (
                <AlertModal
                    confirmAction={confirmAction}
                    setModalClick={() => setShow(false)}
                    confirmBtn={true}
                    modalTitle={[
                        <h2>
                            Do you really want to delete {temp?.name || "N/A"}
                            <br /> Template permanently?
                        </h2>,
                    ]}
                    modalDescription={[]}
                />
            )}
            <div className="responsive-table w-100">
                <table className="table">
                    <thead>
                        <tr
                            className="no-shadow"
                            style={{ background: "#f6f6f6" }}
                        >
                            <td colSpan="6" height="16"></td>
                        </tr>
                        <tr>
                            <th>Form Name</th>
                            <th className="description">Description</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filtered
                            ?.slice(count * page - count, count * page)
                            .map((data) => (
                                <>
                                    <tr className="no-shadow">
                                        <td colSpan="6" height="16"></td>
                                    </tr>
                                    <tr key={data?._id}>
                                        <td>{data?.name}</td>
                                        <td>{data?.description}</td>
                                        <td>
                                            <span
                                                className={`status ${
                                                    data.isActive
                                                        ? "active"
                                                        : "nonactive"
                                                }`}
                                            >
                                                {data.isActive
                                                    ? "Active"
                                                    : "Inactive"}
                                            </span>
                                        </td>

                                        <td>
                                            <div className="flex items-center">
                                                <div
                                                    className="table-action update cursor-pointer"
                                                    onClick={() =>
                                                        navigate(
                                                            "/online-form",
                                                            {
                                                                state: {
                                                                    template:
                                                                        data,
                                                                },
                                                            }
                                                        )
                                                    }
                                                >
                                                    <img
                                                        src={update}
                                                        alt="update"
                                                    />
                                                    <span>
                                                        <small>Update</small>
                                                    </span>
                                                </div>
                                                <div
                                                    onClick={() => {
                                                        handleDelete(data);
                                                    }}
                                                    className="table-action del cursor-pointer"
                                                >
                                                    <img src={del} alt="del" />
                                                    <span>
                                                        <small>Delete</small>
                                                    </span>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </>
                            ))}
                    </tbody>
                </table>
                <div className="flex pagination-combine">
                    <EntriesSelection
                        setCount={setCount}
                        page={page}
                        count={count}
                        data={filtered}
                    />
                    <Pagination
                        count={count}
                        data={filtered}
                        setPage={setPage}
                        page={page}
                    />
                </div>
            </div>
        </>
    );
}
