import { Link, useNavigate } from "react-router-dom";
import { useRef, useState } from "react";
import user from "../../../assets/images/user.png";
import Scheduler, { Editing } from "devextreme-react/scheduler";
import Calendar from "react-calendar";
import { useSelector } from "react-redux";
import { toastify } from "../../../helpers/toast/toastify";
import { api } from "../../../helpers/apiHelper/requestHelper";
import { useEffect } from "react";
import Appointment from "./Appointment";
import { useDispatch } from "react-redux";
import { setRoute } from "../../../redux/route/routeSlice";
import { setSidebar } from "../../../helpers/sidebar/setSidebar";
import { shortFormatDate } from "../../../helpers/dataHelper/dateFormatter";
import { setLoading } from "../../../redux/loader/loadingSlice";
import { AlertModal } from "../../../components/modals/AlertModal";

export default function Schedule() {
    const [date, setDate] = useState(new Date());
    const [schedules, setSchedules] = useState([]);
    const [active, setActive] = useState();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedSchedule, setSelectedSchedule] = useState();
    const reduxData = useSelector((state) => state);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const update = (data) => {
        navigate("/schedule/add-schedule", { state: { schedule: data } });
    };

    const onAppointmentFormOpening = (e) => {
        const { form, appointmentData } = e;

        let options = [
            {
                label: {
                    text: "Title",
                },
                editorType: "dxTextBox",
                editorOptions: {
                    value: appointmentData?.text,
                    readOnly: true,
                },
            },
            {
                label: {
                    text: "Date",
                },
                editorType: "dxTextBox",
                editorOptions: {
                    value: shortFormatDate(appointmentData?.startDate),
                    readOnly: true,
                },
            },
            {
                label: {
                    text: "Start Time",
                },
                editorType: "dxTextBox",
                editorOptions: {
                    value: `${appointmentData?.startTime} hours`,
                    readOnly: true,
                },
            },
            {
                label: {
                    text: "End Time",
                },
                editorType: "dxTextBox",
                editorOptions: {
                    value: `${appointmentData?.endTime} hours`,
                    readOnly: true,
                },
            },
            {
                label: {
                    text: "Remarks",
                },
                editorType: "dxTextArea",
                editorOptions: {
                    value: appointmentData?.remarks,
                    readOnly: true,
                },
            },
        ];
        if (
            reduxData?.auth?.user?._id === appointmentData?.createdBy ||
            reduxData?.userRoles?.currentRole === "Admin"
        ) {
            options.push({
                label: {
                    text: "Actions",
                },
                editorType: "dxButtonGroup",
                editorOptions: {
                    items: [
                        {
                            text: "Update",
                            type: "success",
                            onClick: () => {
                                update(appointmentData);
                            },
                        },
                        {
                            text: "Delete",
                            type: "danger",
                            onClick: () => {
                                setSelectedSchedule(appointmentData?._id);
                                setShowDeleteModal(true);
                            },
                        },
                    ],
                },
            });
        }
        form.option("items", options);
    };

    const check = (n) => {
        if (active === n) return "active";
        else return "";
    };

    const getSchedules = async () => {
        dispatch(setLoading(true));
        let response = await api(
            "get",
            reduxData?.userRoles?.currentRole === "Admin"
                ? "schedules/unfilteredList"
                : "schedules/index"
        );
        dispatch(setLoading(false));
        if (response?.status) {
            setSchedules(response?.data);
        } else {
            toastify(response?.message, "error");
        }
    };

    useEffect(() => {
        setSidebar("Scheduler");
        getSchedules();
    }, []);

    const select = (week) => {
        let daysToAdd = week * 7;
        let _date = new Date();
        _date.setDate(_date.getDate() + daysToAdd);
        setDate(_date);

        setActive(week);
    };

    const confirmAction = () => {
        deleteSchedule();
    };

    const deleteSchedule = async () => {
        dispatch(setLoading(true));
        const response = await api("post", "schedules/update", {
            id: selectedSchedule,
            isDeleted: true,
        });
        console.log({ response });
        if (response?.status) {
            toastify("Schedule deleted successfully.", "success");
            setShowDeleteModal(false);
            setSelectedSchedule();
            getSchedules();
        } else {
            toastify(response?.message);
        }
        dispatch(setLoading(false));
    };

    return (
        <>
            {showDeleteModal && (
                <AlertModal
                    confirmAction={confirmAction}
                    setModalClick={() => {
                        setSelectedSchedule();
                        setShowDeleteModal(false);
                    }}
                    confirmBtn={true}
                    modalTitle={[
                        <h2>Do you really want to delete the schedule?</h2>,
                    ]}
                    modalDescription={[]}
                    sendId={true}
                />
            )}
            <h4 className="flex flex-wrap mini-title">Scheduler</h4>
            <div className="flex w-100">
                <Link
                    to="/schedule/add-schedule"
                    className="ml-auto simple-btn"
                >
                    Add Schedule
                </Link>
            </div>
            <div className="flex scheduler-wrapper">
                <div className="calender-date-wrap">
                    <Scheduler
                        // dataCellComponent={DataCell}
                        // resourceCellComponent={ResourceCell}
                        // allowAdding={false}
                        // appointmentRender={(e) => console.log(e)}
                        // appointmentComponent={Appointment}
                        // groups={["employeeID"]}
                        // showAllDayPanel={true}
                        // views={["day", "week", "month"]}
                        views={[]}
                        dataSource={schedules?.map((s) => {
                            return {
                                allDay: true,
                                text: s?.title,
                                type: s?.type,
                                startDate: new Date(s?.date),
                                startTime: s?.startTime,
                                endTime: s?.endTime,
                                remarks: s?.remarks,
                                createdBy: s?.createdBy,
                                participants: s?.participants,
                                lead: s?.lead,
                                _id: s?._id,
                            };
                        })}
                        timeZone={
                            Intl.DateTimeFormat().resolvedOptions().timeZone ||
                            "Asia/Singapore"
                        }
                        defaultCurrentView="month"
                        defaultCurrentDate={new Date()}
                        height={600}
                        firstDayOfWeek={1}
                        startDayHour={0}
                        endDayHour={24}
                        editing={false}
                        onCurrentDateChange={(e) => setDate(e)}
                        currentDate={date}
                        onAppointmentFormOpening={onAppointmentFormOpening}
                    >
                        <Editing allowDragging={false} />
                    </Scheduler>
                </div>
                <div className="calender-user">
                    <section>
                        <figure>
                            <img src={user} alt="user" />
                            <figcaption>
                                <h6>
                                    {reduxData?.auth?.user?.username || "N / A"}
                                </h6>
                                <span>{reduxData?.userRoles?.currentRole}</span>
                            </figcaption>
                        </figure>
                    </section>
                    <Calendar onChange={(e) => setDate(e)} value={date} />
                    <div className="jump-date flex column-direction">
                        <h2>Jump By Week</h2>
                        <ul className="list-style-none flex">
                            <li onClick={() => select("+1")}>
                                <span className={check("+1")}>+1</span>
                            </li>
                            <li onClick={() => select("+2")}>
                                <span className={check("+2")}>+2</span>
                            </li>
                            <li onClick={() => select("+3")}>
                                <span className={check("+3")}>+3</span>
                            </li>
                            <li onClick={() => select("+4")}>
                                <span className={check("+4")}>+4</span>
                            </li>
                            <li onClick={() => select("+5")}>
                                <span className={check("+5")}>+5</span>
                            </li>
                            <li onClick={() => select("+6")}>
                                <span className={check("+6")}>+6</span>
                            </li>
                            <li onClick={() => select("+7")}>
                                <span className={check("+7")}>+7</span>
                            </li>
                        </ul>
                        <ul className="list-style-none flex">
                            <li onClick={() => select("-1")}>
                                <span className={check("-1")}>-1</span>
                            </li>
                            <li onClick={() => select("-2")}>
                                <span className={check("-2")}>-2</span>
                            </li>
                            <li onClick={() => select("-3")}>
                                <span className={check("-3")}>-3</span>
                            </li>
                            <li onClick={() => select("-4")}>
                                <span className={check("-4")}>-4</span>
                            </li>
                            <li onClick={() => select("-5")}>
                                <span className={check("-5")}>-5</span>
                            </li>
                            <li onClick={() => select("-6")}>
                                <span className={check("-6")}>-6</span>
                            </li>
                            <li onClick={() => select("-7")}>
                                <span className={check("-7")}>-7</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}
