import { Accordion } from "react-accessible-accordion";
import AllLeadAccordions from "../rm-dashboard/accordion-listing/lead-accordions/AllLeadAccordions";
import AllCaseAccordions from "../rm-dashboard/accordion-listing/case-accordions/AllCaseAccordions";
import React, { useState } from "react";
import filterIcon from "../../../assets/images/user.svg";
import { useSelector } from "react-redux";
import { checkTeamLead, getTeam } from "../../../helpers/dataHelper/teamHelper";

export default function RMTeam() {
    const reduxData = useSelector((state) => state);
    const [user, setUser] = useState("");

    return (
        <>
            <div className="flex content-justify-end">
                <div className="search-filter single-sort">
                    <img src={filterIcon} alt="filterIcon" />
                    <select
                        className="form-control "
                        disabled={!reduxData?.auth?.user?.team}
                        value={user}
                        onChange={(e) => setUser(e.target.value)}
                    >
                        <option value="">Select Team Member</option>
                        {getTeam(
                            reduxData?.allTeams?.value?.teams,
                            reduxData?.auth?.user?.team?._id
                        )?.members?.map((e) => (
                            <option value={e?._id}>
                                {e.username}
                                {checkTeamLead(
                                    getTeam(
                                        reduxData?.allTeams?.value?.teams,
                                        reduxData?.auth?.user?.team?._id
                                    ),
                                    e._id
                                ) && " ( Leader )"}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <h2 className="section-title">Leads</h2>
            <Accordion allowZeroExpanded>
                <AllLeadAccordions
                    filter={"all"}
                    arrow={true}
                    arrowLink={
                        reduxData?.userRoles.currentRole === "RM Team Leader"
                            ? "/rm-lead/leads/all"
                            : reduxData?.userRoles.currentRole === "RM Member"
                            ? "/rm-member/leads/all"
                            : reduxData?.userRoles.currentRole ===
                              "Lead Coordinator"
                            ? "/lead-coordinator/leads/all"
                            : "/leads/all"
                    }
                    user={user}
                />
                <AllLeadAccordions filter={"reassigned"} user={user} />
            </Accordion>
            <h2 className="section-title">Cases</h2>
            <Accordion allowZeroExpanded>
                <AllCaseAccordions filter={"all"} user={user} />
            </Accordion>
        </>
    );
}
