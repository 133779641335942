import Multiselect from "multiselect-react-dropdown";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { api } from "../../../helpers/apiHelper/requestHelper";
import { missingPopup } from "../../../helpers/dataHelper/missingData";
import { toastify } from "../../../helpers/toast/toastify";

export default function AddNotification() {
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [participants, setParticipants] = useState([]);
    const [remarks, setRemarks] = useState("");
    const [title, setTitle] = useState("");

    const getUsers = async () => {
        let response = await api("get", "users/usersList");
        if (response?.status) {
            setUsers(response?.data?.reverse());
        } else {
            toastify(response?.message, "error");
        }
    };

    useEffect(() => {
        getUsers();
    }, []);

    const cancel = () => {
        navigate(-1);
    };

    const submit = async () => {
        if (!title) missingPopup("Title");
        else if (!remarks) missingPopup("Remarks");
        else if (participants.length === 0) missingPopup("Sent To");
        else {
            let data = {
                participants: participants?.map((e) => {
                    return { user: e._id, acknowledged: false };
                }),
                remarks,
                title,
            };

            await api("post", "notifications/create", data);
            toastify("Notification Created Successfully.", "success");
            navigate("/notification");
        }
    };

    return (
        <>
            <h4 className="flex flex-wrap mini-title">
                <span onClick={() => navigate("/notification")}>
                    Notifications{" "}
                </span>
                / Add Notification{" "}
            </h4>
            <div className="card">
                <div className="card-body">
                    <div className="edit-form-wrapper">
                        <ul className="list-style-none flex flex-wrap">
                            <li className="w-100">
                                <ul className="list-style-none">
                                    <li>
                                        <div className="form-group">
                                            <label htmlFor="">
                                                Topic / Title *
                                            </label>
                                            <input
                                                value={title}
                                                type="text"
                                                className="form-control mandatory"
                                                placeholder="Topic / Title"
                                                onChange={(e) =>
                                                    setTitle(e.target.value)
                                                }
                                            />
                                        </div>
                                    </li>
                                </ul>
                            </li>
                            <li className="w-100">
                                <ul className="list-style-none">
                                    <li>
                                        <div className="form-group">
                                            <label htmlFor="">Remarks *</label>
                                            <textarea
                                                className="form-control mandatory"
                                                placeholder="Remarks"
                                                onChange={(e) =>
                                                    setRemarks(e.target.value)
                                                }
                                                value={remarks}
                                            ></textarea>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                            <li className="w-100">
                                <ul className="list-style-none">
                                    <li>
                                        <div className="form-group">
                                            <label htmlFor="">Send to *</label>
                                            <Multiselect
                                                className="mandatory-multi"
                                                showCheckbox
                                                options={users}
                                                selectedValues={participants}
                                                onSelect={setParticipants}
                                                onRemove={setParticipants}
                                                displayValue={"username"}
                                            />
                                        </div>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="flex items-start content-justify-center  pb-150">
                <button className="simple-btn cancel" onClick={cancel}>
                    Cancel
                </button>
                <button className="simple-btn" onClick={submit}>
                    Submit
                </button>
            </div>
        </>
    );
}
