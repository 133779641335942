import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel,
} from "react-accessible-accordion";
import React from "react";
import { Link } from "react-router-dom";
import {
    formatDate,
    shortFormatDate,
} from "../../../../helpers/dataHelper/dateFormatter";

export default function BusinessInformation(props) {
    return (
        <div className="case-information">
            <Accordion
                allowZeroExpanded
                allowMultipleExpanded
                preExpanded={["1", "2"]}
            >
                <AccordionItem uuid="1">
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            {props?.lead?.entityType ||
                                props?.case?.lead?.entityType}{" "}
                            Information
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <div className="business-information-list">
                            {(props?.lead?.entityType
                                ? props?.lead?.entityType
                                : props?.case?.lead?.entityType) ===
                            "Business" ? (
                                <ul className="list-style-none">
                                    <li>
                                        <h4>Company Name</h4>
                                        <span>
                                            {props?.lead?.businessEntity
                                                ?.name ||
                                                props?.case?.lead
                                                    ?.businessEntity?.name}
                                        </span>
                                    </li>
                                    <li>
                                        <h4>Email</h4>
                                        <span>
                                            {props?.lead?.businessEntity
                                                ?.email ||
                                                props?.case?.lead
                                                    ?.businessEntity?.email}
                                        </span>
                                    </li>
                                    <li>
                                        <h4>Company UEN</h4>
                                        <span>
                                            {props?.lead?.businessEntity?.uen ||
                                                props?.case?.lead
                                                    ?.businessEntity?.uen}
                                        </span>
                                    </li>
                                    <li>
                                        <h4>Register Address</h4>
                                        <div className="ml-auto">
                                            {(
                                                props?.lead?.businessEntity
                                                    ?.addresses ||
                                                props?.case?.lead
                                                    ?.businessEntity?.addresses
                                            )?.map((e) => (
                                                <div>{e}</div>
                                            ))}
                                        </div>
                                    </li>
                                    <li>
                                        <h4>Representative</h4>
                                        <span>
                                            <Link to="#">
                                                {props?.lead
                                                    ? props?.lead
                                                          ?.businessEntity
                                                          ?.representative
                                                          ?.firstName +
                                                      " " +
                                                      props?.lead
                                                          ?.businessEntity
                                                          ?.representative
                                                          ?.lastName
                                                    : props?.case?.lead
                                                          ?.businessEntity
                                                          ?.representative
                                                          ?.firstName +
                                                      " " +
                                                      props?.case?.lead
                                                          ?.businessEntity
                                                          ?.representative
                                                          ?.lastName}
                                            </Link>
                                        </span>
                                    </li>
                                    <li>
                                        <h4>Representative's Contact Number</h4>
                                        <span>
                                            <Link to="#">
                                                {props?.lead
                                                    ? props?.lead
                                                          ?.businessEntity
                                                          ?.representative
                                                          ?.contact || "N/A"
                                                    : props?.case?.lead
                                                          ?.businessEntity
                                                          ?.representative
                                                          ?.contact || "N/A"}
                                            </Link>
                                        </span>
                                    </li>
                                    <li>
                                        <h4>Customer Interests</h4>
                                        <div className=" ml-auto">
                                            {(
                                                props?.lead?.businessEntity
                                                    ?.customerInterests ||
                                                props?.case?.lead
                                                    ?.businessEntity
                                                    ?.customerInterests
                                            )?.map((e, i) => (
                                                <span>
                                                    {e.name}
                                                    {i !==
                                                        (
                                                            props?.lead
                                                                ?.businessEntity
                                                                ?.customerInterests ||
                                                            props?.case?.lead
                                                                ?.businessEntity
                                                                ?.customerInterests
                                                        )?.length -
                                                            1 && ", "}
                                                </span>
                                            ))}
                                        </div>
                                    </li>
                                    <li>
                                        <h4>Customer 1</h4>
                                        <div className="ml-auto">
                                            {
                                                (
                                                    props?.lead
                                                        ?.businessEntity ||
                                                    props?.case?.lead
                                                        ?.businessEntity
                                                )?.customers[0]
                                            }
                                        </div>
                                    </li>
                                    <li>
                                        <h4>Customer 2</h4>
                                        <div className="ml-auto">
                                            {
                                                (
                                                    props?.lead
                                                        ?.businessEntity ||
                                                    props?.case?.lead
                                                        ?.businessEntity
                                                )?.customers[1]
                                            }
                                        </div>
                                    </li>
                                    <li>
                                        <h4>Supplier 1</h4>
                                        <div className="ml-auto">
                                            {
                                                (
                                                    props?.lead
                                                        ?.businessEntity ||
                                                    props?.case?.lead
                                                        ?.businessEntity
                                                )?.suppliers[0]
                                            }
                                        </div>
                                    </li>
                                    <li>
                                        <h4>Supplier 2</h4>
                                        <div className="ml-auto">
                                            {
                                                (
                                                    props?.lead
                                                        ?.businessEntity ||
                                                    props?.case?.lead
                                                        ?.businessEntity
                                                )?.suppliers[1]
                                            }
                                        </div>
                                    </li>
                                </ul>
                            ) : (
                                <ul className="list-style-none">
                                    <li>
                                        <h4>First Name</h4>
                                        <span>
                                            {props?.lead?.individualEntity
                                                ?.firstName ||
                                                props?.case?.lead
                                                    ?.individualEntity
                                                    ?.firstName}
                                        </span>
                                    </li>
                                    <li>
                                        <h4>Last Name</h4>
                                        <span>
                                            {props?.lead?.individualEntity
                                                ?.lastName ||
                                                props?.case?.lead
                                                    ?.individualEntity
                                                    ?.lastName}
                                        </span>
                                    </li>
                                    <li>
                                        <h4>Gender</h4>
                                        <span>
                                            {props?.lead?.individualEntity
                                                ?.gender ||
                                                props?.case?.lead
                                                    ?.individualEntity?.gender}
                                        </span>
                                    </li>
                                    <li>
                                        <h4>Date of Birth</h4>
                                        <span>
                                            {props?.lead
                                                ? shortFormatDate(
                                                      props?.lead
                                                          ?.individualEntity
                                                          ?.dob
                                                  )
                                                : shortFormatDate(
                                                      props?.case?.lead
                                                          ?.individualEntity
                                                          ?.dob
                                                  )}
                                        </span>
                                    </li>
                                    <li>
                                        <h4>Email</h4>
                                        <span>
                                            {props?.lead?.individualEntity
                                                ?.email ||
                                                props?.case?.lead
                                                    ?.individualEntity?.email}
                                        </span>
                                    </li>
                                    <li>
                                        <h4>NRIC</h4>
                                        <span>
                                            {props?.lead?.individualEntity
                                                ?.nric ||
                                                props?.case?.lead
                                                    ?.individualEntity?.nric}
                                        </span>
                                    </li>
                                    <li>
                                        <h4>Contact Number</h4>
                                        <span>
                                            {props?.lead?.individualEntity
                                                ?.contact ||
                                                props?.case?.lead
                                                    ?.individualEntity?.contact}
                                        </span>
                                    </li>
                                    <li>
                                        <h4>Postal Code</h4>
                                        <span>
                                            {props?.lead?.individualEntity
                                                ?.postalCode ||
                                                props?.case?.lead
                                                    ?.individualEntity
                                                    ?.postalCode}
                                        </span>
                                    </li>
                                    <li>
                                        <h4>City</h4>
                                        <span>
                                            {props?.lead?.individualEntity
                                                ?.city ||
                                                props?.case?.lead
                                                    ?.individualEntity?.city}
                                        </span>
                                    </li>
                                    <li>
                                        <h4>State</h4>
                                        <span>
                                            {props?.lead?.individualEntity
                                                ?.state ||
                                                props?.case?.lead
                                                    ?.individualEntity?.state}
                                        </span>
                                    </li>
                                    <li>
                                        <h4>Country</h4>
                                        <span>
                                            {props?.lead?.individualEntity
                                                ?.country ||
                                                props?.case?.lead
                                                    ?.individualEntity?.country}
                                        </span>
                                    </li>
                                    <li>
                                        <h4>Register Address</h4>
                                        <div className=" ml-auto">
                                            {(
                                                props?.lead?.individualEntity
                                                    ?.addresses ||
                                                props?.case?.lead
                                                    ?.individualEntity
                                                    ?.addresses
                                            )?.map((e) => (
                                                <div>{e}</div>
                                            ))}
                                        </div>
                                    </li>
                                    <li>
                                        <h4>Customer Interests</h4>
                                        <div className=" ml-auto">
                                            {(
                                                props?.lead?.individualEntity
                                                    ?.customerInterests ||
                                                props?.case?.lead
                                                    ?.individualEntity
                                                    ?.customerInterests
                                            )?.map((e, i) => (
                                                <span>
                                                    {e.name}
                                                    {i !==
                                                        (
                                                            props?.lead
                                                                ?.individualEntity
                                                                ?.customerInterests ||
                                                            props?.case?.lead
                                                                ?.individualEntity
                                                                ?.customerInterests
                                                        )?.length -
                                                            1 && ", "}
                                                </span>
                                            ))}
                                        </div>
                                    </li>
                                </ul>
                            )}
                        </div>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem uuid="2">
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Lead Activity Log
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <div className="history">
                            <div className="responsive-table lead-active-log-table">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Date & Time</th>
                                            <th>Operator</th>
                                            <th>Role</th>
                                            <th className="description">
                                                Details
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(
                                            props?.lead?.logs ||
                                            props?.case?.lead?.logs
                                        )?.map((data, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>
                                                        {formatDate(
                                                            new Date(data?.date)
                                                        )}
                                                    </td>
                                                    <td>
                                                        {
                                                            data?.operator
                                                                ?.username
                                                        }
                                                    </td>
                                                    <td>
                                                        {data?.operator?.roles?.join(
                                                            ", "
                                                        )}
                                                    </td>
                                                    <td>{data?.comment}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>
        </div>
    );
}
