import React, { useEffect, useState } from "react";
import update from "../../../../assets/images/update.svg";
import del from "../../../../assets/images/del.svg";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import EntriesSelection from "../../../../components/pagination/EntriesSelection";
import Pagination from "../../../../components/pagination/Pagination";
import { setLoading } from "../../../../redux/loader/loadingSlice";
import { useDispatch } from "react-redux";
import { api } from "../../../../helpers/apiHelper/requestHelper";
import { toastify } from "../../../../helpers/toast/toastify";
import { AlertModal } from "../../../../components/modals/AlertModal";

export default function BusinessEntityList(props) {
    const navigate = useNavigate();
    const reduxData = useSelector((state) => state);
    const [filtered, setFiltered] = useState([]);
    const [count, setCount] = useState(5);
    const [page, setPage] = useState(1);
    const [selectedEntity, setSelectedEntity] = useState();
    const [show, setShow] = useState(false);
    const dispatch = useDispatch();

    const deleteEntity = async (data) => {
        setShow(true);
        setSelectedEntity(data);
    };

    const confirmAction = async () => {
        dispatch(setLoading(true));
        const response = await api("post", "businessEntity/update", {
            isDeleted: true,
            id: selectedEntity._id,
        });
        dispatch(setLoading(false));
        if (response?.data?.status) {
            props?.getEntities();
            toastify("Entity deleted successfully", "success");
            setShow(false);
        } else {
            toastify(response?.message, "error");
        }
    };

    useEffect(() => {
        setFiltered(reduxData?.businessEntities?.value);
    }, [reduxData?.businessEntities?.value]);

    return (
        <>
            <div className="responsive-table w-100">
                {show && (
                    <AlertModal
                        confirmAction={confirmAction}
                        setModalClick={() => setShow(false)}
                        confirmBtn={true}
                        modalTitle={[
                            <h2>
                                Do you really want to delete{" "}
                                {selectedEntity?.name || "N/A"}
                                <br /> Entity permanently?
                            </h2>,
                        ]}
                        modalDescription={[]}
                    />
                )}
                <table className="table">
                    <thead>
                        <tr
                            className="no-shadow"
                            style={{ background: "#f6f6f6" }}
                        >
                            <td colSpan="5" height="16"></td>
                        </tr>
                        <tr>
                            <th>Business Name</th>
                            {/* <th>UEN</th> */}
                            <th>Contact Number</th>
                            {/* <th>Register Address</th> */}
                            <th>Representative</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filtered
                            ?.filter((e) => {
                                if (props.search !== "") {
                                    if (
                                        e?.name
                                            ?.toLowerCase()
                                            ?.includes(props.search) ||
                                        // e?.uen
                                        //     ?.toLowerCase()
                                        //     ?.includes(props.search) ||
                                        e?.contact
                                            ?.toLowerCase()
                                            ?.includes(props.search) ||
                                        e?.email
                                            ?.toLowerCase()
                                            ?.includes(props.search)
                                    ) {
                                        return e;
                                    }
                                } else {
                                    return e;
                                }
                            })
                            ?.filter((e) => {
                                if (props?.dateFilter) {
                                    let d1 = new Date(props.dateFilter);
                                    let d2 = new Date(e?.created_at);
                                    if (
                                        d1.getFullYear() === d2.getFullYear() &&
                                        d1.getMonth() === d2.getMonth() &&
                                        d1.getDate() === d2.getDate()
                                    ) {
                                        return e;
                                    }
                                } else {
                                    return e;
                                }
                            })
                            ?.slice(count * page - count, count * page)
                            ?.map((data) => {
                                console.log({ data });
                                return (
                                    <>
                                        <tr className="no-shadow">
                                            <td colSpan="9" height="16"></td>
                                        </tr>
                                        <tr>
                                            <td>{data?.name}</td>
                                            {/* <td>{data?.uen}</td> */}
                                            <td>{data?.contact}</td>
                                            {/* <td>
                                                {data?.addresses?.map((e) => (
                                                    <div>{e}</div>
                                                ))}
                                            </td> */}

                                            <td>
                                                {data?.representative
                                                    ?.firstName +
                                                    " " +
                                                    data?.representative
                                                        ?.lastName}
                                            </td>

                                            <td>
                                                <div className="flex items-center">
                                                    <button
                                                        className="table-action update cursor-pointer"
                                                        onClick={() => {
                                                            if (
                                                                reduxData
                                                                    ?.userRoles
                                                                    .currentRole ===
                                                                "RM Team Leader"
                                                            ) {
                                                                navigate(
                                                                    "/rm-lead/business-entity",
                                                                    {
                                                                        state: {
                                                                            entity: data,
                                                                        },
                                                                    }
                                                                );
                                                            } else if (
                                                                reduxData
                                                                    ?.userRoles
                                                                    .currentRole ===
                                                                "RM Member"
                                                            ) {
                                                                navigate(
                                                                    "/rm-member/business-entity",
                                                                    {
                                                                        state: {
                                                                            entity: data,
                                                                        },
                                                                    }
                                                                );
                                                            } else if (
                                                                reduxData
                                                                    ?.userRoles
                                                                    .currentRole ===
                                                                "Lead Coordinator"
                                                            ) {
                                                                navigate(
                                                                    "/lead-coordinator/business-entity",
                                                                    {
                                                                        state: {
                                                                            entity: data,
                                                                        },
                                                                    }
                                                                );
                                                            }
                                                        }}
                                                    >
                                                        <img
                                                            src={update}
                                                            alt="update"
                                                        />
                                                        <span>
                                                            <small>
                                                                Update
                                                            </small>
                                                        </span>
                                                    </button>
                                                    <button
                                                        className="table-action del "
                                                        onClick={() =>
                                                            deleteEntity(data)
                                                        }
                                                    >
                                                        <img
                                                            src={del}
                                                            alt="del"
                                                        />
                                                        <span>
                                                            <small>
                                                                Delete
                                                            </small>
                                                        </span>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    </>
                                );
                            })}
                    </tbody>
                </table>
                <div className="flex pagination-combine">
                    <EntriesSelection
                        setCount={setCount}
                        page={page}
                        count={count}
                        data={filtered}
                    />
                    <Pagination
                        count={count}
                        data={filtered}
                        setPage={setPage}
                        page={page}
                    />
                </div>
            </div>
        </>
    );
}
