import { Link, useNavigate } from "react-router-dom";
import "./login.scss";
import logoImage from "../../../assets/images/logo.png";
import emailIcon from "../../../assets/images/email-icon.svg";
import passwordIcon from "../../../assets/images/password-eye.png";
import { useEffect, useState } from "react";
import { api } from "../../../helpers/apiHelper/requestHelper";
import { useDispatch, useSelector } from "react-redux";
// import { login } from "../../../redux/auth/authSlice";
import { toastify } from "../../../helpers/toast/toastify";
import { missingPopup } from "../../../helpers/dataHelper/missingData";
import { setActiveRole, setUserRoles } from "../../../redux/roles/rolesSlice";
import { login, logout } from "../../../redux/auth/authSlice";
import { getVersion } from "../../../utils/common";

export const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const dispatch = useDispatch();
    const Auth = useSelector((state) => state.auth);
    const userRoles = useSelector((state) => state?.userRoles);
    const { user } = Auth;
    const navigate = useNavigate();

    const handleRoles = (data) => {
        let roles = data?.user?.roles;
        let activeRole = roles[0];

        localStorage.setItem("token", data?.token);
        localStorage.setItem("orgid", data?.selectedOrg);
        dispatch(login(data?.user));
        dispatch(setUserRoles(roles));
        dispatch(setActiveRole(activeRole));

        toastify(`Logged in successfully as ${activeRole}`, "success");
    };

    useEffect(() => {
        if (user && localStorage.getItem("token")) {
            if (userRoles?.currentRole === "RM Team Leader") {
                navigate("/rm-lead");
            } else if (userRoles?.currentRole === "RM Member") {
                navigate("/rm-member");
            } else if (userRoles?.currentRole === "Lead Coordinator") {
                navigate("/lead-coordinator");
            } else if (userRoles?.currentRole === "Case Manager") {
                navigate("/case-manager");
            } else {
                navigate("/");
            }
        } else {
            dispatch(logout());
        }
    }, [user]);

    const loginFun = async (e) => {
        e.preventDefault();
        if (!email) {
            missingPopup("Email");
        } else if (!password) {
            missingPopup("Password");
        } else {
            const userData = {
                email,
                password,
            };
            let response = await api("post", "auth/login", userData);

            if (!response.status) {
                toastify(response?.message, "error");
            } else {
                let roles = response?.data?.data?.user?.roles;
                if (roles.length) {
                    handleRoles(response?.data?.data);
                } else {
                    toastify(
                        "You are not assigned any role. Please contact admin.",
                        "error"
                    );
                }
            }
        }
    };

    const showPasswordFun = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className="flex column-direction items-center content-justify-center main-form-wrapper">
            <div className="container">
                <form
                    onSubmit={loginFun}
                    className="form-wrapper flex flex-wrap column-direction content-justify-center"
                >
                    <div className="login-logo-div">
                        <img src={logoImage} alt="logo" />
                    </div>
                    <p
                        style={{
                            fontWeight: "bold",
                            marginBottom: "20px",
                            textAlign: "center",
                        }}
                    >
                        Version {getVersion()}
                    </p>
                    <h1>Login</h1>
                    <ul className="list-style-none">
                        <li>
                            <label htmlFor="">Email or Username</label>
                            <div className="form-group">
                                <input
                                    className="form-control"
                                    type="text"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="Email or Username"
                                    id="email"
                                />
                                <img src={emailIcon} alt="email" />
                            </div>
                        </li>
                        <li>
                            <label htmlFor="">Password</label>
                            <div
                                className={`form-group password ${
                                    showPassword ? "passwordHide" : ""
                                }`}
                            >
                                <input
                                    className="form-control"
                                    type={showPassword ? "text" : "password"}
                                    value={password}
                                    onChange={(e) =>
                                        setPassword(e.target.value)
                                    }
                                    placeholder="Password"
                                    id="password"
                                />
                                <img
                                    src={passwordIcon}
                                    onClick={showPasswordFun}
                                    alt="password"
                                />
                            </div>

                            <Link to="/forgot-password" className="forgot">
                                Forgot Password?
                            </Link>
                        </li>
                        <li>
                            <button className="simple-btn" onClick={loginFun}>
                                Login
                            </button>
                        </li>
                    </ul>
                </form>
            </div>
        </div>
    );
};
