import Search from "../../../components/search/Search";
import UsersList from "./UsersList";
import "./users.scss";
import { api } from "../../../helpers/apiHelper/requestHelper";
import { toastify } from "../../../helpers/toast/toastify";
import { useEffect, useState } from "react";
import { setSidebar } from "../../../helpers/sidebar/setSidebar";
import {
    storeTeams,
    storeUnassignedMembers,
} from "../../../redux/teams/teamsSlice";
import { storeUsers } from "../../../redux/users/usersSlice";
import { useDispatch } from "react-redux";

export default function Users() {
    const [users, setUsers] = useState([]);
    const dispatch = useDispatch();

    const getUsers = async () => {
        let response = await api("get", "users/allUsers");
        if (response?.status) {
            setUsers(response?.data?.reverse());
            dispatch(storeUsers(response?.data));
        } else {
            toastify(response?.message, "error");
        }
    };

    const getTeams = async () => {
        let response = await api("get", "teams/index");
        if (response?.status) {
            dispatch(storeTeams(response?.data));
            dispatch(storeUnassignedMembers(response?.data?.unassignedUsers));
        } else {
            toastify(response?.message, "error");
        }
    };

    useEffect(() => {
        setSidebar("Users");
        getUsers();
        getTeams();
    }, []);

    return (
        <>
            <h4 className="flex column-direction mini-title">Users</h4>
            <UsersList users={users} refresh={getUsers} />
        </>
    );
}
