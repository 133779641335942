export const getCaseUsers = (selectedCase) => {
    let lead = selectedCase?.lead;
    let users = [];
    if (lead?.assignedTo) {
        if (
            lead?.assignedTo?._id &&
            !users.includes(lead?.assignedTo?._id) &&
            !users.includes(lead?.assignedTo)
        ) {
            users.push(lead?.assignedTo?._id);
        } else if (
            lead?.assignedTo &&
            !users.includes(lead?.assignedTo) &&
            !users.includes(lead?.assignedTo?._id)
        ) {
            users?.push(lead?.assignedTo);
        }
    }
    if (lead?.assignedFrom) {
        if (
            lead?.assignedFrom?._id &&
            !users.includes(lead?.assignedFrom?._id) &&
            !users.includes(lead?.assignedFrom)
        ) {
            users.push(lead?.assignedFrom?._id);
        } else if (
            lead?.assignedFrom &&
            !users.includes(lead?.assignedFrom) &&
            !users.includes(lead?.assignedFrom?._id)
        ) {
            users?.push(lead?.assignedFrom);
        }
    }

    return users;
};

export const getLeadUsers = (lead) => {
    let users = [];
    if (lead?.assignedTo) {
        if (
            lead?.assignedTo?._id &&
            !users.includes(lead?.assignedTo?._id) &&
            !users.includes(lead?.assignedTo)
        ) {
            users.push(lead?.assignedTo?._id);
        } else if (
            lead?.assignedTo &&
            !users.includes(lead?.assignedTo) &&
            !users.includes(lead?.assignedTo?._id)
        ) {
            users?.push(lead?.assignedTo);
        }
    }
    if (lead?.assignedFrom) {
        if (
            lead?.assignedFrom?._id &&
            !users.includes(
                lead?.assignedFrom?._id && !users.includes(lead?.assignedFrom)
            )
        ) {
            users.push(lead?.assignedFrom?._id);
        } else if (
            lead?.assignedFrom &&
            !users.includes(lead?.assignedFrom) &&
            !users.includes(lead?.assignedFrom?._id)
        ) {
            users?.push(lead?.assignedFrom);
        }
    }

    return users;
};
