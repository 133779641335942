import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { api } from "../../../helpers/apiHelper/requestHelper";
import { missingPopup } from "../../../helpers/dataHelper/missingData";
import { toastify } from "../../../helpers/toast/toastify";
import { setLoading } from "../../../redux/loader/loadingSlice";

export default function AddBank() {
    const [name, setName] = useState();
    const { state } = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const submit = async () => {
        if (!name) missingPopup("Bank Name");
        else {
            if (state?.bank) {
                dispatch(setLoading(true));
                const response = await api("post", "banks/update", {
                    name,
                    id: state?.bank?._id,
                });
                dispatch(setLoading(false));
                if (response?.data?.status) {
                    toastify("Bank Updated Successfully!", "success");
                    navigate("/bank-list");
                } else {
                    toastify(response?.message);
                }
            } else {
                dispatch(setLoading(true));
                const response = await api("post", "banks/create", { name });
                dispatch(setLoading(false));
                if (response?.data?.status) {
                    toastify("Bank Added Successfully!", "success");
                    navigate("/bank-list");
                } else {
                    toastify(response?.message);
                }
            }
        }
    };

    useEffect(() => {
        if (state?.bank) setName(state?.bank?.name);
    }, [state?.bank]);

    return (
        <>
            <h2 className="flex flex-wrap mini-title">
                <span onClick={() => navigate("/bank-list")}>Bank</span> / Add
                Bank
            </h2>
            <div className="card">
                <div className="card-title">
                    <h2>Bank</h2>
                </div>
                <div className="card-body">
                    <div className="edit-form-wrapper">
                        <ul className="list-style-none flex flex-wrap">
                            <li>
                                <div className="form-group">
                                    <label htmlFor="">Bank Name *</label>
                                    <input
                                        type="text"
                                        value={name}
                                        onChange={(e) =>
                                            setName(e.target.value)
                                        }
                                        className="form-control mandatory"
                                        placeholder="Enter Bank Name"
                                    />
                                </div>
                            </li>
                            <li className="w-100">
                                <button className="simple-btn" onClick={submit}>
                                    {state?.bank ? "Update" : "Create"} Bank
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}
