export const setSidebar = (heading) => {
    let activeA = document.querySelector("a.active");
    if (activeA) {
        activeA.classList.remove("active");
    }

    let activeSpan = document.querySelector("span.active");
    if (activeSpan) {
        activeSpan.classList.remove("active");
    }

    let sidebar = document.querySelector(".sidebar-wrapper");
    const spanElements = sidebar.querySelectorAll("span");
    let targetSpan;
    spanElements.forEach((span) => {
        if (span.innerText === heading) {
            targetSpan = span;
            return;
        }
    });

    if (targetSpan) {
        targetSpan.classList.add("active");
    }
};
