import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    value: [],
};

export const casesSlice = createSlice({
    name: "casesSlice",
    initialState,
    reducers: {
        storeCases: (state, action) => {
            state.value = action.payload;
        },
    },
});

export const { storeCases } = casesSlice.actions;

export default casesSlice.reducer;
