import view from "../../assets/images/view.svg";
import Search from "../../components/search/Search";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { api } from "../../helpers/apiHelper/requestHelper";
import { setUserAnnouncements } from "../../redux/announcements/announcementSlice";
import { toastify } from "../../helpers/toast/toastify";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import EntriesSelection from "../../components/pagination/EntriesSelection";
import Pagination from "../../components/pagination/Pagination";
import { shortFormatDate } from "../../helpers/dataHelper/dateFormatter";

export default function AnnouncementList() {
    const dispatch = useDispatch();
    const reduxData = useSelector((state) => state);
    const navigate = useNavigate();
    const [filtered, setFiltered] = useState([]);
    const [announcements, setAnnouncements] = useState([]);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(5);

    useEffect(() => {
        getAnnouncements();
    }, []);

    const getAnnouncements = async () => {
        const response = await api("get", "announcements/index");
        console.log(response);
        if (response?.status) {
            setAnnouncements(response?.data?.reverse());
        } else {
            toastify(response?.message);
        }
    };

    useEffect(() => {
        setFiltered(announcements);
    }, [announcements]);

    const getAcknowledgement = (array) => {
        let user = [...array].find(
            (e) => e.user?._id === reduxData?.auth?.user?._id
        );
        if (user?.acknowledged)
            return <span className="status active">Acknowledged</span>;
        else return <span className="status">Unacknowledged</span>;
    };

    return (
        <>
            <h4 className="flex flex-wrap mini-title">PDPA</h4>
            {/* <Search userSearch={true} filedText="Search Announcement" /> */}
            <Search
                userSearch={true}
                filedText="Search PDPA"
                teamDropdown={false}
                filters={false}
                addButton={false}
                setAnnouncements={setFiltered}
                announcements={announcements}
                component={"Announcement List"}
            />
            <div className="responsive-table w-100">
                <table className="table">
                    <thead>
                        <tr
                            className="no-shadow"
                            style={{ background: "#f6f6f6" }}
                        >
                            <td colSpan="6" height="16"></td>
                        </tr>
                        <tr>
                            <th>Title</th>
                            <th>Description</th>
                            <th>From Date</th>
                            <th>To Date</th>
                            <th>Ack Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filtered
                            ?.slice(count * page - count, count * page)
                            ?.map((data, index) => {
                                return (
                                    <>
                                        <tr className="no-shadow">
                                            <td colSpan="6" height="16"></td>
                                        </tr>
                                        <tr key={index}>
                                            <td>{data.title}</td>
                                            <td>{data.description}</td>
                                            <td>
                                                {shortFormatDate(data.fromDate)}
                                            </td>
                                            <td>
                                                {shortFormatDate(data.toDate)}
                                            </td>
                                            <td>
                                                {getAcknowledgement(data.users)}
                                            </td>
                                            <td>
                                                <div className="flex items-center">
                                                    <div
                                                        // to="/announcement-details"
                                                        onClick={() =>
                                                            navigate(
                                                                "/announcements-details",
                                                                {
                                                                    state: {
                                                                        announcement:
                                                                            data,
                                                                    },
                                                                }
                                                            )
                                                        }
                                                        className="table-action view cursor-pointer"
                                                    >
                                                        <img
                                                            src={view}
                                                            alt="view"
                                                        />
                                                        <span>
                                                            <small>View</small>
                                                        </span>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </>
                                );
                            })}
                    </tbody>
                </table>
                <div className="flex pagination-combine">
                    <EntriesSelection
                        setCount={setCount}
                        page={page}
                        count={count}
                        data={filtered}
                    />
                    <Pagination
                        count={count}
                        data={filtered}
                        setPage={setPage}
                        page={page}
                    />
                </div>
            </div>
        </>
    );
}
