import logo from "../../assets/images/white-logo.png";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { logout } from "../../redux/auth/authSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import AdminSidebar from "./AdminSidebar";
import RmLeadSidebar from "./RmLeadSidebar";
import RmMemberSidebar from "./RmMemberSidebar";
import LeadCoordinatorSidebar from "./LeadCoordinatorSidebar";
import CaseManagerSidebar from "./CaseManagerSidebar";
import { getVersion } from "../../utils/common";
import { setRoute } from "../../redux/route/routeSlice";

export default function SideBar(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userRoles = useSelector((state) => state.userRoles);

    let adminSidebar = <AdminSidebar menuClick={props?.menuClick} />;

    let rmLeadSidebar = <RmLeadSidebar menuClick={props?.menuClick} />;

    let rmMemberSidebar = <RmMemberSidebar menuClick={props?.menuClick} />;

    let leadCoordinatorSidebar = (
        <LeadCoordinatorSidebar menuClick={props?.menuClick} />
    );

    let caseManagerSidebar = (
        <CaseManagerSidebar menuClick={props?.menuClick} />
    );

    const getSidebar = () => {
        if (userRoles?.currentRole === "RM Team Leader") {
            return rmLeadSidebar;
        } else if (userRoles?.currentRole === "Admin") {
            return adminSidebar;
        } else if (userRoles?.currentRole === "RM Member") {
            return rmMemberSidebar;
        } else if (userRoles?.currentRole === "Lead Coordinator") {
            return leadCoordinatorSidebar;
        } else if (userRoles?.currentRole === "Case Manager") {
            return caseManagerSidebar;
        }
    };

    return (
        <div className="sidebar-content">
            <figure>
                <img src={logo} alt="logo" />
                <span style={{ color: "white", fontSize: 14 }}>
                    Version {getVersion()}
                </span>
            </figure>
            {getSidebar()}
            <div
                onClick={() => {
                    localStorage.clear();
                    dispatch(logout());
                    dispatch(setRoute(""));
                    navigate("/login");
                }}
                className="logout-btn cursor-pointer"
            >
                Logout
            </div>
            <div style={{ position: "absolute", bottom: 15, right: 10 }}>
                <span style={{ color: "white", fontSize: 14 }}>
                    Version {getVersion()}
                </span>
            </div>
        </div>
    );
}
