import { useEffect } from "react";
import { useState } from "react";

export default function FolderNameModal(props) {
    const [name, setName] = useState();

    useEffect(() => {
        if (props?.folder) {
            setName(props?.folder?.name);
        }
    }, [props?.folder]);

    return (
        <div className={`modal-wrapper inner_modal`}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-body ">
                        <div className="modal-forms">
                            <ul className="list-style-none">
                                <li>
                                    <div className="form-group">
                                        <label htmlFor="">
                                            {props?.folder?.url
                                                ? "File"
                                                : "Folder"}{" "}
                                            Name *
                                        </label>
                                        <input
                                            autoFocus
                                            type="text"
                                            value={name}
                                            onChange={(e) =>
                                                setName(e.target.value)
                                            }
                                            className="form-control mandatory"
                                            placeholder="Enter Folder Name"
                                        />
                                    </div>
                                </li>

                                <li>
                                    <div className="flex content-justify-center">
                                        <button
                                            onClick={() => props?.cancel()}
                                            className="modal-btn cancel"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            onClick={() => {
                                                if (props?.folder) {
                                                    props?.rename(name);
                                                } else {
                                                    props?.confirm(name);
                                                }
                                            }}
                                            className="modal-btn"
                                        >
                                            Confirm
                                        </button>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
