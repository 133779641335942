import { Link } from "react-router-dom";
import update from "../../../assets/images/update.svg";
import del from "../../../assets/images/del.svg";
import addIcon from "../../../assets/images/add-icon.svg";
import Pagination from "../../../components/pagination/Pagination";
import { AddTeamModal } from "../../../components/modals/AddTeamModal";
import { AlertModal } from "../../../components/modals/AlertModal";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    selectTeam,
    storeTeams,
    storeUnassignedMembers,
} from "../../../redux/teams/teamsSlice";
import { api } from "../../../helpers/apiHelper/requestHelper";
import { toastify } from "../../../helpers/toast/toastify";
import { setLoading } from "../../../redux/loader/loadingSlice";

export default function TeamList() {
    const [openTeamModal, setOpenTeamModal] = useState(false);
    const teams = useSelector((state) => state.allTeams);
    const [show, setShow] = useState(false);
    const [team, setTeam] = useState();
    const dispatch = useDispatch();
    const [count, setCount] = useState(10);
    const [page, setPage] = useState(1);

    const showTeamModal = () => {
        dispatch(selectTeam(null));
        setOpenTeamModal(!openTeamModal);
    };

    const handleUpdate = async (team) => {
        dispatch(selectTeam(team));
        setOpenTeamModal(true);
    };

    const handleDelete = (data) => {
        setShow(true);
        setTeam(data);
    };

    const populateTeams = async () => {
        let response = await api("get", "teams/index");
        if (response?.status) {
            dispatch(storeTeams(response?.data));
            dispatch(storeUnassignedMembers(response?.data?.unassignedUsers));
        } else {
            toastify(response?.message, "error");
        }
    };

    const confirmAction = async () => {
        dispatch(setLoading(true));
        const response = await api("post", "teams/delete", { id: team?._id });
        dispatch(setLoading(false));
        if (response?.data?.status) {
            toastify("Team deleted successfully.", "success");
            populateTeams();
            // dispatch(selectTeam(response?.data?.data));
        } else {
            toastify(response?.message, "error");
        }
        setShow(false);
    };

    return (
        <>
            {show && (
                <AlertModal
                    confirmAction={confirmAction}
                    setModalClick={() => setShow(false)}
                    confirmBtn={true}
                    modalTitle={[
                        <h2>
                            Do you really want to delete {team?.name || "N/A"}
                            <br /> team permanently?
                        </h2>,
                    ]}
                    modalDescription={[]}
                />
            )}
            {openTeamModal && (
                <AddTeamModal setModalClick={() => setOpenTeamModal(false)} />
            )}
            {/* {show && (
                <AddTeamModal setModalClick={() => setOpenTeamModal(false)} />
            )} */}
            <div className="responsive-table w-100">
                <table className="table">
                    <thead>
                        <tr
                            className="no-shadow"
                            style={{ background: "#f6f6f6" }}
                        >
                            <td colSpan="5" height="16"></td>
                        </tr>
                        <tr>
                            <th>
                                Teams
                                <img
                                    className="add-icon"
                                    src={addIcon}
                                    alt="addIcon"
                                    onClick={showTeamModal}
                                />
                            </th>
                            <th>Action</th>
                        </tr>
                    </thead>

                    {console.log({ teams })}

                    <tbody>
                        {teams?.value?.teams?.length > 0 &&
                            teams?.value?.teams
                                ?.slice(count * page - count, count * page)
                                ?.map((data, index) => {
                                    return (
                                        <>
                                            <tr className="no-shadow">
                                                <td
                                                    colSpan="5"
                                                    height="16"
                                                ></td>
                                            </tr>
                                            <tr
                                                key={`team-${index}`}
                                                className="cursor-pointer"
                                                onClick={() => {
                                                    dispatch(selectTeam(data));
                                                }}
                                            >
                                                <td
                                                    className={
                                                        teams?.selectedTeam
                                                            ?._id ===
                                                            data?._id &&
                                                        "selected-team"
                                                    }
                                                >
                                                    {data?.name}
                                                </td>
                                                <td>
                                                    <div className="flex items-center">
                                                        <div
                                                            className="table-action update cursor-pointer"
                                                            onClick={() =>
                                                                handleUpdate(
                                                                    data
                                                                )
                                                            }
                                                        >
                                                            <img
                                                                src={update}
                                                                alt="update"
                                                            />{" "}
                                                            <span>
                                                                <small>
                                                                    Update
                                                                </small>
                                                            </span>
                                                        </div>
                                                        <button
                                                            onClick={() =>
                                                                handleDelete(
                                                                    data
                                                                )
                                                            }
                                                            className="table-action del"
                                                        >
                                                            <img
                                                                src={del}
                                                                alt="del"
                                                            />{" "}
                                                            <span>
                                                                <small>
                                                                    Delete
                                                                </small>
                                                            </span>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </>
                                    );
                                })}
                    </tbody>
                </table>

                <Pagination
                    count={count}
                    data={teams?.value?.teams}
                    setPage={setPage}
                    page={page}
                />
            </div>
        </>
    );
}
