import leads from "../../../../assets/images/leads.svg";
import rLeads from "../../../../assets/images/rLeads.svg";
import gLeads from "../../../../assets/images/gLeads.svg";
import products from "../../../../assets/images/products.svg";
import customForm from "../../../../assets/images/customform.svg";
import highIcon from "../../../../assets/images/highIcon.svg";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

export default function Statistics() {
    const reduxData = useSelector((state) => state);

    const statisticsList = [
        {
            statisticsIcon: leads,
            statisticsCount: reduxData?.leads?.value?.length || 0,
            statisticsTitle: "Total Leads",
        },
        {
            statisticsIcon: gLeads,
            statisticsCount:
                reduxData?.leads?.value?.filter((e) => e.assignedTo)?.length ||
                0,
            statisticsTitle: "Total Assigned Leads",
        },
        {
            statisticsIcon: rLeads,
            statisticsCount:
                reduxData?.leads?.value?.filter((e) => !e.assignedTo)?.length ||
                0,
            statisticsTitle: "Total Unassigned Leads",
        },
    ];
    return (
        <div className="statistics-wrapper flex column-direction">
            <ul className="list-style-none flex content-justify-center flex-wrap">
                {statisticsList.map(
                    (
                        { statisticsIcon, statisticsCount, statisticsTitle },
                        index
                    ) => {
                        return (
                            <li key={`statistics-${index}`}>
                                <Link className="flex items-center">
                                    <section>
                                        <h2>{statisticsCount}</h2>
                                        <span>
                                            {statisticsTitle}{" "}
                                            {/* <small>
                                                <img
                                                    src={highIcon}
                                                    alt="highIcon"
                                                />{" "}
                                                +2
                                            </small> */}
                                        </span>
                                    </section>
                                    <figure>
                                        <img
                                            src={statisticsIcon}
                                            alt="statisticsIcon"
                                        />
                                    </figure>
                                </Link>
                            </li>
                        );
                    }
                )}
            </ul>
        </div>
    );
}
