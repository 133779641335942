import "./modals.scss";
import crossIcon from "../../assets/images/cross.svg";
import { shortFormatDate } from "../../helpers/dataHelper/dateFormatter";

export const ProductDetail = ({ close, product }) => {
    return (
        <div className={`modal-wrapper reminder-modal`}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div
                        className="modal-body"
                        style={{ maxHeight: "80vh", overflowY: "scroll" }}
                    >
                        <img
                            src={crossIcon}
                            className="cross-btn"
                            onClick={close}
                            alt="crossIcon"
                        />
                        <div className="modal-forms">
                            <h3>Product Details</h3>
                            {/* <h5>Hello</h5> */}
                            {/* {product?.name} */}
                            <ul className="list-style-none mb-20">
                                <li>
                                    <h6 className="p-0 m-0">Name</h6>
                                    <span>{product?.name}</span>
                                </li>
                                <li>
                                    <h6 className="p-0 m-0">Description</h6>
                                    <span>{product?.description}</span>
                                </li>
                                <li>
                                    <h6 className="p-0 m-0">Advisory Fee</h6>
                                    <span>
                                        {product?.advisoryFee +
                                            " - " +
                                            product?.price}
                                    </span>
                                </li>
                                {product?.documents?.length > 0 && (
                                    <li>
                                        <h6 className="p-0 m-0">
                                            Required Documents
                                        </h6>
                                        {product?.documents?.map((e) => (
                                            <>
                                                <span>{e.name}</span>
                                                <br />
                                            </>
                                        ))}
                                    </li>
                                )}
                                {product?.internalForms?.length > 0 && (
                                    <li>
                                        <h6 className="p-0 m-0">
                                            Internal Forms
                                        </h6>
                                        {product?.internalForms?.map((e) => (
                                            <>
                                                <span>{e.name}</span>
                                                <br />
                                            </>
                                        ))}
                                    </li>
                                )}
                            </ul>
                            <div className="flex content-justify-center">
                                <button onClick={close} className="modal-btn">
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
