import "./modals.scss";
import crossIcon from "../../assets/images/cross.svg";
import downloadIcon from "../../assets/images/download.svg";

export const ImageModal = (props) => {
    const getData = () => {
        let arr = props?.url.split(".");
        if (
            ["jpg", "jpeg", "png", "gif", "bmp", "svg"].includes(
                arr[arr.length - 1]
            )
        ) {
            return <img src={props?.url} />;
        } else {
            return <p>Unable to view this file. Please download.</p>;
        }
    };

    return (
        <div className={`modal-wrapper`}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-body ">
                        <img
                            src={crossIcon}
                            className="cross-btn"
                            onClick={props.setShow}
                            alt="crossIcon"
                        />
                        <div className="doc-image flex content-justify-center items-center column-direction">
                            {getData()}

                            <button
                                className="simple-btn"
                                onClick={props.download}
                                style={{
                                    background: "#6BC335",
                                    borderWidth: "0px",
                                    marginTop: 20,
                                    fontSize: 18,
                                }}
                            >
                                <img
                                    src={downloadIcon}
                                    style={{ marginRight: 10 }}
                                />
                                Download
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
