export const formatDate = (date) => {
    try {
        let day = date.getDate().toString().padStart(2, "0");
        let month = (date.getMonth() + 1).toString().padStart(2, "0");
        let year = date.getFullYear().toString().slice(-2);
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let meridiem = "AM";

        if (hours >= 12) {
            meridiem = "PM";
            if (hours > 12) {
                hours -= 12;
            }
        }

        const formattedDate = `${day}/${month}/${year} at ${hours}:${minutes
            .toString()
            .padStart(2, "0")} ${meridiem}`;

        return formattedDate;
    } catch (error) {
        console.log({ error });
        return "InvalidDate";
    }
};

export const formatTime = (date) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let meridiem = "AM";

    if (hours >= 12) {
        meridiem = "PM";
        if (hours > 12) {
            hours -= 12;
        }
    }

    const formattedDate = `${hours}:${minutes
        .toString()
        .padStart(2, "0")} ${meridiem}`;

    return formattedDate;
};

export const shortFormatDate = (date) => {
    try {
        let day = new Date(date).getDate().toString().padStart(2, "0");
        let month = (new Date(date).getMonth() + 1).toString().padStart(2, "0");
        let year = new Date(date).getFullYear().toString().slice(-2);

        const formattedDate = `${day}/${month}/${year}`;

        return formattedDate;
    } catch (error) {
        return null;
    }
};
