import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    value: null,
};

export const routeSlice = createSlice({
    name: "routeSlice",
    initialState,
    reducers: {
        setRoute: (state, action) => {
            state.value = action.payload;
        },
    },
});

export const { setRoute } = routeSlice.actions;

export default routeSlice.reducer;
