import {
    Menu,
    MenuItem,
    ProSidebarProvider,
    Sidebar,
    SubMenu,
} from "react-pro-sidebar";
import { Link, useNavigate } from "react-router-dom";
import dashboard from "../../assets/images/icon1.svg";
import template from "../../assets/images/icon5.svg";
import team from "../../assets/images/icon3.svg";
import report from "../../assets/images/icon8.svg";
import folderIcon from "../../assets/images/sidebarFolderIcon.svg";
import entity from "../../assets/images/icon9.svg";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setRoute } from "../../redux/route/routeSlice";
import schedule from "../../assets/images/scheduler.svg";
import announcement from "../../assets/images/icon6.svg";
import activityLog from "../../assets/images/activityLog.svg";
import bell from "../../assets/images/bell.svg";
import productList from "../../assets/images/productList.svg";

export default function RmMemberSidebar(props) {
    const [activePage, setActivePage] = useState(null);
    const [dropdown, setDropdown] = useState();
    const navigation = useNavigate();
    const dispatch = useDispatch();
    const reduxData = useSelector((state) => state);

    useEffect(() => {
        if (reduxData?.heading?.value?.includes("Cases")) {
            setDropdown("Cases");
        } else if (reduxData?.heading?.value?.includes("Leads")) {
            setDropdown("Leads");
        }
    }, [reduxData?.heading?.value]);

    const getFolderName = () => {
        if (reduxData?.folders?.folderStructure?.length) {
            let name = reduxData?.folders?.folderStructure[0]?.name;
            return name;
        }
    };

    function handleActive(event, heading) {
        let activeA = document.querySelector("a.active");
        if (activeA) {
            activeA.classList.remove("active");
        }
        dispatch(setRoute(heading));
        if (!event.target.classList.value.includes("active")) {
            event.target.classList.toggle("active");
            if (activePage) activePage.classList.remove("active");
            setActivePage(event.target);
        }
    }

    const leadLink = () => {
        let activeSpan = document.querySelector("span.active");
        if (activeSpan) {
            activeSpan.classList.remove("active");
        }
        setDropdown(dropdown === "Leads" ? null : "Leads");
        dispatch(setRoute("Leads"));
        navigation("/rm-member/leads/all");
    };

    return (
        <ProSidebarProvider>
            <Sidebar>
                <Menu>
                    <MenuItem
                        onClick={(e) => {
                            setDropdown(null);
                            handleActive(e, "Dashboard");
                        }}
                        routerLink={
                            <Link onClick={props.menuClick} to="/rm-member" />
                        }
                    >
                        <img src={dashboard} alt="dashboard" /> Dashboard
                    </MenuItem>

                    <SubMenu
                        icon={<img src={template} alt="setting" />}
                        className="settings"
                        label="Leads"
                        onClick={leadLink}
                        open={dropdown === "Leads"}
                    >
                        <MenuItem
                            onClick={(e) => {
                                handleActive(e, "Leads");
                            }}
                            routerLink={
                                <Link
                                    onClick={props.menuClick}
                                    to="/rm-member/leads/all"
                                />
                            }
                        >
                            All Leads
                        </MenuItem>
                        <MenuItem
                            onClick={(e) => {
                                handleActive(e, "Leads");
                            }}
                            routerLink={
                                <Link
                                    onClick={props.menuClick}
                                    to="/rm-member/leads/assigned"
                                />
                            }
                        >
                            Assigned Leads
                        </MenuItem>
                        <MenuItem
                            onClick={(e) => {
                                handleActive(e, "Leads");
                            }}
                            routerLink={
                                <Link
                                    onClick={props.menuClick}
                                    to="/rm-member/leads/unassigned"
                                />
                            }
                        >
                            Own Leads
                        </MenuItem>
                    </SubMenu>

                    <SubMenu
                        icon={<img src={template} alt="setting" />}
                        className="settings"
                        label="Case"
                        onClick={() => {
                            let activeSpan =
                                document.querySelector("span.active");
                            if (activeSpan) {
                                activeSpan.classList.remove("active");
                            }
                            setDropdown(dropdown === "Cases" ? null : "Cases");
                        }}
                        open={dropdown === "Cases"}
                    >
                        <MenuItem
                            onClick={(e) => {
                                handleActive(e, "Cases");
                            }}
                            routerLink={
                                <Link
                                    onClick={props.menuClick}
                                    to="/rm-member/all-cases"
                                />
                            }
                        >
                            All Case
                        </MenuItem>
                        <MenuItem
                            onClick={(e) => {
                                handleActive(e, "Cases");
                            }}
                            routerLink={
                                <Link
                                    onClick={props.menuClick}
                                    to="/rm-member/pending-cases"
                                />
                            }
                        >
                            Pending
                        </MenuItem>
                        <MenuItem
                            onClick={(e) => {
                                handleActive(e, "Cases");
                            }}
                            routerLink={
                                <Link
                                    onClick={props.menuClick}
                                    to="/rm-lead/submitted-cases"
                                />
                            }
                        >
                            Submitted
                        </MenuItem>
                        <MenuItem
                            onClick={(e) => {
                                handleActive(e, "Cases");
                            }}
                            routerLink={
                                <Link
                                    onClick={props.menuClick}
                                    to="/rm-member/processing-cases"
                                />
                            }
                        >
                            Processing
                        </MenuItem>
                        <MenuItem
                            onClick={(e) => {
                                handleActive(e, "Cases");
                            }}
                            routerLink={
                                <Link
                                    onClick={props.menuClick}
                                    to="/rm-member/rejected-cases"
                                />
                            }
                        >
                            Rejected
                        </MenuItem>
                        <MenuItem
                            onClick={(e) => {
                                handleActive(e, "Cases");
                            }}
                            routerLink={
                                <Link
                                    onClick={props.menuClick}
                                    to="/rm-member/approved-cases"
                                />
                            }
                        >
                            Approved
                        </MenuItem>
                        <MenuItem
                            onClick={(e) => {
                                handleActive(e, "Cases");
                            }}
                            routerLink={
                                <Link
                                    onClick={props.menuClick}
                                    to="/rm-member/draft-cases"
                                />
                            }
                        >
                            Draft
                        </MenuItem>
                        <MenuItem
                            onClick={(e) => {
                                handleActive(e, "Cases");
                            }}
                            routerLink={
                                <Link
                                    onClick={props.menuClick}
                                    to="/rm-lead/completed-cases"
                                />
                            }
                        >
                            Completed
                        </MenuItem>
                    </SubMenu>

                    <MenuItem
                        onClick={(e) => {
                            handleActive(e, "Reports");
                            setDropdown(null);
                        }}
                        routerLink={
                            <Link
                                onClick={props.menuClick}
                                to="/rm-member/report"
                            />
                        }
                    >
                        <img src={report} alt="Form Template" /> Reports
                    </MenuItem>

                    <MenuItem
                        onClick={(e) => {
                            setDropdown(null);
                            handleActive(e, "Entities");
                        }}
                        routerLink={
                            <Link
                                onClick={props.menuClick}
                                to="/rm-member/entities"
                            />
                        }
                    >
                        <img src={entity} alt="Entities" /> Entities
                    </MenuItem>

                    <MenuItem
                        onClick={(e) => {
                            setDropdown(null);
                            handleActive(e, "Scheduler");
                        }}
                        routerLink={
                            <Link onClick={props.menuClick} to="/schedule" />
                        }
                    >
                        <img src={schedule} alt="Form Template" />
                        Scheduler
                    </MenuItem>

                    {/* <MenuItem
                        onClick={(e) => {
                            setDropdown(null);
                            handleActive(e, "Notification");
                        }}
                        routerLink={
                            <Link
                                onClick={props.menuClick}
                                to="/notification"
                            />
                        }
                    >
                        <img src={bell} alt="Form Template" />
                        Notification
                    </MenuItem> */}

                    <MenuItem
                        onClick={(e) => {
                            setDropdown(null);
                            handleActive(e, "Activity Logs");
                        }}
                        routerLink={
                            <Link
                                onClick={props.menuClick}
                                to="/activity-log"
                            />
                        }
                    >
                        <img src={activityLog} alt="Form Template" />
                        Activity Logs
                    </MenuItem>

                    <MenuItem
                        onClick={(e) => {
                            setDropdown(null);
                            handleActive(e, "PDPA");
                        }}
                        routerLink={
                            <Link
                                onClick={props.menuClick}
                                to="/announcements-list"
                            />
                        }
                    >
                        <img src={announcement} alt="Form Template" />
                        PDPA
                    </MenuItem>

                    <MenuItem
                        onClick={(e) => {
                            setDropdown(null);
                            handleActive(e, "Product");
                        }}
                        routerLink={
                            <Link
                                onClick={props.menuClick}
                                to="/product-list"
                            />
                        }
                    >
                        <img src={productList} alt="Form Template" />
                        Product
                    </MenuItem>
                    <MenuItem
                        onClick={(e) => {
                            handleActive(e, getFolderName());
                        }}
                        routerLink={
                            <Link
                                onClick={props.menuClick}
                                to="/folder-structure"
                            />
                        }
                    >
                        <img src={folderIcon} alt="Folder" />
                        {getFolderName()}
                    </MenuItem>
                </Menu>
            </Sidebar>
        </ProSidebarProvider>
    );
}
