import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    value: [],
};

export const iEntitySlice = createSlice({
    name: "iEntitySlice",
    initialState,
    reducers: {
        setIndividualEntities: (state, action) => {
            state.value = action.payload;
        },
        addIndividualEntity: (state, action) => {
            let newData = [...state?.value];
            newData?.push(action.payload);
            state.value = newData;
        },
    },
});

export const { setIndividualEntities, addIndividualEntity } =
    iEntitySlice.actions;

export default iEntitySlice.reducer;
