import React, { useRef } from "react";
import DatePicker from "react-date-picker";
import crossIcon from "../../../assets/images/form-cross.svg";
import upIcon from "../../../assets/images/up.png";
import downIcon from "../../../assets/images/down.png";
import uploadIcon from "../../../assets/images/upload.svg";
import cross from "../../../assets/images/cross.svg";

const InputElements = ({
    id,
    type,
    value,
    placeholder,
    onChange,
    text,
    options,
    deleteData,
    label,
    move,
    index,
    uploadFile,
    filename,
    url,
    removeAttachment,
}) => {
    const fileInputRef = useRef(null);

    return (
        <li className="w-100 flex items-start">
            <div className="form-group">
                {label && (
                    <label>
                        <span onClick={(e) => e.preventDefault()}>{label}</span>
                        <img
                            onClick={(e) => {
                                e.preventDefault();
                                deleteData(id);
                            }}
                            src={crossIcon}
                            className="cross-btn"
                            alt="crossIcon"
                        />
                        <img
                            className="arrows"
                            src={upIcon}
                            onClick={(e) => {
                                e.preventDefault();
                                move(index, "up");
                            }}
                        />
                        <img
                            className="arrows"
                            src={downIcon}
                            onClick={(e) => {
                                e.preventDefault();
                                move(index, "down");
                            }}
                        />

                        {type === "file" && (
                            <>
                                <label
                                    class="file-input-label"
                                    htmlFor={`file-input-${id}`}
                                >
                                    <img
                                        className="uploadIcon"
                                        src={uploadIcon}
                                        style={{
                                            width: 20,
                                            height: 20,
                                            marginLeft: 5,
                                            marginRight: 5,
                                            cursor: "pointer",
                                        }}
                                    />
                                </label>
                                <input
                                    id={`file-input-${id}`}
                                    ref={fileInputRef}
                                    type="file"
                                    style={{
                                        display: "none",
                                    }}
                                    onChange={(e) => {
                                        if (e.target.files[0]) {
                                            uploadFile(e.target.files[0], id);
                                        }
                                    }}
                                />
                                {filename && (
                                    <div onClick={(e) => e.preventDefault()}>
                                        {filename}
                                    </div>
                                )}
                                {filename && (
                                    <span
                                        style={{ cursor: "pointer" }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            removeAttachment(id);
                                            fileInputRef.current.value = null;
                                        }}
                                    >
                                        <img
                                            src={cross}
                                            style={{ width: 20 }}
                                        />
                                    </span>
                                )}
                            </>
                        )}
                    </label>
                )}

                {type === "heading" && (
                    <h2 className="builder-title">
                        {text}
                        <img
                            onClick={() => deleteData(id)}
                            src={crossIcon}
                            className="cross-btn"
                            alt="crossIcon"
                        />
                        <img
                            className="arrows"
                            src={upIcon}
                            onClick={() => move(index, "up")}
                        />
                        <img
                            className="arrows"
                            src={downIcon}
                            onClick={() => move(index, "down")}
                        />
                    </h2>
                )}

                {type === "textarea" && (
                    <textarea
                        className="form-control"
                        value={value}
                        onChange={onChange}
                        placeholder={placeholder}
                    />
                )}

                {type === "input" && (
                    <input
                        type={type}
                        className="form-control"
                        value={value}
                        onChange={onChange}
                        placeholder={placeholder}
                    />
                )}

                {type === "date" && (
                    <DatePicker
                        format="dd/MM/y"
                        // onChange={(e) => {
                        //     setFromDate(e);
                        // }}
                        className="form-control"
                        // value={fromDate}
                        onKeyDown={(e) => {
                            e.preventDefault();
                        }}
                    />
                )}

                {type === "button" && (
                    <div className="flex items-center">
                        <button className="simple-btn">{text}</button>

                        <div>
                            <img
                                onClick={() => deleteData(id)}
                                src={crossIcon}
                                className="cross-btn"
                                alt="crossIcon"
                            />
                            <img
                                className="arrows"
                                src={upIcon}
                                onClick={() => move(index, "up")}
                            />
                            <img
                                className="arrows"
                                src={downIcon}
                                onClick={() => move(index, "down")}
                            />
                        </div>
                    </div>
                )}

                {type === "file" && (
                    <input
                        type={"file"}
                        className="form-control"
                        value={value}
                        onChange={onChange}
                        disabled
                    />
                )}

                {type === "checkbox" && (
                    <>
                        {options.map((e, i) => (
                            <label htmlFor={i + "-" + id} className="check-box">
                                <input
                                    id={i + "-" + id}
                                    type="checkbox"
                                    title={e}
                                />
                                <span>{e}</span>
                            </label>
                        ))}
                    </>
                )}

                {type === "radio" && (
                    <div>
                        {options.map((e) => (
                            <label id="group1" className="check-radio">
                                <input type="radio" name="group1" />
                                <span>{e}</span>
                            </label>
                        ))}
                    </div>
                )}

                {type === "select" && (
                    <select className="form-control field-margin  w-300">
                        {options.map((e) => (
                            <option value={e}>{e}</option>
                        ))}
                    </select>
                )}
            </div>
        </li>
    );
};

export default InputElements;
