import userIcon from "../../../../assets/images/usericon.svg";
import add from "../../../../assets/images/add-icon.svg";
import view from "../../../../assets/images/view.svg";
import update from "../../../../assets/images/update.svg";
import del from "../../../../assets/images/del.svg";
import React, { useEffect } from "react";
import Search from "../../../../components/search/Search";
import { AddCase } from "../../../../components/modals/AddCase";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { storeLeads } from "../../../../redux/leads/leadsSlice";
import { toastify } from "../../../../helpers/toast/toastify";
import { api } from "../../../../helpers/apiHelper/requestHelper";
import { useDispatch } from "react-redux";
import LeadDetailsModal from "../lead-details-modal/LeadDetailsModal";
import { PENDING, PROCESSING } from "../../../../config/leadStatus";
import { AlertModal } from "../../../../components/modals/AlertModal";
import { setLoading } from "../../../../redux/loader/loadingSlice";
import EntriesSelection from "../../../../components/pagination/EntriesSelection";
import Pagination from "../../../../components/pagination/Pagination";
import { shortFormatDate } from "../../../../helpers/dataHelper/dateFormatter";
import {
    APPROVED,
    COMPLETED,
    PENDING_1,
    PENDING_2,
    PROCESSING_1,
} from "../../../../config/casesStatus";
import calenderIcon from "../../../../assets/images/calender.svg";
import DatePicker from "react-date-picker";

export default function AllLeads() {
    const reduxLeads = useSelector((state) => state.leads.value);
    const userRoles = useSelector((state) => state.userRoles);

    const [openAddModal, setOpenAddModal] = useState(false);
    const [filter, setFilter] = useState("all");
    const [filtered, setFiltered] = useState(reduxLeads || []);
    const [selectedLead, setSelectedLead] = useState();
    const [show, setShow] = useState(false);
    const [lead, setLead] = useState();
    const [openDetailModal, setOpenDetailModal] = useState(false);
    const [count, setCount] = useState(5);
    const [page, setPage] = useState(1);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();

    useEffect(() => {
        let year = new Date().getFullYear();
        setStartDate(new Date(year, 0, 1));
        setEndDate(new Date());
    }, []);

    const compareDates = (a, b) => {
        let sDate = new Date(a);
        let eDate = new Date(b);
        if (
            sDate.getFullYear() === eDate.getFullYear() &&
            sDate.getMonth() === eDate.getMonth() &&
            sDate.getDate() === eDate.getDate()
        ) {
            return true;
        } else {
            return false;
        }
    };

    const filterDataByDate = (e) => {
        if (startDate) {
            if (
                new Date(e?.created_at) >= new Date(startDate) ||
                compareDates(e?.created_at, startDate)
            ) {
                if (endDate) {
                    if (
                        new Date(e?.created_at) <= new Date(endDate) ||
                        compareDates(e?.created_at, endDate)
                    ) {
                        return e;
                    }
                } else {
                    return e;
                }
            }
        } else if (endDate) {
            if (
                new Date(e?.created_at) <= new Date(endDate) ||
                compareDates(e?.created_at, endDate)
            ) {
                if (startDate) {
                    if (
                        new Date(e?.created_at) >= new Date(startDate) ||
                        compareDates(e?.created_at, startDate)
                    ) {
                        return e;
                    }
                } else {
                    return e;
                }
            }
        }
    };

    useEffect(() => {
        setFiltered(reduxLeads);
    }, [reduxLeads]);

    useEffect(() => {
        if (window.location.pathname) {
            let data = [...reduxLeads]?.filter((e) => {
                if (
                    (window.location.pathname.split("/")[3] === "assigned" &&
                        e.assignedTo) ||
                    (window.location.pathname.split("/")[3] === "unassigned" &&
                        !e.assignedTo) ||
                    window.location.pathname.split("/")[3] === "all" ||
                    (window.location.pathname.split("/")[3] === "reassigned" &&
                        e.reassigned)
                ) {
                    return e;
                }
            });
            setFiltered(data);
            setFilter(window.location.pathname.split("/")[3]);
        }
    }, [window.location.pathname, reduxLeads]);

    const getStartDate = async () => {
        let response = await api("get", "settings/getDateSettings");

        if (response?.status) {
            setStartDate(new Date(response?.data?.startDate));
        }
    };

    useEffect(() => {
        getLeads();
        getStartDate();
    }, []);

    const getLeads = async () => {
        let response = await api("post", "leads/index", {
            assignedFromRole: userRoles?.currentRole,
        });

        if (response?.status) {
            dispatch(storeLeads(response?.data?.data?.reverse()));
        } else {
            toastify(response?.message, "error");
        }
    };

    const getSubStatus = (status) => {
        if (status?.toLowerCase() === "pending") {
            return PENDING_1;
        } else if (status?.toLowerCase() === "submitted") {
            return PENDING_2;
        } else if (status?.toLowerCase() === "processing") {
            return PROCESSING_1;
        } else if (status?.toLowerCase() === "approved") {
            return APPROVED;
        } else if (status?.toLowerCase() === "completed") {
            return COMPLETED;
        } else {
            return null;
        }
    };

    const addCase = async (data) => {
        dispatch(setLoading(true));

        const response = await api("post", "leads/register", {
            leadId: selectedLead?._id,
            ...data,
        });
        dispatch(setLoading(false));

        if (response?.data?.status) {
            setOpenAddModal(false);
            toastify("Case Added Successfully", "success");
            getLeads();
        } else {
            toastify(response?.message, "error");
        }
    };

    const deleteLead = async (data) => {
        setShow(true);
        setLead(data);
    };

    const confirmAction = async () => {
        dispatch(setLoading(true));
        const response = await api("post", "leads/update", {
            isDeleted: true,
            id: lead._id,
        });
        dispatch(setLoading(false));
        if (response?.data?.status) {
            getLeads();
            toastify("Lead deleted successfully", "success");
            setShow(false);
        } else {
            toastify(response?.message, "error");
        }
    };

    return (
        <>
            <div className="flex items-end mb-20">
                <h4 className="flex flex-wrap mini-title">
                    <span
                        onClick={() =>
                            navigate(
                                "/" +
                                    window.location.pathname.split("/")[1] +
                                    "/leads/all"
                            )
                        }
                    >
                        Leads{" "}
                    </span>
                    /{" "}
                    {filter === "assigned"
                        ? "Assigned"
                        : filter === "unassigned"
                        ? "Unassigned"
                        : "All"}{" "}
                    Leads{" "}
                </h4>
                <div className="flex ml-auto">
                    <div>
                        <p>Start Date</p>
                        <DatePicker
                            onChange={(e) => setStartDate(e)}
                            maxDate={endDate}
                            format="dd/MM/y"
                            className="form-control"
                            value={startDate}
                            onKeyDown={(e) => {
                                e.preventDefault();
                            }}
                        />
                    </div>
                    <div className="ml-20">
                        <p>End Date</p>
                        <DatePicker
                            onChange={(e) => setEndDate(e)}
                            minDate={startDate}
                            format="dd/MM/y"
                            className="form-control"
                            value={endDate}
                            onKeyDown={(e) => {
                                e.preventDefault();
                            }}
                        />
                    </div>
                </div>
            </div>

            <Search
                userSearch={true}
                filedText="Search here"
                teamDropdown={false}
                filters={false}
                typeFilters={true}
                typeFiltersTitle="Lead Type"
                // dateFilters={true}
                addButton={true}
                addButtonText="Add Leads"
                buttonRoute={"/case-manager/add-leads"}
                extraClass="mega-search"
                statusFilter={true}
                setLeads={setFiltered}
                leads={reduxLeads}
                component={"Leads"}
                filter={filter}
            />
            {show && (
                <AlertModal
                    confirmAction={confirmAction}
                    setModalClick={() => setShow(false)}
                    confirmBtn={true}
                    modalTitle={[
                        <h2>
                            Do you really want to delete{" "}
                            {lead?.identifier || "N/A"}
                            <br /> Lead permanently?
                        </h2>,
                    ]}
                    modalDescription={[]}
                />
            )}
            <div className="responsive-table accordion-list w-100">
                <table className="table">
                    <thead>
                        <tr
                            className="no-shadow"
                            style={{ background: "#f6f6f6" }}
                        >
                            <td colSpan="10" height="16"></td>
                        </tr>
                        <tr>
                            <th>ID</th>
                            <th>Created Date</th>
                            <th>Lead Type</th>
                            <th>Entities Type</th>
                            <th>Name</th>
                            <th>Contact No.</th>
                            <th>Assign From</th>
                            {userRoles.currentRole !== "RM Member" && (
                                <th>Assign To</th>
                            )}
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filtered
                            ?.filter((e) => {
                                if (startDate || endDate) {
                                    return filterDataByDate(e);
                                } else {
                                    return e;
                                }
                            })
                            ?.slice(count * page - count, count * page)
                            ?.map((data) => {
                                return (
                                    <>
                                        <tr className="no-shadow">
                                            <td colSpan="10" height="16"></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span className="flex items-center">
                                                    <img
                                                        src={userIcon}
                                                        alt="userIcon"
                                                    />{" "}
                                                    {data?.identifier}
                                                </span>
                                            </td>
                                            <td>
                                                {shortFormatDate(
                                                    data?.created_at
                                                )}
                                            </td>
                                            <td>{data?.leadType}</td>
                                            <td>{data?.entityType}</td>
                                            <td>
                                                {data?.entityType === "Business"
                                                    ? data?.businessEntity
                                                          ?.representative
                                                          ?.firstName +
                                                      " " +
                                                      data?.businessEntity
                                                          ?.representative
                                                          ?.lastName
                                                    : data?.individualEntity
                                                          ?.firstName +
                                                      " " +
                                                      data?.individualEntity
                                                          ?.lastName}
                                            </td>
                                            <td>
                                                <span className="number">
                                                    {data?.entityType ===
                                                    "Business"
                                                        ? data?.businessEntity
                                                              ?.representative
                                                              ?.contact
                                                        : data?.individualEntity
                                                              ?.contact}{" "}
                                                    <small>
                                                        {data?.leadType}
                                                    </small>
                                                </span>
                                            </td>
                                            <td>
                                                {data?.assignedFrom?.username ||
                                                    "N/A"}
                                            </td>
                                            {userRoles.currentRole !==
                                                "RM Member" && (
                                                <td>
                                                    {data?.assignedTo
                                                        ?.username || "N/A"}
                                                </td>
                                            )}
                                            <td>
                                                <span
                                                    className={`status ${data?.status
                                                        ?.toLowerCase()
                                                        ?.split(" ")
                                                        .join("-")}`}
                                                >
                                                    {data?.status}
                                                </span>
                                                <small>
                                                    {getSubStatus(data?.status)}
                                                </small>
                                            </td>
                                            <td>
                                                <div className="flex items-center">
                                                    <button
                                                        className="table-action add"
                                                        onClick={() => {
                                                            setOpenAddModal(
                                                                true
                                                            );
                                                            setSelectedLead(
                                                                data
                                                            );
                                                        }}
                                                    >
                                                        <img
                                                            src={add}
                                                            alt="add"
                                                        />
                                                        <span>
                                                            <small>
                                                                Add Case
                                                            </small>
                                                        </span>
                                                    </button>
                                                    <button
                                                        className="table-action view"
                                                        onClick={() => {
                                                            setSelectedLead(
                                                                data
                                                            );
                                                            setOpenDetailModal(
                                                                true
                                                            );
                                                        }}
                                                    >
                                                        <img
                                                            src={view}
                                                            alt="view"
                                                        />
                                                        <span>
                                                            <small>View</small>
                                                        </span>
                                                    </button>
                                                    <button
                                                        className="table-action update cursor-pointer"
                                                        onClick={() => {
                                                            navigate(
                                                                "/case-manager/add-leads",
                                                                {
                                                                    state: {
                                                                        lead: data,
                                                                    },
                                                                }
                                                            );
                                                        }}
                                                    >
                                                        <img
                                                            src={update}
                                                            alt="update"
                                                        />
                                                        <span>
                                                            <small>
                                                                Update
                                                            </small>
                                                        </span>
                                                    </button>
                                                    <button
                                                        className="table-action del"
                                                        onClick={() =>
                                                            deleteLead(data)
                                                        }
                                                    >
                                                        <img
                                                            src={del}
                                                            alt="del"
                                                        />
                                                        <span>
                                                            <small>
                                                                Delete
                                                            </small>
                                                        </span>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    </>
                                );
                            })}
                    </tbody>
                </table>
                {/* {filter === "all" && ( */}
                <div className="flex pagination-combine">
                    <EntriesSelection
                        setCount={setCount}
                        page={page}
                        count={count}
                        data={filtered?.filter((e) => {
                            if (startDate || endDate) {
                                return filterDataByDate(e);
                            } else {
                                return e;
                            }
                        })}
                    />
                    <Pagination
                        count={count}
                        data={filtered?.filter((e) => {
                            if (startDate || endDate) {
                                return filterDataByDate(e);
                            } else {
                                return e;
                            }
                        })}
                        setPage={setPage}
                        page={page}
                    />
                </div>
                {/* )} */}
            </div>
            {openAddModal && (
                <AddCase
                    setModalClick={() => {
                        setOpenAddModal(!openAddModal);
                    }}
                    addCase={addCase}
                />
            )}
            {openDetailModal && (
                <LeadDetailsModal
                    setModalClick={() => {
                        setOpenDetailModal(!openDetailModal);
                    }}
                    setOpenDetailModal={() => {
                        setOpenDetailModal(!openDetailModal);
                    }}
                    lead={selectedLead}
                    getLeads={getLeads}
                />
            )}
        </>
    );
}
