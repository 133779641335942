import "./modals.scss";
import crossIcon from "../../assets/images/cross.svg";

export const ReminderModal = (props) => {
    return (
        <div className={`modal-wrapper reminder-modal`}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-body">
                        <img
                            src={crossIcon}
                            className="cross-btn"
                            onClick={props.setModalClick}
                            alt="crossIcon"
                        />
                        <div className="modal-forms">
                            <h3>Reminder</h3>
                            <h5>{props?.title + " ( " + props.type + " ) "}</h5>
                            {props.reminderDescription}
                            <div className="flex content-justify-center">
                                <button
                                    onClick={() => props.acknowledge(props.id)}
                                    className="modal-btn"
                                >
                                    Acknowledge
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
