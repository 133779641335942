import {
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel,
} from "react-accessible-accordion";
import userIcon from "../../../../../assets/images/usericon.svg";
import add from "../../../../../assets/images/add-icon.svg";
import view from "../../../../../assets/images/view.svg";
import update from "../../../../../assets/images/update.svg";
import del from "../../../../../assets/images/del.svg";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { PENDING, PROCESSING } from "../../../../../config/leadStatus";
import { storeCases } from "../../../../../redux/cases/casesSlice";
import { toastify } from "../../../../../helpers/toast/toastify";
import { api } from "../../../../../helpers/apiHelper/requestHelper";
import { useDispatch } from "react-redux";
import { shortFormatDate } from "../../../../../helpers/dataHelper/dateFormatter";

export default function AllCaseAccordions(props) {
    const reduxData = useSelector((state) => state);
    const dispatch = useDispatch();

    const getSubStatus = (status) => {
        if (status.toLowerCase() === "pending") {
            return PENDING;
        } else if (status.toLowerCase() === "processing") {
            return PROCESSING;
        }
        return status;
    };

    return (
        <>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        {props?.filter === "rejected"
                            ? "Rejected "
                            : props?.filter === "approved"
                            ? "Approved "
                            : props?.filter === "pending"
                            ? "Pending "
                            : "All "}
                        Case
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <div className="responsive-table accordion-list cases-list w-100">
                        <table className="table">
                            <thead>
                                <tr
                                    className="no-shadow"
                                    style={{ background: "#f6f6f6" }}
                                >
                                    <td colSpan="10" height="16"></td>
                                </tr>
                                <tr>
                                    <th>Case ID</th>
                                    <th>Lead ID</th>
                                    <th>Created Date</th>
                                    <th>Product Name</th>
                                    <th>Entities Type</th>
                                    <th>Assign from</th>
                                    {reduxData?.userRoles.currentRole !==
                                        "RM Member" && <th>Assign to</th>}
                                    <th>Status</th>
                                    {/* <th>Action</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {reduxData?.cases?.value
                                    ?.filter((e) => {
                                        if (
                                            props.filter === "rejected" &&
                                            e.status.toLowerCase() ===
                                                props.filter
                                        ) {
                                            return e;
                                        } else if (
                                            props.filter === "approved" &&
                                            e.status.toLowerCase() ===
                                                props.filter
                                        ) {
                                            return e;
                                        } else if (props.filter === "all") {
                                            if (props.user) {
                                                if (
                                                    e?.lead?.assignedTo?._id ===
                                                    props.user
                                                ) {
                                                    return e;
                                                }
                                            } else {
                                                return e;
                                            }
                                        } else if (
                                            props.filter === "pending" &&
                                            e.status.toLowerCase() ===
                                                props.filter
                                        ) {
                                            return e;
                                        }
                                    })
                                    ?.map((data, index) => {
                                        if (index < 5) {
                                            return (
                                                <>
                                                    <tr className="no-shadow">
                                                        <td
                                                            colSpan="10"
                                                            height="16"
                                                        ></td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            {data?.identifier}
                                                        </td>
                                                        <td>
                                                            {
                                                                data?.lead
                                                                    ?.identifier
                                                            }
                                                        </td>
                                                        <td>
                                                            {shortFormatDate(
                                                                data?.created_at
                                                            )}
                                                        </td>
                                                        <td>
                                                            {
                                                                data?.product
                                                                    ?.name
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                data?.lead
                                                                    ?.entityType
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                data?.lead
                                                                    ?.assignedFrom
                                                                    ?.username
                                                            }
                                                        </td>
                                                        {reduxData?.userRoles
                                                            .currentRole !==
                                                            "RM Member" && (
                                                            <td>
                                                                {
                                                                    data?.lead
                                                                        ?.assignedTo
                                                                        ?.username
                                                                }
                                                            </td>
                                                        )}
                                                        <td>
                                                            <span
                                                                className={`status ${data?.status.toLowerCase()}`}
                                                            >
                                                                {data?.status}
                                                            </span>
                                                            <small>
                                                                {getSubStatus(
                                                                    data?.status
                                                                )}
                                                            </small>
                                                        </td>
                                                        {/* <td>
                                                            <div className="flex items-center">
                                                                <button className="table-action add">
                                                                <img src={add} alt="add"/>
                                                                <span>
                                                            <small>
                                                                Add Case
                                                            </small>
                                                        </span>
                                                            </button>
                                                                <button className="table-action view">
                                                                    <img
                                                                        src={view}
                                                                        alt="view"
                                                                    />
                                                                    <span>
                                                                        <small>
                                                                            View
                                                                        </small>
                                                                    </span>
                                                                </button>
                                                                <button className="table-action update cursor-pointer">
                                                                    <img
                                                                        src={update}
                                                                        alt="update"
                                                                    />
                                                                    <span>
                                                                        <small>
                                                                            Update
                                                                        </small>
                                                                    </span>
                                                                </button>
                                                                <button className="table-action del">
                                                                    <img
                                                                        src={del}
                                                                        alt="del"
                                                                    />
                                                                    <span>
                                                                        <small>
                                                                            Delete
                                                                        </small>
                                                                    </span>
                                                                </button>
                                                            </div>
                                                        </td> */}
                                                    </tr>
                                                </>
                                            );
                                        }
                                    })}
                            </tbody>
                        </table>
                    </div>
                </AccordionItemPanel>
            </AccordionItem>
        </>
    );
}
