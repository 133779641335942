import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel,
} from "react-accessible-accordion";
import React, { useEffect, useState } from "react";
import caseIcon from "../../../../assets/images/caseIcon.svg";
import doneIcon from "../../../../assets/images/check-doc.svg";
import pngFile from "../../../../assets/images/pngFile.svg";
import fileIcon from "../../../../assets/images/file-icon.svg";
import update from "../../../../assets/images/update.svg";
import { useNavigate } from "react-router-dom";
import { api } from "../../../../helpers/apiHelper/requestHelper";
import { toastify } from "../../../../helpers/toast/toastify";
import { DocumentModal } from "../../../../components/modals/DocumentModal";
import { PdfModal } from "../../../../components/modals/PdfModal";
import DatePicker from "react-date-picker";
import { useSelector } from "react-redux";
import { SendDocumentModal } from "../../../../components/modals/SendDocumentModal";
import { ImageModal } from "../../../../components/modals/ImageModal";
import { AlertModal } from "../../../../components/modals/AlertModal";
import { resetSidebar } from "../../../../helpers/sidebar/resetSidebar";
import { BankModal } from "../../../../components/modals/BankModal";
import addIcon from "../../../../assets/images/add.svg";
import moreIcon from "../../../../assets/images/more.svg";
import OutsideClickHandler from "react-outside-click-handler";
import { RejectionModal } from "../../../../components/modals/RejectionModal";
import acceptIcon from "../../../../assets/images/check-doc-white.svg";
import rejectIcon from "../../../../assets/images/reject-doc-white.svg";
import { PdpaModal } from "../../../../components/modals/PdpaModal";
import { setLoading } from "../../../../redux/loader/loadingSlice";
import { useDispatch } from "react-redux";
import { missingPopup } from "../../../../helpers/dataHelper/missingData";
import { getCaseUsers } from "../../../../helpers/dataHelper/getCaseUsers";
import {
    formatDate,
    shortFormatDate,
} from "../../../../helpers/dataHelper/dateFormatter";
import { DownloadDocsModal } from "../../../../components/modals/DownloadDocsModal";
import { CashOutModal } from "../../../../components/modals/CashOutModal";
import { FundsModal } from "../../../../components/modals/FundsModal";
import BankNameModal from "../../../../components/modals/BankNameModal";
import parse from "html-react-parser";
import { formatCurrency } from "../../../../helpers/dataHelper/currentFormatter";

export default function CaseInformation(props) {
    const [selectedCase, setSelectedCase] = useState();
    const [show, setShow] = useState(false);
    const [pdfShow, setPdfShow] = useState(false);
    const [imageShow, setImageShow] = useState(false);
    const [showSend, setShowSend] = useState(false);
    const [url, setUrl] = useState("");
    const navigate = useNavigate();
    const userRoles = useSelector((state) => state.userRoles);
    const numberRegex = new RegExp(`^((0|([1-9][0-9]*))([.]|[.][0-9]{1,2})?)?$`);
    const [selectedBank, setSelectedBank] = useState();
    const [approvedSelectedBank, setApprovedSelectedBank] = useState();
    const [selectionModal, setSelectionModal] = useState(false);
    const [downloadShow, setDownloadShow] = useState(false);
    const [institutes, setInstitutes] = useState([]);
    const [amount, setAmount] = useState("");
    const [openAlertModal, setOpenAlertModal] = useState(false);
    const [bankModal, setBankModal] = useState(false);
    const [rejectionId, setRejectionId] = useState();
    const [showBankRejection, setShowBankRejection] = useState(false);
    const [completeModal, setCompleteModal] = useState(false);
    const [showRej, setShowRej] = useState(false);
    const reduxData = useSelector((state) => state);
    const [pdpaDel, setPdpdDel] = useState(false);
    const [approvedAmount, setApprovedAmount] = useState("");
    const [allApprovedAmounts, setAllApprovedAmounts] = useState([]);
    const [allSubmittedAmounts, setAllSubmittedAmounts] = useState([]);
    const [elements, setElements] = useState([]);
    const [bankEdit, setBankEdit] = useState();
    const [cashOut, setCashOut] = useState({
        amount: "",
        fundsDisbursed: new Date(),
        cashOutModal: false,
        fundsModal: false,
        bank: null,
        maxAmount: "0",
    });
    const dispatch = useDispatch();

    useEffect(() => {
        if (props.case) {
            setSelectedCase(props.case);
        }
    }, [props.case]);

    useEffect(() => {
        if (institutes.length) {
            let aArr = [];
            institutes.forEach((i) =>
                aArr.push(i.approvedAmount || i.amount || "0")
            );
            setAllApprovedAmounts(aArr);

            let sArr = [];
            institutes.forEach((i) => sArr.push(i.amount || "0"));
            setAllSubmittedAmounts(sArr);
        }
    }, [institutes]);

    useEffect(() => {
        if (props?.lead?.cases?.length) {
            setSelectedCase(props?.lead?.cases[0]);
        }
    }, []);

    const showDoc = (caseDoc) => {
        setUrl(caseDoc?.url);
        let extension = caseDoc?.url.split(".");
        if (extension[extension.length - 1] === "pdf") {
            setPdfShow(true);
        } else {
            setImageShow(true);
        }
    };

    const saveApproved = (id) => {
        if (approvedAmount && approvedAmount !== "0") {
            submitApprovedAmount(id);
        } else {
            missingPopup("Approved amount");
        }
    };

    const getCaseDocument = (doc) => {
        let _caseDoc = (
            props?.case ? props?.case : selectedCase
        )?.caseDocuments.find((e) => e.documentId === doc?._id);

        if (_caseDoc && _caseDoc?.url) {
            return (
                <>
                    {_caseDoc?.input && (
                        <div className="input-font">{_caseDoc?.input}</div>
                    )}

                    <span
                        onClick={async () => {
                            if (_caseDoc?.uuid) {
                                const response = await api(
                                    "post",
                                    "cases/getSignedDocument",
                                    { uuid: _caseDoc?.uuid }
                                );

                                if (
                                    !response?.data?.status ||
                                    !response?.data?.data
                                ) {
                                    showDoc(_caseDoc);
                                } else {
                                    window.open(response?.data?.data, "_blank");
                                }
                            } else {
                                console.log("NO UUID FOUND");
                                showDoc(_caseDoc);
                            }
                        }}
                        className="cursor-pointer"
                    >
                        <img src={fileIcon} alt="pdfIcon" /> {_caseDoc?.name}
                    </span>
                </>
            );
        }
    };

    const getName = (lead) => {
        if (lead?.entityType === "Business") {
            return (
                lead?.businessEntity?.representative?.firstName +
                lead?.businessEntity?.representative?.lastName
            );
        } else if (lead?.entityType === "Individual") {
            return (
                lead?.individualEntity?.firstName +
                " " +
                lead?.individualEntity?.lastName
            );
        } else {
            return "N/A";
        }
    };

    const markAsComplete = async () => {
        const response = await api("post", "cases/update", {
            id: props?.case?._id || selectedCase._id,
            status: "Completed",
            logs: [
                ...selectedCase?.logs,
                {
                    date: new Date(),
                    operator: reduxData?.auth?.user?._id,
                    comment: `Case has been marked as completed.`,
                },
            ],
        });

        if (response?.data?.status) {
            let allUsers = getCaseUsers(selectedCase);
            if (!allUsers.includes(reduxData?.auth?.user?._id)) {
                allUsers.push(reduxData?.auth?.user?._id);
            }
            await api("post", "activity/create", {
                activity: `${response?.data?.data?.identifier} - has been marked as completed.`,
                users: allUsers,
            });
            await sendNotification("Completed");

            toastify("Case marked as Completed", "success");
            setSelectedCase(response?.data?.data);
            props?.setCurrentCase(response?.data?.data);
            resetSidebar();
            props.getCases();
            setCompleteModal(false);
        } else {
            toastify(response?.message, "error");
        }
    };

    const sendNotification = async (status, bank) => {
        let participants = [];
        // reduxData?.users?.value?.forEach((element) => {
        //     if (element.roles.includes("Case Manager")) {
        //         if (element._id) {
        //             participants.push(element._id);
        //         }
        //     }
        // });
        if (selectedCase?.lead?.assignedTo?._id) {
            participants.push(selectedCase?.lead?.assignedTo?._id);
        }
        if (selectedCase?.lead?.assignedFrom?._id) {
            participants.push(selectedCase?.lead?.assignedFrom?._id);
        }

        let _participants = [...new Set(participants)];

        participants = _participants?.map((e) => {
            return { user: e, acknowledged: false };
        });

        let data;

        if (status === "Accept Bank") {
            data = {
                participants,
                remarks: `Case ${selectedCase?.identifier} has been accepted by ${bank}`,
                title: `Case Accepted by Bank`,
            };
        } else {
            data = {
                participants,
                remarks: `Case ${selectedCase?.identifier} has been marked as ${status}.`,
                title: `Case ${status}`,
            };
        }

        await api("post", "notifications/create", data);
    };

    const markClientAccept = async () => {
        let _institutes = institutes.map((e) => {
            return { ...e };
        });
        const index = _institutes.findIndex((obj) => obj._id === cashOut?.bank);
        _institutes[index]["clientDecision"] = "Accepted";
        _institutes[index]["cashOut"] = cashOut.amount;
        dispatch(setLoading(true));
        const response = await api("post", "cases/update", {
            id: selectedCase?._id,
            institutes: _institutes,
            logs: [
                ...selectedCase?.logs,
                {
                    date: new Date(),
                    operator: reduxData?.auth?.user?._id,
                    comment: `Client accepted the loan of ${formatCurrency(
                        cashOut.amount,
                        true
                    )} from ${selectedBank?.name}`,
                },
            ],
        });
        dispatch(setLoading(false));

        if (response?.data?.status) {
            let allUsers = getCaseUsers(selectedCase);
            if (!allUsers.includes(reduxData?.auth?.user?._id)) {
                allUsers.push(reduxData?.auth?.user?._id);
            }
            await api("post", "activity/create", {
                activity: `${
                    selectedCase?.identifier
                } - Client accepted the loan of ${formatCurrency(
                    cashOut.amount,
                    true
                )} from ${selectedBank?.name}`,
                users: allUsers,
            });
            setSelectedCase(response?.data?.data);
            resetSidebar();
            props.getCases();
            setCashOut({
                amount: "",
                fundsDisbursed: new Date(),
                cashOutModal: false,
                fundsModal: false,
                bank: null,
                maxAmount: "0",
            });
            setSelectedBank();
        } else {
            toastify(response?.message);
        }
    };

    const markClientReject = async (id) => {
        let _institutes = institutes.map((e) => {
            return { ...e };
        });
        const index = _institutes.findIndex((obj) => obj._id === id);
        _institutes[index]["clientDecision"] = "Rejected";
        dispatch(setLoading(true));
        const response = await api("post", "cases/update", {
            id: selectedCase?._id,
            institutes: _institutes,
            logs: [
                ...selectedCase?.logs,
                {
                    date: new Date(),
                    operator: reduxData?.auth?.user?._id,
                    comment: `Client rejected the bank.`,
                },
            ],
        });
        dispatch(setLoading(false));

        if (response?.data?.status) {
            let allUsers = getCaseUsers(selectedCase);
            if (!allUsers.includes(reduxData?.auth?.user?._id)) {
                allUsers.push(reduxData?.auth?.user?._id);
            }
            await api("post", "activity/create", {
                activity: `${response?.data?.data?.identifier} - Client rejected the bank.`,
                users: allUsers,
            });
            setSelectedCase(response?.data?.data);
            resetSidebar();
            props.getCases();
        } else {
            toastify(response?.message);
        }
    };

    const deleteBank = async (id, name) => {
        let _institutes = institutes.filter((e) => e._id !== id);
        const response = await api("post", "cases/update", {
            id: selectedCase?._id,
            institutes: _institutes,
            logs: [
                ...selectedCase?.logs,
                {
                    date: new Date(),
                    operator: reduxData?.auth?.user?._id,
                    comment: `${name} has been deleted.`,
                },
            ],
        });

        if (response?.data?.status) {
            let allUsers = getCaseUsers(selectedCase);
            if (!allUsers.includes(reduxData?.auth?.user?._id)) {
                allUsers.push(reduxData?.auth?.user?._id);
            }
            await api("post", "activity/create", {
                activity: `${response?.data?.data?.identifier} - ${name} has been deleted.`,
                users: allUsers,
            });
            setSelectedCase(response?.data?.data);
            resetSidebar();
            props.getCases();
        } else {
            toastify(response?.message);
        }
        setSelectionModal();
    };

    const getClassName = (doc) => {
        let thisCase = selectedCase || props?.case;
        let myDoc = thisCase?.caseDocuments?.find(
            (e) => e.documentId === doc._id
        );
        if (myDoc) {
            if ((doc?.upload && !myDoc?.url) || (doc?.input && !myDoc?.input)) {
                if (myDoc?.pdpaDeleted) {
                    return "";
                }
                return "reject";
            } else {
                return "";
            }
        } else {
            return "waiting";
        }
    };

    const getPdpa = (doc) => {
        let thisCase = selectedCase || props.case;
        let myDoc = thisCase?.caseDocuments?.find(
            (e) => e.documentId === doc._id
        );
        if (myDoc?.pdpaDeleted) {
            return true;
        }
        return false;
    };

    const rejectCase = async (reason) => {
        if (!reason) toastify("Please enter a reason.");
        else {
            dispatch(setLoading(true));
            const response = await api("post", "cases/update", {
                id: props?.case?._id || selectedCase?._id,
                decision: "Rejected",
                status: "Rejected",
                decisionReason: reason,
                logs: [
                    ...(props?.case || selectedCase)?.logs,
                    {
                        operator: reduxData?.auth?.user?._id,
                        date: new Date(),
                        comment: `Case Manager has rejected the case for the following reason: ${reason}.`,
                    },
                ],
            });

            if (response?.data?.status) {
                let allUsers = getCaseUsers(props?.case || selectedCase);
                if (!allUsers.includes(reduxData?.auth?.user?._id)) {
                    allUsers.push(reduxData?.auth?.user?._id);
                }
                await api("post", "activity/create", {
                    activity: `${response?.data?.data?.identifier} - Case Manager has rejected the case for the following reason: ${reason}.`,
                    users: allUsers,
                });
                await sendNotification("Rejected");
                setShowRej(false);
                resetSidebar();
                props.getCases();
                props.setOpenDetailModal();
                dispatch(setLoading(false));

                toastify("Case rejected successfully.", "success");
            } else {
                toastify("Case rejection failed.", "error");
            }
            dispatch(setLoading(false));
        }
    };

    const unset = async (id) => {
        dispatch(setLoading(true));
        let _institutes = institutes.map((e) => {
            return { ...e };
        });
        const index = _institutes.findIndex((obj) => obj._id === id);
        _institutes[index]["decision"] = null;
        _institutes[index]["amount"] = null;
        _institutes[index]["approvedAmount"] = null;
        _institutes[index]["cashOut"] = null;
        _institutes[index]["fundsDisbursed"] = null;
        _institutes[index]["clientDecision"] = null;
        const response = await api("post", "cases/update", {
            id: selectedCase?._id,
            institutes: _institutes,
            status: "Processing",
            logs: [
                ...selectedCase?.logs,
                {
                    date: new Date(),
                    operator: reduxData?.auth?.user?._id,
                    comment: `Unset ${_institutes[index]?.bank?.name}`,
                },
            ],
        });
        let allUsers = getCaseUsers(selectedCase);
        if (!allUsers.includes(reduxData?.auth?.user?._id)) {
            allUsers.push(reduxData?.auth?.user?._id);
        }
        await api("post", "activity/create", {
            activity: `${selectedCase?.identifier} - Unset ${_institutes[index]?.bank?.name}.`,
            users: allUsers,
        });
        dispatch(setLoading(false));

        if (response?.data?.status) {
            setSelectedCase(response?.data?.data);
            resetSidebar();
            props.getCases();
        } else {
            toastify(response?.message);
        }
        setSelectionModal();
    };

    const updateDate = async (date, id) => {
        let _institutes = institutes.map((e) => {
            return { ...e };
        });
        const index = _institutes.findIndex((obj) => obj._id === id);
        _institutes[index]["submissionDate"] = date;
        const response = await api("post", "cases/update", {
            id: selectedCase?._id,
            institutes: _institutes,
            logs: [
                ...selectedCase?.logs,
                {
                    date: new Date(),
                    operator: reduxData?.auth?.user?._id,
                    comment: `Submission date updated to ${shortFormatDate(
                        date
                    )}`,
                },
            ],
        });

        if (response?.data?.status) {
            let allUsers = getCaseUsers(selectedCase);
            if (!allUsers.includes(reduxData?.auth?.user?._id)) {
                allUsers.push(reduxData?.auth?.user?._id);
            }
            await api("post", "activity/create", {
                activity: `${
                    response?.data?.data?.identifier
                } - Submission date updated to ${shortFormatDate(date)}.`,
                users: allUsers,
            });
            setSelectedCase(response?.data?.data);
            resetSidebar();
            props.getCases();
        } else {
            toastify(response?.message);
        }
    };

    const acceptBank = async (id) => {
        dispatch(setLoading(true));
        let _institutes = institutes.map((e) => {
            return { ...e };
        });
        const index = _institutes.findIndex((obj) => obj._id === id);
        _institutes[index]["decision"] = "Accepted";
        const response = await api(
            "post",
            "cases/update",
            _institutes.length === index + 1
                ? {
                      id: selectedCase?._id,
                      institutes: _institutes,
                      status: "Approved",
                      logs: [
                          ...selectedCase?.logs,
                          {
                              date: new Date(),
                              operator: reduxData?.auth?.user?._id,
                              comment: `Case accepted by ${_institutes[index].bank.name}`,
                          },
                      ],
                  }
                : {
                      id: selectedCase?._id,
                      institutes: _institutes,
                      logs: [
                          ...selectedCase?.logs,
                          {
                              date: new Date(),
                              operator: reduxData?.auth?.user?._id,
                              comment: `Case accepted by ${_institutes[index].bank.name}`,
                          },
                      ],
                  }
        );
        dispatch(setLoading(false));

        if (response?.data?.status) {
            let allUsers = getCaseUsers(selectedCase);
            if (!allUsers.includes(reduxData?.auth?.user?._id)) {
                allUsers.push(reduxData?.auth?.user?._id);
            }
            await api("post", "activity/create", {
                activity: `${response?.data?.data?.identifier} - Case accepted by ${_institutes[index].bank.name}`,
                users: allUsers,
            });
            await sendNotification("Accept Bank", _institutes[index].bank.name);

            setSelectedCase(response?.data?.data);
            props?.setCurrentCase(response?.data?.data);
            resetSidebar();
            props.getCases();
        } else {
            toastify(response?.message);
        }
    };

    const acceptCase = async () => {
        dispatch(setLoading(true));
        const response = await api("post", "cases/update", {
            id: props?.case?._id || selectedCase?._id,
            decision: "Accepted",
            status: "Processing",
            logs: (props?.case || selectedCase)?.logs?.length
                ? [
                      ...(props?.case || selectedCase)?.logs,
                      {
                          operator: reduxData?.auth?.user?._id,
                          date: new Date(),
                          comment: `Case Manager has accepted the case.`,
                      },
                  ]
                : [
                      {
                          operator: reduxData?.auth?.user?._id,
                          date: new Date(),
                          comment: `Case Manager has accepted the case.`,
                      },
                  ],
        });

        if (response?.data?.status) {
            let allUsers = getCaseUsers(props?.case || selectedCase);
            if (!allUsers.includes(reduxData?.auth?.user?._id)) {
                allUsers.push(reduxData?.auth?.user?._id);
            }
            await api("post", "activity/create", {
                activity: `Case ${response?.data?.data?.identifier} has been accepted by Case Manager.`,
                users: allUsers,
            });
            await sendNotification("Accepted");
            setShow(false);
            setOpenAlertModal(false);
            // props.setOpenDetailModal();
            setSelectedCase(response?.data?.data);
            resetSidebar();
            props?.setCurrentCase(response?.data?.data);
            props.getCases();
            dispatch(setLoading(false));

            toastify("Case accepted successfully.", "success");
        } else {
            toastify("Case acceptance failed.", "error");
        }
        dispatch(setLoading(false));
    };

    const submitAmount = async (id) => {
        dispatch(setLoading(true));
        let _institutes = institutes.map((e) => {
            return { ...e };
        });
        const index = _institutes.findIndex((obj) => obj._id === id);
        _institutes[index]["amount"] = allSubmittedAmounts[index];
        const response = await api("post", "cases/update", {
            id: selectedCase?._id,
            institutes: _institutes,
            logs: [
                ...selectedCase?.logs,
                {
                    date: new Date(),
                    operator: reduxData?.auth?.user?._id,
                    comment: `Submitted ${formatCurrency(
                        allSubmittedAmounts[index],
                        true
                    )} amount in ${_institutes[index].bank.name}.`,
                },
            ],
        });

        if (response?.data?.status) {
            let allUsers = getCaseUsers(selectedCase);
            if (!allUsers.includes(reduxData?.auth?.user?._id)) {
                allUsers.push(reduxData?.auth?.user?._id);
            }
            await api("post", "activity/create", {
                activity: `${
                    response?.data?.data?.identifier
                } - Submitted ${formatCurrency(
                    allSubmittedAmounts[index],
                    true
                )} amount in ${_institutes[index].bank.name}.`,
                users: allUsers,
            });
            toastify("Amount added successfully!", "success");
            props.getCases();
            setSelectedCase(response?.data?.data);
            resetSidebar();
        } else {
            toastify(response?.message);
        }
        dispatch(setLoading(false));

        setSelectionModal();
        setSelectedBank();
        setApprovedAmount();
    };

    const submitApprovedAmount = async (id) => {
        dispatch(setLoading(true));
        let _institutes = institutes.map((e) => {
            return { ...e };
        });
        const index = _institutes.findIndex((obj) => obj._id === id);
        _institutes[index]["approvedAmount"] = allApprovedAmounts[index];
        const response = await api("post", "cases/update", {
            id: selectedCase?._id,
            institutes: _institutes,
            logs: [
                ...selectedCase?.logs,
                {
                    date: new Date(),
                    operator: reduxData?.auth?.user?._id,
                    comment: `Submitted approved amount of ${formatCurrency(
                        allApprovedAmounts[index],
                        true
                    )} in ${_institutes[index].bank.name}`,
                },
            ],
        });

        if (response?.data?.status) {
            let allUsers = getCaseUsers(selectedCase);
            if (!allUsers.includes(reduxData?.auth?.user?._id)) {
                allUsers.push(reduxData?.auth?.user?._id);
            }
            await api("post", "activity/create", {
                activity: `${
                    response?.data?.data?.identifier
                } - Submitted approved amount of ${formatCurrency(
                    allApprovedAmounts[index],
                    true
                )} in ${_institutes[index].bank.name}`,
                users: allUsers,
            });
            toastify("Approved amount added successfully!", "success");
            props.getCases();
            setSelectedCase(response?.data?.data);
            resetSidebar();
            setApprovedAmount();
        } else {
            toastify(response?.message);
        }
        dispatch(setLoading(false));
        setSelectionModal();
        setApprovedSelectedBank();
        setSelectedBank();
    };

    const checkBanks = (myCase) => {
        let banks = myCase?.institutes;

        if (banks?.length === 0) {
            return false;
        } else {
            let a = banks.some((obj) => {
                return obj?.decision === "Accepted";
            });

            return a;
        }
    };

    const markCmComplete = async (docs) => {
        dispatch(setLoading(true));
        let response = await api("post", "cases/pdpaDelete", docs);

        if (response?.data?.status) {
            toastify("PDPA Deleted Successfully!", "success");
            await props?.getCases();
            props?.setOpenDetailModal(false);
            setPdpdDel(false);
        } else {
            toastify("Error marking case as PDPA Complete!", "error");
        }
        dispatch(setLoading(false));
    };

    const rejectBank = async (reason) => {
        dispatch(setLoading(true));

        let _institutes = institutes.map((e) => {
            return { ...e };
        });
        const index = _institutes.findIndex((obj) => obj._id === rejectionId);

        _institutes[index]["decision"] = "Rejected";
        _institutes[index]["rejectionReason"] = reason;
        const response = await api("post", "cases/update", {
            id: selectedCase?._id,
            institutes: _institutes,
            logs: [
                ...selectedCase?.logs,
                {
                    date: new Date(),
                    operator: reduxData?.auth?.user?._id,
                    comment: `Rejected by ${_institutes[index].bank.name}.`,
                },
            ],
        });

        dispatch(setLoading(false));

        if (response?.data?.status) {
            let allUsers = getCaseUsers(selectedCase);
            if (!allUsers.includes(reduxData?.auth?.user?._id)) {
                allUsers.push(reduxData?.auth?.user?._id);
            }
            await api("post", "activity/create", {
                activity: `${response?.data?.data?.identifier} - Rejected by ${_institutes[index].bank.name}.`,
                users: allUsers,
            });
            setSelectedCase(response?.data?.data);
            resetSidebar();
            props.getCases();
            setRejectionId();
            setShowBankRejection(false);
        } else {
            toastify(response?.message);
        }
    };

    const addBank = async (data) => {
        if (!data) toastify("Please select a bank.");
        else {
            dispatch(setLoading(true));
            const response = await api("post", "cases/update", {
                id: selectedCase?._id,
                institutes: [
                    ...institutes,
                    { bank: data?._id, submissionDate: new Date() },
                ],
                logs: [
                    ...selectedCase?.logs,
                    {
                        date: new Date(),
                        operator: reduxData?.auth?.user?._id,
                        comment: `Added a bank  ${data.name}`,
                    },
                ],
            });
            dispatch(setLoading(false));

            if (response?.data?.status) {
                let allUsers = getCaseUsers(selectedCase);
                if (!allUsers.includes(reduxData?.auth?.user?._id)) {
                    allUsers.push(reduxData?.auth?.user?._id);
                }
                await api("post", "activity/create", {
                    activity: `${response?.data?.data?.identifier} - Added a bank  ${data.name}.`,
                    users: allUsers,
                });
                setSelectedCase(response?.data?.data);
                setBankModal(false);
                resetSidebar();
                props.getCases();
            } else {
                toastify(response?.message);
            }
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const els = await Promise.all(
                    selectedCase?.product?.internalForms?.map(getStatus)
                );
                setElements(els);
            } catch (error) {
                console.error(error);
            }
        };

        if (selectedCase) {
            setInstitutes(selectedCase?.institutes);
            fetchData();
        }
    }, [selectedCase]);

    const getStatus = async (e) => {
        const response = await api("post", "forms/formStatus", {
            caseId: selectedCase?._id,
            formId: e._id,
        });
        if (response?.data?.data === "incomplete") {
            return <h4 className={`cursor-pointer reject`}>{e?.name}</h4>;
        } else {
            return <h4 className={`cursor-pointer`}>{e?.name}</h4>;
        }
    };

    const downloadDocs = async (data, email) => {
        if (data?.length > 0) {
            if (email) {
                try {
                    const emailBody = "This is the message body.";

                    const formData = new FormData();
                    const encodedEmailBody = encodeURIComponent(emailBody);

                    formData.append("body", encodedEmailBody);

                    const form = document.createElement("form");
                    form.action =
                        "mailto:" +
                        (reduxData?.auth?.user?.email || "user@mailinator.com");
                    form.method = "post";
                    form.enctype = "multipart/form-data";
                    form.style.display = "none";

                    for (const [key, value] of formData.entries()) {
                        const input = document.createElement("input");
                        input.type = "hidden";
                        input.name = key;
                        input.value = value;
                        form.appendChild(input);
                    }

                    document.body.appendChild(form);
                    form.submit();
                } catch (error) {
                    console.log({ error });
                }
            } else {
                for (const dc of data) {
                    try {
                        const response = await fetch(dc.url);
                        const blob = await response.blob();
                        const url = window.URL.createObjectURL(blob);
                        const link = document.createElement("a");
                        link.href = url;
                        link.download = dc.name;
                        link.click();
                        window.URL.revokeObjectURL(url);
                    } catch (error) {
                        toastify(`Error downloading ${dc.name}:`);
                        console.log(`Error downloading ${dc.name}:`, error);
                    }
                }
            }
        }
    };

    const disburseFunds = async () => {
        let _institutes = institutes.map((e) => {
            return { ...e };
        });
        const index = _institutes.findIndex((obj) => obj._id === cashOut?.bank);
        _institutes[index]["fundsDisbursed"] = cashOut.fundsDisbursed;
        dispatch(setLoading(true));
        const response = await api("post", "cases/update", {
            id: selectedCase?._id,
            institutes: _institutes,
            logs: [
                ...selectedCase?.logs,
                {
                    date: new Date(),
                    operator: reduxData?.auth?.user?._id,
                    comment: `${formatCurrency(
                        _institutes[index].cashOut,
                        true
                    )} has been disbursed.`,
                },
            ],
        });
        dispatch(setLoading(false));

        if (response?.data?.status) {
            let allUsers = getCaseUsers(selectedCase);
            if (!allUsers.includes(reduxData?.auth?.user?._id)) {
                allUsers.push(reduxData?.auth?.user?._id);
            }
            await api("post", "activity/create", {
                activity: `${
                    response?.data?.data?.identifier
                } - ${formatCurrency(
                    _institutes[index].cashOut,
                    true
                )} has been disbursed.`,
                users: allUsers,
            });
            setSelectedCase(response?.data?.data);
            await sendNotification("Funds Disbursed");

            resetSidebar();
            props.getCases();
            props?.setCurrentCase(response?.data?.data);
            setCashOut({
                amount: "",
                fundsDisbursed: new Date(),
                cashOutModal: false,
                fundsModal: false,
                bank: null,
                maxAmount: "0",
            });
        } else {
            toastify(response?.message);
        }
    };

    const updateBankName = async (id, name) => {
        let _institutes = institutes.map((e) => {
            return { ...e };
        });
        const index = _institutes.findIndex((obj) => obj._id === id);
        _institutes[index]["nick"] = name;
        dispatch(setLoading(true));
        const response = await api("post", "cases/update", {
            id: selectedCase?._id,
            institutes: _institutes,
            logs: [
                ...selectedCase?.logs,
                {
                    date: new Date(),
                    operator: reduxData?.auth?.user?._id,
                    comment: `Renamed the bank to ${name}.`,
                },
            ],
        });
        dispatch(setLoading(false));

        if (response?.data?.status) {
            let allUsers = getCaseUsers(selectedCase);
            if (!allUsers.includes(reduxData?.auth?.user?._id)) {
                allUsers.push(reduxData?.auth?.user?._id);
            }
            await api("post", "activity/create", {
                activity: `${response?.data?.data?.identifier} - Renamed the bank to ${name}.`,
                users: allUsers,
            });
            setSelectedCase(response?.data?.data);
            resetSidebar();
            props.getCases();
            setBankEdit();
        } else {
            toastify(response?.message);
        }
    };

    return (
        <div className="case-information">
            {pdpaDel && (
                <PdpaModal
                    setShow={() => setPdpdDel(false)}
                    case={selectedCase}
                    confirm={markCmComplete}
                />
            )}
            {downloadShow && (
                <DownloadDocsModal
                    setShow={setDownloadShow}
                    confirmAction={downloadDocs}
                    case={selectedCase}
                />
            )}
            {show && (
                <DocumentModal
                    getCases={props?.getCases}
                    case={selectedCase}
                    setShow={setShow}
                />
            )}
            {pdfShow && (
                <PdfModal
                    url={url}
                    download={() => {
                        setPdfShow(!pdfShow);
                        window.open(url);
                    }}
                    setShow={() => setPdfShow(!pdfShow)}
                />
            )}
            {showRej && (
                <RejectionModal
                    confirmAction={rejectCase}
                    setModalClick={() => {
                        setShowRej(false);
                    }}
                    confirmBtn={true}
                    modalTitle={[
                        <h2>
                            Please provide a reason to customer for case
                            rejection.
                        </h2>,
                    ]}
                />
            )}
            {completeModal && (
                <AlertModal
                    confirmAction={markAsComplete}
                    setModalClick={() => setCompleteModal(false)}
                    confirmBtn={true}
                    modalTitle={[
                        <h2>
                            Are you sure you want to mark this case as Complete?
                        </h2>,
                    ]}
                />
            )}
            {showBankRejection && (
                <RejectionModal
                    confirmAction={rejectBank}
                    setModalClick={() => {
                        setShowBankRejection(false);
                    }}
                    innerClass="inner_modal"
                    confirmBtn={true}
                    modalTitle={[
                        <h2 style={{ textAlign: "center" }}>
                            Please provide a reason to customer for why the bank
                            is rejected.
                        </h2>,
                    ]}
                />
            )}
            {imageShow && (
                <ImageModal
                    url={url}
                    download={() => {
                        setImageShow(!imageShow);
                        window.open(url);
                    }}
                    setShow={() => setImageShow(!imageShow)}
                />
            )}
            {bankModal && (
                <BankModal
                    setShow={setBankModal}
                    confirmAction={(e) => addBank(e)}
                />
            )}
            {showSend && (
                <SendDocumentModal
                    confirmAction={() => {
                        setShowSend(false);
                        setShow(true);
                    }}
                    setModalClick={() => setShowSend(false)}
                    confirmBtn={true}
                    modalTitle={[
                        <h2>
                            Do you really want to send this document to{" "}
                            {getName(
                                (props?.case || selectedCase)?.lead ||
                                    props?.lead
                            )}
                            ? <br />
                        </h2>,
                    ]}
                    modalDescription={[]}
                />
            )}
            {openAlertModal && (
                <AlertModal
                    confirmAction={acceptCase}
                    setModalClick={() => setOpenAlertModal(false)}
                    confirmBtn={true}
                    modalTitle={[
                        <h2>Are you sure you want to accept this case?</h2>,
                    ]}
                />
            )}

            {cashOut?.fundsModal && (
                <FundsModal
                    setCashOut={setCashOut}
                    cashOut={cashOut}
                    disburseFunds={disburseFunds}
                />
            )}

            <div className="flex case-dorpdown">
                <select
                    value={JSON.stringify(selectedCase)}
                    onChange={(e) =>
                        setSelectedCase(JSON.parse(e.target.value))
                    }
                    disabled={props?.case}
                >
                    <option value="" style={{ display: "none" }}>
                        {props?.lead
                            ? "Select Case Product"
                            : props?.case?.identifier}
                    </option>
                    {props?.lead?.cases?.map((e) => (
                        <option value={JSON.stringify(e)}>
                            {e?.identifier}
                        </option>
                    ))}
                </select>
            </div>
            <Accordion
                allowZeroExpanded
                allowMultipleExpanded
                preExpanded={["1", "2", "3", "4"]}
            >
                <AccordionItem uuid="1">
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Product Specification
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <div className="responsive-table case-information-table">
                            <table className="table first-table">
                                <thead>
                                    <tr>
                                        <th>Product Name</th>
                                        <th>Product Specification</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{selectedCase?.product?.name}</td>
                                        <td>
                                            {selectedCase?.product?.description}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Required Documents</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <section className="docs-list">
                                                {selectedCase?.product?.documents?.map(
                                                    (e) => (
                                                        <span>{e?.name}</span>
                                                    )
                                                )}
                                            </section>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Requested Loan Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <section className="docs-list">
                                                <span>
                                                    {formatCurrency(
                                                        selectedCase?.loanAmount,
                                                        true
                                                    ) || "N/A"}
                                                </span>
                                            </section>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Advisory Fee</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div className="form-group">
                                                <label
                                                    id="advisory"
                                                    className="check-radio f-14"
                                                >
                                                    <input
                                                        type="radio"
                                                        name="advisory"
                                                        checked={
                                                            selectedCase
                                                                ?.advisoryFee
                                                                ?.type ===
                                                            "fixed"
                                                        }
                                                    />
                                                    <span>Fixed</span>
                                                </label>
                                                <label
                                                    id="advisory"
                                                    className="check-radio f-14"
                                                >
                                                    <input
                                                        type="radio"
                                                        name="advisory"
                                                        checked={
                                                            selectedCase
                                                                ?.advisoryFee
                                                                ?.type ===
                                                            "percentage"
                                                        }
                                                    />
                                                    <span>Percentage</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    placeholder="Enter Value"
                                                    className="form-control w-300 f-14"
                                                    value={
                                                        selectedCase
                                                            ?.advisoryFee
                                                            ?.type === "fixed"
                                                            ? formatCurrency(
                                                                  selectedCase
                                                                      ?.advisoryFee
                                                                      ?.amount,
                                                                  true
                                                              )
                                                            : selectedCase
                                                                  ?.advisoryFee
                                                                  ?.amount
                                                    }
                                                    disabled
                                                    // maxLength={10}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem uuid="2">
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Case Documents
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <div className="document-wrapper flex">
                            <div className="cases-doc-list">
                                <ul className="list-style-none">
                                    <li className="title">
                                        <h2>Internal Form</h2>
                                    </li>

                                    {selectedCase?.product?.internalForms?.map(
                                        (e) => (
                                            <li
                                                className={`bordered-form`}
                                                onClick={async () => {
                                                    // if (
                                                    //     selectedCase.status !==
                                                    //     "Completed"
                                                    // ) {
                                                    // window.open(
                                                    //     `${
                                                    //         process.env
                                                    //             .REACT_APP_BASE_URL
                                                    //     }form/${e?._id}/${
                                                    //         (
                                                    //             selectedCase ||
                                                    //             props?.case
                                                    //         )?._id
                                                    //     }`,
                                                    //     "_blank"
                                                    // );
                                                    navigate(
                                                        `/form/${e?._id}/${
                                                            (
                                                                selectedCase ||
                                                                props?.case
                                                            )?._id
                                                        }`
                                                    );
                                                    // }
                                                }}
                                            >
                                                {elements.find(
                                                    (f) =>
                                                        f?.props?.children ===
                                                        e.name
                                                )}
                                            </li>
                                        )
                                    )}
                                    <li className="title">
                                        <h2>Required Documents</h2>
                                    </li>
                                    {selectedCase?.product?.documents?.map(
                                        (e) => (
                                            <li>
                                                <h4 className={getClassName(e)}>
                                                    {e?.name}{" "}
                                                    {getPdpa(e) &&
                                                        " - PDPA Deleted"}
                                                    <h2 className="ml-auto user-input"></h2>
                                                </h4>
                                                {/* {getInput(e)} */}
                                                {getCaseDocument(e)}
                                            </li>
                                        )
                                    )}
                                    <li className="title">
                                        <h2>Financial Institutes</h2>
                                    </li>
                                    {selectedCase?.institutes?.map((e) => (
                                        <li>
                                            <h4
                                                className={
                                                    e.decision === "Rejected"
                                                        ? "reject"
                                                        : !e.decision
                                                        ? "waiting"
                                                        : ""
                                                }
                                            >
                                                {e?.bank?.name}{" "}
                                            </h4>
                                        </li>
                                    ))}
                                </ul>

                                {props?.lead?.cases?.length !== 0 &&
                                    !props?.lead && (
                                        <button
                                            className="simple-btn mx-250"
                                            onClick={() =>
                                                setDownloadShow(true)
                                            }
                                        >
                                            Download All
                                        </button>
                                    )}
                            </div>

                            <div style={{ width: "50%" }}>
                                {selectedCase &&
                                    selectedCase?.attachments?.length > 0 && (
                                        <div
                                            className="cases-doc-list "
                                            style={{
                                                width: "100%",
                                                padding: 0,
                                            }}
                                        >
                                            <section
                                                className="latest-comment bg-white p-0"
                                                style={{ width: "100%" }}
                                            >
                                                <h3 className="p-0 m-0">
                                                    Attachments
                                                </h3>
                                            </section>
                                            <ul className="p-0 m-0 mb-0">
                                                <div className="flex column-direction case_input_des ">
                                                    {selectedCase?.attachments?.map(
                                                        (e) => {
                                                            return (
                                                                <li className="p-0">
                                                                    <span
                                                                        style={{
                                                                            padding:
                                                                                "20px 40px 20px 20px",
                                                                        }}
                                                                    >
                                                                        <img
                                                                            src={
                                                                                fileIcon
                                                                            }
                                                                            className="cursor-pointer"
                                                                            alt="pdfIcon"
                                                                            onClick={() => {
                                                                                window.open(
                                                                                    e.url
                                                                                );
                                                                            }}
                                                                        />
                                                                        <div
                                                                            className="file_name cursor-pointer"
                                                                            onClick={() => {
                                                                                window.open(
                                                                                    e.url
                                                                                );
                                                                            }}
                                                                        >
                                                                            {
                                                                                e?.filename
                                                                            }
                                                                        </div>
                                                                    </span>
                                                                </li>
                                                            );
                                                        }
                                                    )}
                                                </div>
                                            </ul>
                                        </div>
                                    )}
                                {(!props?.case ? selectedCase : props?.case)
                                    ?.comments.length > 0 && (
                                    <section
                                        className="latest-comment"
                                        style={{ width: "100%" }}
                                    >
                                        <h3>Latest Comment</h3>
                                        <ul className="list-style-none flex column-direction">
                                            {(!props?.case
                                                ? selectedCase
                                                : props?.case
                                            )?.comments.map((data) => {
                                                return (
                                                    <li>
                                                        <h6>
                                                            {data?.commentBy &&
                                                                `${
                                                                    data
                                                                        ?.commentBy
                                                                        ?.username ||
                                                                    data?.commentBy
                                                                }`}
                                                        </h6>
                                                        <span>
                                                            {parse(
                                                                data.comment.replace(
                                                                    /\n/g,
                                                                    "<br />"
                                                                )
                                                            )}
                                                        </span>
                                                        <small>
                                                            {formatDate(
                                                                new Date(
                                                                    data?.date
                                                                )
                                                            )}
                                                        </small>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </section>
                                )}
                            </div>

                            {/* <div className="log-listing">
                               
                            </div> */}
                        </div>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem uuid="3">
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Case Activity Logs
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <div className="history">
                            <div className="responsive-table maxed">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Date & Time</th>
                                            {/* <th>Time</th> */}
                                            <th>Operator</th>
                                            <th>Role</th>
                                            <th className="description">
                                                Details
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {selectedCase?.logs
                                            ?.slice()
                                            ?.reverse()
                                            ?.map((data, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td className="w-200">
                                                            {formatDate(
                                                                new Date(
                                                                    data?.date
                                                                )
                                                            )}
                                                        </td>
                                                        {/* <td>
                                                            {new Date(
                                                                data?.date
                                                            )?.toLocaleTimeString(
                                                                "en-US",
                                                                {
                                                                    timeStyle:
                                                                        "short",
                                                                }
                                                            )}
                                                        </td> */}
                                                        <td>
                                                            {
                                                                data?.operator
                                                                    ?.username
                                                            }
                                                        </td>
                                                        <td>
                                                            {data?.operator?.roles?.join(
                                                                ", "
                                                            )}
                                                        </td>
                                                        <td>{data?.comment}</td>
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </AccordionItemPanel>
                </AccordionItem>
                {selectedCase?.decision === "Accepted" && (
                    <AccordionItem uuid="4">
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Financial Institutes
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            {/* {!selectedCase?.status !== "Completed" && ( */}
                            <button
                                className="add-list-wrapper"
                                onClick={() => {
                                    // if (selectedCase.status !== "Completed") {
                                    setBankModal(true);
                                    // }
                                }}
                            >
                                <img src={addIcon} alt="addIcon" /> Apply to
                                Bank
                            </button>
                            {/* )} */}
                            {cashOut?.cashOutModal && (
                                <CashOutModal
                                    setCashOut={setCashOut}
                                    cashOut={cashOut}
                                    submitCashOut={markClientAccept}
                                    setSelectedBank={setSelectedBank}
                                />
                            )}
                            {bankEdit && (
                                <BankNameModal
                                    close={() => setBankEdit()}
                                    confirm={updateBankName}
                                    bank={bankEdit}
                                />
                            )}
                            {selectedCase?.institutes?.map((e, i) => (
                                <>
                                    <div className="banklist-wrapper">
                                        <h3 className="flex items-center content-justify-between">
                                            <div className="flex content-justify-center">
                                                {e?.nick || e.bank.name}
                                                <img
                                                    src={update}
                                                    className="cursor-pointer ml-20"
                                                    alt="update"
                                                    onClick={() =>
                                                        setBankEdit(e)
                                                    }
                                                />
                                            </div>
                                            <div
                                                className={`selection-modal ${
                                                    selectionModal && "active"
                                                }`}
                                            >
                                                <img
                                                    src={moreIcon}
                                                    onClick={() =>
                                                        setSelectionModal(i)
                                                    }
                                                    alt="moreIcon"
                                                />
                                                {selectionModal === i && (
                                                    <OutsideClickHandler
                                                        onOutsideClick={() => {
                                                            setSelectionModal();
                                                        }}
                                                    >
                                                        <div className="selection_drop">
                                                            {e.decision ? (
                                                                <>
                                                                    <button
                                                                        // disabled={
                                                                        //     selectedCase?.status ===
                                                                        //     "Completed"
                                                                        // }
                                                                        onClick={() => {
                                                                            // if (
                                                                            //     selectedCase?.status !==
                                                                            //     "Completed"
                                                                            // ) {
                                                                            unset(
                                                                                e._id
                                                                            );
                                                                            // }
                                                                        }}
                                                                    >
                                                                        Unset
                                                                    </button>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <button
                                                                        onClick={() => {
                                                                            // if (
                                                                            //     selectedCase?.status !==
                                                                            //     "Completed"
                                                                            // ) {
                                                                            deleteBank(
                                                                                e._id,
                                                                                e
                                                                                    .bank
                                                                                    .name
                                                                            );
                                                                            // }
                                                                        }}
                                                                    >
                                                                        Delete
                                                                        Bank
                                                                    </button>
                                                                </>
                                                            )}
                                                        </div>
                                                    </OutsideClickHandler>
                                                )}
                                            </div>
                                        </h3>
                                        <div className="date-wrapper flex items-end content-justify-between">
                                            <div className="flex align-bottom">
                                                <div className="w-200">
                                                    <span>Submission Date</span>
                                                    <DatePicker
                                                        format="dd/MM/y"
                                                        disabled={e.decision}
                                                        className="form-control p-0"
                                                        onChange={(d) =>
                                                            updateDate(d, e._id)
                                                        }
                                                        value={
                                                            new Date(
                                                                e.submissionDate
                                                            )
                                                        }
                                                        onKeyDown={(e) => {
                                                            e.preventDefault();
                                                        }}
                                                    />
                                                </div>
                                                <div className="px-2 w-160">
                                                    <span>
                                                        Submitted Amount
                                                    </span>
                                                    <input
                                                        value={
                                                            e.amount
                                                                ? formatCurrency(
                                                                      allSubmittedAmounts[
                                                                          i
                                                                      ],
                                                                      true
                                                                  )
                                                                : allSubmittedAmounts[
                                                                      i
                                                                  ]
                                                        }
                                                        className="form-control"
                                                        placeholder="Submitted Amount"
                                                        disabled={e.amount}
                                                        onChange={(ev) => {
                                                            let value =
                                                                ev.target.value;
                                                            if (
                                                                numberRegex.test(
                                                                    value
                                                                ) 
                                                            ) {
                                                                let arr = [
                                                                    ...allSubmittedAmounts,
                                                                ];
                                                                arr[i] = value;
                                                                setAllSubmittedAmounts(
                                                                    arr
                                                                );
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                {!e.amount && (
                                                    <div>
                                                        <button
                                                            className="submit-button-case"
                                                            onClick={() => {
                                                                if (
                                                                    allSubmittedAmounts[
                                                                        i
                                                                    ] &&
                                                                    allSubmittedAmounts[
                                                                        i
                                                                    ] !== 0
                                                                ) {
                                                                    submitAmount(
                                                                        e._id
                                                                    );
                                                                } else {
                                                                    toastify(
                                                                        "Please enter amount."
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            Submit
                                                        </button>
                                                    </div>
                                                )}
                                                {e.decision === "Accepted" &&
                                                    e.amount && (
                                                        <div className="px-2 w-160">
                                                            <span>
                                                                Approved Amount
                                                            </span>

                                                            <input
                                                                value={
                                                                    e.approvedAmount
                                                                        ? formatCurrency(
                                                                              allApprovedAmounts[
                                                                                  i
                                                                              ],
                                                                              true
                                                                          )
                                                                        : allApprovedAmounts[
                                                                              i
                                                                          ]
                                                                }
                                                                disabled={
                                                                    e.approvedAmount
                                                                }
                                                                className="form-control"
                                                                placeholder="Approved Amount"
                                                                onChange={(
                                                                    ev
                                                                ) => {
                                                                    let value =
                                                                        ev
                                                                            .target
                                                                            .value;
                                                                    if (
                                                                        numberRegex.test(
                                                                            value
                                                                        ) ||
                                                                        // &&
                                                                        //     parseInt(
                                                                        //         value
                                                                        //     ) <=
                                                                        //         parseInt(
                                                                        //             allSubmittedAmounts[
                                                                        //                 i
                                                                        //             ]
                                                                        //         )
                                                                        value ===
                                                                            ""
                                                                    ) {
                                                                        let arr =
                                                                            [
                                                                                ...allApprovedAmounts,
                                                                            ];
                                                                        arr[i] =
                                                                            value;
                                                                        setAllApprovedAmounts(
                                                                            arr
                                                                        );
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    )}

                                                {e.cashOut && (
                                                    <div className="px-2 w-160">
                                                        <span>
                                                            Cash Out Amount
                                                        </span>

                                                        <input
                                                            value={formatCurrency(
                                                                e.cashOut,
                                                                true
                                                            )}
                                                            disabled
                                                            className="form-control"
                                                        />
                                                    </div>
                                                )}

                                                {e.decision === "Accepted" &&
                                                    !e.approvedAmount &&
                                                    e.amount && (
                                                        <div>
                                                            <button
                                                                className="submit-button-case"
                                                                onClick={() => {
                                                                    if (
                                                                        allApprovedAmounts[
                                                                            i
                                                                        ] &&
                                                                        allApprovedAmounts[
                                                                            i
                                                                        ] !== 0
                                                                    ) {
                                                                        submitApprovedAmount(
                                                                            e._id
                                                                        );
                                                                    }
                                                                }}
                                                            >
                                                                Submit
                                                            </button>
                                                        </div>
                                                    )}
                                                <ul className="list-style-none flex bank-status-wrap">
                                                    {!e.decision &&
                                                        e?.amount && (
                                                            <>
                                                                <li>
                                                                    <button
                                                                        className="blank-status"
                                                                        onClick={() => {
                                                                            // if (
                                                                            //     selectedCase?.status !==
                                                                            //     "Completed"
                                                                            // ) {
                                                                            setRejectionId(
                                                                                e._id
                                                                            );
                                                                            setBankModal(
                                                                                false
                                                                            );
                                                                            setShowBankRejection(
                                                                                true
                                                                            );
                                                                            // }
                                                                        }}
                                                                    >
                                                                        <img
                                                                            src={
                                                                                rejectIcon
                                                                            }
                                                                            alt="rejectIcon"
                                                                        />{" "}
                                                                        Reject
                                                                        by bank
                                                                    </button>
                                                                </li>
                                                                <li>
                                                                    <button
                                                                        onClick={() => {
                                                                            // if (
                                                                            //     selectedCase?.status !==
                                                                            //     "Completed"
                                                                            // ) {
                                                                            acceptBank(
                                                                                e._id
                                                                            );
                                                                            // }
                                                                        }}
                                                                        className="accepted blank-status"
                                                                    >
                                                                        <img
                                                                            src={
                                                                                acceptIcon
                                                                            }
                                                                            alt="acceptIcon"
                                                                        />
                                                                        Accept
                                                                        by bank
                                                                    </button>
                                                                </li>
                                                            </>
                                                        )}
                                                </ul>
                                            </div>
                                            <div className="flex column-direction">
                                                {e.decision && (
                                                    <span
                                                        className={`${
                                                            e.decision ===
                                                                "Accepted" &&
                                                            "completed "
                                                        } case-status`}
                                                    >
                                                        {e.decision} by Bank
                                                    </span>
                                                )}
                                                {e.clientDecision && (
                                                    <span
                                                        className={`${
                                                            e.clientDecision ===
                                                                "Accepted" &&
                                                            "completed "
                                                        } case-status mt-10`}
                                                    >
                                                        {e.clientDecision} by
                                                        Client
                                                    </span>
                                                )}
                                                {e.fundsDisbursed && (
                                                    <div className="disbursed">
                                                        Funds disbursed on{" "}
                                                        {shortFormatDate(
                                                            e.fundsDisbursed
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        {e.cashOut && !e.fundsDisbursed && (
                                            <div className="flex content-justify-center mt-20">
                                                <button
                                                    onClick={() => {
                                                        setCashOut({
                                                            ...cashOut,
                                                            fundsModal: true,
                                                            bank: e._id,
                                                        });
                                                    }}
                                                    className="simple-btn accept"
                                                >
                                                    Disburse Funds
                                                </button>
                                            </div>
                                        )}
                                        {e.decision === "Accepted" &&
                                            !e.clientDecision &&
                                            e.approvedAmount && (
                                                <div className="flex content-justify-center log-listing mt-20">
                                                    <button
                                                        onClick={() => {
                                                            setSelectedBank(
                                                                e?.bank
                                                            );
                                                            setCashOut({
                                                                ...cashOut,
                                                                bank: e._id,
                                                                cashOutModal: true,
                                                                amount: allApprovedAmounts[
                                                                    i
                                                                ],
                                                                maxAmount:
                                                                    allApprovedAmounts[
                                                                        i
                                                                    ],
                                                            });
                                                        }}
                                                        className="simple-btn accept"
                                                    >
                                                        Accept by Client
                                                    </button>

                                                    <button
                                                        onClick={() =>
                                                            markClientReject(
                                                                e._id
                                                            )
                                                        }
                                                        className="simple-btn cancel"
                                                    >
                                                        Reject by Client
                                                    </button>
                                                </div>
                                            )}
                                        {e.decision === "Rejected" && (
                                            <>
                                                <span className="rejectionReason">
                                                    {e.rejectionReason}
                                                </span>
                                                <div className="flex content-justify-center log-listing">
                                                    <button
                                                        onClick={() => {
                                                            setShowRej(true);
                                                        }}
                                                        className="simple-btn cancel"
                                                    >
                                                        Reject
                                                    </button>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </>
                            ))}

                            <div className="approved-div">
                                <div>Total approved amount by bank</div>
                                <div>
                                    {formatCurrency(
                                        selectedCase?.institutes?.reduce(
                                            (accumulator, currentObject) => {
                                                const numericAmount =
                                                    parseFloat(
                                                        currentObject.approvedAmount
                                                    ) || 0;
                                                return (
                                                    accumulator + numericAmount
                                                );
                                            },
                                            0
                                        ) || "0",
                                        true
                                    )}
                                </div>
                            </div>
                            <div className="client-approved-div">
                                <div>Total accepted amount by client</div>
                                <div>
                                    {formatCurrency(
                                        selectedCase?.institutes
                                            ?.filter(
                                                (e) =>
                                                    e?.clientDecision ===
                                                    "Accepted"
                                            )
                                            ?.reduce(
                                                (
                                                    accumulator,
                                                    currentObject
                                                ) => {
                                                    const numericAmount =
                                                        parseFloat(
                                                            currentObject.cashOut
                                                        ) || 0;
                                                    return (
                                                        accumulator +
                                                        numericAmount
                                                    );
                                                },
                                                0
                                            ) || "0",
                                        true
                                    )}
                                </div>
                            </div>
                        </AccordionItemPanel>
                    </AccordionItem>
                )}
            </Accordion>
            {!selectedCase?.decision && (
                <div className="flex content-justify-center log-listing">
                    <button
                        onClick={() => {
                            setShowRej(true);
                        }}
                        className="simple-btn cancel"
                    >
                        Reject
                    </button>
                    <button
                        className="simple-btn accept"
                        onClick={() => {
                            setOpenAlertModal(true);
                        }}
                    >
                        Accept
                    </button>
                </div>
            )}
            {selectedCase?.decision === "Accepted" && (
                <>
                    {selectedCase?.status !== "Completed" && (
                        // selectedCase.clientDecision === "Accepted" &&
                        // checkBanks(selectedCase) &&
                        <button
                            onClick={() => setCompleteModal(true)}
                            className="simple-btn mt-30 w-100 colored"
                        >
                            Mark this Case as Complete
                        </button>
                    )}

                    {selectedCase?.status === "Completed" &&
                        !selectedCase?.cmPdpa && (
                            <button
                                onClick={() => {
                                    if (selectedCase?.rmPdpa) {
                                        setPdpdDel(true);
                                    } else {
                                        toastify(
                                            "RM has not marked PDPA complete yet."
                                        );
                                    }
                                }}
                                className="simple-btn mt-30 w-100"
                            >
                                Mark as Case Manager PDPA Complete
                            </button>
                        )}
                </>
            )}
            {selectedCase?.decision !== "Accepted" &&
                selectedCase?.status === "Rejected" &&
                selectedCase?.rmPdpa &&
                !selectedCase?.cmPdpa && (
                    <button
                        onClick={() => {
                            if (selectedCase?.rmPdpa) {
                                setPdpdDel(true);
                            } else {
                                toastify(
                                    "RM has not marked PDPA complete yet."
                                );
                            }
                        }}
                        className="simple-btn mt-30 w-100"
                    >
                        Mark as Case Manager PDPA Complete
                    </button>
                )}
        </div>
    );
}
