import { useEffect } from "react";
import { useState } from "react";

export default function BankNameModal(props) {
    const [name, setName] = useState();

    useEffect(() => {
        if (props?.bank) setName(props?.bank?.nick || props?.bank?.bank?.name);
    }, [props?.bank]);

    return (
        <div className={`modal-wrapper inner_modal`}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-body ">
                        <div className="modal-forms">
                            <ul className="list-style-none">
                                <li>
                                    <div className="form-group">
                                        <label htmlFor="">Bank Name *</label>
                                        <input
                                            type="text"
                                            value={name}
                                            onChange={(e) =>
                                                setName(e.target.value)
                                            }
                                            className="form-control mandatory"
                                            placeholder="Enter Bank Name"
                                        />
                                    </div>
                                </li>

                                <li>
                                    <div className="flex content-justify-center">
                                        <button
                                            onClick={() => props?.close()}
                                            className="modal-btn cancel"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            onClick={() => {
                                                props?.confirm(
                                                    props?.bank?._id,
                                                    name
                                                );
                                            }}
                                            className="modal-btn"
                                        >
                                            Confirm
                                        </button>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
