import del from "../../../assets/images/del.svg";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { api } from "../../../helpers/apiHelper/requestHelper";
import { toastify } from "../../../helpers/toast/toastify";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { AlertModal } from "../../../components/modals/AlertModal";
import { setLoading } from "../../../redux/loader/loadingSlice";
import { useSelector } from "react-redux";
import EntriesSelection from "../../../components/pagination/EntriesSelection";
import Pagination from "../../../components/pagination/Pagination";
import { setSidebar } from "../../../helpers/sidebar/setSidebar";
import { shortFormatDate } from "../../../helpers/dataHelper/dateFormatter";

export default function Notification() {
    const [notifications, setNotifications] = useState([]);
    const [show, setShow] = useState(false);
    const [selectedNotification, setSelectedNotification] = useState();
    const [filtered, setFiltered] = useState([]);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(5);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const reduxData = useSelector((state) => state);

    const getNotifications = async () => {
        let response = await api("get", "notifications/index");

        if (response?.status) {
            setNotifications(response?.data?.reverse());
        } else {
            toastify(response?.message, "error");
        }
    };

    useEffect(() => {
        setSidebar("Notification");
        getNotifications();
    }, []);

    useEffect(() => {
        setFiltered(notifications);
    }, [notifications]);

    const confirmAction = async () => {
        dispatch(setLoading(true));
        const response = await api("post", "notifications/update", {
            isDeleted: true,
            id: selectedNotification._id,
        });
        dispatch(setLoading(false));
        if (response?.data?.status) {
            getNotifications();
            toastify("Notification deleted successfully", "success");
        } else {
            toastify(response?.message, "error");
        }
        setShow(false);
    };

    return (
        <>
            <h4 className="flex flex-wrap mini-title">Notification</h4>
            {show && (
                <AlertModal
                    confirmAction={confirmAction}
                    setModalClick={() => setShow(false)}
                    confirmBtn={true}
                    modalTitle={[
                        <h2>
                            Do you really want to delete{" "}
                            {"@" + selectedNotification.title} <br />
                            permanently?
                        </h2>,
                    ]}
                    modalDescription={[]}
                />
            )}
            <div className="flex content-justify-end">
                <Link
                    to="/notification/add-notification"
                    className="simple-btn"
                >
                    Add Notification
                </Link>
            </div>
            <div className="responsive-table w-100">
                <table className="table">
                    <thead>
                        <tr
                            className="no-shadow"
                            style={{ background: "#f6f6f6" }}
                        >
                            <td colSpan="6" height="16"></td>
                        </tr>
                        <tr>
                            <th>Date</th>
                            <th>Topic</th>
                            <th className="description">Remarks</th>
                            <th>Participants</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filtered
                            ?.slice(count * page - count, count * page)
                            ?.map((data, index) => {
                                return (
                                    <>
                                        <tr className="no-shadow">
                                            <td colSpan="6" height="16"></td>
                                        </tr>
                                        <tr key={index}>
                                            <td>
                                                {shortFormatDate(
                                                    data.created_at
                                                )}
                                            </td>
                                            <td>{data.title}</td>
                                            <td>{data.remarks}</td>
                                            <td>
                                                {data?.participants?.map(
                                                    (e) => (
                                                        <div>
                                                            {e?.user?.username}
                                                        </div>
                                                    )
                                                )}
                                            </td>
                                            {reduxData?.auth?.user?._id ===
                                                data?.createdBy && (
                                                <td>
                                                    <div className="flex items-center">
                                                        <div
                                                            className="table-action del cursor-pointer"
                                                            onClick={() => {
                                                                setSelectedNotification(
                                                                    data
                                                                );
                                                                setShow(true);
                                                            }}
                                                        >
                                                            <img
                                                                src={del}
                                                                alt="del"
                                                            />
                                                            <span>
                                                                <small>
                                                                    Delete
                                                                </small>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </td>
                                            )}
                                        </tr>
                                    </>
                                );
                            })}
                    </tbody>
                </table>
                <div className="flex pagination-combine">
                    <EntriesSelection
                        setCount={setCount}
                        page={page}
                        count={count}
                        data={filtered}
                    />
                    <Pagination
                        count={count}
                        data={filtered}
                        setPage={setPage}
                        page={page}
                    />
                </div>
            </div>
        </>
    );
}
