import React, { useState } from "react";
import "./FolderStructure.scss";
import { useRef } from "react";
import { AddFilesModal } from "../../components/modals/AddFilesModal";
import { DeleteModal } from "../../components/modals/DeleteModal";
import { DuplicateModal } from "../../components/modals/DuplicateModal";
import { useDispatch, useSelector } from "react-redux";
import { convertBytesToMbsKbs } from "../../helpers/sizeConverter/sizeConverter";
import fileIcon from "../../assets/images/explorerFileIcon.svg";
import folderIcon from "../../assets/images/explorerFolderIcon.svg";
import EntriesSelection from "../../components/pagination/EntriesSelection";
import Pagination from "../../components/pagination/Pagination";
import downloadIcon from "../../assets/images/downloadIcon.svg";
import update from "../../assets/images/update.svg";
import del from "../../assets/images/del.svg";
import attachIcon from "../../assets/images/attachment.png";
import FolderNameModal from "../../components/modals/FolderNameModal";
import { storeFolders } from "../../redux/folderStructure/folderStructureSlice";
import { useEffect } from "react";
import { api } from "../../helpers/apiHelper/requestHelper";
import { toastify } from "../../helpers/toast/toastify";
import { setLoading } from "../../redux/loader/loadingSlice";
import { shortFormatDate } from "../../helpers/dataHelper/dateFormatter";
import generateUID from "../../helpers/dataHelper/uidGenerator";
import axios from "axios";
import JSZip from "jszip";
import sortIcon from "../../assets/images/sortIcon.svg";
import HyperlinkModalModal from "../../components/modals/HyperlinkModal";

const FolderStructure = () => {
    const [folders, setFolders] = useState([]);
    const [currentFolder, setCurrentFolder] = useState(null);
    const [openFolders, setOpenFolders] = useState([]);
    const [show, setShow] = useState(false);
    const reduxData = useSelector((state) => state);
    const [selected, setSelected] = useState([]);
    const [count, setCount] = useState(5);
    const [page, setPage] = useState(1);
    const [deleteModal, setDeleteModal] = useState(false);
    const [folderModal, setFolderModal] = useState(false);
    const [editingFolder, setEditingFolder] = useState();
    const [editingHyperlink, setEditingHyperlink] = useState();
    const [folderContent, setFolderContent] = useState([]);
    const [alertModal, setAlertModal] = useState(false);
    const [hyperlinkModal, setHyperlinkModal] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const dispatch = useDispatch();

    const config = axios.create({
        baseURL: process.env.REACT_APP_API_BASE_URL,
    });

    const updateFolderContent = () => {
        let content = [];

        currentFolder?.subfolders?.forEach((folder) => {
            content.push({ ...getFolderByUid(folder), isFolder: true });
        });

        currentFolder?.hyperlinks?.forEach((hyperlink) => {
            content.push({
                ...hyperlink,
                isHyperlink: true,
            });
        });

        currentFolder?.files?.forEach((file) => {
            content.push(file);
        });

        setFolderContent(content);
    };

    const fetchFolders = () => {
        loadFolderStructure().then(() => {
            if (reduxData?.folders?.folderStructure?.length) {
                setFolders(reduxData?.folders?.folderStructure);
                setCurrentFolder(reduxData?.folders?.folderStructure[0]);
                setOpenFolders([reduxData?.folders?.folderStructure[0]]);
                setCount("10");
            }
        });
    };

    useEffect(() => {
        fetchFolders();
    }, []);

    useEffect(() => {
        setFolders(reduxData?.folders?.folderStructure);
    }, [reduxData?.folders?.folderStructure]);

    useEffect(() => {
        setSelected([]);
        updateFolderContent();
    }, [currentFolder]);

    const loadFolderStructure = async () => {
        dispatch(setLoading(true));
        let response = await api("get", "folders/index");
        dispatch(setLoading(false));
        if (response?.status) {
            dispatch(storeFolders(response?.data));
        } else {
            toastify(response?.message, "error");
        }
    };

    const getUserById = (id) => {
        let users = reduxData?.users?.value;
        let user = users?.find((e) => e._id === id);
        if (user) {
            return user.username;
        } else {
            return "N/A";
        }
    };

    const uploadFiles = async (filesToUpload, payLoad) => {
        setAlertModal(false);
        setSelectedFiles([]);
        let names = [];
        for (let index = 0; index < currentFolder?.files?.length; index++) {
            const element = currentFolder?.files[index];
            names.push(element?.name);
        }

        const getName = (givenName) => {
            function addUniqueName(name) {
                const [baseName, extension] = name.split(".");

                if (!names.includes(name)) {
                    return name;
                }

                let i = 1;
                while (names.includes(`${baseName} (${i}).${extension}`)) {
                    i++;
                }

                const newName = `${baseName} (${i}).${extension}`;
                return newName;
            }
            return addUniqueName(givenName);
        };

        if (!payLoad) {
            for (let index = 0; index < filesToUpload?.length; index++) {
                const element = filesToUpload[index];
                if (names.includes(element.name)) {
                    setSelectedFiles(filesToUpload);
                    dispatch(setLoading(false));
                    setAlertModal(true);
                    return;
                }
            }

            let uploadedFiles = [];
            for (let index = 0; index < filesToUpload?.length; index++) {
                const element = filesToUpload[index];
                let fd = new FormData();
                fd.append("file", element?.file);
                fd.append("uid", element?.uid);
                let response = await config.post("auth/uploadFsFile", fd);
                if (response?.data?.status) {
                    uploadedFiles.push({
                        ...element,
                        url: response?.data?.data,
                    });
                }
            }
            return uploadedFiles;
        } else if (payLoad === "Confirm") {
            let uploadedFiles = [];
            for (let index = 0; index < filesToUpload?.length; index++) {
                const element = filesToUpload[index];
                let fd = new FormData();
                fd.append("file", element?.file);
                fd.append("uid", element?.uid);
                let response = await config.post("auth/uploadFsFile", fd);
                if (response?.data?.status) {
                    if (names.includes(element.name)) {
                        uploadedFiles.push({
                            ...element,
                            url: response?.data?.data,
                            name: getName(element.name),
                        });
                    } else {
                        uploadedFiles.push({
                            ...element,
                            url: response?.data?.data,
                        });
                    }
                }
            }
            return uploadedFiles;
        } else if (payLoad === "Cancel") {
            let uploadedFiles = [];
            let newFiles = [];
            filesToUpload?.forEach((e) => {
                if (!names.includes(e.name)) {
                    newFiles.push(e);
                }
            });
            for (let index = 0; index < newFiles?.length; index++) {
                const element = newFiles[index];
                let fd = new FormData();
                fd.append("file", element?.file);
                fd.append("uid", element?.uid);
                let response = await config.post("auth/uploadFsFile", fd);
                if (response?.data?.status) {
                    uploadedFiles.push({
                        ...element,
                        url: response?.data?.data,
                    });
                }
            }
            return uploadedFiles;
        }
    };

    const handleFileUpload = async (filesToHandle, payLoad) => {
        dispatch(setLoading(true));
        uploadFiles(filesToHandle, payLoad)
            .then(async (uploadedFiles) => {
                if (uploadedFiles?.length) {
                    const response = await api(
                        "post",
                        "folders/addFilesToFolder",
                        {
                            files: uploadedFiles,
                            id: currentFolder?._id,
                        }
                    );
                    dispatch(setLoading(false));
                    if (response?.data?.status) {
                        setFolders(response?.data?.data);
                        let _currentFolder = response?.data?.data?.find(
                            (e) => e.uid === currentFolder?.uid
                        );
                        setCurrentFolder(_currentFolder);
                    } else {
                        toastify(response?.message);
                    }
                    setShow(false);
                } else {
                    dispatch(setLoading(false));
                    setShow(false);
                }
            })
            .catch((e) => console.log({ e }));
    };

    const checkTick = (uid) => {
        if (selected?.includes(uid)) {
            return true;
        } else {
            return false;
        }
    };

    const getFolderByUid = (uid) => {
        let folder = folders?.find((e) => e.uid === uid);
        return folder;
    };

    const checkParentCheck = () => {
        let selectedLength = selected?.length;
        let subfoldersLength = currentFolder?.subfolders?.length;
        let filesLength = currentFolder?.files?.length;
        let hyperlinksLength = currentFolder?.hyperlinks?.length;

        if (
            selectedLength ===
                subfoldersLength + filesLength + hyperlinksLength &&
            selectedLength !== 0
        ) {
            return true;
        } else {
            return false;
        }
    };

    const handleSelectAll = (e) => {
        if (e.target.checked) {
            let arr = [];
            currentFolder?.subfolders?.forEach((folderUids) => {
                let folder = getFolderByUid(folderUids);
                arr.push(folder?.uid);
            });
            currentFolder?.files?.forEach((file) => {
                arr.push(file?.uid);
            });
            currentFolder?.hyperlinks?.forEach((hyperlink) => {
                arr.push(hyperlink?.uid);
            });
            setSelected(arr);
        } else {
            setSelected([]);
        }
    };

    const handleSelect = (event, uid) => {
        if (event.target.checked) {
            let _selected = [...selected];
            _selected.push(uid);
            setSelected(_selected);
        } else {
            let _selected = [...selected]?.filter((e) => e !== uid);
            setSelected(_selected);
        }
    };

    const sortData = (param) => {
        if (param === "File Name") {
            let newArray = [...folderContent].sort((a, b) =>
                a.name.localeCompare(b.name)
            );
            if (JSON.stringify(folderContent) === JSON.stringify(newArray)) {
                newArray = [...folderContent].reverse();
            }
            setFolderContent(newArray);
        }
        if (param === "Modified Date") {
            let newArray = [...folderContent].sort(
                (a, b) => new Date(a.modifiedDate) - new Date(b.modifiedDate)
            );
            if (JSON.stringify(folderContent) === JSON.stringify(newArray)) {
                newArray = [...folderContent].reverse();
            }
            setFolderContent(newArray);
        }
        if (param === "Modified By") {
            let newArray = [...folderContent].sort((a, b) =>
                getUserById(a.modifiedBy)?.localeCompare(
                    getUserById(b.modifiedBy)
                )
            );
            if (JSON.stringify(folderContent) === JSON.stringify(newArray)) {
                newArray = [...folderContent].reverse();
            }
            setFolderContent(newArray);
        }
        if (param === "Uploaded Date") {
            let newArray = [...folderContent].sort(
                (a, b) => new Date(a.uploadedDate) - new Date(b.uploadedDate)
            );
            if (JSON.stringify(folderContent) === JSON.stringify(newArray)) {
                newArray = [...folderContent].reverse();
            }
            setFolderContent(newArray);
        }
        if (param === "Uploaded By") {
            let newArray = [...folderContent].sort((a, b) =>
                getUserById(a.uploadedBy)?.localeCompare(
                    getUserById(b.uploadedBy)
                )
            );
            if (JSON.stringify(folderContent) === JSON.stringify(newArray)) {
                newArray = [...folderContent].reverse();
            }
            setFolderContent(newArray);
        }
        if (param === "Size") {
            let newArray = [...folderContent].sort((a, b) => {
                const amountA = parseFloat(a?.size) || 0;
                const amountB = parseFloat(b?.size) || 0;

                return amountA - amountB;
            });
            if (JSON.stringify(folderContent) === JSON.stringify(newArray)) {
                newArray = [...folderContent].reverse();
            }
            setFolderContent(newArray);
        }
    };

    const renderContent = () => {
        if (folderContent?.length > 0) {
            return (
                <div className="responsive-table folder-table">
                    <table className="table">
                        <thead>
                            <tr
                                className="no-shadow"
                                style={{ background: "#f6f6f6" }}
                            >
                                <td colSpan="8" height="6"></td>
                            </tr>
                            <tr>
                                <th>
                                    <input
                                        type="checkbox"
                                        checked={checkParentCheck()}
                                        onChange={handleSelectAll}
                                    />
                                </th>
                                <th>
                                    <img
                                        src={sortIcon}
                                        className="sort-icon ml-0"
                                        onClick={() => sortData("File Name")}
                                    />
                                    File Name
                                </th>
                                <th>
                                    <img
                                        src={sortIcon}
                                        className="sort-icon ml-0"
                                        onClick={() =>
                                            sortData("Modified Date")
                                        }
                                    />
                                    Modified Date
                                </th>
                                <th>
                                    <img
                                        src={sortIcon}
                                        className="sort-icon ml-0"
                                        onClick={() => sortData("Modified By")}
                                    />
                                    Modified By
                                </th>
                                <th>
                                    <img
                                        src={sortIcon}
                                        className="sort-icon ml-0"
                                        onClick={() =>
                                            sortData("Uploaded Date")
                                        }
                                    />
                                    Upload Date
                                </th>
                                <th>
                                    <img
                                        src={sortIcon}
                                        className="sort-icon ml-0"
                                        onClick={() => sortData("Uploaded By")}
                                    />
                                    Uploaded By
                                </th>
                                <th>
                                    <img
                                        src={sortIcon}
                                        className="sort-icon ml-0"
                                        onClick={() => sortData("Size")}
                                    />
                                    Size
                                </th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {folderContent
                                ?.slice(count * page - count, count * page)
                                ?.map((el) => (
                                    <>
                                        <tr className="no-shadow">
                                            <td colSpan="8" height="16"></td>
                                        </tr>

                                        <tr
                                            className={
                                                checkTick(el?.uid)
                                                    ? "cursor-pointer selected"
                                                    : "cursor-pointer"
                                            }
                                            onDoubleClick={() => {
                                                if (el?.isFolder) {
                                                    setCurrentFolder(el);
                                                } else if (el?.isHyperlink) {
                                                    // console.log({ el });
                                                    let url;
                                                    if (
                                                        el?.link?.includes(
                                                            "http"
                                                        )
                                                    ) {
                                                        url = el?.link;
                                                    } else {
                                                        url = `https://${el?.link}`;
                                                    }
                                                    window.open(url, "_blank");
                                                } else {
                                                    downloadAction(el);
                                                }
                                            }}
                                        >
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    checked={checkTick(el?.uid)}
                                                    onChange={(e) =>
                                                        handleSelect(e, el?.uid)
                                                    }
                                                />
                                            </td>
                                            <td>
                                                <img
                                                    src={
                                                        el?.isFolder
                                                            ? folderIcon
                                                            : el?.isHyperlink
                                                            ? attachIcon
                                                            : fileIcon
                                                    }
                                                    className="h-20"
                                                />
                                                {el?.name}
                                            </td>
                                            <td>
                                                {shortFormatDate(
                                                    new Date(el?.modifiedDate)
                                                )}
                                            </td>
                                            <td>
                                                {getUserById(el?.modifiedBy)}
                                            </td>
                                            <td>
                                                {shortFormatDate(
                                                    new Date(el?.uploadedDate)
                                                )}
                                            </td>
                                            <td>
                                                {getUserById(el?.uploadedBy)}
                                            </td>
                                            <td>
                                                {el?.isFolder || el?.isHyperlink
                                                    ? ""
                                                    : convertBytesToMbsKbs(
                                                          el?.size
                                                      )}
                                            </td>
                                            <td>
                                                <div className="directory-actions">
                                                    {reduxData?.userRoles
                                                        ?.currentRole ===
                                                        "Admin" && (
                                                        <>
                                                            <button
                                                                onClick={() =>
                                                                    updateAction(
                                                                        el
                                                                    )
                                                                }
                                                            >
                                                                <img
                                                                    src={update}
                                                                    alt="update"
                                                                />
                                                            </button>
                                                            <button
                                                                onClick={() =>
                                                                    deleteAction(
                                                                        el
                                                                    )
                                                                }
                                                            >
                                                                <img
                                                                    src={del}
                                                                    alt="del"
                                                                />
                                                            </button>
                                                        </>
                                                    )}
                                                    {!el?.isHyperlink && (
                                                        <button
                                                            onClick={() =>
                                                                downloadAction(
                                                                    el
                                                                )
                                                            }
                                                        >
                                                            <img
                                                                src={
                                                                    downloadIcon
                                                                }
                                                                alt="download"
                                                            />
                                                        </button>
                                                    )}
                                                </div>
                                            </td>
                                        </tr>
                                    </>
                                ))}
                        </tbody>
                    </table>
                </div>
            );
        }
    };

    const returnTopFolders = () => {
        const topFolders = [];
        folders.map((currentCheckingFolder) => {
            let folderFound = false;
            folders.forEach((folder) => {
                if (folder?.uid !== currentCheckingFolder?.uid) {
                    if (
                        folder?.subfolders?.includes(currentCheckingFolder?.uid)
                    ) {
                        folderFound = true;
                        return;
                    }
                }
            });
            if (!folderFound) {
                topFolders.push(currentCheckingFolder);
            }
        });
        return topFolders;
    };

    const getCurrentSubFolders = (folder) => {
        const folderNames = [...folder.subfolders];
        let foldersToReturn = [];
        if (folderNames.length > 0) {
            folders.forEach((folder) => {
                if (folderNames.includes(folder.uid)) {
                    foldersToReturn.push(folder);
                }
            });
        }
        return foldersToReturn;
    };

    const checkIfThisFolderIsOpen = (folder) => {
        let isPresent = false;
        openFolders.forEach((openFolder) => {
            if (openFolder?.uid === folder?.uid) {
                isPresent = true;
                return;
            }
        });
        return isPresent;
    };

    const handleFolderClick = (folder) => {
        const isAlreadyPresent = openFolders?.find(
            (openFolder) => openFolder?.uid === folder?.uid
        );
        let newOpenFolders = [...openFolders];
        if (isAlreadyPresent) {
            newOpenFolders = newOpenFolders?.filter(
                (newFolder) => newFolder?.uid !== isAlreadyPresent?.uid
            );
        } else {
            newOpenFolders = [...newOpenFolders, folder];
        }
        setOpenFolders(newOpenFolders);
    };

    const addFolder = async (name) => {
        if (currentFolder && folderModal) {
            let newFolders = [...folders];
            const newFolder = {
                name,
                files: [],
                subfolders: [],
                modifiedDate: new Date(),
                modifiedBy: reduxData?.auth?.user?._id,
                uploadedDate: new Date(),
                uploadedBy: reduxData?.auth?.user?._id,
                uid: generateUID(),
                isDeleted: false,
            };
            const parentFolderIndex = newFolders.findIndex(
                (folder) => folder.uid === currentFolder.uid
            );
            if (parentFolderIndex !== -1) {
                const parentFolder = { ...newFolders[parentFolderIndex] };
                const parentNewSubFolders = [
                    ...parentFolder.subfolders,
                    newFolder?.uid,
                ];
                const updatedParentFolder = {
                    ...parentFolder,
                    subfolders: parentNewSubFolders,
                };
                newFolders[parentFolderIndex] = updatedParentFolder;
                newFolders.push(newFolder);

                dispatch(setLoading(true));
                const response = await api(
                    "post",
                    "folders/updateFolderStructure",
                    { folders: newFolders }
                );
                if (response?.data?.status) {
                    // fetchFolders();
                    dispatch(storeFolders(response?.data?.data));
                    setFolders(response?.data?.data);
                    toastify("Folder added successfully", "success");
                    if (!checkIfThisFolderIsOpen(parentFolder)) {
                        let _arr = [...openFolders];
                        _arr.push(updatedParentFolder);
                        setOpenFolders(_arr);
                    }

                    setFolderModal(false);
                    setCurrentFolder(updatedParentFolder);
                    // setFolders(newFolders);
                    // setFolderModal(false);
                } else {
                    toastify(response?.message);
                }
                dispatch(setLoading(false));
            }
        }
    };

    const renderFolders = (foldersToRender, depth = 0) => {
        return (
            <div>
                {foldersToRender?.map((folder, i) => {
                    return (
                        <div>
                            <div
                                className={`flex items-center list-folder`}
                                style={{
                                    backgroundColor:
                                        currentFolder?.uid === folder.uid
                                            ? "#9C8831"
                                            : "transparent",
                                    color:
                                        currentFolder?.uid === folder.uid
                                            ? "#fff"
                                            : "#000",
                                    padding: `0 5px 0 ${depth * 15}px`,
                                }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if (folder?.uid === currentFolder?.uid) {
                                        setCurrentFolder();
                                    } else {
                                        setCurrentFolder(folder);
                                    }
                                }}
                            >
                                <div className="folder_name">
                                    <img src={folderIcon} />
                                    {folder.name}
                                </div>

                                {checkIfThisFolderIsOpen(folder) ? (
                                    <i
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleFolderClick(folder);
                                        }}
                                        className="fa fa-sort-up ml-auto"
                                    ></i>
                                ) : (
                                    <i
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleFolderClick(folder);
                                        }}
                                        className="fa fa-sort-down ml-auto"
                                    ></i>
                                )}
                            </div>
                            {checkIfThisFolderIsOpen(folder) &&
                                folder?.subfolders.length > 0 && (
                                    <div>
                                        {renderFolders(
                                            getCurrentSubFolders(folder),
                                            depth + 1
                                        )}
                                    </div>
                                )}
                        </div>
                    );
                })}
            </div>
        );
    };

    const getFileNameWithUrl = (url) => {
        for (let index = 0; index < folders.length; index++) {
            const element = folders[index];
            for (let index = 0; index < element?.files?.length; index++) {
                const file = element?.files[index];
                if (file?.url === url) {
                    let name = file.name;
                    if (name.includes(".")) {
                        return name.split(".")[0];
                    } else {
                        return name;
                    }
                }
            }
        }
    };

    const downloadFilesAsZip = async (s3Links) => {
        try {
            const zip = new JSZip();

            const promises = s3Links.map(async (s3Link, index) => {
                try {
                    const response = await axios.get(s3Link, {
                        responseType: "arraybuffer",
                    });
                    const contentType =
                        response.headers["content-type"] ||
                        "application/octet-stream";
                    const extension = s3Link.split(".").pop();
                    const fileName = `${getFileNameWithUrl(
                        s3Link
                    )}.${extension}`;

                    zip.file(
                        fileName,
                        new Blob([response.data], { type: contentType })
                    );
                } catch (error) {
                    console.error(`Error fetching ${s3Link}:`, error);
                }
            });

            await Promise.all(promises);

            // Generate the zip file
            const content = await zip.generateAsync({ type: "blob" });

            // Create a download link and trigger the download
            const downloadLink = document.createElement("a");
            downloadLink.href = URL.createObjectURL(content);
            downloadLink.download = "files.zip";
            downloadLink.click();
        } catch (error) {
            console.error("Error downloading files:", error);
        }
    };

    const downloadFiles = () => {
        let allUids = getAllChildFiles();
        let allUrls = [];
        for (let index = 0; index < folders.length; index++) {
            const element = folders[index];
            for (let index = 0; index < element?.files?.length; index++) {
                const file = element?.files[index];
                if (allUids?.includes(file.uid)) {
                    allUrls.push(file?.url);
                }
            }
        }
        if (allUrls?.length) {
            downloadFilesAsZip(allUrls);
        } else {
            toastify("No files found to download.");
        }
    };

    const getContent = () => {
        let arr = [];
        currentFolder?.subfolders?.forEach((element) => {
            arr.push(getFolderByUid(element));
        });
        currentFolder?.files?.forEach((element) => {
            arr.push(element);
        });
        return arr;
    };

    function getAllChildFolders() {
        let result = [];

        function findNestedUids(folder) {
            for (const subfolderUid of folder.subfolders) {
                const subfolder = folders.find((f) => f?.uid === subfolderUid);
                if (subfolder) {
                    result.push(subfolder?.uid);
                    findNestedUids(subfolder);
                }
            }
        }

        for (const uid of selected) {
            const folder = folders.find((f) => f.uid === uid);
            if (folder) {
                result.push(folder?.uid);
                findNestedUids(folder);
            }
        }

        return [...new Set(result)];
    }

    function getAllChildFiles(providedFolder) {
        let result = [];

        function findNestedUids(folder) {
            for (const subfolderUid of folder.subfolders) {
                const subfolder = folders.find((f) => f.uid === subfolderUid);
                if (subfolder) {
                    findNestedUids(subfolder);
                }
            }
            for (const file of folder.files) {
                result.push(file.uid);
            }
        }

        if (providedFolder && providedFolder?.length) {
            for (const uid of providedFolder) {
                const folder = folders.find((f) => f.uid === uid);
                if (folder) {
                    findNestedUids(folder);
                } else {
                    result.push(uid);
                }
            }
        } else {
            for (const uid of selected) {
                const folder = folders.find((f) => f.uid === uid);
                if (folder) {
                    findNestedUids(folder);
                } else {
                    result.push(uid);
                }
            }
        }

        return result;
    }

    const deleteData = async () => {
        if (selected?.length) {
            const filesToBeDeleted = getAllChildFiles();
            const foldersToBeDeleted = getAllChildFolders();

            let _folders = [...folders]
                ?.filter((e) => !selected?.includes(e.uid))
                ?.map((folder) => {
                    if (
                        folder?.files?.length > 0 ||
                        folder?.hyperlinks?.length > 0
                    ) {
                        return {
                            ...folder,
                            files: folder?.files?.filter((file) => {
                                if (!selected?.includes(file?.uid)) {
                                    return file;
                                }
                            }),
                            hyperlinks: folder?.hyperlinks?.filter(
                                (hyperlink) => {
                                    if (!selected?.includes(hyperlink?.uid)) {
                                        return hyperlink;
                                    }
                                }
                            ),
                        };
                    } else {
                        return { ...folder };
                    }
                });

            console.log({ _folders });
            let __folders = _folders.map((folder) => {
                let updatedSubfolders = folder?.subfolders?.filter(
                    (subfolder) => !selected.includes(subfolder)
                );
                return { ...folder, subfolders: updatedSubfolders };
            });
            dispatch(setLoading(true));
            const response = await api("post", "folders/deleteFolderData", {
                folders: __folders,
                filesToBeDeleted,
                foldersToBeDeleted,
            });
            dispatch(setLoading(false));

            if (response?.data?.status) {
                dispatch(storeFolders(response?.data?.data));

                setFolders(response?.data?.data);

                let newOpenFolders = openFolders.map((openFolder) => {
                    let found = response?.data?.data?.find(
                        (folder) => folder?.uid === openFolder?.uid
                    );
                    if (found) {
                        return found;
                    }
                });
                setOpenFolders(newOpenFolders);

                let newCurrentFolder = response?.data?.data?.find(
                    (folder) => folder?.uid === currentFolder?.uid
                );
                setCurrentFolder(newCurrentFolder);

                setSelected([]);
                setDeleteModal(false);
            } else {
                toastify(response?.message);
            }
        } else {
            toastify("Please select Files / Folders to delete.");
        }
    };

    const updateAction = (data) => {
        if (data?.isHyperlink) {
            setEditingHyperlink(data);
            setHyperlinkModal(true);
        } else {
            setEditingFolder(data);
            setFolderModal(true);
        }
    };

    const rename = async (name) => {
        let _folders = JSON.parse(JSON.stringify(folders));
        let foundIndex = _folders?.findIndex(
            (e) => e.uid === editingFolder?.uid
        );
        if (foundIndex !== -1) {
            _folders[foundIndex]["name"] = name;
            const updatedFolders = _folders.map((obj) => {
                if (obj?.subfolders?.includes(editingFolder?.uid)) {
                    return {
                        ...obj,
                        subfolders: obj?.subfolders?.map((subfolder) =>
                            subfolder === editingFolder?.uid
                                ? _folders[foundIndex]?.uid
                                : subfolder
                        ),
                    };
                }
                return obj;
            });
            dispatch(setLoading(true));
            const response = await api(
                "post",
                "folders/updateFolderStructure",
                {
                    folders: updatedFolders,
                }
            );
            dispatch(setLoading(false));

            if (response?.data?.status) {
                dispatch(storeFolders(response?.data?.data));

                setFolders(response?.data?.data);

                const newOpenFolders = [...openFolders].map((obj) => {
                    return response?.data?.data?.find(
                        (e) => e.uid === obj?.uid
                    );
                });
                setOpenFolders(newOpenFolders);

                let newCurrentFolder = response?.data?.data?.find(
                    (e) => e.uid === currentFolder?.uid
                );
                setCurrentFolder(newCurrentFolder);

                setFolderModal(false);
                setEditingFolder();
            } else {
                toastify(response?.message);
            }
        } else {
            dispatch(setLoading(true));
            const response = await api("post", "folders/renameFileWithUid", {
                name,
                uid: editingFolder?.uid,
            });
            dispatch(setLoading(false));
            if (response?.data?.status) {
                dispatch(storeFolders(response?.data?.data));

                setFolders(response?.data?.data);

                const newOpenFolders = [...openFolders].map((obj) => {
                    return response?.data?.data?.find(
                        (e) => e.uid === obj?.uid
                    );
                });
                setOpenFolders(newOpenFolders);

                let newCurrentFolder = response?.data?.data?.find(
                    (e) => e.uid === currentFolder?.uid
                );
                setCurrentFolder(newCurrentFolder);

                setFolderModal(false);
                setEditingFolder();
            } else {
                toastify(response?.message);
            }
        }
    };

    const deleteAction = (data) => {
        setSelected([data?.uid]);
        setDeleteModal(true);
    };

    const downloadFile = async (s3Link) => {
        try {
            const response = await axios.get(s3Link, { responseType: "blob" });
            const contentType =
                response.headers["content-type"] || "application/octet-stream";
            const extension = s3Link.split(".").pop();

            // Custom filename logic (replace this with your own logic)
            const customFileName = `${getFileNameWithUrl(s3Link)}.${extension}`;

            const downloadLink = document.createElement("a");
            downloadLink.href = URL.createObjectURL(
                new Blob([response.data], { type: contentType })
            );
            downloadLink.download = customFileName;
            downloadLink.click();
        } catch (error) {
            console.error(`Error downloading ${s3Link}:`, error);
        }
    };

    const downloadAction = (data) => {
        if (data?.url) {
            // downloadFile(data?.url);
            window.open(data?.url, "_blank");
        } else {
            let allUids = getAllChildFiles([data?.uid]);
            let allUrls = [];
            for (let index = 0; index < folders.length; index++) {
                const element = folders[index];
                for (let index = 0; index < element?.files?.length; index++) {
                    const file = element?.files[index];
                    if (allUids?.includes(file.uid)) {
                        allUrls.push(file?.url);
                    }
                }
            }
            if (allUrls?.length) {
                downloadFilesAsZip(allUrls);
            } else {
                toastify("No files found to download.");
            }
        }
    };

    const findParentFolder = (_folders, _currentFolder, arr = []) => {
        const parentFolder = _folders.find((folder) =>
            folder?.subfolders?.includes(_currentFolder.uid)
        );
        if (parentFolder) {
            if (parentFolder.uid !== _folders[0].uid) {
                arr.unshift(parentFolder.name);
            }
            return findParentFolder(_folders, parentFolder, arr);
        } else {
            if (_currentFolder !== undefined) {
                if (arr.length > 0) {
                    arr.unshift(_currentFolder.name);
                } else {
                    arr.push(_currentFolder.name);
                }
            }
            return arr.join(" > ");
        }
    };

    const getBreakCrumbs = () => {
        if (currentFolder) {
            let breakCrumbs = findParentFolder(folders, currentFolder);

            return breakCrumbs;
        }
    };

    const addHyperlink = async (name, link) => {
        if (!name) {
            toastify("Text is missing!");
        } else if (!link) {
            toastify("Web Address is missing!");
        } else {
            dispatch(setLoading(true));
            let response;
            if (editingHyperlink) {
                response = await api("post", "folders/updateHyperlink", {
                    name,
                    link,
                    uid: editingHyperlink?.uid,
                    modifiedDate: new Date(),
                    modifiedBy: reduxData?.auth?.user?._id,
                });
            } else {
                response = await api("post", "folders/addHyperlinkToFolder", {
                    name,
                    link,
                    uid: generateUID(),
                    modifiedDate: new Date(),
                    modifiedBy: reduxData?.auth?.user?._id,
                    uploadedDate: new Date(),
                    uploadedBy: reduxData?.auth?.user?._id,
                    id: currentFolder?._id,
                });
            }
            dispatch(setLoading(false));
            if (response?.data?.status) {
                setFolders(response?.data?.data);
                let _currentFolder = response?.data?.data?.find(
                    (e) => e.uid === currentFolder?.uid
                );
                setCurrentFolder(_currentFolder);
                setHyperlinkModal(false);
                setEditingHyperlink();
            } else {
                toastify(response?.message);
            }
        }
    };

    return (
        <>
            <div className="folder-breadcrumbs">
                {getBreakCrumbs()}
                {currentFolder &&
                    currentFolder?.uid !==
                        reduxData?.folders?.folderStructure[0]?.uid && (
                        <>
                            <div className="anchor"> {" > "} </div>
                            <div className="bold">{currentFolder?.name}</div>
                        </>
                    )}
            </div>
            <div className="custom-file-system-container">
                {show && (
                    <AddFilesModal
                        userId={reduxData?.auth?.user?._id}
                        cancel={() => setShow(false)}
                        confirm={handleFileUpload}
                    />
                )}
                {deleteModal && (
                    <DeleteModal
                        confirm={deleteData}
                        cancel={() => setDeleteModal(false)}
                        count={selected?.length}
                    />
                )}
                {alertModal && (
                    <DuplicateModal
                        confirm={() =>
                            handleFileUpload(selectedFiles, "Confirm")
                        }
                        cancel={() => handleFileUpload(selectedFiles, "Cancel")}
                    />
                )}
                {folderModal && (
                    <FolderNameModal
                        confirm={addFolder}
                        rename={rename}
                        cancel={() => {
                            setFolderModal(false);
                            setEditingFolder();
                        }}
                        folder={editingFolder}
                    />
                )}
                {hyperlinkModal && (
                    <HyperlinkModalModal
                        confirm={addHyperlink}
                        cancel={() => {
                            setHyperlinkModal(false);
                            setEditingHyperlink();
                        }}
                        hyperlink={editingHyperlink}
                    />
                )}
                <div className="left-panel">
                    <div className="inner-folder-wrap">
                        {renderFolders(returnTopFolders())}
                    </div>
                </div>
                <div className="right-panel">
                    {currentFolder && (
                        <div className="right-panel-content">
                            <div className="flex items-center">
                                {selected?.length > 0 && (
                                    <>
                                        <div>{selected?.length} Selected</div>
                                        {reduxData?.userRoles?.currentRole ===
                                            "Admin" && (
                                            <button
                                                onClick={() =>
                                                    setDeleteModal(true)
                                                }
                                                className="custom-icon-buttons"
                                            >
                                                <img src={del} />
                                            </button>
                                        )}
                                        <button
                                            onClick={downloadFiles}
                                            className="custom-icon-buttons"
                                        >
                                            <img src={downloadIcon} />
                                        </button>
                                    </>
                                )}
                                {reduxData?.userRoles?.currentRole ===
                                    "Admin" && (
                                    <div className="button-group-1 ml-auto">
                                        <button
                                            onClick={() => {
                                                setFolderModal(true);
                                            }}
                                            className="modal-btn cancel"
                                        >
                                            <img
                                                src={folderIcon}
                                                className="h-20"
                                            />
                                            New Folder
                                        </button>
                                        <button
                                            onClick={() => {
                                                setHyperlinkModal(true);
                                            }}
                                            className="modal-btn cancel ml-20"
                                        >
                                            <img
                                                src={attachIcon}
                                                className="h-20"
                                            />
                                            New Link
                                        </button>
                                        <button
                                            onClick={() => setShow(true)}
                                            className="simple-btn ml-20"
                                        >
                                            <img
                                                src={fileIcon}
                                                className="h-20 mr-10"
                                            />
                                            Upload
                                        </button>
                                    </div>
                                )}
                            </div>
                            {renderContent()}
                        </div>
                    )}
                    {currentFolder &&
                        (currentFolder?.files?.length > 0 ||
                            currentFolder?.subfolders?.length > 0) && (
                            <div className="flex pagination-combine">
                                <EntriesSelection
                                    setCount={setCount}
                                    page={page}
                                    count={count}
                                    data={getContent()}
                                />
                                <Pagination
                                    count={count}
                                    data={getContent()}
                                    setPage={setPage}
                                    page={page}
                                />
                            </div>
                        )}
                </div>
            </div>
        </>
    );
};

export default FolderStructure;
