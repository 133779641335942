import "./header.scss";
import userImage from "../../assets/images/profile.png";
import notifyIcon from "../../assets/images/notification.svg";
import notifyEmptyIcon from "../../assets/images/notification-empty.svg";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setActiveRole, setUserRoles } from "../../redux/roles/rolesSlice";
import { setLoading } from "../../redux/loader/loadingSlice";
import { useEffect } from "react";
import crossIcon from "../../assets/images/cross.svg";
import update from "../../assets/images/update.svg";
import { setUserAnnouncements } from "../../redux/announcements/announcementSlice";
import { setUserNotifications } from "../../redux/notifications/notificationsSlice";
import { api } from "../../helpers/apiHelper/requestHelper";
import { toastify } from "../../helpers/toast/toastify";
import { logout } from "../../redux/auth/authSlice";
import {
    shortFormatDate,
    formatDate,
} from "../../helpers/dataHelper/dateFormatter";
import { setFolderModal } from "../../redux/folderStructure/folderStructureSlice";
import { setRoute } from "../../redux/route/routeSlice";

export default function PanelHeader(props) {
    const [userDrop, setUserDrop] = useState(false);
    const [userDropDown, setUserDropDown] = useState(false);
    const [showTab, setShowTab] = useState(false);
    const reduxData = useSelector((state) => state);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const userPop = () => {
        setUserDrop(!userDrop);
    };

    const userDropPop = () => {
        setUserDropDown(!userDropDown);
    };

    useEffect(() => {
        dispatch(setLoading(false));
    }, []);

    const switchRole = (role) => {
        dispatch(setLoading(true));
        dispatch(setActiveRole(role));

        // function getRole() {
        //     if (role === "RM Team Leader") {
        //         return "rm-lead";
        //     } else if (role === "RM Member") {
        //         return "rm-member";
        //     } else if (role === "Lead Coordinator") {
        //         return "lead-coordinator";
        //     } else if (role === "Case Manager") {
        //         return "case-manager";
        //     } else {
        //         return "";
        //     }
        // }

        // let baseURL = process.env.REACT_APP_BASE_URL;

        setTimeout(() => {
            // window.location.href = baseURL;
            navigate("/login");
            // + getRole();
        }, 500);
    };

    const getAnnouncements = async () => {
        const response = await api("get", "announcements/getAnnouncementById");
        if (response?.status) {
            dispatch(setUserAnnouncements(response?.data?.reverse()));
        } else {
            toastify(response?.message);
        }
    };

    const getNotifications = async () => {
        let response = await api("post", "notifications/getNotificationsById");
        if (response?.status) {
            dispatch(setUserNotifications(response?.data?.data?.reverse()));
        } else {
            toastify(response?.message, "error");
        }
    };

    const acknowledgeAnnouncement = async (id) => {
        const response = await api("post", "announcements/acknowledge", {
            announcementId: id,
        });
        if (response?.data?.status) {
            getAnnouncements();
            toastify("Acknowledged!", "success");
        } else {
            toastify("Unable to acknowledge.", "error");
        }
    };

    const acknowledgeNotification = async (id) => {
        const response = await api("post", "notifications/acknowledge", {
            notificationId: id,
        });
        if (response?.data?.status) {
            getNotifications();
            toastify("Acknowledged!", "success");
        } else {
            toastify("Unable to acknowledge.", "error");
        }
    };

    const acknowledgeAll = async () => {
        dispatch(setLoading(true));
        const response = await api("post", "announcements/acknowledgeAll");

        if (response?.data?.status) {
            getNotifications();
            getAnnouncements();
            dispatch(setLoading(false));
            toastify("Acknowledged!", "success");
        } else {
            dispatch(setLoading(false));
            toastify("Unable to acknowledge.", "error");
        }
    };

    return (
        <header className="flex header-wrapper items-center">
            <h1 className="flex ">
                {props.title}
                {window.location.pathname === "/folder-structure" &&
                    reduxData?.userRoles?.currentRole === "Admin" && (
                        <img
                            style={{ width: 20 }}
                            className="ml-20 cursor-pointer"
                            src={update}
                            onClick={() => dispatch(setFolderModal(true))}
                        />
                    )}
            </h1>
            <div className="right-header-section flex items-center ml-auto">
                <OutsideClickHandler
                    onOutsideClick={() => {
                        setUserDropDown(false);
                    }}
                >
                    <figure className="select-drops">
                        <span
                            className="simple-btn"
                            onClick={() => {
                                if (reduxData?.userRoles?.roles?.length > 1) {
                                    userDropPop();
                                }
                            }}
                        >
                            {reduxData?.auth?.user?.username +
                                " - " +
                                reduxData?.userRoles?.currentRole}
                        </span>
                        {userDropDown && (
                            <figcaption>
                                {reduxData?.userRoles?.roles
                                    ?.filter(
                                        (e) =>
                                            e !==
                                            reduxData?.userRoles?.currentRole
                                    )
                                    ?.map((e) => (
                                        <Link onClick={() => switchRole(e)}>
                                            {e}
                                        </Link>
                                    ))}
                            </figcaption>
                        )}
                    </figure>
                </OutsideClickHandler>
                <OutsideClickHandler
                    onOutsideClick={() => {
                        setShowTab(false);
                    }}
                >
                    <figure>
                        <img
                            className="user-notification cursor-pointer"
                            src={
                                reduxData?.userAnnouncements?.value?.length >
                                    0 ||
                                reduxData?.userNotifications?.value?.length > 0
                                    ? notifyIcon
                                    : notifyEmptyIcon
                            }
                            alt="userImage"
                            onClick={() => setShowTab(!showTab)}
                        />
                        {showTab && (
                            <figcaption className="notification-wrapper">
                                <h5 className="flex row-direction justify-space-betweens items-center">
                                    PDPA / Notifications
                                    <img
                                        src={crossIcon}
                                        onClick={() => acknowledgeAll()}
                                        className="clear-all-icon"
                                        alt="crossIcon"
                                    />
                                </h5>

                                {reduxData?.userAnnouncements?.value?.map(
                                    (ann) => (
                                        <section>
                                            <div className="flex">
                                                <h6>
                                                    {ann?.title}{" "}
                                                    <small>(PDPA)</small>
                                                </h6>
                                                <img
                                                    src={crossIcon}
                                                    onClick={() =>
                                                        acknowledgeAnnouncement(
                                                            ann?._id
                                                        )
                                                    }
                                                    alt="crossIcon"
                                                />
                                            </div>

                                            <p>{ann?.description}</p>
                                            <time>
                                                {shortFormatDate(
                                                    ann?.fromDate
                                                ) +
                                                    " to " +
                                                    shortFormatDate(
                                                        ann?.toDate
                                                    )}
                                            </time>
                                        </section>
                                    )
                                )}
                                {reduxData?.userNotifications?.value?.map(
                                    (not, index) => (
                                        <section>
                                            <div className="flex">
                                                <h6>
                                                    {not?.title}
                                                    <small>
                                                        (Notification)
                                                    </small>
                                                </h6>
                                                <img
                                                    src={crossIcon}
                                                    onClick={() =>
                                                        acknowledgeNotification(
                                                            not?._id
                                                        )
                                                    }
                                                    alt="crossIcon"
                                                />
                                            </div>

                                            <p>{not?.remarks}</p>
                                            <div className="font-gray mt-20">
                                                {formatDate(
                                                    new Date(not?.created_at)
                                                )}
                                            </div>
                                        </section>
                                    )
                                )}
                            </figcaption>
                        )}
                    </figure>
                </OutsideClickHandler>
                <OutsideClickHandler
                    onOutsideClick={() => {
                        setUserDrop(false);
                    }}
                >
                    <figure>
                        <span onClick={userPop}>
                            <img
                                src={
                                    reduxData?.auth?.user?.profile ?? userImage
                                }
                                alt="userImage"
                            />
                        </span>
                        {userDrop && (
                            <figcaption>
                                <div
                                    onClick={() => {
                                        setUserDrop(false);
                                        navigate("/edit-user-details", {
                                            state: {
                                                user: reduxData?.auth?.user,
                                                isMe: true,
                                            },
                                        });
                                    }}
                                    className="cursor-pointer"
                                >
                                    Edit Profile
                                </div>
                                <div
                                    onClick={async () => {
                                        dispatch(logout());
                                        dispatch(setRoute(""));
                                        navigate("/login");
                                    }}
                                    className="cursor-pointer"
                                >
                                    Logout
                                </div>
                            </figcaption>
                        )}
                    </figure>
                </OutsideClickHandler>
            </div>
        </header>
    );
}
