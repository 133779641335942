import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { PieChart } from "react-minimal-pie-chart";
import { setRoute } from "../../../../redux/route/routeSlice";
import { useDispatch } from "react-redux";
import { setSidebar } from "../../../../helpers/sidebar/setSidebar";
import { resetSidebar } from "../../../../helpers/sidebar/resetSidebar";

export default function CMStatistics() {
    const reduxCases = useSelector((state) => state.cases.value);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const statisticsList = [
        {
            statisticsCount: reduxCases?.length || 0,
            statisticsTitle: "Total Cases",
            url: "/case-manager/all-cases",
            graph: (
                <div className="donut-chart">
                    <span>{reduxCases?.length || 0}</span>
                    <PieChart
                        lineWidth={30}
                        totalValue={100}
                        rounded
                        animate
                        animationDuration={1000}
                        lengthAngle={-360}
                        data={[
                            {
                                title: "Total Cases",
                                value: 15,
                                color: "#d0eaec",
                            },
                            {
                                title: "Total Cases",
                                value: 85,
                                color: "#00919e",
                            },
                        ]}
                    />
                </div>
            ),
        },
        {
            statisticsCount:
                reduxCases?.filter(
                    (e) => e?.status?.toLowerCase() === "submitted"
                )?.length || 0,
            statisticsTitle: "Total Case Need To Be Verified",
            url: "/case-manager/unverified-cases",
            graph: (
                <div className="donut-chart">
                    <span>
                        {reduxCases?.filter(
                            (e) => e?.status?.toLowerCase() === "submitted"
                        )?.length || 0}
                    </span>
                    <PieChart
                        lineWidth={30}
                        totalValue={100}
                        rounded
                        animate
                        animationDuration={1000}
                        lengthAngle={-360}
                        data={[
                            {
                                title: "Unverified",
                                value: 25,
                                color: "#fbf2df",
                            },
                            {
                                title: "Unverified",
                                value: 75,
                                color: "#eabb60",
                            },
                        ]}
                    />
                </div>
            ),
        },
        {
            statisticsCount:
                reduxCases?.filter(
                    (e) => e?.status?.toLowerCase() !== "submitted"
                )?.length || 0,
            statisticsTitle: "Total Verified",
            url: "/case-manager/verified-cases",
            graph: (
                <div className="donut-chart">
                    <span>
                        {reduxCases?.filter(
                            (e) => e?.status?.toLowerCase() !== "submitted"
                        )?.length || 0}
                    </span>
                    <PieChart
                        lineWidth={30}
                        totalValue={100}
                        rounded
                        animate
                        animationDuration={1000}
                        lengthAngle={-360}
                        data={[
                            {
                                title: "Verified",
                                value: 45,
                                color: "#d7f5e1",
                            },
                            {
                                title: "Verified",
                                value: 55,
                                color: "#00c96a",
                            },
                        ]}
                    />
                </div>
            ),
        },
    ];

    return (
        <div className="statistics-wrapper flex column-direction">
            <ul className="list-style-none flex flex-wrap">
                {statisticsList.map(
                    ({ statisticsTitle, graph, url }, index) => {
                        return (
                            <li
                                key={`statistics-${index}`}
                                onClick={() => {
                                    dispatch(setRoute("Cases"));
                                    resetSidebar();

                                    if (statisticsTitle === "Total Cases") {
                                        setSidebar("All Case");
                                    } else if (
                                        statisticsTitle ===
                                        "Total Case Need To Be Verified"
                                    ) {
                                        setSidebar("Case Need to Verified");
                                    } else if (
                                        statisticsTitle === "Total Verified"
                                    ) {
                                        setSidebar("Case Verified");
                                    }

                                    navigate(url, {
                                        state: {
                                            isPrevious: true,
                                        },
                                    });
                                }}
                            >
                                <Link className="flex items-center">
                                    <section>
                                        <h3>{statisticsTitle}</h3>
                                        <span>
                                            {/* <small>
                                            <img
                                                src={highIcon}
                                                alt="highIcon"
                                            />{" "}
                                            +2
                                        </small> */}
                                        </span>
                                    </section>
                                    {graph}
                                </Link>
                            </li>
                        );
                    }
                )}
            </ul>
        </div>
    );
}
