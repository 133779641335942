import "./modals.scss";
import crossIcon from "../../assets/images/cross.svg";
import del from "../../assets/images/del.svg";
import { useSelector } from "react-redux";
import { toastify } from "../../helpers/toast/toastify";
import { api } from "../../helpers/apiHelper/requestHelper";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
    selectTeam,
    storeTeams,
    storeUnassignedMembers,
} from "../../redux/teams/teamsSlice";
import { useEffect } from "react";
import { setLoading } from "../../redux/loader/loadingSlice";

export const AddTeamModal = (props) => {
    const teams = useSelector((state) => state.allTeams);
    const dispatch = useDispatch();
    const [name, setName] = useState("");
    const [lead, setLead] = useState();

    const handleTeam = async () => {
        if (!name) toastify("Please enter a team name.");
        else if (!lead) toastify("Please select a team lead.");
        else {
            if (teams?.selectedTeam) {
                let data = { name, teamLead: lead ? lead : null };
                dispatch(setLoading(true));
                const response = await api("post", "teams/update", {
                    ...data,
                    id: teams?.selectedTeam?._id,
                });
                dispatch(setLoading(false));
                if (response?.data?.status) {
                    updateData();
                    dispatch(selectTeam(response?.data?.data));

                    toastify("Team modified successfully.", "success");
                    props.setModalClick();
                    setLead();
                    setName("");
                } else {
                    toastify(response?.message, "error");
                }
            } else {
                let data = { name };
                if (lead) {
                    data = { ...data, teamLead: lead };
                }
                dispatch(setLoading(true));
                const response = await api("post", "teams/create", data);
                dispatch(setLoading(false));
                if (response?.data?.status) {
                    updateData();
                    toastify("Team created successfully.", "success");
                    props.setModalClick();
                    setLead();
                    setName("");
                } else {
                    toastify(response?.message, "error");
                }
            }
        }
    };

    const updateData = async () => {
        let response = await api("get", "teams/index");
        if (response?.status) {
            dispatch(storeTeams(response?.data));
            dispatch(storeUnassignedMembers(response?.data?.unassignedUsers));
        } else {
            toastify(response?.message, "error");
        }
    };

    useEffect(() => {
        if (teams?.selectedTeam) {
            setName(teams?.selectedTeam?.name);
            setLead(teams?.selectedTeam?.teamLead?._id);
        }
    }, [teams?.selectedTeam]);

    return (
        <div className={`modal-wrapper`}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-body">
                        <img
                            src={crossIcon}
                            className="cross-btn"
                            onClick={props.setModalClick}
                            alt="crossIcon"
                        />
                        <div className="modal-forms">
                            <h2>Team</h2>
                            <ul className="list-style-none">
                                <li>
                                    <div className="form-group">
                                        <label htmlFor="">Team Name *</label>
                                        <input
                                            type="text"
                                            placeholder="Enter team name"
                                            className="form-control mandatory"
                                            value={name}
                                            onChange={(e) =>
                                                setName(e.target.value)
                                            }
                                        />
                                    </div>
                                </li>
                                <li>
                                    <div className="form-group">
                                        <label htmlFor="">
                                            Select Team Lead *
                                        </label>
                                        <select
                                            className="form-control mandatory"
                                            value={lead}
                                            onChange={(e) =>
                                                setLead(e.target.value)
                                            }
                                        >
                                            <option value="">Select</option>
                                            {(teams?.selectedTeam
                                                ? teams?.selectedTeam?.members.concat(
                                                      teams?.unassignedMembers
                                                  )
                                                : teams?.unassignedMembers
                                            )?.map((e) => (
                                                <option value={e._id}>
                                                    {e.username}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </li>
                                <li className="flex content-justify-center">
                                    <button
                                        className="simple-btn"
                                        onClick={handleTeam}
                                    >
                                        Assign
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
