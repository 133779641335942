import "./modals.scss";
import crossIcon from "../../assets/images/cross.svg";
import { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import downloadIcon from "../../assets/images/download.svg";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const PdfModal = (props) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    return (
        <div className={`modal-wrapper`}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-body ">
                        <img
                            src={crossIcon}
                            className="cross-btn"
                            onClick={props.setShow}
                            alt="crossIcon"
                        />
                        <Document
                            file={props?.url}
                            onLoadSuccess={onDocumentLoadSuccess}
                            error="Loading PDF…"
                        >
                            <Page
                                pageNumber={pageNumber}
                                renderTextLayer={false}
                                renderAnnotationLayer={false}
                            />
                        </Document>
                        <p
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <p
                                className="cursor-pointer"
                                style={{ marginRight: 10, fontSize: 30 }}
                                onClick={() => {
                                    if (pageNumber !== 1) {
                                        setPageNumber(pageNumber - 1);
                                    }
                                }}
                            >
                                {"<"}
                            </p>
                            Page {pageNumber} of {numPages}
                            <p
                                className="cursor-pointer"
                                style={{ marginLeft: 10, fontSize: 30 }}
                                onClick={() => {
                                    if (pageNumber < numPages) {
                                        setPageNumber(pageNumber + 1);
                                    }
                                }}
                            >
                                {">"}
                            </p>
                        </p>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <button
                                className="simple-btn"
                                onClick={props.download}
                                style={{
                                    background: "#6BC335",
                                    borderWidth: "0px",
                                    fontSize: 18,
                                }}
                            >
                                <img
                                    src={downloadIcon}
                                    style={{ marginRight: 10 }}
                                />
                                Download
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
