import AnnouncementList from "./AnnouncementList";
import Search from "../../../components/search/Search";
import "./announcement.scss";
import { useEffect, useState } from "react";
import { api } from "../../../helpers/apiHelper/requestHelper";
import { toastify } from "../../../helpers/toast/toastify";
import { setSidebar } from "../../../helpers/sidebar/setSidebar";

export default function Announcement() {
    const [announcements, setAnnouncements] = useState([]);

    useEffect(() => {
        setSidebar("Announcements");
        getAnnouncements();
    }, []);

    const getAnnouncements = async () => {
        let response = await api("get", "announcements/allAnnouncements");
        if (response?.status) {
            setAnnouncements(response?.data?.reverse());
        } else {
            toastify(response?.message, "error");
        }
    };

    return (
        <>
            <h4 className="flex column-direction mini-title">PDPA</h4>

            <AnnouncementList
                announcements={announcements}
                refresh={getAnnouncements}
            />
        </>
    );
}
