import "./team.scss";
import Search from "../../../components/search/Search";
import TeamList from "./TeamList";
import AssignedMembers from "./AssignedMembers";
import UnassignedMembers from "./UnassignedMembers";
import { api } from "../../../helpers/apiHelper/requestHelper";
import { useEffect, useState } from "react";
import { toastify } from "../../../helpers/toast/toastify";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import {
    selectTeam,
    storeTeams,
    storeUnassignedMembers,
} from "../../../redux/teams/teamsSlice";
import { setSidebar } from "../../../helpers/sidebar/setSidebar";

export default function Team() {
    const dispatch = useDispatch();

    useEffect(() => {
        const getTeams = async () => {
            let response = await api("get", "teams/index");
            if (response?.status) {
                dispatch(storeTeams(response?.data));
                dispatch(
                    storeUnassignedMembers(response?.data?.unassignedUsers)
                );
                dispatch(selectTeam());
            } else {
                toastify(response?.message, "error");
            }
        };

        setSidebar("Team");
        getTeams();
    }, []);

    return (
        <>
            <h4 className="flex column-direction mini-title">Teams</h4>
            {/* <Search
                userSearch={false}
                teamDropdown={true}
                filters={false}
                addButton={false}
            /> */}
            <div className="team-wrapper flex">
                <div className="team-list">
                    <TeamList />
                </div>
                <div className="team-list">
                    <AssignedMembers />
                </div>
                <div className="team-list">
                    <UnassignedMembers />
                </div>
            </div>
        </>
    );
}
