import DatePicker from "react-date-picker";
import "./modals.scss";

export const FundsModal = (props) => {
    const numberRegex = new RegExp(`^((0|([1-9][0-9]*))([.]|[.][0-9]{1,2})?)?$`);

    return (
        <div className={`modal-wrapper inner_modal`}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-body ">
                        <div className="modal-forms">
                            <ul className="list-style-none">
                                <li>
                                    <div className="form-group">
                                        <label>
                                            Select Date for Funds Disbursement
                                        </label>

                                        <DatePicker
                                            format="dd/MM/y"
                                            className="form-control mb-20 index-picker"
                                            style={{ zIndex: 2 }}
                                            onChange={(e) => {
                                                props?.setCashOut({
                                                    ...props?.cashOut,
                                                    fundsDisbursed: new Date(e),
                                                });
                                            }}
                                            value={
                                                props?.cashOut?.fundsDisbursed
                                            }
                                            onKeyDown={(e) => {
                                                e.preventDefault();
                                            }}
                                        />
                                    </div>
                                </li>
                                <li>
                                    <div className="flex content-justify-center">
                                        <button
                                            onClick={() =>
                                                props?.setCashOut({
                                                    ...props?.cashOut,
                                                    fundsModal: false,
                                                })
                                            }
                                            className="modal-btn cancel"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            onClick={() => {
                                                props?.disburseFunds();
                                            }}
                                            className="modal-btn"
                                        >
                                            Confirm
                                        </button>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
