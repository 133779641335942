import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    value: [],
};

export const formsSlice = createSlice({
    name: "formsSlice",
    initialState,
    reducers: {
        setInternalForms: (state, action) => {
            state.value = action.payload;
        },
    },
});

export const { setInternalForms } = formsSlice.actions;

export default formsSlice.reducer;
