import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    value: [],
    selectedTeam: null,
    unassignedMembers: [],
};

export const teamsSlice = createSlice({
    name: "teamsSlice",
    initialState,
    reducers: {
        storeTeams: (state, action) => {
            state.value = action.payload;
        },
        selectTeam: (state, action) => {
            state.selectedTeam = action.payload;
        },
        storeUnassignedMembers: (state, action) => {
            state.unassignedMembers = action.payload;
        },
    },
});

export const { storeTeams, selectTeam, storeUnassignedMembers } =
    teamsSlice.actions;

export default teamsSlice.reducer;
