import { Link } from "react-router-dom";
import view from "../../../../assets/images/view.svg";
import update from "../../../../assets/images/update.svg";
import del from "../../../../assets/images/del.svg";
import EntriesSelection from "../../../../components/pagination/EntriesSelection";
import Pagination from "../../../../components/pagination/Pagination";
import { useState } from "react";

export default function AcknowledgementReports({ users }) {
    const [count, setCount] = useState(10);
    const [page, setPage] = useState(1);
    return (
        <>
            <div className="responsive-table w-100">
                <h3>Acknowledgement Reports</h3>
                <table className="table">
                    <thead>
                        <tr
                            className="no-shadow"
                            style={{ background: "#f6f6f6" }}
                        >
                            <td colSpan="3" height="16"></td>
                        </tr>
                        <tr>
                            <th>Name</th>
                            <th>Role</th>
                            <th>Ack. Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users
                            ?.slice(count * page - count, count * page)
                            ?.map(({ user, acknowledged }, index) => {
                                return (
                                    <>
                                        <tr className="no-shadow">
                                            <td colSpan="3" height="16"></td>
                                        </tr>
                                        <tr key={index}>
                                            <td>{user?.username}</td>
                                            <td>{user?.roles?.join(", ")}</td>
                                            <td>
                                                {acknowledged
                                                    ? "Acknowledged"
                                                    : "UnAcknowledged"}
                                            </td>
                                        </tr>
                                    </>
                                );
                            })}
                    </tbody>
                </table>
                <div className="flex pagination-combine">
                    <EntriesSelection
                        page={page}
                        count={count}
                        data={users}
                        setCount={setCount}
                    />
                    <Pagination
                        count={count}
                        data={users}
                        setPage={setPage}
                        page={page}
                    />
                </div>
            </div>
        </>
    );
}
