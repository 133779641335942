import "./modals.scss";
import "../../pages/shared/FolderStructure.scss";

export const DuplicateModal = (props) => {
    return (
        <div className={`modal-wrapper`}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div
                        className="modal-body alert-modals"
                        style={{ paddingBottom: 20, paddingTop: 30 }}
                    >
                        <div className="modal-forms">
                            <div className="bold mb-20 fs-18">Warning</div>
                            <div className="mb-20">
                                File with the same name already exists in this
                                folder. Continuing will add a prefix to the file
                                name to ensure uniqueness. Are you sure you want
                                to proceed with the upload?
                            </div>
                            <div className="flex content-justify-center mt-20 custom-button-group">
                                <button
                                    onClick={() => props.cancel()}
                                    className="modal-btn cancel"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={() => props?.confirm()}
                                    className="simple-btn"
                                >
                                    Proceed
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
