import userIcon from "../../../../assets/images/usericon.svg";
import add from "../../../../assets/images/add-icon.svg";
import sortIcon from "../../../../assets/images/sort.png";
import view from "../../../../assets/images/view.svg";
import update from "../../../../assets/images/update.svg";
import del from "../../../../assets/images/del.svg";
import React, { useEffect } from "react";
import Search from "../../../../components/search/Search";
import { AddCase } from "../../../../components/modals/AddCase";
import { useState } from "react";
import { useSelector } from "react-redux";
import { api } from "../../../../helpers/apiHelper/requestHelper";
import { storeCases } from "../../../../redux/cases/casesSlice";
import { toastify } from "../../../../helpers/toast/toastify";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { AlertModal } from "../../../../components/modals/AlertModal";
import EntriesSelection from "../../../../components/pagination/EntriesSelection";
import Pagination from "../../../../components/pagination/Pagination";
import { setLoading } from "../../../../redux/loader/loadingSlice";
import {
    APPROVED,
    COMPLETED,
    DRAFT,
    PENDING_1,
    PENDING_2,
    PROCESSING_1,
} from "../../../../config/casesStatus";
import LeadDetailsModal from "../../leads/lead-details-modal/LeadDetailsModal";
import { setUserNotifications } from "../../../../redux/notifications/notificationsSlice";
import { sortTable } from "../../../../helpers/dataHelper/sorting";
import { shortFormatDate } from "../../../../helpers/dataHelper/dateFormatter";
import { formatCurrency } from "../../../../helpers/dataHelper/currentFormatter";
import DatePicker from "react-date-picker";
import Multiselect from "multiselect-react-dropdown";

export default function AllCases() {
    const reduxCases = useSelector((state) => state?.cases?.value);
    const userRoles = useSelector((state) => state.userRoles);

    const [filter, setFilter] = useState("all-cases");
    const [count, setCount] = useState(5);
    const [page, setPage] = useState(1);
    const [filtered, setFiltered] = useState(reduxCases || []);
    const [show, setShow] = useState(false);
    const [openDetailModal, setOpenDetailModal] = useState(false);
    const [selectedCase, setSelectedCase] = useState();
    const [tab, setTab] = useState("case");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { state } = useLocation();
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [statuses, setStatuses] = useState([]);
    const [caseIds, setCaseIds] = useState([]);

    const filterCaseIds = (values) => {
        setCaseIds(values);
        let _filtered = [...reduxCases].filter(
            (e) => values.includes(e.identifier) || values.length === 0
        );
        setFiltered(_filtered);
    };

    const filterStatuses = (values) => {
        setStatuses(values);
        let _filtered = [...reduxCases].filter(
            (e) => values.includes(e.status) || values.length === 0
        );
        setFiltered(_filtered);
    };

    useEffect(() => {
        if (state?.cases) {
            let cs = [];
            filtered?.forEach((element) => {
                if (state?.cases?.includes(element?._id)) {
                    cs.push(element);
                }
            });
            setFiltered(cs);
        }
        if (state?.isPrevious) {
            setStartDate(null);
            setEndDate(null);
        }
    }, [state?.cases]);

    useEffect(() => {
        let year = new Date().getFullYear();
        setStartDate(new Date(year, 0, 1));
        setEndDate(new Date());
    }, []);

    const compareDates = (a, b) => {
        let sDate = new Date(a);
        let eDate = new Date(b);
        if (
            sDate.getFullYear() === eDate.getFullYear() &&
            sDate.getMonth() === eDate.getMonth() &&
            sDate.getDate() === eDate.getDate()
        ) {
            return true;
        } else {
            return false;
        }
    };

    const filterDataByDate = (e) => {
        if (startDate) {
            if (
                new Date(e?.created_at) >= new Date(startDate) ||
                compareDates(e?.created_at, startDate)
            ) {
                if (endDate) {
                    if (
                        new Date(e?.created_at) <= new Date(endDate) ||
                        compareDates(e?.created_at, endDate)
                    ) {
                        return e;
                    }
                } else {
                    return e;
                }
            }
        } else if (endDate) {
            if (
                new Date(e?.created_at) <= new Date(endDate) ||
                compareDates(e?.created_at, endDate)
            ) {
                if (startDate) {
                    if (
                        new Date(e?.created_at) >= new Date(startDate) ||
                        compareDates(e?.created_at, startDate)
                    ) {
                        return e;
                    }
                } else {
                    return e;
                }
            }
        }
    };

    useEffect(() => {
        if (state?.loanDetails) {
            setTab("loan");
        }
    }, [state?.loanDetails]);

    useEffect(() => {
        if (state?.cases) {
            let cs = [];
            filtered?.forEach((element) => {
                if (state?.cases?.includes(element?._id)) {
                    cs.push(element);
                }
            });
            setFiltered(cs);
        } else {
            setFiltered(reduxCases);
        }
        if (state?.isPrevious) {
            setStartDate(null);
            setEndDate(null);
        }
        getNotifications();
    }, [reduxCases]);

    const getNotifications = async () => {
        let response = await api("post", "notifications/getNotificationsById");
        if (response?.status) {
            dispatch(setUserNotifications(response?.data?.data?.reverse()));
        } else {
            toastify(response?.message, "error");
        }
    };

    useEffect(() => {
        if (window.location.pathname) {
            let url = window.location.pathname.split("/")[2];
            let data = [...reduxCases]?.filter((e) => {
                if (
                    url === "rejected-cases" &&
                    e?.status.includes("Rejected")
                ) {
                    return e;
                } else if (
                    url === "pending-cases" &&
                    e?.status.includes("Pending")
                ) {
                    return e;
                } else if (
                    url === "submitted-cases" &&
                    e?.status.includes("Submitted")
                ) {
                    return e;
                } else if (
                    url === "completed-cases" &&
                    e?.status.includes("Completed")
                ) {
                    return e;
                } else if (
                    url === "processing-cases" &&
                    e?.status.includes("Processing")
                ) {
                    return e;
                } else if (
                    url === "approved-cases" &&
                    e?.status.includes("Approved")
                ) {
                    return e;
                } else if (
                    url === "draft-cases" &&
                    e?.status.includes("Draft")
                ) {
                    return e;
                } else if (url === "all-cases") {
                    return e;
                }
            });
            setFilter(url);
            if (state?.cases) {
                let cs = [];
                filtered?.forEach((element) => {
                    if (state?.cases?.includes(element?._id)) {
                        cs.push(element);
                    }
                });
                setFiltered(cs);
            } else {
                setFiltered(data);
            }
            if (state?.isPrevious) {
                setStartDate(null);
                setEndDate(null);
            }
        }
    }, [window.location.pathname, reduxCases]);

    const getStartDate = async () => {
        let response = await api("get", "settings/getDateSettings");

        if (response?.status) {
            setStartDate(new Date(response?.data?.startDate));
        }
    };

    useEffect(() => {
        getCases();
        getStartDate();
    }, []);

    const getCases = async () => {
        let response = await api("post", "cases/index", {
            role: userRoles.currentRole,
        });
        if (response?.data?.status) {
            dispatch(storeCases(response?.data?.data?.reverse()));
            if (state?.caseId) {
                let cs = reduxCases?.find((e) => e._id === state?.caseId);
                if (cs) {
                    setSelectedCase(cs);
                    setOpenDetailModal(true);
                }
            }
        } else {
            toastify(response?.message, "error");
        }
    };

    const confirmAction = async () => {
        dispatch(setLoading(true));
        const response = await api("post", "cases/update", {
            isDeleted: true,
            id: selectedCase._id,
        });
        dispatch(setLoading(false));
        if (response?.data?.status) {
            getCases();
            toastify("Case deleted successfully", "success");
            setShow(false);
        } else {
            toastify(response?.message, "error");
        }
    };

    const deleteCase = async (data) => {
        setShow(true);
        setSelectedCase(data);
    };

    const getSubStatus = (status, reason, rmPdpa, cmPdpa, fundsDisbursed) => {
        if (cmPdpa) {
            return "Case Manager PDPA Deletion";
        } else if (rmPdpa) {
            return "RM PDPA Deletion";
        } else {
            if (fundsDisbursed) {
                return "Disbursed By Bank";
            } else if (status?.toLowerCase() === "pending") {
                return PENDING_1;
            } else if (status?.toLowerCase() === "submitted") {
                return PENDING_2;
            } else if (status?.toLowerCase() === "processing") {
                return PROCESSING_1;
            } else if (status?.toLowerCase() === "approved") {
                return APPROVED;
            } else if (status?.toLowerCase() === "completed") {
                return COMPLETED;
            } else if (status?.toLowerCase() === "rejected") {
                return reason;
            } else {
                return null;
            }
        }
    };

    const calculateAdvisoryFee = (advisoryFee, banks, status) => {
        if (status === "Completed") {
            if (advisoryFee?.type === "fixed") {
                const advFee = parseFloat(advisoryFee?.amount);
                return formatCurrency(advFee, true);
            } else if (advisoryFee?.type === "percentage") {
                let total = 0;
                banks?.forEach((b) => (total += parseFloat(b.cashOut)));
                return `${formatCurrency(
                    (total * parseFloat(advisoryFee?.amount)) / 100,
                    true
                )} (${advisoryFee?.amount}%)`;
            } else {
                return 0;
            }
        } else {
            return "$0";
        }
    };

    return (
        <>
            {show && (
                <AlertModal
                    confirmAction={confirmAction}
                    setModalClick={() => setShow(false)}
                    confirmBtn={true}
                    modalTitle={[
                        <h2>
                            Do you really want to delete{" "}
                            {selectedCase?.identifier || "N/A"}
                            <br /> Case permanently?
                        </h2>,
                    ]}
                    modalDescription={[]}
                />
            )}
            {openDetailModal && (
                <LeadDetailsModal
                    setModalClick={() => {
                        setOpenDetailModal(!openDetailModal);
                        setSelectedCase();
                    }}
                    case={selectedCase}
                    setOpenDetailModal={setOpenDetailModal}
                    getCases={getCases}
                />
            )}
            <div className="flex items-end mb-20">
                <h4 className="flex flex-wrap mini-title">
                    <span
                        onClick={() =>
                            navigate(
                                "/" +
                                    window.location.pathname.split("/")[1] +
                                    "/all-cases"
                            )
                        }
                    >
                        Cases{" "}
                    </span>
                    / All Cases
                </h4>
                <div className="flex ml-auto">
                    <div className="w-200">
                        <p>Status</p>
                        <Multiselect
                            isObject={false}
                            showCheckbox
                            options={[
                                "Draft",
                                "Pending",
                                "Submitted",
                                "Approved",
                                "Processing",
                                "Rejected",
                                "Completed",
                            ]}
                            hideSelectedList
                            selectedValues={statuses}
                            onSelect={(e) => filterStatuses(e)}
                            onRemove={(e) => filterStatuses(e)}
                            placeholder="Filter Status"
                        />
                    </div>
                    <div className="w-200 ml-20">
                        <p>Case ID</p>
                        <Multiselect
                            isObject={false}
                            showCheckbox
                            options={reduxCases?.map((e) => {
                                return e.identifier;
                            })}
                            hideSelectedList
                            selectedValues={caseIds}
                            onSelect={(e) => filterCaseIds(e)}
                            onRemove={(e) => filterCaseIds(e)}
                            placeholder="Filter Case ID"
                        />
                    </div>
                    <div className="w-200 mx-20">
                        <p>Start Date</p>
                        <DatePicker
                            onChange={(e) => setStartDate(e)}
                            maxDate={endDate}
                            format="dd/MM/y"
                            className="form-control"
                            value={startDate}
                            onKeyDown={(e) => {
                                e.preventDefault();
                            }}
                        />
                    </div>
                    <div className="w-200">
                        <p>End Date</p>
                        <DatePicker
                            onChange={(e) => setEndDate(e)}
                            minDate={startDate}
                            format="dd/MM/y"
                            className="form-control"
                            value={endDate}
                            onKeyDown={(e) => {
                                e.preventDefault();
                            }}
                        />
                    </div>
                </div>
            </div>

            <Search
                userSearch={true}
                filedText="Search here"
                teamDropdown={false}
                filters={false}
                typeFilters={true}
                typeFiltersTitle="Product"
                // dateFilters={true}
                addButton={true}
                addButtonText="Add Case"
                buttonRoute={
                    userRoles.currentRole === "RM Team Leader"
                        ? "/rm-lead/edit-case"
                        : userRoles.currentRole === "RM Member"
                        ? "/rm-member/edit-case"
                        : userRoles.currentRole === "Lead Coordinator"
                        ? "/lead-coordinator/edit-case"
                        : "edit-case"
                }
                extraClass="case-search"
                statusFilter={false}
                component={"Cases"}
                setCases={setFiltered}
                cases={reduxCases}
                filter={filter}
            />
            <div className="responsive-table accordion-list w-100">
                <div className="entity-type-tab mb-0">
                    <ul className="list-style-none flex ">
                        <li>
                            <button
                                onClick={() => {
                                    setTab("case");
                                }}
                                className={`brr-0 ${
                                    tab === "case" ? "active" : ""
                                }`}
                            >
                                Case Details
                            </button>
                        </li>
                        <li>
                            <button
                                onClick={() => {
                                    setTab("loan");
                                }}
                                className={`br-0 ${
                                    tab === "loan" ? "active" : ""
                                }`}
                            >
                                Loan Details
                            </button>
                        </li>
                        <li>
                            <button
                                onClick={() => {
                                    setTab("misc");
                                }}
                                className={`brl-0 ${
                                    tab === "misc" ? "active" : ""
                                }`}
                            >
                                Misc. Details
                            </button>
                        </li>
                    </ul>
                </div>
                {tab === "case" ? (
                    <table className="centered-table">
                        <thead>
                            <tr
                                className="no-shadow"
                                style={{ background: "#f6f6f6" }}
                            >
                                <td colSpan="10" height="16"></td>
                            </tr>
                            <tr>
                                <th>
                                    Case ID{" "}
                                    <img
                                        src={sortIcon}
                                        className="sort-icon"
                                        onClick={() =>
                                            setFiltered(
                                                sortTable(
                                                    "Cases",
                                                    filtered,
                                                    "Case ID"
                                                )
                                            )
                                        }
                                    />
                                </th>
                                <th>
                                    Lead ID
                                    <img
                                        src={sortIcon}
                                        className="sort-icon"
                                        onClick={() =>
                                            setFiltered(
                                                sortTable(
                                                    "Cases",
                                                    filtered,
                                                    "Lead ID"
                                                )
                                            )
                                        }
                                    />
                                </th>
                                <th>
                                    Created Date
                                    <img
                                        src={sortIcon}
                                        className="sort-icon"
                                        onClick={() =>
                                            setFiltered(
                                                sortTable(
                                                    "Cases",
                                                    filtered,
                                                    "Created Date"
                                                )
                                            )
                                        }
                                    />
                                </th>
                                <th>
                                    Product <br /> Name
                                    <img
                                        src={sortIcon}
                                        className="sort-icon"
                                        onClick={() =>
                                            setFiltered(
                                                sortTable(
                                                    "Cases",
                                                    filtered,
                                                    "Product Name"
                                                )
                                            )
                                        }
                                    />
                                </th>

                                <th>
                                    Name
                                    <img
                                        src={sortIcon}
                                        className="sort-icon"
                                        onClick={() =>
                                            setFiltered(
                                                sortTable(
                                                    "Cases",
                                                    filtered,
                                                    "Name"
                                                )
                                            )
                                        }
                                    />
                                </th>

                                <th>
                                    Status
                                    <img
                                        src={sortIcon}
                                        className="sort-icon"
                                        onClick={() =>
                                            setFiltered(
                                                sortTable(
                                                    "Cases",
                                                    filtered,
                                                    "Status"
                                                )
                                            )
                                        }
                                    />
                                </th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filtered
                                ?.filter((e) => {
                                    if (startDate || endDate) {
                                        return filterDataByDate(e);
                                    } else {
                                        return e;
                                    }
                                })
                                ?.slice(count * page - count, count * page)
                                ?.map((data) => {
                                    return (
                                        <>
                                            <tr className="no-shadow">
                                                <td
                                                    colSpan="10"
                                                    height="16"
                                                ></td>
                                            </tr>
                                            <tr>
                                                <td>{data?.identifier}</td>
                                                <td>
                                                    {data?.lead?.identifier}
                                                </td>
                                                <td>
                                                    {shortFormatDate(
                                                        data.created_at
                                                    )}
                                                </td>
                                                <td>{data?.product?.name}</td>

                                                <td>
                                                    {data?.lead?.entityType ===
                                                        "Business" && (
                                                        <div className="bold">
                                                            {
                                                                data?.lead
                                                                    ?.businessEntity
                                                                    ?.name
                                                            }
                                                        </div>
                                                    )}
                                                    {data?.lead
                                                        ? data?.lead
                                                              ?.entityType ===
                                                          "Business"
                                                            ? data?.lead
                                                                  ?.businessEntity
                                                                  ?.representative
                                                                  ?.firstName +
                                                              " " +
                                                              data?.lead
                                                                  ?.businessEntity
                                                                  ?.representative
                                                                  ?.lastName
                                                            : data?.lead
                                                                  ?.individualEntity
                                                                  ?.firstName +
                                                              " " +
                                                              data?.lead
                                                                  ?.individualEntity
                                                                  ?.lastName
                                                        : ""}
                                                </td>

                                                <td>
                                                    <span
                                                        className={`status ${data?.status
                                                            ?.toLowerCase()
                                                            ?.split(" ")
                                                            .join("-")}`}
                                                    >
                                                        {data.status}
                                                    </span>
                                                    <small>
                                                        {getSubStatus(
                                                            data?.status,
                                                            data?.decisionReason,
                                                            data?.rmPdpa,
                                                            data?.cmPdpa,
                                                            data?.institutes?.find(
                                                                (e) =>
                                                                    e.fundsDisbursed
                                                            ) || false
                                                        )}
                                                    </small>
                                                </td>
                                                <td>
                                                    <div className="flex items-center">
                                                        {data?.status !==
                                                            "Completed" && (
                                                            <button
                                                                className="table-action update cursor-pointer"
                                                                onClick={() => {
                                                                    if (
                                                                        userRoles.currentRole ===
                                                                        "RM Team Leader"
                                                                    ) {
                                                                        navigate(
                                                                            "/rm-lead/edit-case",
                                                                            {
                                                                                state: {
                                                                                    case: data,
                                                                                },
                                                                            }
                                                                        );
                                                                    } else if (
                                                                        userRoles.currentRole ===
                                                                        "RM Member"
                                                                    ) {
                                                                        navigate(
                                                                            "/rm-member/edit-case",
                                                                            {
                                                                                state: {
                                                                                    case: data,
                                                                                },
                                                                            }
                                                                        );
                                                                    } else if (
                                                                        userRoles.currentRole ===
                                                                        "Lead Coordinator"
                                                                    ) {
                                                                        navigate(
                                                                            "/lead-coordinator/edit-case",
                                                                            {
                                                                                state: {
                                                                                    case: data,
                                                                                },
                                                                            }
                                                                        );
                                                                    }
                                                                }}
                                                            >
                                                                <img
                                                                    src={update}
                                                                    alt="update"
                                                                />
                                                                <span>
                                                                    <small>
                                                                        Update
                                                                    </small>
                                                                </span>
                                                            </button>
                                                        )}
                                                        <button
                                                            className="table-action view"
                                                            onClick={() => {
                                                                setSelectedCase(
                                                                    data
                                                                );
                                                                setOpenDetailModal(
                                                                    true
                                                                );
                                                            }}
                                                        >
                                                            <img
                                                                src={view}
                                                                alt="view"
                                                            />
                                                            <span>
                                                                <small>
                                                                    View
                                                                </small>
                                                            </span>
                                                        </button>
                                                        <button
                                                            className="table-action del"
                                                            onClick={() =>
                                                                deleteCase(data)
                                                            }
                                                        >
                                                            <img
                                                                src={del}
                                                                alt="del"
                                                            />
                                                            <span>
                                                                <small>
                                                                    Delete
                                                                </small>
                                                            </span>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </>
                                    );
                                })}
                        </tbody>
                    </table>
                ) : tab === "loan" ? (
                    <table className="centered-table">
                        <thead>
                            <tr
                                className="no-shadow"
                                style={{ background: "#f6f6f6" }}
                            >
                                <td colSpan="10" height="16"></td>
                            </tr>
                            <tr>
                                <th>
                                    Case ID
                                    <img
                                        src={sortIcon}
                                        className="sort-icon"
                                        onClick={() =>
                                            setFiltered(
                                                sortTable(
                                                    "Cases",
                                                    filtered,
                                                    "Case ID"
                                                )
                                            )
                                        }
                                    />
                                </th>
                                <th>
                                    Lead ID
                                    <img
                                        src={sortIcon}
                                        className="sort-icon"
                                        onClick={() =>
                                            setFiltered(
                                                sortTable(
                                                    "Cases",
                                                    filtered,
                                                    "Lead ID"
                                                )
                                            )
                                        }
                                    />
                                </th>
                                <th>
                                    Loan <br /> Amount
                                </th>
                                <th>
                                    Submitted <br /> Amount
                                </th>
                                <th>
                                    Approved <br /> Amount
                                </th>
                                <th>
                                    Cash Out <br /> Amount
                                </th>
                                <th>
                                    Advisory
                                    <br /> Fee
                                </th>

                                <th>
                                    Status
                                    <img
                                        src={sortIcon}
                                        className="sort-icon"
                                        onClick={() =>
                                            setFiltered(
                                                sortTable(
                                                    "Cases",
                                                    filtered,
                                                    "Status"
                                                )
                                            )
                                        }
                                    />
                                </th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filtered
                                ?.filter((e) => {
                                    if (startDate || endDate) {
                                        return filterDataByDate(e);
                                    } else {
                                        return e;
                                    }
                                })
                                ?.slice(count * page - count, count * page)
                                ?.map((data) => {
                                    return (
                                        <>
                                            <tr className="no-shadow">
                                                <td
                                                    colSpan="10"
                                                    height="16"
                                                ></td>
                                            </tr>
                                            <tr>
                                                <td>{data?.identifier}</td>

                                                <td>
                                                    {data?.lead?.identifier}
                                                </td>
                                                <td>
                                                    {formatCurrency(
                                                        data?.loanAmount || "0",
                                                        true
                                                    ) || "$ 0"}
                                                </td>

                                                <td>
                                                    {formatCurrency(
                                                        data?.institutes?.reduce(
                                                            (total, bank) =>
                                                                total +
                                                                parseFloat(
                                                                    bank.amount ||
                                                                        "0"
                                                                ),
                                                            0
                                                        ),
                                                        true
                                                    )}
                                                </td>
                                                <td>
                                                    {formatCurrency(
                                                        data?.institutes?.reduce(
                                                            (total, bank) =>
                                                                total +
                                                                parseFloat(
                                                                    bank.approvedAmount ||
                                                                        "0"
                                                                ),
                                                            0
                                                        ),
                                                        true
                                                    )}
                                                </td>
                                                <td>
                                                    {formatCurrency(
                                                        data?.institutes
                                                            ?.filter(
                                                                (e) =>
                                                                    e.clientDecision ===
                                                                    "Accepted"
                                                            )
                                                            ?.reduce(
                                                                (total, bank) =>
                                                                    total +
                                                                    parseFloat(
                                                                        bank.cashOut ||
                                                                            "0"
                                                                    ),
                                                                0
                                                            ),
                                                        true
                                                    )}
                                                </td>
                                                <td>
                                                    {calculateAdvisoryFee(
                                                        data?.advisoryFee,
                                                        data?.institutes?.filter(
                                                            (e) => e.cashOut
                                                        ),
                                                        data?.status
                                                    )}
                                                </td>

                                                <td>
                                                    <div
                                                        className={`status ${data?.status
                                                            ?.toLowerCase()
                                                            ?.split(" ")
                                                            .join("-")}`}
                                                    >
                                                        {data.status}
                                                    </div>
                                                    <small>
                                                        {getSubStatus(
                                                            data?.status,
                                                            data?.decisionReason,
                                                            data?.rmPdpa,
                                                            data?.cmPdpa,
                                                            data?.institutes?.find(
                                                                (e) =>
                                                                    e.fundsDisbursed
                                                            ) || false
                                                        )}
                                                    </small>
                                                </td>
                                                <td>
                                                    <div className="flex items-center">
                                                        {data?.status !==
                                                            "Completed" && (
                                                            <button
                                                                className="table-action update cursor-pointer"
                                                                onClick={() => {
                                                                    if (
                                                                        userRoles.currentRole ===
                                                                        "RM Team Leader"
                                                                    ) {
                                                                        navigate(
                                                                            "/rm-lead/edit-case",
                                                                            {
                                                                                state: {
                                                                                    case: data,
                                                                                },
                                                                            }
                                                                        );
                                                                    } else if (
                                                                        userRoles.currentRole ===
                                                                        "RM Member"
                                                                    ) {
                                                                        navigate(
                                                                            "/rm-member/edit-case",
                                                                            {
                                                                                state: {
                                                                                    case: data,
                                                                                },
                                                                            }
                                                                        );
                                                                    } else if (
                                                                        userRoles.currentRole ===
                                                                        "Lead Coordinator"
                                                                    ) {
                                                                        navigate(
                                                                            "/lead-coordinator/edit-case",
                                                                            {
                                                                                state: {
                                                                                    case: data,
                                                                                },
                                                                            }
                                                                        );
                                                                    }
                                                                }}
                                                            >
                                                                <img
                                                                    src={update}
                                                                    alt="update"
                                                                />
                                                                <span>
                                                                    <small>
                                                                        Update
                                                                    </small>
                                                                </span>
                                                            </button>
                                                        )}
                                                        <button
                                                            className="table-action view"
                                                            onClick={() => {
                                                                setSelectedCase(
                                                                    data
                                                                );
                                                                setOpenDetailModal(
                                                                    true
                                                                );
                                                            }}
                                                        >
                                                            <img
                                                                src={view}
                                                                alt="view"
                                                            />
                                                            <span>
                                                                <small>
                                                                    View
                                                                </small>
                                                            </span>
                                                        </button>
                                                        <button
                                                            className="table-action del"
                                                            onClick={() =>
                                                                deleteCase(data)
                                                            }
                                                        >
                                                            <img
                                                                src={del}
                                                                alt="del"
                                                            />
                                                            <span>
                                                                <small>
                                                                    Delete
                                                                </small>
                                                            </span>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </>
                                    );
                                })}
                        </tbody>
                    </table>
                ) : (
                    <table className="centered-table">
                        <thead>
                            <tr
                                className="no-shadow"
                                style={{ background: "#f6f6f6" }}
                            >
                                <td colSpan="10" height="16"></td>
                            </tr>
                            <tr>
                                <th>
                                    Case ID{" "}
                                    <img
                                        src={sortIcon}
                                        className="sort-icon"
                                        onClick={() =>
                                            setFiltered(
                                                sortTable(
                                                    "Cases",
                                                    filtered,
                                                    "Case ID"
                                                )
                                            )
                                        }
                                    />
                                </th>
                                <th>
                                    Lead ID
                                    <img
                                        src={sortIcon}
                                        className="sort-icon"
                                        onClick={() =>
                                            setFiltered(
                                                sortTable(
                                                    "Cases",
                                                    filtered,
                                                    "Lead ID"
                                                )
                                            )
                                        }
                                    />
                                </th>

                                <th>
                                    Entities Type
                                    <img
                                        src={sortIcon}
                                        className="sort-icon"
                                        onClick={() =>
                                            setFiltered(
                                                sortTable(
                                                    "Cases",
                                                    filtered,
                                                    "Entities Type"
                                                )
                                            )
                                        }
                                    />
                                </th>

                                <th>
                                    Assigned From
                                    <img
                                        src={sortIcon}
                                        className="sort-icon"
                                        onClick={() =>
                                            setFiltered(
                                                sortTable(
                                                    "Cases",
                                                    filtered,
                                                    "Assigned From"
                                                )
                                            )
                                        }
                                    />
                                </th>
                                <th>
                                    Assigned To
                                    <img
                                        src={sortIcon}
                                        className="sort-icon"
                                        onClick={() =>
                                            setFiltered(
                                                sortTable(
                                                    "Cases",
                                                    filtered,
                                                    "Assigned To"
                                                )
                                            )
                                        }
                                    />
                                </th>
                                <th>
                                    Status
                                    <img
                                        src={sortIcon}
                                        className="sort-icon"
                                        onClick={() =>
                                            setFiltered(
                                                sortTable(
                                                    "Cases",
                                                    filtered,
                                                    "Status"
                                                )
                                            )
                                        }
                                    />
                                </th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filtered
                                ?.filter((e) => {
                                    if (startDate || endDate) {
                                        return filterDataByDate(e);
                                    } else {
                                        return e;
                                    }
                                })
                                ?.slice(count * page - count, count * page)
                                ?.map((data) => {
                                    return (
                                        <>
                                            <tr className="no-shadow">
                                                <td
                                                    colSpan="10"
                                                    height="16"
                                                ></td>
                                            </tr>
                                            <tr>
                                                <td>{data?.identifier}</td>
                                                <td>
                                                    {data?.lead?.identifier}
                                                </td>

                                                <td>
                                                    {data?.lead?.entityType}
                                                </td>

                                                <td>
                                                    {data?.lead?.assignedFrom
                                                        ?.username || "N/A"}
                                                </td>
                                                <td>
                                                    {data?.lead?.assignedTo
                                                        ?.username || "N/A"}
                                                </td>
                                                <td>
                                                    <span
                                                        className={`status ${data?.status
                                                            ?.toLowerCase()
                                                            ?.split(" ")
                                                            .join("-")}`}
                                                    >
                                                        {data.status}
                                                    </span>
                                                    <small>
                                                        {getSubStatus(
                                                            data?.status,
                                                            data?.decisionReason,
                                                            data?.rmPdpa,
                                                            data?.cmPdpa,
                                                            data?.institutes?.find(
                                                                (e) =>
                                                                    e.fundsDisbursed
                                                            ) || false
                                                        )}
                                                    </small>
                                                </td>
                                                <td>
                                                    <div className="flex items-center">
                                                        {data?.status !==
                                                            "Completed" && (
                                                            <button
                                                                className="table-action update cursor-pointer"
                                                                onClick={() => {
                                                                    if (
                                                                        userRoles.currentRole ===
                                                                        "RM Team Leader"
                                                                    ) {
                                                                        navigate(
                                                                            "/rm-lead/edit-case",
                                                                            {
                                                                                state: {
                                                                                    case: data,
                                                                                },
                                                                            }
                                                                        );
                                                                    } else if (
                                                                        userRoles.currentRole ===
                                                                        "RM Member"
                                                                    ) {
                                                                        navigate(
                                                                            "/rm-member/edit-case",
                                                                            {
                                                                                state: {
                                                                                    case: data,
                                                                                },
                                                                            }
                                                                        );
                                                                    } else if (
                                                                        userRoles.currentRole ===
                                                                        "Lead Coordinator"
                                                                    ) {
                                                                        navigate(
                                                                            "/lead-coordinator/edit-case",
                                                                            {
                                                                                state: {
                                                                                    case: data,
                                                                                },
                                                                            }
                                                                        );
                                                                    }
                                                                }}
                                                            >
                                                                <img
                                                                    src={update}
                                                                    alt="update"
                                                                />
                                                                <span>
                                                                    <small>
                                                                        Update
                                                                    </small>
                                                                </span>
                                                            </button>
                                                        )}
                                                        <button
                                                            className="table-action view"
                                                            onClick={() => {
                                                                setSelectedCase(
                                                                    data
                                                                );
                                                                setOpenDetailModal(
                                                                    true
                                                                );
                                                            }}
                                                        >
                                                            <img
                                                                src={view}
                                                                alt="view"
                                                            />
                                                            <span>
                                                                <small>
                                                                    View
                                                                </small>
                                                            </span>
                                                        </button>
                                                        <button
                                                            className="table-action del"
                                                            onClick={() =>
                                                                deleteCase(data)
                                                            }
                                                        >
                                                            <img
                                                                src={del}
                                                                alt="del"
                                                            />
                                                            <span>
                                                                <small>
                                                                    Delete
                                                                </small>
                                                            </span>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </>
                                    );
                                })}
                        </tbody>
                    </table>
                )}
                <div className="flex pagination-combine">
                    <EntriesSelection
                        setCount={setCount}
                        page={page}
                        count={count}
                        data={filtered?.filter((e) => {
                            if (startDate || endDate) {
                                return filterDataByDate(e);
                            } else {
                                return e;
                            }
                        })}
                    />
                    <Pagination
                        count={count}
                        data={filtered?.filter((e) => {
                            if (startDate || endDate) {
                                return filterDataByDate(e);
                            } else {
                                return e;
                            }
                        })}
                        setPage={setPage}
                        page={page}
                    />
                </div>
            </div>
        </>
    );
}
