import "./modals.scss";

export const AbortionModal = (props) => {
    return (
        <div className={`modal-wrapper inner_modal`}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-body ">
                        {/* <div className="modal-forms">
                            <ul className="list-style-none">
                                <li>
                                    <div className="form-group">
                                        <label>
                                            These files will be PDPA marked
                                            completed. Please ensure these files
                                            are no longer on your device
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <div className="flex content-justify-center">
                                        <button
                                            onClick={() => {
                                                props.confirmAction();
                                            }}
                                            className="modal-btn"
                                        >
                                            Ok
                                        </button>
                                    </div>
                                </li>
                            </ul>
                        </div> */}
                        <div className="modal-forms">
                            These files will be PDPA marked completed. Please
                            ensure these files are no longer on your device
                            <div className="flex content-justify-center mt-20">
                                <button
                                    onClick={() => {
                                        props.confirmAction();
                                    }}
                                    className="modal-btn"
                                >
                                    Ok
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
