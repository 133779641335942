import Search from "../../../../components/search/Search";
import React, { useEffect, useState } from "react";
import BusinessEntityList from "./BusinessEntityList";
import { Link, useNavigate } from "react-router-dom";
import "./entity.scss";
import IndividualEntityList from "./IndividualEntityList";
import { setIndividualEntities } from "../../../../redux/individualEntities/iEntitySlice";
import { setBusinessEntities } from "../../../../redux/businessEntities/bEntitySlice";
import { useDispatch } from "react-redux";
import { api } from "../../../../helpers/apiHelper/requestHelper";
import { toastify } from "../../../../helpers/toast/toastify";
import { useSelector } from "react-redux";

export default function AllEntity() {
    const [activeTab, setActiveTab] = useState("tab1");
    const [search, setSearch] = useState("");
    const [dateFilter, setDateFilter] = useState();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userRoles = useSelector((state) => state.userRoles);

    useEffect(() => {
        getBusinessEntities();
        getIndividualEntities();
    }, []);

    const getBusinessEntities = async () => {
        let response = await api(
            "get",
            userRoles?.currentRole === "RM Member"
                ? "businessEntity/index"
                : userRoles?.currentRole === "RM Team Leader"
                ? "businessEntity/rmLeadIndex"
                : "businessEntity/adminIndex"
        );
        if (response?.status) {
            dispatch(setBusinessEntities(response?.data?.reverse()));
        } else {
            toastify(response?.message, "error");
        }
    };

    const getIndividualEntities = async () => {
        let response = await api(
            "get",
            userRoles.currentRole === "RM Member"
                ? "individualEntity/index"
                : userRoles.currentRole === "RM Team Leader"
                ? "individualEntity/rmLeadIndex"
                : "individualEntity/unfilteredIndex"
        );
        if (response?.status) {
            dispatch(setIndividualEntities(response?.data?.reverse()));
        } else {
            toastify(response?.message, "error");
        }
    };

    return (
        <>
            <h4 className="flex flex-wrap items-center mini-title">
                <span>Entity</span>
            </h4>
            <Search
                userSearch={true}
                filedText="Search here"
                teamDropdown={false}
                filters={false}
                typeFilters={false}
                typeFiltersTitle="Lead Type"
                dateFilters={true}
                addButton={false}
                addButtonText="Add Leads"
                buttonRoute={
                    userRoles.currentRole === "RM Team Leader"
                        ? "/rm-lead/add-leads"
                        : userRoles.currentRole === "RM Member"
                        ? "/rm-member/add-leads"
                        : userRoles.currentRole === "Lead Coordinator"
                        ? "/lead-coordinator/add-leads"
                        : "/leads/add-leads"
                }
                statusFilter={false}
                extraClass="entities-wrap"
                component={"Entity"}
                setSearch={setSearch}
                setDateFilter={setDateFilter}
            />
            <div className="entity-tabs flex items-center">
                <ul className="list-style-none flex">
                    <li>
                        <button
                            onClick={() => {
                                setActiveTab("tab1");
                            }}
                            className={`${
                                activeTab === "tab1" ? "active" : ""
                            }`}
                        >
                            Individual
                        </button>
                    </li>
                    <li>
                        <button
                            onClick={() => {
                                setActiveTab("tab2");
                            }}
                            className={`${
                                activeTab === "tab2" ? "active" : ""
                            }`}
                        >
                            Business
                        </button>
                    </li>
                </ul>
                <div
                    onClick={() => {
                        if (activeTab === "tab1") {
                            if (userRoles.currentRole === "RM Team Leader") {
                                navigate("/rm-lead/individual-entity");
                            } else if (userRoles.currentRole === "RM Member") {
                                navigate("/rm-member/individual-entity");
                            } else if (
                                userRoles.currentRole === "Lead Coordinator"
                            ) {
                                navigate("/lead-coordinator/individual-entity");
                            }
                        } else {
                            if (userRoles.currentRole === "RM Team Leader") {
                                navigate("/rm-lead/business-entity");
                            } else if (userRoles.currentRole === "RM Member") {
                                navigate("/rm-member/business-entity");
                            } else if (
                                userRoles.currentRole === "Lead Coordinator"
                            ) {
                                navigate("/lead-coordinator/business-entity");
                            }
                        }
                    }}
                    className="simple-btn ml-auto cursor-pointer"
                >
                    Add New
                </div>
            </div>
            {activeTab === "tab1" ? (
                <IndividualEntityList
                    getEntities={getIndividualEntities}
                    search={search}
                    dateFilter={dateFilter}
                />
            ) : (
                <BusinessEntityList
                    getEntities={getBusinessEntities}
                    search={search}
                    dateFilter={dateFilter}
                />
            )}
        </>
    );
}
