var PENDING_1 = "Waiting for Client Signature";
var PENDING_2 = "Verifying Documents";
var SUBMITTED = "Verifying Documents";
var PROCESSING_1 = "Awaiting Submission to Bank";
var PROCESSING_2 = "Submitted to Bank";
var APPROVED = "Accepted by Bank";
var COMPLETED = "Accepted by Client";

export {
    PENDING_1,
    PENDING_2,
    PROCESSING_1,
    PROCESSING_2,
    APPROVED,
    COMPLETED,
    SUBMITTED,
};
