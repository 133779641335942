import "./adminLayout.scss";
import { Outlet, useNavigate } from "react-router-dom";
import PanelHeader from "../../components/panel-header/PanelHeader";
import menuIcon from "../../assets/images/menu.svg";
import SideBar from "../../components/sidebar/SideBar";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import OutsideClickHandler from "react-outside-click-handler/esm/OutsideClickHandler";
import { useDispatch } from "react-redux";
import { setRoute } from "../../redux/route/routeSlice";
import { Loader } from "../../components/modals/Loader";
import { api } from "../../helpers/apiHelper/requestHelper";
import { toastify } from "../../helpers/toast/toastify";
import { setUserSchedule } from "../../redux/schedules/scheduleSlice";
import { ReminderModal } from "../../components/modals/ReminderModal";
import { AnnouncementModal } from "../../components/modals/AnnouncementModal";
import { setUserAnnouncements } from "../../redux/announcements/announcementSlice";
import { setUserNotifications } from "../../redux/notifications/notificationsSlice";
import { setProductForms } from "../../redux/productForms/productsSlice";
import {
    storeTeams,
    storeUnassignedMembers,
} from "../../redux/teams/teamsSlice";
import { setInternalForms } from "../../redux/internalForms/formsSlice";
import { storeUsers } from "../../redux/users/usersSlice";
import { shortFormatDate } from "../../helpers/dataHelper/dateFormatter";
import { storeLeads } from "../../redux/leads/leadsSlice";
import { storeCases } from "../../redux/cases/casesSlice";
import { login } from "../../redux/auth/authSlice";
import { setActiveRole, setUserRoles } from "../../redux/roles/rolesSlice";
import {
    setFolderModal,
    storeFolders,
} from "../../redux/folderStructure/folderStructureSlice";
import FolderNameModal from "../../components/modals/FolderNameModal";
import { setLoading } from "../../redux/loader/loadingSlice";

export const AdminLayout = () => {
    const [openMenu, setOpenMenu] = useState(false);

    const Auth = useSelector((state) => state.auth);
    const reduxData = useSelector((state) => state);
    const [announcementModal, setAnnouncementModal] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [reminderModal, setReminderModal] = useState(false);

    useEffect(() => {
        if (reduxData?.schedules?.value?.length > 0) setReminderModal(true);
    }, [reduxData?.schedules?.value?.length]);

    useEffect(() => {
        if (reduxData?.userAnnouncements?.value?.length > 0)
            setAnnouncementModal(true);
    }, [reduxData?.userAnnouncements?.value?.length]);

    useEffect(() => {
        getUserProfile().then(() => getFolderStructure());
    }, []);

    useEffect(() => {
        if (!Auth?.user) navigate("/login");
        else {
            getFolderStructure();
            getUsers();
            getCases();
            getLeads();
            getInternalForms();
            getProductForms();
            getTeams();
            getSchedules();
            getAnnouncements();
            getNotifications();
        }
    }, [Auth]);

    const getSchedules = async () => {
        const response = await api("post", "schedules/getSchedulesById");
        if (response?.data?.status) {
            dispatch(setUserSchedule(response?.data?.data));
        } else {
            toastify(response?.message);
        }
    };

    const getNotifications = async () => {
        let response = await api("post", "notifications/getNotificationsById");
        if (response?.status) {
            dispatch(setUserNotifications(response?.data?.data?.reverse()));
        } else {
            toastify(response?.message, "error");
        }
    };

    const acknowledge = async (id) => {
        const response = await api("post", "schedules/acknowledge", {
            scheduleId: id,
        });
        if (response?.data?.status) {
            toastify("Acknowledged!", "success");
            getSchedules();
        } else {
            toastify("Unable to acknowledge.", "error");
        }
        setReminderModal(!reminderModal);
    };

    const getUserProfile = async () => {
        const response = await api("get", "users/getUser");

        if (response?.status) {
            let roles = response?.data?.roles;

            dispatch(login(response?.data));
            if (
                JSON.stringify(reduxData?.userRoles?.roles) !==
                JSON.stringify(response?.data?.roles)
            ) {
                let activeRole = roles[0];

                dispatch(setUserRoles(roles));
                dispatch(setActiveRole(activeRole));
            }
        } else {
            toastify(response?.message);
        }
    };

    const getReminderComponent = () => {
        return reduxData?.schedules?.value
            ?.filter((e) => {
                let hours = parseInt(e?.endTime?.split(":")[0]);
                let minutes = parseInt(e?.endTime?.split(":")[1]);
                let hoursNow = new Date().getHours();
                let minutesNow = new Date().getMinutes();

                if (hours >= hoursNow) {
                    if (hours === hoursNow) {
                        if (minutes > minutesNow) {
                            return true;
                        }
                    } else {
                        return true;
                    }
                }
            })
            ?.map((e) => (
                <ReminderModal
                    setModalClick={() => setReminderModal(false)}
                    acknowledge={acknowledge}
                    id={e._id}
                    title={e.title}
                    type={e.type}
                    reminderDescription={[
                        <p>
                            {e.remarks} <br />
                            <span>Date: </span>
                            {shortFormatDate(e.date)}
                            <br />
                            <span>Starts</span> at {e.startTime} and{" "}
                            <span>Ends</span> at {e.endTime}
                        </p>,
                    ]}
                />
            ));
    };

    const getUsers = async () => {
        let response = await api("get", "users/usersList");
        if (response?.status) {
            dispatch(storeUsers(response?.data?.reverse()));
        } else {
            toastify(response?.message, "error");
        }
    };

    const getFolderStructure = async () => {
        let response = await api("get", "folders/index");
        if (response?.status) {
            dispatch(storeFolders(response?.data));
        } else {
            toastify(response?.message, "error");
        }
    };

    const getInternalForms = async () => {
        let response = await api("get", "forms/index");
        if (response?.status) {
            dispatch(setInternalForms(response?.data?.reverse()));
        } else {
            toastify(response?.message, "error");
        }
    };

    const getProductForms = async () => {
        let response = await api("get", "products/index");
        if (response?.status) {
            dispatch(setProductForms(response?.data?.reverse()));
        } else {
            toastify(response?.message, "error");
        }
    };

    const getTeams = async () => {
        let response = await api("get", "teams/index");
        if (response?.status) {
            dispatch(storeTeams(response?.data));
            dispatch(storeUnassignedMembers(response?.data?.unassignedUsers));
        } else {
            toastify(response?.message, "error");
        }
    };

    const getLeads = async () => {
        let response = await api("post", "leads/adminIndex", {
            assignedFromRole: reduxData?.userRoles?.currentRole,
        });
        if (response?.status) {
            dispatch(storeLeads(response?.data?.data?.reverse()));
        } else {
            toastify(response?.message, "error");
        }
    };

    const getCases = async () => {
        let response = await api("get", "cases/adminCases");

        if (response?.data?.status) {
            dispatch(storeCases(response?.data?.data?.reverse()));
        } else if (response?.status) {
            dispatch(storeCases(response?.data?.reverse()));
        } else {
            toastify(response?.message, "error");
        }
    };

    const getAnnouncements = async (id) => {
        const response = await api("get", "announcements/getAnnouncementById");
        if (response?.status) {
            dispatch(setUserAnnouncements(response?.data?.reverse()));
        } else {
            toastify(response?.message);
        }
    };

    const renameFolder = async (name) => {
        dispatch(setLoading(true));
        const response = await api("post", "folders/updateFolderName", {
            name,
        });
        if (response?.data?.status) {
            toastify("Folder structure updated.", "success");
            dispatch(storeFolders(response?.data?.data));
        } else {
            toastify(response?.message);
        }
        dispatch(setRoute(name));
        dispatch(setLoading(false));
        dispatch(setFolderModal(false));
    };

    return (
        <div className="panel-wrapper flex">
            {reminderModal && getReminderComponent()}
            {announcementModal && (
                <AnnouncementModal
                    setShow={setAnnouncementModal}
                    getData={getAnnouncements}
                />
            )}
            {reduxData?.loading?.value && <Loader />}
            {reduxData?.folders?.folderModal && (
                <FolderNameModal
                    rename={renameFolder}
                    cancel={() => {
                        dispatch(setFolderModal(false));
                    }}
                    folder={reduxData?.folders?.folderStructure[0]}
                />
            )}

            {window.screen.width > 1200 ? (
                <div
                    className={`sidebar-wrapper flex column-direction ${
                        openMenu === true ? "active" : ""
                    }`}
                >
                    <SideBar />
                </div>
            ) : (
                <OutsideClickHandler
                    onOutsideClick={() => {
                        setOpenMenu(false);
                    }}
                >
                    <div
                        className={`sidebar-wrapper flex column-direction ${
                            openMenu === true ? "active" : ""
                        }`}
                    >
                        <SideBar menuClick={() => setOpenMenu(false)} />
                    </div>
                </OutsideClickHandler>
            )}
            <div
                className={`flex flex-wrap column-direction panel-content-wrapper ${
                    openMenu === true ? "active" : ""
                }`}
            >
                <button
                    className={`menu-btn ${openMenu === true ? "active" : ""}`}
                    onClick={() => setOpenMenu(!openMenu)}
                >
                    <img src={menuIcon} alt="menuIcon" />
                </button>
                <PanelHeader title={reduxData?.heading?.value} />
                <div className="panel-content">
                    <Outlet />
                </div>
            </div>
        </div>
    );
};
