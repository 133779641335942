import "./modals.scss";

export const SendDocumentModal = (props) => {
    return (
        <div className={`modal-wrapper`}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-body alert-modals">
                        <div className="modal-forms">
                            {props.modalTitle}
                            {props.modalDescription}
                            <div className="flex content-justify-center">
                                <button
                                    onClick={() => {
                                        props.setModalClick();
                                        props?.setDocuments([]);
                                    }}
                                    className="modal-btn cancel"
                                >
                                    Cancel
                                </button>
                                {props.confirmBtn === true && (
                                    <button
                                        onClick={
                                            props?.confirmAction ||
                                            props.setModalClick
                                        }
                                        className="modal-btn"
                                    >
                                        Confirm
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
