import axios from "axios";

const config = axios.create({
    // baseURL: "https://a7d4-39-55-255-179.ngrok-free.app",
    baseURL: process.env.REACT_APP_API_BASE_URL,
    // baseURL: "http://192.168.1.7:8086/",
});

export const api = async (method, url, payload) => {
    if (method === "post") {
        try {
            const data = await config.post(url, payload, {
                headers: {
                    Authorization: localStorage.getItem("token"),
                    orgid: localStorage.getItem("orgid"),
                },
            });

            return data;
        } catch (error) {
            console.log({ error });
            return error?.response?.data || "Error";
        }
    } else if (method === "postFormData") {
        try {
            const data = await config.post(url, payload, {
                headers: {
                    Authorization: localStorage.getItem("token"),
                    orgid: localStorage.getItem("orgid"),
                    "Content-Type": "multipart/form-data",
                },
            });

            return data;
        } catch (error) {
            return error?.response?.data || "Error";
        }
    } else if (method === "get") {
        try {
            const response = await config.get(url, {
                headers: {
                    Authorization: localStorage.getItem("token"),
                    orgid: localStorage.getItem("orgid"),
                },
            });
            return response?.data;
        } catch (error) {
            console.log({ error });
            return error?.response?.data;
        }
    } else if (method === "upload") {
        try {
            const data = await config.post(url, payload, {
                headers: {
                    Authorization: localStorage.getItem("token"),
                    "Content-Type": "multipart/form-data; charset=utf-8",
                    orgid: localStorage.getItem("orgid"),
                },
            });

            return data;
        } catch (error) {
            console.log(error);
            return error?.response?.data || "Error";
        }
    } else if (method === "delete") {
        try {
            const response = await config.delete(url, {
                headers: {
                    Authorization: localStorage.getItem("token"),
                    orgid: localStorage.getItem("orgid"),
                },
            });
            return response?.data;
        } catch (error) {
            console.log({ error });
            return error?.response?.data;
        }
    }
};
