import userIcon from "../../../../assets/images/users.svg";
import products from "../../../../assets/images/products.svg";
import customForm from "../../../../assets/images/customform.svg";
import highIcon from "../../../../assets/images/highIcon.svg";
import { Link, useNavigate } from "react-router-dom";
import "./statistics.scss";
import { useDispatch, useSelector } from "react-redux";
import { setSidebar } from "../../../../helpers/sidebar/setSidebar";
import { resetSidebar } from "../../../../helpers/sidebar/resetSidebar";
import { setRoute } from "../../../../redux/route/routeSlice";

export default function Statistics() {
    const users = useSelector((state) => state.users);
    const prod = useSelector((state) => state.productForms.value);
    const forms = useSelector((state) => state.internalForms.value);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const statisticsList = [
        {
            statisticsIcon: userIcon,
            statisticsCount: users?.value?.length || 0,
            statisticsTitle: "Total Users",
            url: "/users",
        },
        {
            statisticsIcon: products,
            statisticsCount: prod?.length || 0,
            statisticsTitle: "Total Products",
            url: "/product-form",
        },
        {
            statisticsIcon: customForm,
            statisticsCount: forms?.length || 0,
            statisticsTitle: "Total Custom \nForm",
            url: "/internal-form",
        },
    ];
    return (
        <div className="statistics-wrapper flex column-direction">
            <ul className="list-style-none flex flex-wrap">
                {statisticsList.map(
                    (
                        {
                            statisticsIcon,
                            statisticsCount,
                            statisticsTitle,
                            url,
                        },
                        index
                    ) => {
                        return (
                            <li
                                key={`statistics-${index}`}
                                onClick={() => {
                                    if (statisticsTitle.includes("Form")) {
                                        dispatch(setRoute("Forms"));
                                        resetSidebar();
                                        setSidebar("Internal Form");
                                    }
                                    navigate(url, {
                                        state: {
                                            isPrevious: true,
                                        },
                                    });
                                }}
                            >
                                <Link className="flex items-center">
                                    <section>
                                        <h2>{statisticsCount}</h2>
                                        <span>{statisticsTitle} </span>
                                    </section>
                                    <figure>
                                        <img
                                            src={statisticsIcon}
                                            alt="statisticsIcon"
                                        />
                                    </figure>
                                </Link>
                            </li>
                        );
                    }
                )}
            </ul>
        </div>
    );
}
