import { useState } from "react";
import "./modals.scss";
import uploadIcon from "../../assets/images/uploadIcon.svg";
import { useRef } from "react";
import fileIcon from "../../assets/images/file-icon.svg";
import del from "../../assets/images/cross_del.svg";
import { convertBytesToMbsKbs } from "../../helpers/sizeConverter/sizeConverter";
import generateUID from "../../helpers/dataHelper/uidGenerator";
import { toastify } from "../../helpers/toast/toastify";

export const AddFilesModal = (props) => {
    const [files, setFiles] = useState([]);
    const fileInputRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        fileInputRef.current.value = null;
        let _files = [...files];
        if (selectedFile?.size !== 0 && selectedFile?.type !== "") {
            _files.push({
                name: selectedFile?.name,
                size: selectedFile?.size,
                type: selectedFile?.type,
                modifiedDate: new Date(),
                modifiedBy: props?.userId,
                uploadedDate: new Date(),
                uploadedBy: props?.userId,
                uid: generateUID(),
                file: selectedFile,
            });
        }
        setFiles(_files);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        setIsDragging(false);
    };

    const handleDivClick = () => {
        fileInputRef.current.click();
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
        e.dataTransfer.dropEffect = "copy";
        setIsDragging(true);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const selectedFiles = e.dataTransfer.files;
        console.log({ selectedFiles });
        let _files = [...files];
        for (let index = 0; index < selectedFiles?.length; index++) {
            const element = selectedFiles[index];
            if (element.size !== 0 && element?.type !== "") {
                _files.push({
                    name: element?.name,
                    size: element?.size,
                    type: element?.type,
                    modifiedDate: new Date(),
                    modifiedBy: props?.userId,
                    uploadedDate: new Date(),
                    uploadedBy: props?.userId,
                    uid: generateUID(),
                    file: element,
                });
            }
        }
        setFiles(_files);
        setIsDragging(false);
        fileInputRef.current.value = null;
    };

    return (
        <div className={`modal-wrapper`}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-body alert-modals">
                        <div className="modal-forms">
                            Upload
                            <div className="uploaded-files">
                                {files?.map((f, index) => (
                                    // <div>{f?.name}</div>
                                    <div
                                        className="cases-doc-list "
                                        style={{ width: "100%", padding: 0 }}
                                    >
                                        <ul className="p-0 m-0 mb-0">
                                            <div className="flex column-direction case_input_des ">
                                                <li className="p-0">
                                                    <span
                                                        style={{
                                                            padding:
                                                                "15px 0px 15px 20px",
                                                        }}
                                                    >
                                                        <img
                                                            src={fileIcon}
                                                            className="cursor-pointer"
                                                            alt="pdfIcon"
                                                        />
                                                        <div>
                                                            <div className="file_name cursor-pointer">
                                                                {f?.name}
                                                            </div>
                                                            <div className="file_size">
                                                                {convertBytesToMbsKbs(
                                                                    f?.size
                                                                )}
                                                            </div>
                                                        </div>
                                                        <img
                                                            className="cursor-pointer ml-auto"
                                                            src={del}
                                                            alt="del"
                                                            onClick={() => {
                                                                let _files = [
                                                                    ...files,
                                                                ]?.filter(
                                                                    (e, i) =>
                                                                        i !==
                                                                        index
                                                                );
                                                                setFiles(
                                                                    _files
                                                                );
                                                            }}
                                                        />
                                                    </span>
                                                </li>
                                            </div>
                                        </ul>
                                    </div>
                                ))}
                            </div>
                            <div
                                className={`file_dropzone ${
                                    isDragging ? "dragging" : ""
                                }`}
                                onDragOver={handleDragOver}
                                onDrop={handleDrop}
                                onClick={handleDivClick}
                                onDragLeave={handleDragLeave}
                                style={{ cursor: "pointer" }}
                            >
                                <label>
                                    <img src={uploadIcon} alt="uploadIcon" />
                                    Upload your Files
                                </label>
                                <input
                                    style={{ display: "none" }}
                                    type="file"
                                    id="fileInput"
                                    ref={fileInputRef}
                                    onChange={handleFileChange}
                                />
                            </div>
                            <div className="flex content-justify-center">
                                <button
                                    onClick={() => {
                                        props.cancel();
                                        setFiles([]);
                                    }}
                                    className="modal-btn cancel"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={() => {
                                        props.confirm(files);
                                        setFiles([]);
                                    }}
                                    className="modal-btn"
                                >
                                    Upload
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
