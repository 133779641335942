import { useEffect, useRef, useState } from "react";
import DatePicker from "react-date-picker";
import Multiselect from "multiselect-react-dropdown";
import { api } from "../../../../helpers/apiHelper/requestHelper";
import { toastify } from "../../../../helpers/toast/toastify";
import { missingPopup } from "../../../../helpers/dataHelper/missingData";
import { useLocation, useNavigate } from "react-router-dom";
import {
    storeTeams,
    storeUnassignedMembers,
} from "../../../../redux/teams/teamsSlice";
import fileIcon from "../../../../assets/images/file-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import uploadIcon from "../../../../assets/images/upload.svg";
import axios from "axios";
import { setLoading } from "../../../../redux/loader/loadingSlice";
import del from "../../../../assets/images/cross_del.svg";

export default function AddAnnouncement() {
    const [title, setTitle] = useState("");
    const [announcementFor, setAnnouncementFor] = useState("");
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [description, setDescription] = useState("");
    const [items, setItems] = useState([]);
    const [users, setUsers] = useState([]);
    const [attachments, setAttachments] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState();
    const navigate = useNavigate();
    const { state } = useLocation();
    const dispatch = useDispatch();
    const teams = useSelector((state) => state.allTeams);
    const fileInputRef = useRef(null);

    useEffect(() => {
        getUsers();
        getTeams();
    }, []);

    useEffect(() => {
        if (state?.announcement) {
            let data = state?.announcement;
            setTitle(data?.title);
            setDescription(data?.description);
            setFromDate(new Date(data?.fromDate));
            setToDate(new Date(data?.toDate));
            setAttachments(data?.attachments);

            if (data?.teams.length > 0) {
                setAnnouncementFor("team");
                setItems(data?.teams);
            } else {
                setAnnouncementFor("user");
                let us = data?.users?.map((e) => e.user);
                setItems(us);
            }
        }
    }, [state]);

    const getUsers = async () => {
        let response = await api("get", "users/usersList");
        if (response?.status) {
            setUsers(response?.data?.reverse());
        } else {
            toastify(response?.message, "error");
        }
    };

    const getTeams = async () => {
        let response = await api("get", "teams/index");
        if (response?.status) {
            dispatch(storeTeams(response?.data));
        } else {
            toastify(response?.message, "error");
        }
    };

    const handleSelect = (selectedList) => {
        setItems(selectedList);
    };

    const handleRemove = (selectedList) => {
        setItems(selectedList);
    };

    const handleSubmit = async () => {
        if (!title) missingPopup("Title");
        else if (!description) missingPopup("Description");
        else if (!fromDate) missingPopup("From Date");
        else if (!toDate) missingPopup("To Date");
        else if (!announcementFor) missingPopup("PDPA For");
        else if (!items.length) missingPopup("PDPA For User");
        else {
            let data = {
                title,
                description,
                fromDate,
                toDate,
                attachments,
            };
            if (announcementFor === "team") {
                data = {
                    ...data,
                    teams:
                        announcementFor === "team"
                            ? items.map((t) => {
                                  return t._id;
                              })
                            : null,
                };
            } else {
                data = {
                    ...data,
                    users: items?.map((e) => {
                        return { user: e._id, acknowledged: false };
                    }),
                };
            }

            if (state?.announcement) {
                const response = await api(
                    "post",
                    "announcements/updateAnnouncement",
                    { ...data, id: state?.announcement?._id }
                );

                if (response?.data?.status) {
                    toastify("PDPA modified successfully.", "success");
                    navigate("/announcements");
                } else {
                    toastify(response?.message, "error");
                }
            } else {
                const response = await api(
                    "post",
                    "announcements/createAnnouncement",
                    data
                );

                if (response?.data?.status) {
                    toastify("PDPA added successfully.", "success");
                    navigate("/announcements");
                } else {
                    toastify(response?.message, "error");
                }
            }
        }
    };

    const config = axios.create({
        baseURL: process.env.REACT_APP_API_BASE_URL,
    });

    const uploadFile = async (file) => {
        dispatch(setLoading(true));
        try {
            let fd = new FormData();
            fd.append("file", file);
            if (state?.announcement) {
                fd.append("announcement", state?.announcement?._id);
            }
            fd.append("type", "Announcement");
            let response = await config.post("auth/uploadFile", fd);

            if (response?.data?.status) {
                let url = response?.data?.data;
                if (url) {
                    let att = [...attachments];
                    att.push({ url, filename: file.name });
                    setAttachments(att);
                    fileInputRef.current.value = null;
                } else {
                    toastify("Unable to upload document.");
                }
            } else {
                toastify(response?.message);
            }
        } catch (error) {
            console.log({ error });
            toastify(error?.response?.data?.message);
        }

        dispatch(setLoading(false));
    };

    return (
        <div className="pb-150">
            <h2 className="flex flex-wrap mini-title">
                <span onClick={() => navigate("/announcements")}>PDPA</span> /
                Add
            </h2>
            <div className="card">
                <div className="card-title">
                    <h2>PDPA</h2>
                </div>
                <div className="card-body">
                    <div className="edit-form-wrapper">
                        <ul className="list-style-none flex flex-wrap">
                            <li className="w-100">
                                <div className="form-group">
                                    <label htmlFor="">Title *</label>
                                    <input
                                        type="text"
                                        value={title}
                                        onChange={(e) =>
                                            setTitle(e.target.value)
                                        }
                                        className="form-control mandatory"
                                        placeholder="Title"
                                    />
                                </div>
                            </li>
                            <li className="w-100">
                                <div className="form-group">
                                    <label htmlFor="">Description *</label>
                                    <textarea
                                        value={description}
                                        onChange={(e) =>
                                            setDescription(e.target.value)
                                        }
                                        className="form-control mandatory"
                                        placeholder="Description"
                                    ></textarea>
                                </div>
                            </li>
                            <li>
                                <div className="form-group">
                                    <label htmlFor="">From Date *</label>
                                    <DatePicker
                                        format="dd/MM/y"
                                        onChange={(e) => {
                                            setFromDate(e);
                                        }}
                                        className="form-control mandatory"
                                        value={fromDate}
                                        minDate={new Date()}
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                    />
                                </div>
                            </li>
                            <li>
                                <div className="form-group">
                                    <label htmlFor="">To Date *</label>
                                    <DatePicker
                                        format="dd/MM/y"
                                        onChange={(e) => {
                                            setToDate(e);
                                        }}
                                        className="form-control mandatory"
                                        value={toDate}
                                        minDate={new Date()}
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                    />
                                </div>
                            </li>
                            <li>
                                <div className="form-group">
                                    <label htmlFor="">PDPA For *</label>
                                    <select
                                        value={announcementFor}
                                        onChange={(e) => {
                                            setAnnouncementFor(e.target.value);
                                            setItems([]);
                                        }}
                                        className="form-control mandatory"
                                    >
                                        <option value="" defaultChecked>
                                            Select
                                        </option>
                                        <option value="team">Team</option>
                                        <option value="user">User</option>
                                    </select>
                                </div>
                            </li>
                            <li>
                                <div className="form-group">
                                    <label htmlFor="">
                                        PDPA For{" "}
                                        {announcementFor === "team"
                                            ? "Team"
                                            : "User"}{" "}
                                        *
                                    </label>
                                    <Multiselect
                                        showCheckbox
                                        className="mandatory-multi"
                                        options={
                                            announcementFor === "team"
                                                ? teams?.value?.teams
                                                : users
                                        }
                                        selectedValues={items}
                                        onSelect={handleSelect}
                                        onRemove={handleRemove}
                                        displayValue={
                                            announcementFor === "team"
                                                ? "name"
                                                : "username"
                                        }
                                        // selectionLimit={
                                        //     announcementFor === "team" ? 1 : 999
                                        // }
                                    />
                                </div>
                            </li>
                            <li>
                                <div className="form-group">
                                    <label htmlFor="">
                                        Attachment{" "}
                                        <span className="add-extra-list del ml-20 ">
                                            <label
                                                class="file-input-label"
                                                htmlFor={`file-input-i`}
                                            >
                                                <img src={uploadIcon} />
                                            </label>
                                            <input
                                                ref={fileInputRef}
                                                id={`file-input-i`}
                                                type="file"
                                                style={{
                                                    display: "none",
                                                }}
                                                onChange={(e) => {
                                                    if (e.target.files[0]) {
                                                        uploadFile(
                                                            e.target.files[0]
                                                        );
                                                    }
                                                }}
                                            />
                                        </span>
                                    </label>
                                </div>
                                {attachments?.length > 0 &&
                                    attachments?.map((attachment) => (
                                        <div
                                            className="cases-doc-list "
                                            style={{
                                                width: "100%",
                                                padding: 0,
                                            }}
                                        >
                                            <ul className="p-0 m-0 mb-0">
                                                <div className="flex column-direction case_input_des ">
                                                    <li className="p-0">
                                                        <span
                                                            style={{
                                                                padding:
                                                                    "20px 40px 20px 20px",
                                                            }}
                                                        >
                                                            <img
                                                                src={fileIcon}
                                                                className="cursor-pointer"
                                                                alt="pdfIcon"
                                                                onClick={() => {
                                                                    window.open(
                                                                        attachment.url
                                                                    );
                                                                }}
                                                            />
                                                            <div
                                                                className="file_name cursor-pointer"
                                                                onClick={() => {
                                                                    window.open(
                                                                        attachment.url
                                                                    );
                                                                }}
                                                            >
                                                                {
                                                                    attachment?.filename
                                                                }
                                                            </div>
                                                            <div className="action_button_wrap">
                                                                <img
                                                                    className="cursor-pointer"
                                                                    src={del}
                                                                    alt="del"
                                                                    onClick={() => {
                                                                        let att =
                                                                            [
                                                                                ...attachments,
                                                                            ].filter(
                                                                                (
                                                                                    el
                                                                                ) =>
                                                                                    el.url !==
                                                                                    attachment.url
                                                                            );
                                                                        setAttachments(
                                                                            att
                                                                        );

                                                                        fileInputRef.current.value =
                                                                            null;
                                                                    }}
                                                                />
                                                            </div>
                                                        </span>
                                                    </li>
                                                </div>
                                            </ul>
                                        </div>
                                    ))}
                            </li>
                            <li></li>
                            <li>
                                <button
                                    className="simple-btn create-form"
                                    onClick={handleSubmit}
                                >
                                    {state?.announcement ? "Update" : "Create"}{" "}
                                    PDPA
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}
