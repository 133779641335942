export const UNAVAILABLE_COUNTRIES = [
    "AX",
    "AS",
    "AI",
    "AQ",
    "AW",
    "BM",
    "BV",
    "IO",
    "KY",
    "CX",
    "CC",
    "CK",
    "FK",
    "FO",
    "GF",
    "PF",
    "TF",
    "GI",
    "GL",
    "GP",
    "GU",
    "GG",
    "HM",
    "HK",
    "JE",
    "MO",
    "IM",
    "MH",
    "MQ",
    "YT",
    "MC",
    "MS",
    "BQ",
    "NC",
    "NU",
    "NF",
    "MP",
    "PS",
    "PN",
    "PR",
    "RE",
    "SH",
    "PM",
    "BL",
    "MF",
    "GS",
    "SJ",
    "TK",
    "TC",
    "UM",
    "VA",
    "VG",
    "VI",
    "WF",
    "EH",
    "XK",
    "CW",
    "SX",
];
