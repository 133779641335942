import { useEffect } from "react";
import { useState } from "react";
import { api } from "../../helpers/apiHelper/requestHelper";
import { toastify } from "../../helpers/toast/toastify";
import { setSidebar } from "../../helpers/sidebar/setSidebar";
import {
    formatDate,
    shortFormatDate,
} from "../../helpers/dataHelper/dateFormatter";

export default function ActivityLog() {
    const [logs, setLogs] = useState([]);

    useEffect(() => {
        setSidebar("Activity Logs");
        getActivityLogs();
    }, []);

    const getActivityLogs = async () => {
        let response = await api("get", "activity/index");
        if (response?.status) {
            setLogs([...response?.data]?.reverse());
        } else {
            toastify(response?.message, "error");
        }
    };

    return (
        <>
            <h4 className="flex flex-wrap mini-title">Activity Log</h4>
            <div className="responsive-table activity_wrap w-100">
                <table className="table">
                    <thead>
                        <tr
                            className="no-shadow"
                            style={{ background: "#f6f6f6" }}
                        >
                            <td colSpan="6" height="16"></td>
                        </tr>
                        <tr>
                            <th>Date & Time</th>
                            {/* <th>Time</th> */}
                            <th>Activity Log</th>
                        </tr>
                    </thead>
                    <tbody>
                        {logs?.map((data, index) => {
                            return (
                                <>
                                    <tr className="no-shadow">
                                        <td colSpan="6" height="16"></td>
                                    </tr>
                                    <tr key={index}>
                                        <td>
                                            {formatDate(
                                                new Date(data.created_at)
                                            )}
                                        </td>
                                        {/* <td>
                                            {new Date(
                                                data.created_at
                                            ).toLocaleTimeString("en-US", {
                                                timeStyle: "short",
                                            })}
                                        </td> */}
                                        <td>{data.log}</td>
                                    </tr>
                                </>
                            );
                        })}
                    </tbody>
                </table>
                {/*<div className="flex pagination-combine">*/}
                {/*    <EntriesSelection setCount={setCount} />*/}
                {/*    <Pagination*/}
                {/*        count={count}*/}
                {/*        data={filtered}*/}
                {/*        setPage={setPage}*/}
                {/*        page={page}*/}
                {/*    />*/}
                {/*</div>*/}
            </div>
        </>
    );
}
