import React, { useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { StickyTable, Row, Cell } from "react-sticky-table";

function TableComponent({ data, columns }) {
    const [rows, setRows] = useState([]);

    useEffect(() => {
        if (data?.length > 0 && columns?.length > 0) {
            const _rows = data.map((dataItem, rowIndex) => {
                const cells = columns.map((column, columnIndex) => {
                    return {
                        key: `cell_${rowIndex}_${columnIndex}`,
                        value: dataItem[column.dataField],
                    };
                });

                return {
                    cells,
                };
            });
            setRows(_rows);
        }
    }, [data, columns]);

    return (
        <>
            <StickyTable leftStickyColumnCount={2} leftColumnZ={5}>
                <Row>
                    {columns.map((column) => (
                        <Cell key={column.name}>{column.name}</Cell>
                    ))}
                </Row>
                {rows.map((row, index) => (
                    <Row key={index}>
                        {row.cells.map((cell) => (
                            <Cell key={cell.key}>{cell.value}</Cell>
                        ))}
                    </Row>
                ))}
            </StickyTable>
        </>
    );
}

export default TableComponent;
