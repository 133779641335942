import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { api } from "../../../helpers/apiHelper/requestHelper";
import { toastify } from "../../../helpers/toast/toastify";
import { setUserAnnouncements } from "../../../redux/announcements/announcementSlice";
import { setInternalForms } from "../../../redux/internalForms/formsSlice";
import { setLoading } from "../../../redux/loader/loadingSlice";
import { setProductForms } from "../../../redux/productForms/productsSlice";
import {
    storeTeams,
    storeUnassignedMembers,
} from "../../../redux/teams/teamsSlice";
import { storeUsers } from "../../../redux/users/usersSlice";
import Statistics from "./statistics/Statistics";
import { setSidebar } from "../../../helpers/sidebar/setSidebar";
import { setRoute } from "../../../redux/route/routeSlice";

export default function DashBoard() {
    const Auth = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userRoles = useSelector((state) => state?.userRoles);

    useEffect(() => {
        if (!Auth.user) {
            navigate("/login");
        } else {
            dispatch(setLoading(false));
            if (userRoles?.currentRole === "RM Team Leader") {
                navigate("/rm-lead");
            } else if (userRoles?.currentRole === "RM Member") {
                navigate("/rm-member");
            } else if (userRoles?.currentRole === "Lead Coordinator") {
                navigate("/lead-coordinator");
            } else if (userRoles?.currentRole === "Case Manager") {
                navigate("/case-manager");
            } else {
                navigate("/");
            }
        }
    }, [Auth.user]);

    useEffect(() => {
        setSidebar("Dashboard");
        dispatch(setRoute("Dashboard"));
    }, []);

    return (
        <>
            <Statistics />
        </>
    );
}
