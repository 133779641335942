import {
    Menu,
    MenuItem,
    ProSidebarProvider,
    Sidebar,
    SubMenu,
} from "react-pro-sidebar";
import { Link, useNavigate } from "react-router-dom";
import dashboard from "../../assets/images/icon1.svg";
import users from "../../assets/images/icon2.svg";
import team from "../../assets/images/icon3.svg";
import template from "../../assets/images/icon5.svg";
import product from "../../assets/images/product.png";
import iForm from "../../assets/images/contact-form.png";
import banklist from "../../assets/images/icon7.svg";
import { useEffect, useState } from "react";
import schedule from "../../assets/images/scheduler.svg";
import entity from "../../assets/images/icon9.svg";
import announcement from "../../assets/images/icon6.svg";
import activityLog from "../../assets/images/activityLog.svg";
import bell from "../../assets/images/bell.svg";
import { setRoute } from "../../redux/route/routeSlice";
import { useDispatch, useSelector } from "react-redux";
import report from "../../assets/images/icon8.svg";
import folderIcon from "../../assets/images/sidebarFolderIcon.svg";

export default function AdminSidebar(props) {
    const [activePage, setActivePage] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [dropdown, setDropdown] = useState();
    const [sidebarOpen, setSidebarOpen] = useState();
    const navigation = useNavigate();
    const reduxData = useSelector((state) => state);

    function handleActive(event, heading) {
        // if (activePage) activePage.classList.remove("active");
        let activeA = document.querySelector("span.active");
        if (activeA) {
            activeA.classList.remove("active");
        }
        dispatch(setRoute(heading));
        if (!event.target.classList.value.includes("active")) {
            event.target.classList.add("active");
            // setActivePage(event.target);
        }
    }

    const navClick = (route) => {
        if (props?.menuClick) {
            props?.menuClick();
        }
        navigate(route);
    };

    const leadLink = (route) => {
        let activeSpan = document.querySelector("span.active");
        if (activeSpan) {
            activeSpan.classList.remove("active");
        }
        setDropdown(dropdown === "Settings" ? null : "Settings");
        dispatch(setRoute(route));
    };

    useEffect(() => {
        if (reduxData?.heading?.value?.includes("Forms")) {
            setDropdown("Internal Forms");
            setSidebarOpen("Internal Forms");
        }
    }, [reduxData?.heading?.value]);

    const getFolderName = () => {
        if (reduxData?.folders?.folderStructure?.length) {
            let name = reduxData?.folders?.folderStructure[0]?.name;
            return name;
        }
    };

    return (
        <ProSidebarProvider>
            <Sidebar>
                <Menu>
                    <MenuItem
                        onClick={(e) => {
                            handleActive(e, "Dashboard");
                        }}
                        routerLink={<div onClick={() => navClick("/")} />}
                    >
                        <img src={dashboard} alt="dashboard" /> Dashboard
                    </MenuItem>

                    <MenuItem
                        onClick={(e) => {
                            handleActive(e, "Users");
                        }}
                        routerLink={<div onClick={() => navClick("/users")} />}
                    >
                        <img src={users} alt="Users" /> Users
                    </MenuItem>

                    <SubMenu
                        icon={<img src={template} alt="leads" />}
                        className="leads"
                        label="Leads"
                        onClick={() => leadLink("Leads")}
                    >
                        <MenuItem
                            onClick={(e) => {
                                handleActive(e, "Leads");
                            }}
                            routerLink={
                                <Link
                                    onClick={props.menuClick}
                                    to="/leads/all"
                                />
                            }
                        >
                            All Leads
                        </MenuItem>
                        <MenuItem
                            onClick={(e) => {
                                handleActive(e, "Leads");
                            }}
                            routerLink={
                                <Link
                                    onClick={props.menuClick}
                                    to="/leads/assigned"
                                />
                            }
                        >
                            Assigned Leads
                        </MenuItem>

                        <MenuItem
                            onClick={(e) => {
                                handleActive(e, "Leads");
                            }}
                            routerLink={
                                <Link
                                    onClick={props.menuClick}
                                    to="/leads/unassigned"
                                />
                            }
                        >
                            Unassigned Leads
                        </MenuItem>
                    </SubMenu>

                    <SubMenu
                        icon={<img src={template} alt="setting" />}
                        className="settings"
                        label="Case"
                        open={sidebarOpen === "Cases"}
                        onClick={() => {
                            let activeSpan =
                                document.querySelector("span.active");
                            if (activeSpan) {
                                activeSpan.classList.remove("active");
                            }
                            setSidebarOpen(
                                sidebarOpen === "Cases" ? null : "Cases"
                            );
                            dispatch(setRoute("Cases"));
                        }}
                    >
                        <MenuItem
                            onClick={(e) => {
                                handleActive(e, "Cases");
                            }}
                            routerLink={
                                <Link
                                    onClick={props.menuClick}
                                    to="/all-cases"
                                />
                            }
                        >
                            All Case
                        </MenuItem>
                        <MenuItem
                            onClick={(e) => {
                                handleActive(e, "Cases");
                            }}
                            routerLink={
                                <Link
                                    onClick={props.menuClick}
                                    to="/verified-cases"
                                />
                            }
                        >
                            Case Verified
                        </MenuItem>
                        <MenuItem
                            onClick={(e) => {
                                handleActive(e, "Cases");
                            }}
                            routerLink={
                                <Link
                                    onClick={props.menuClick}
                                    to="/unverified-cases"
                                />
                            }
                        >
                            Case Need to Verified
                        </MenuItem>
                    </SubMenu>

                    <MenuItem
                        onClick={(e) => {
                            handleActive(e, "Entities");
                        }}
                        routerLink={
                            <Link onClick={props.menuClick} to="/entities" />
                        }
                    >
                        <img src={entity} alt="Entities" /> Entities
                    </MenuItem>

                    <MenuItem
                        onClick={(e) => {
                            handleActive(e, "Team");
                        }}
                        routerLink={<div onClick={() => navClick("/team")} />}
                    >
                        <img src={team} alt="Team" /> Team
                    </MenuItem>
                    <MenuItem
                        onClick={(e) => {
                            handleActive(e, "Product Form");
                        }}
                        routerLink={
                            <div onClick={() => navClick("/product-form")} />
                        }
                    >
                        <img src={product} alt="Form Template" /> Product Form
                    </MenuItem>

                    <SubMenu
                        icon={<img src={template} alt="setting" />}
                        className="settings"
                        label="Internal Forms"
                        open={sidebarOpen === "Internal Forms"}
                        onClick={() => {
                            let activeSpan =
                                document.querySelector("span.active");
                            if (activeSpan) {
                                activeSpan.classList.remove("active");
                            }
                            setSidebarOpen(
                                sidebarOpen === "Internal Forms"
                                    ? null
                                    : "Internal Forms"
                            );
                            dispatch(setRoute("Forms"));
                        }}
                    >
                        <MenuItem
                            onClick={(e) => {
                                handleActive(e, "Forms");
                            }}
                            routerLink={
                                <Link
                                    onClick={props.menuClick}
                                    to="/internal-form"
                                />
                            }
                        >
                            Internal Form
                        </MenuItem>
                        <MenuItem
                            onClick={(e) => {
                                handleActive(e, "Forms");
                            }}
                            routerLink={
                                <Link
                                    onClick={props.menuClick}
                                    to="/form-template"
                                />
                            }
                        >
                            Form Template
                        </MenuItem>
                    </SubMenu>

                    <MenuItem
                        onClick={(e) => {
                            handleActive(e, "Reports");
                        }}
                        routerLink={<div onClick={() => navClick("/report")} />}
                    >
                        <img src={report} alt="Reports" /> Reports
                    </MenuItem>

                    <MenuItem
                        onClick={(e) => {
                            handleActive(e, "PDPA");
                        }}
                        routerLink={
                            <div onClick={() => navClick("/announcements")} />
                        }
                    >
                        <img src={announcement} alt="PDPA" />
                        PDPA
                    </MenuItem>

                    <MenuItem
                        onClick={(e) => {
                            handleActive(e, "Banks");
                        }}
                        routerLink={
                            <div onClick={() => navClick("/bank-list")} />
                        }
                    >
                        <img src={banklist} alt="Bank List" /> Bank List
                    </MenuItem>
                    <MenuItem
                        onClick={(e) => {
                            handleActive(e, "Scheduler");
                        }}
                        routerLink={
                            <div onClick={() => navClick("/schedule")} />
                        }
                    >
                        <img src={schedule} alt="Form Template" />
                        Scheduler
                    </MenuItem>
                    <MenuItem
                        onClick={(e) => {
                            handleActive(e, "Notification");
                        }}
                        routerLink={
                            <div onClick={() => navClick("/notification")} />
                        }
                    >
                        <img src={bell} alt="Notification" />
                        Notification
                    </MenuItem>

                    <SubMenu
                        icon={<img src={template} alt="setting" />}
                        className="settings"
                        label="Settings"
                        onClick={() => leadLink("Settings")}
                        open={dropdown === "Settings"}
                    >
                        <MenuItem
                            onClick={(e) => {
                                handleActive(e, "Auto Reminder");
                            }}
                            routerLink={
                                <Link
                                    onClick={props.menuClick}
                                    to="/auto-reminder"
                                />
                            }
                        >
                            Auto Reminder
                        </MenuItem>

                        <MenuItem
                            onClick={(e) => {
                                handleActive(e, "File Uploads");
                            }}
                            routerLink={
                                <Link
                                    onClick={props.menuClick}
                                    to="/upload-size"
                                />
                            }
                        >
                            File Uploads
                        </MenuItem>

                        <MenuItem
                            onClick={(e) => {
                                handleActive(e, "Date");
                            }}
                            routerLink={
                                <Link
                                    onClick={props.menuClick}
                                    to="/date-settings"
                                />
                            }
                        >
                            Date
                        </MenuItem>
                    </SubMenu>
                    <MenuItem
                        onClick={(e) => {
                            handleActive(e, "Activity Logs");
                        }}
                        routerLink={
                            <div onClick={() => navClick("/activity-log")} />
                        }
                    >
                        <img src={activityLog} alt="Activity Logs" />
                        Activity Logs
                    </MenuItem>
                    <MenuItem
                        onClick={(e) => {
                            handleActive(e, getFolderName());
                        }}
                        routerLink={
                            <div
                                onClick={() => navClick("/folder-structure")}
                            />
                        }
                    >
                        <img src={folderIcon} alt="Folder" />
                        {getFolderName()}
                    </MenuItem>
                </Menu>
            </Sidebar>
        </ProSidebarProvider>
    );
}
