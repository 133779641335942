import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import crossIcon from "../../assets/images/cross.svg";
import arrows from "../../assets/images/outline-arrow.svg";
import { api } from "../../helpers/apiHelper/requestHelper";
import { toastify } from "../../helpers/toast/toastify";
import { shortFormatDate } from "../../helpers/dataHelper/dateFormatter";
import fileIcon from "../../assets/images/file-icon.svg";

export const AnnouncementModal = (props) => {
    const data = useSelector((state) => state?.userAnnouncements?.value);
    const [ann, setAnn] = useState();

    const acknowledge = async () => {
        const response = await api("post", "announcements/acknowledge", {
            announcementId: ann._id,
        });
        props.setShow(false);
        if (response?.data?.status) {
            toastify("Acknowledged!", "success");
            props.getData();
        } else {
            toastify("Unable to acknowledge.", "error");
        }
    };

    useEffect(() => {
        if (data?.length > 0) {
            setAnn(data[0]);
        } else {
            props.setShow(false);
        }
    }, [data]);

    const handleNext = () => {
        let index = data?.findIndex((e) => e._id === ann._id);
        if (data[index + 1]) setAnn(data[index + 1]);
    };

    const handlePrev = () => {
        let index = data?.findIndex((e) => e._id === ann._id);
        if (data[index - 1]) setAnn(data[index - 1]);
    };

    return (
        <div className={`modal-wrapper`}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-body">
                        <img
                            src={crossIcon}
                            className="cross-btn"
                            onClick={() => props.setShow(false)}
                            alt="crossIcon"
                        />
                        <div className="modal-forms announcement-modal">
                            <h2>PDPA</h2>
                            <div className="flex title-wrap">
                                <h4>{ann?.title}</h4>
                                <section className="ml-auto flex column-direction">
                                    <span>
                                        <strong>Start Date: </strong>
                                        {shortFormatDate(ann?.fromDate)}
                                    </span>
                                    <span>
                                        <strong>End Date:</strong>
                                        {shortFormatDate(ann?.toDate)}
                                    </span>
                                </section>
                            </div>
                            <p>{ann?.description}</p>
                            {ann?.attachments?.length > 0 &&
                                ann?.attachments?.map((att) => (
                                    <div
                                        className="cases-doc-list "
                                        style={{ width: "100%", padding: 0 }}
                                    >
                                        <ul
                                            className="p-0 m-0 mb-0"
                                            onClick={() => {
                                                window.open(att.url);
                                            }}
                                        >
                                            <div className="flex column-direction case_input_des ">
                                                <li className="p-0">
                                                    <span
                                                        style={{
                                                            padding:
                                                                "20px 40px 20px 20px",
                                                        }}
                                                    >
                                                        <img
                                                            src={fileIcon}
                                                            className="cursor-pointer"
                                                            alt="pdfIcon"
                                                        />
                                                        <div className="file_name cursor-pointer">
                                                            {att?.filename}
                                                        </div>
                                                    </span>
                                                </li>
                                            </div>
                                        </ul>
                                    </div>
                                ))}
                            <div className="announcement-button-wrap flex">
                                {data?.length !== 1 ? (
                                    <img
                                        src={arrows}
                                        className="prevIcon"
                                        alt="prevIcon"
                                        onClick={handlePrev}
                                    />
                                ) : (
                                    <div></div>
                                )}
                                <button
                                    className="simple-btn"
                                    onClick={acknowledge}
                                >
                                    Acknowledge
                                </button>
                                {data?.length !== 1 ? (
                                    <img
                                        src={arrows}
                                        className="nextIcon"
                                        alt="nextIcon"
                                        onClick={handleNext}
                                    />
                                ) : (
                                    <div></div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
