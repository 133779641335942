export function formatCurrency(numberString, symbol) {
    // Parse the input string as a floating-point number
    const number = parseFloat(numberString);

    // Check if the parsing was successful
    if (isNaN(number)) {
        return "Invalid input";
    }

    const formattedNumber = symbol
        ? number
              .toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
              })
              
        : number.toLocaleString("en-US");

    return formattedNumber;
}
