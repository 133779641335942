import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import "./modals.scss";
import { useEffect } from "react";
import { toastify } from "../../helpers/toast/toastify";

export const DownloadDocsModal = (props) => {
    const [docs, setDocs] = useState([]);
    const reduxData = useSelector((state) => state);

    useEffect(() => {
        if (props?.case) {
            setDocs(
                props?.case?.caseDocuments
                    ?.filter((e) => e?.url)
                    ?.map((e) => {
                        return { ...e, selected: true };
                    })
            );
        }
    }, [props?.case]);

    const formatFilename = (filename, maxLength = 20) => {
        const [name, extension] = filename.split(".");
        const nameLength = maxLength - extension.length - 1; // Reserve one character for the dot before the extension
        if (name.length > nameLength) {
            const truncatedName = name.slice(0, nameLength - 3) + "..";
            const formattedFilename = `${truncatedName}.${extension}`;
            return formattedFilename;
        } else {
            return filename;
        }
    };

    return (
        <div className={`modal-wrapper inner_modal`}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-body ">
                        <div className="modal-forms">
                            <ul className="list-style-none">
                                <li>
                                    <div className="form-group">
                                        <label className="mb-20">
                                            {docs?.length > 0
                                                ? "Download All"
                                                : "No Documents Found!"}
                                        </label>
                                        {docs?.map((e) => (
                                            <div>
                                                <label
                                                    id={e._id}
                                                    className="check-box"
                                                >
                                                    <input
                                                        checked={
                                                            docs?.find(
                                                                (f) =>
                                                                    f._id ===
                                                                    e._id
                                                            )?.selected === true
                                                        }
                                                        type="checkbox"
                                                        name={e.name}
                                                        onChange={(v) => {
                                                            let _docs = [
                                                                ...docs,
                                                            ];
                                                            let docIndex =
                                                                _docs?.findIndex(
                                                                    (f) =>
                                                                        f._id ===
                                                                        e._id
                                                                );
                                                            _docs[docIndex][
                                                                "selected"
                                                            ] =
                                                                v.target.checked;
                                                            setDocs(_docs);
                                                        }}
                                                    />
                                                    <span className="no-text-decoration">
                                                        {formatFilename(e.name)}
                                                    </span>
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </li>

                                <li>
                                    <div className="flex content-justify-center">
                                        <button
                                            onClick={() => props.setShow(false)}
                                            className="modal-btn cancel"
                                        >
                                            Cancel
                                        </button>
                                        {docs?.length > 0 && (
                                            <>
                                                <button
                                                    onClick={() => {
                                                        if (
                                                            docs?.length === 0
                                                        ) {
                                                            toastify(
                                                                "Please select documents to download"
                                                            );
                                                        } else {
                                                            props.confirmAction(
                                                                docs?.filter(
                                                                    (e) =>
                                                                        e.selected
                                                                )
                                                            );
                                                        }
                                                    }}
                                                    className="modal-btn"
                                                >
                                                    Download
                                                </button>
                                                {/* <button
                                                    onClick={() => {
                                                        if (
                                                            docs?.length === 0
                                                        ) {
                                                            toastify(
                                                                "Please select documents to download"
                                                            );
                                                        } else {
                                                            props.confirmAction(
                                                                docs,
                                                                true
                                                            );
                                                        }
                                                    }}
                                                    className="modal-btn"
                                                >
                                                    Download in Email
                                                </button> */}
                                            </>
                                        )}
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
