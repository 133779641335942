import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authReducer from "../redux/auth/authSlice";
import routeReducer from "../redux/route/routeSlice";
import teamsReducer from "../redux/teams/teamsSlice";
import userReducer from "../redux/users/usersSlice";
import announcementReducer from "../redux/announcements/announcementSlice";
import formReducer from "../redux/internalForms/formsSlice";
import productReducer from "../redux/productForms/productsSlice";
import loadingReducer from "../redux/loader/loadingSlice";
import leadReducer from "../redux/leads/leadsSlice";
import caseReducer from "../redux/cases/casesSlice";
import bEntityReducer from "../redux/businessEntities/bEntitySlice";
import iEntityReducer from "../redux/individualEntities/iEntitySlice";
import rolesReducer from "../redux/roles/rolesSlice";
import banksReducer from "../redux/banks/banksSlice";
import scheduleReducer from "../redux/schedules/scheduleSlice";
import notificationReducer from "../redux/notifications/notificationsSlice";
import foldersReducer from "../redux/folderStructure/folderStructureSlice";

import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";

const persistConfig = {
    key: "root",
    storage,
};

const rootReducer = combineReducers({
    auth: authReducer,
    heading: routeReducer,
    allTeams: teamsReducer,
    users: userReducer,
    userAnnouncements: announcementReducer,
    internalForms: formReducer,
    productForms: productReducer,
    loading: loadingReducer,
    leads: leadReducer,
    cases: caseReducer,
    businessEntities: bEntityReducer,
    individualEntities: iEntityReducer,
    userRoles: rolesReducer,
    banks: banksReducer,
    schedules: scheduleReducer,
    userNotifications: notificationReducer,
    folders: foldersReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: [thunk],
});

export const persistor = persistStore(store);
