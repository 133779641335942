import React, { useEffect, useRef } from "react";
import "react-quill/dist/quill.snow.css";
import { useState } from "react";
import ReactQuill from "react-quill";
import { file } from "react-quill";
import "./leadDetailModal.scss";
import {
    formatDate,
    shortFormatDate,
} from "../../../../helpers/dataHelper/dateFormatter";
import { api } from "../../../../helpers/apiHelper/requestHelper";
import { toastify } from "../../../../helpers/toast/toastify";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../../redux/loader/loadingSlice";
import icon from "../../../../assets/images/check-doc-white.svg";
import { useSelector } from "react-redux";
import axios from "axios";
import fileIcon from "../../../../assets/images/file-icon.svg";
import del from "../../../../assets/images/cross_del.svg";

const config = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
});

export default function NotesInformation(props) {
    const [value, setValue] = useState(``);
    const editor = useRef(null);
    const dispatch = useDispatch();
    const reduxData = useSelector((state) => state);
    const [notes, setNotes] = useState([]);
    const [schedules, setSchedules] = useState([]);
    const [lead, setLead] = useState();
    const [attachments, setAttachments] = useState([]);
    const fileInputRef = useRef(null);

    var toolbarOptions = [
        "bold",
        "italic",
        "underline",
        "strike",
        "clean",
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
        { direction: "rtl" },
        { header: [1, 2, 3, 4, 5, 6, false] },
        { color: [] },
        { background: [] },
        { font: [] },
        { align: [] },
        "image",
    ];

    const uploadFile = async (file) => {
        dispatch(setLoading(true));
        try {
            let fd = new FormData();
            fd.append("file", file);
            fd.append("type", "Lead Notes");
            fd.append("lead", props?.lead?._id);
            let response = await config.post("auth/uploadFile", fd);
            dispatch(setLoading(false));

            if (response?.data?.status) {
                let url = response?.data?.data;
                return url;
            } else {
                toastify(response?.message);
            }
        } catch (error) {
            console.log({ error });
            toastify(error?.response?.data?.message);
        }
        dispatch(setLoading(false));
    };

    useEffect(() => {
        setLead(props.lead);
        if (props?.lead?.notes) {
            setNotes(props?.lead?.notes);
            // if (props?.lead?.leadType === "Corporate") {
            // } else {
            //     let _notes = props?.lead?.notes?.filter(
            //         (e) => e.user._id === reduxData?.auth?.user?._id
            //     );
            //     setNotes(_notes);
            // }
        }
    }, [props.lead]);

    const getSchedules = async () => {
        dispatch(setLoading(true));
        const response = await api("post", "schedules/schedulesByLead", {
            id: props?.lead?._id,
        });
        console.log({ response });
        dispatch(setLoading(false));
        if (response?.data?.status) {
            setSchedules(response?.data?.data);
        }
    };

    useEffect(() => {
        getSchedules();
    }, []);

    const addNote = async () => {
        dispatch(setLoading(true));
        const response = await api("post", "leads/addNote", {
            note: value,
            id: lead?._id,
            date: new Date(),
            attachments,
        });
        dispatch(setLoading(false));
        console.log({ response });
        if (response?.data?.status) {
            editor.current
                .getEditor()
                .deleteText(0, editor.current.getEditor().getLength());
            setLead(response?.data?.data);
            if (props?.lead?.leadType === "Corporate") {
                setNotes(response?.data?.data?.notes);
            } else {
                let _notes = response?.data?.data?.notes?.filter(
                    (e) => e.user._id === reduxData?.auth?.user?._id
                );
                setNotes(_notes);
            }
            // setNotes(response?.data?.data?.notes);
            setAttachments([]);
            props.getLeads();
            // props.setShow(false);
            toastify("Note added successfully.", "success");
        } else {
            toastify(response?.message);
        }
    };

    return (
        <div className="no-p-styling">
            <ReactQuill
                ref={editor}
                modules={{ toolbar: toolbarOptions }}
                theme="snow"
                value={value}
                onChange={setValue}
            />
            <input
                type="file"
                name={"quil-file"}
                id={"quil-file"}
                class="inputfile"
                ref={fileInputRef}
                onChange={async (e) => {
                    let file = e.target.files[0];
                    if (file) {
                        // const quillInstance = editor.current.getEditor();
                        // if (quillInstance) {
                        const url = await uploadFile(file);
                        let obj = { url, filename: file.name };
                        let _attachments = [...attachments];
                        _attachments.push(obj);
                        setAttachments(_attachments);
                        //     console.log({
                        //         quillInstance: quillInstance.getSelection(),
                        //     });
                        //     const linkText = url;
                        //     const linkUrl = url;
                        //     // const currentIndex =
                        //     //     quillInstance.getSelection().index;

                        //     const index =
                        //         quillInstance.getSelection(true)?.length ||
                        //         quillInstance.getLength();
                        //     quillInstance.insertText(
                        //         index,
                        //         linkText,
                        //         "link",
                        //         linkUrl
                        //     );
                        //     quillInstance.setSelection(index + linkText.length);
                        // } else {
                        //     console.log("else");
                        // }
                    }
                }}
            />

            <label className="quill-file-attachment" for={"quil-file"}>
                Attach a file
            </label>

            {attachments.length > 0 && (
                <div className="cases-doc-list " style={{ padding: 0 }}>
                    <ul className="p-0 m-0 mb-0">
                        <div className="flex column-direction case_input_des ">
                            {attachments?.map((e) => {
                                return (
                                    <li className="p-0 mb-0 mt-20">
                                        <span
                                            style={{
                                                padding: "20px 40px 20px 20px",
                                            }}
                                        >
                                            <img
                                                src={fileIcon}
                                                className="cursor-pointer"
                                                alt="pdfIcon"
                                                onClick={() => {
                                                    window.open(e.url);
                                                }}
                                            />
                                            <div
                                                className="file_name cursor-pointer"
                                                onClick={() => {
                                                    window.open(e.url);
                                                }}
                                            >
                                                {e?.filename}
                                            </div>
                                            <div className="action_button_wrap">
                                                <img
                                                    className="cursor-pointer"
                                                    src={del}
                                                    alt="del"
                                                    onClick={() => {
                                                        let _attachments = [
                                                            ...attachments,
                                                        ];
                                                        let newAttachments =
                                                            _attachments.filter(
                                                                (f) =>
                                                                    f.url !==
                                                                    e.url
                                                            );

                                                        setAttachments(
                                                            newAttachments
                                                        );
                                                        fileInputRef.current.value =
                                                            null;
                                                    }}
                                                />
                                            </div>
                                        </span>
                                    </li>
                                );
                            })}
                        </div>
                    </ul>
                </div>
            )}

            <button className="simple-btn mt-20 mb-20" onClick={addNote}>
                Comment
            </button>

            <section className="latest-comment w-100">
                <h3 className="gold-font">Latest Comment</h3>
                <ul className="list-style-none flex column-direction">
                    {schedules?.map((data, key) => (
                        <li key={key}>
                            <div>
                                <small>
                                    <div>SCHEDULE</div>
                                    <div>Topic: {data?.title}</div>
                                    <div>
                                        Date, Start & End Time:{" "}
                                        {shortFormatDate(data?.date)} from{" "}
                                        {data?.startTime} to {data?.endTime}
                                    </div>
                                    <div>
                                        Participants:{" "}
                                        {data?.participants?.map((p, i) => (
                                            <text>
                                                {p?.user?.username}
                                                {i !==
                                                    data?.participants?.length -
                                                        1 && ", "}
                                            </text>
                                        ))}
                                    </div>
                                    <div>Remarks: {data?.remarks}</div>
                                </small>
                            </div>
                        </li>
                    ))}
                    {notes?.map((data, key) => (
                        <li key={key}>
                            <div className="set-image-size">
                                <h6>
                                    {data?.user &&
                                        ` ${
                                            data?.user?.username || data?.user
                                        }`}
                                </h6>

                                {/* <span
                                        dangerouslySetInnerHTML={{
                                            __html: sanitizedHTML,
                                        }}
                                    /> */}
                                <ReactQuill
                                    theme="bubble"
                                    value={data.html}
                                    readOnly={true}
                                />
                                <div
                                    className="cases-doc-list list-inner-comment"
                                    style={{ padding: 0 }}
                                >
                                    <ul className="p-0 m-0 mb-0">
                                        {data.attachments?.length > 0 &&
                                            data.attachments?.map((e) => (
                                                <li
                                                    onClick={() => {
                                                        window.open(e.url);
                                                    }}
                                                    className="cursor-pointer"
                                                >
                                                    <span
                                                        style={{
                                                            padding:
                                                                "20px 40px 20px 20px",
                                                        }}
                                                    >
                                                        <img
                                                            src={fileIcon}
                                                            alt="pdfIcon"
                                                        />
                                                        <div className="file_name">
                                                            {e?.filename}
                                                        </div>
                                                    </span>
                                                </li>
                                            ))}
                                    </ul>
                                </div>
                                <small>
                                    {formatDate(new Date(data?.date))}
                                </small>
                            </div>
                        </li>
                    ))}
                </ul>
            </section>
        </div>
    );
}
