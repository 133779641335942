import {
    Route,
    createBrowserRouter,
    createRoutesFromElements,
    RouterProvider,
} from "react-router-dom";
import { Login } from "../pages/admin/login/Login";
import { ErrorPage } from "../components/ErrorPage";
import { LoginLayout } from "../layout/LoginLayout";
import { ForgotPassword } from "../pages/admin/login/ForgotPassword";
import { AdminLayout } from "../layout/admin-layout/AdminLayout";
import DashBoard from "../pages/admin/dashboard/DashBoard";
import Users from "../pages/admin/users/Users";
import EditUserDetails from "../pages/admin/users/edit-user-details/EditUserDetails";
import Team from "../pages/admin/team/Team";
import Announcement from "../pages/admin/announcement/Announcement";
import AnnouncementDetail from "../pages/admin/announcement/announcement-detail/AnnouncementDetail";
import AddAnnouncement from "../pages/admin/announcement/add-announcement/AddAnnouncement";
import BankList from "../pages/admin/bank/BankList";
import AddBank from "../pages/admin/bank/AddBank";
import ProductForm from "../pages/admin/settings/product-form/ProductForm";
import InternalForm from "../pages/admin/settings/internal-form/InternalForm";
import FormTemplate from "../pages/admin/form-template/FormTemplate";
import OnlineForm from "../pages/admin/form-template/OnlineForm";
import { RmLeadLayout } from "../layout/rm-lead-layout/RmLeadLayout";
import RmLeadDashboard from "../pages/rm-lead/rm-dashboard/RmDashboard";
import RmMemberDashboard from "../pages/rm-member/rm-dashboard/RmDashboard";
import LeadCoordinatorDashboard from "../pages/lead-coordinator/rm-dashboard/LeadCoordinatorDashboard";
import CaseManagerDashboard from "../pages/case-manager/cm-dashboard/CaseManagerDashboard";
import AllLeads from "../pages/rm-lead/leads/all-leads/AllLeads";
import AdminLeads from "../pages/admin/leads/all-leads/AllLeads";
import CaseManagerLeads from "../pages/case-manager/leads/all-leads/AllLeads";
import AddLeads from "../pages/rm-lead/leads/add-leads/AddLeads";
import AdminAddLeads from "../pages/admin/leads/add-leads/AddLeads";
import AddCMLeads from "../pages/case-manager/leads/add-leads/AddLeads";
import AddIndividualEntity from "../pages/rm-lead/leads/entity/AddIndividualEntity";
import AdminAddIndividualEntity from "../pages/admin/leads/entity/AddIndividualEntity";
import AddCMIndividualEntity from "../pages/case-manager/leads/entity/AddIndividualEntity";
import AddBusinessEntity from "../pages/rm-lead/leads/entity/AddBusinessEntity";
import AdminAddBusinessEntity from "../pages/admin/leads/entity/AddBusinessEntity";
import AddCMBusinessEntity from "../pages/case-manager/leads/entity/AddBusinessEntity";
import AllEntity from "../pages/rm-lead/leads/entity/AllEntity";
import AdminAllEntity from "../pages/admin/leads/entity/AllEntity";
import CMEntity from "../pages/case-manager/leads/entity/AllEntity";
import AllCases from "../pages/rm-lead/cases/all-cases/AllCases";
import EditCase from "../pages/rm-lead/cases/all-cases/EditCase";
import EditCMCase from "../pages/case-manager/all-cases/EditCase";
import EditAdminCase from "../pages/admin/all-cases/EditCase";
import RMTeam from "../pages/rm-lead/team/RMTeam";
import Leads from "../pages/rm-lead/leads/Leads";
import CmAllCases from "../pages/case-manager/all-cases/AllCases";
import AdminAllCases from "../pages/admin/all-cases/AllCases";
import Schedule from "../pages/scheduler/schedule/Schedule";
import AddSchedule from "../pages/scheduler/schedule/AddSchedule";
import Notification from "../pages/scheduler/notification/Notification";
import AddNotification from "../pages/scheduler/notification/AddNotification";
import ActivityLog from "../pages/scheduler/ActivityLog";
import AnnouncementList from "../pages/scheduler/AnnouncementList";
import ProductList from "../pages/scheduler/ProductList";
import AnnouncementDetails from "../pages/scheduler/AnnouncementDetails";
import Reports from "../pages/lead-coordinator/reports/Reports.js";
import FormRenderer from "../pages/admin/form-template/FormRenderer";
import AutoReminder from "../pages/admin/autoReminder/AutoReminder";
import UploadSize from "../pages/admin/uploadSize/UploadSize";
import FolderStructure from "../pages/shared/FolderStructure";
import DateSettings from "../pages/admin/dateSettings/DateSettings.js";

export const MainRouting = () => {
    const router = createBrowserRouter(
        createRoutesFromElements(
            <>
                <Route path="/form/:formId/:caseId" element={<LoginLayout />}>
                    <Route index element={<FormRenderer />}></Route>
                </Route>
                <Route
                    path="/"
                    errorElement={<ErrorPage />}
                    element={<AdminLayout />}
                >
                    <Route index element={<DashBoard />}></Route>

                    <Route path="/users" element={<Users />}></Route>
                    <Route
                        path="/edit-user-details"
                        element={<EditUserDetails />}
                    ></Route>
                    <Route path="/team" element={<Team />}></Route>
                    <Route
                        path="/announcements"
                        element={<Announcement />}
                    ></Route>
                    <Route
                        path="/announcement-details"
                        element={<AnnouncementDetail />}
                    ></Route>
                    <Route
                        path="/add-announcement"
                        element={<AddAnnouncement />}
                    ></Route>
                    <Route path="/bank-list" element={<BankList />}></Route>
                    <Route path="/add-bank" element={<AddBank />}></Route>
                    <Route
                        path="/product-form"
                        element={<ProductForm />}
                    ></Route>
                    <Route
                        path="/internal-form"
                        element={<InternalForm />}
                    ></Route>
                    <Route path="/report" element={<Reports />}></Route>
                    <Route
                        path="/form-template"
                        element={<FormTemplate />}
                    ></Route>
                    <Route path="/online-form" element={<OnlineForm />}></Route>
                    <Route path="/upload-size" element={<UploadSize />}></Route>
                    <Route
                        path="/auto-reminder"
                        element={<AutoReminder />}
                    ></Route>
                    <Route
                        path="/all-cases"
                        element={<AdminAllCases />}
                    ></Route>
                    <Route
                        path="/verified-cases"
                        element={<AdminAllCases />}
                    ></Route>
                    <Route
                        path="/unverified-cases"
                        element={<AdminAllCases />}
                    ></Route>
                    <Route
                        path="/edit-case"
                        element={<EditAdminCase />}
                    ></Route>
                    <Route path="/leads" element={<Leads />}></Route>
                    <Route path="/leads/all" element={<AdminLeads />}></Route>
                    <Route
                        path="/leads/assigned"
                        element={<AdminLeads />}
                    ></Route>
                    <Route
                        path="/leads/reassigned"
                        element={<AdminLeads />}
                    ></Route>
                    <Route
                        path="/leads/unassigned"
                        element={<AdminLeads />}
                    ></Route>
                    <Route
                        path="/add-leads"
                        element={<AdminAddLeads />}
                    ></Route>
                    <Route
                        path="/entities"
                        element={<AdminAllEntity />}
                    ></Route>
                    <Route
                        path="/individual-entity"
                        element={<AdminAddIndividualEntity />}
                    ></Route>
                    <Route
                        path="/business-entity"
                        element={<AdminAddBusinessEntity />}
                    ></Route>
                    <Route
                        path="/date-settings"
                        element={<DateSettings />}
                    ></Route>
                </Route>
                <Route path="/login" element={<LoginLayout />}>
                    <Route index element={<Login />}></Route>
                </Route>
                <Route path="/forgot-password" element={<LoginLayout />}>
                    <Route index element={<ForgotPassword />}></Route>
                </Route>

                {/* RM LEAD ROUTES */}
                <Route
                    path="/rm-lead"
                    errorElement={<ErrorPage />}
                    element={<RmLeadLayout />}
                >
                    <Route index element={<RmLeadDashboard />}></Route>
                    <Route path="/rm-lead/leads" element={<Leads />}></Route>
                    <Route path="/rm-lead/report" element={<Reports />}></Route>
                    <Route
                        path="/rm-lead/leads/all"
                        element={<AllLeads />}
                    ></Route>
                    <Route
                        path="/rm-lead/leads/assigned"
                        element={<AllLeads />}
                    ></Route>
                    <Route
                        path="/rm-lead/leads/reassigned"
                        element={<AllLeads />}
                    ></Route>
                    <Route
                        path="/rm-lead/leads/unassigned"
                        element={<AllLeads />}
                    ></Route>
                    <Route
                        path="/rm-lead/leads/own"
                        element={<AllLeads />}
                    ></Route>
                    <Route
                        path="/rm-lead/add-leads"
                        element={<AddLeads />}
                    ></Route>
                    <Route
                        path="/rm-lead/entities"
                        element={<AllEntity />}
                    ></Route>
                    <Route
                        path="/rm-lead/individual-entity"
                        element={<AddIndividualEntity />}
                    ></Route>
                    <Route
                        path="/rm-lead/business-entity"
                        element={<AddBusinessEntity />}
                    ></Route>
                    <Route
                        path="/rm-lead/all-cases"
                        element={<AllCases />}
                    ></Route>
                    <Route
                        path="/rm-lead/all-cases/:caseId"
                        element={<AllCases />}
                    ></Route>
                    <Route
                        path="/rm-lead/pending-cases"
                        element={<AllCases />}
                    ></Route>
                    <Route
                        path="/rm-lead/rejected-cases"
                        element={<AllCases />}
                    ></Route>
                    <Route
                        path="/rm-lead/processing-cases"
                        element={<AllCases />}
                    ></Route>
                    <Route
                        path="/rm-lead/approved-cases"
                        element={<AllCases />}
                    ></Route>
                    <Route
                        path="/rm-lead/draft-cases"
                        element={<AllCases />}
                    ></Route>
                    <Route
                        path="/rm-lead/completed-cases"
                        element={<AllCases />}
                    ></Route>
                    <Route
                        path="/rm-lead/submitted-cases"
                        element={<AllCases />}
                    ></Route>
                    <Route
                        path="/rm-lead/edit-case"
                        element={<EditCase />}
                    ></Route>
                    <Route path="/rm-lead/team" element={<RMTeam />}></Route>
                </Route>

                {/* LEAD COORDINATOR ROUTES */}
                <Route
                    path="/lead-coordinator"
                    errorElement={<ErrorPage />}
                    element={<RmLeadLayout />}
                >
                    <Route index element={<LeadCoordinatorDashboard />}></Route>
                    <Route
                        path="/lead-coordinator/report"
                        element={<Reports />}
                    ></Route>
                    <Route
                        path="/lead-coordinator/leads"
                        element={<Leads />}
                    ></Route>
                    <Route
                        path="/lead-coordinator/leads/all"
                        element={<AllLeads />}
                    ></Route>
                    <Route
                        path="/lead-coordinator/leads/assigned"
                        element={<AllLeads />}
                    ></Route>
                    <Route
                        path="/lead-coordinator/leads/reassigned"
                        element={<AllLeads />}
                    ></Route>
                    <Route
                        path="/lead-coordinator/leads/unassigned"
                        element={<AllLeads />}
                    ></Route>
                    <Route
                        path="/lead-coordinator/add-leads"
                        element={<AddLeads />}
                    ></Route>
                    <Route
                        path="/lead-coordinator/entities"
                        element={<AllEntity />}
                    ></Route>
                    <Route
                        path="/lead-coordinator/individual-entity"
                        element={<AddIndividualEntity />}
                    ></Route>
                    <Route
                        path="/lead-coordinator/business-entity"
                        element={<AddBusinessEntity />}
                    ></Route>
                    <Route
                        path="/lead-coordinator/all-cases"
                        element={<AllCases />}
                    ></Route>
                    <Route
                        path="/lead-coordinator/all-cases/:caseId"
                        element={<AllCases />}
                    ></Route>
                    <Route
                        path="/lead-coordinator/pending-cases"
                        element={<AllCases />}
                    ></Route>
                    <Route
                        path="/lead-coordinator/rejected-cases"
                        element={<AllCases />}
                    ></Route>
                    <Route
                        path="/lead-coordinator/processing-cases"
                        element={<AllCases />}
                    ></Route>
                    <Route
                        path="/lead-coordinator/approved-cases"
                        element={<AllCases />}
                    ></Route>
                    <Route
                        path="/lead-coordinator/draft-cases"
                        element={<AllCases />}
                    ></Route>
                    <Route
                        path="/lead-coordinator/edit-case"
                        element={<EditCase />}
                    ></Route>
                    <Route
                        path="/lead-coordinator/team"
                        element={<RMTeam />}
                    ></Route>
                </Route>

                {/* RM MEMBER ROUTES */}
                <Route
                    path="/rm-member"
                    errorElement={<ErrorPage />}
                    element={<RmLeadLayout />}
                >
                    <Route index element={<RmMemberDashboard />}></Route>
                    <Route path="/rm-member/leads" element={<Leads />}></Route>
                    <Route
                        path="/rm-member/leads/all"
                        element={<AllLeads />}
                    ></Route>
                    <Route
                        path="/rm-member/report"
                        element={<Reports />}
                    ></Route>
                    <Route
                        path="/rm-member/leads/assigned"
                        element={<AllLeads />}
                    ></Route>
                    <Route
                        path="/rm-member/leads/reassigned"
                        element={<AllLeads />}
                    ></Route>
                    <Route
                        path="/rm-member/leads/unassigned"
                        element={<AllLeads />}
                    ></Route>
                    <Route
                        path="/rm-member/add-leads"
                        element={<AddLeads />}
                    ></Route>
                    <Route
                        path="/rm-member/entities"
                        element={<AllEntity />}
                    ></Route>
                    <Route
                        path="/rm-member/individual-entity"
                        element={<AddIndividualEntity />}
                    ></Route>
                    <Route
                        path="/rm-member/business-entity"
                        element={<AddBusinessEntity />}
                    ></Route>
                    <Route
                        path="/rm-member/all-cases"
                        element={<AllCases />}
                    ></Route>
                    <Route
                        path="/rm-member/all-cases/:caseId"
                        element={<AllCases />}
                    ></Route>
                    <Route
                        path="/rm-member/pending-cases"
                        element={<AllCases />}
                    ></Route>
                    <Route
                        path="/rm-member/rejected-cases"
                        element={<AllCases />}
                    ></Route>
                    <Route
                        path="/rm-member/processing-cases"
                        element={<AllCases />}
                    ></Route>
                    <Route
                        path="/rm-member/approved-cases"
                        element={<AllCases />}
                    ></Route>
                    <Route
                        path="/rm-member/draft-cases"
                        element={<AllCases />}
                    ></Route>
                    <Route
                        path="/rm-member/completed-cases"
                        element={<AllCases />}
                    ></Route>
                    <Route
                        path="/rm-member/submitted-cases"
                        element={<AllCases />}
                    ></Route>
                    <Route
                        path="/rm-member/edit-case"
                        element={<EditCase />}
                    ></Route>
                    <Route path="/rm-member/team" element={<RMTeam />}></Route>
                </Route>

                {/* CAS MANAGER ROUTES */}
                <Route
                    path="/case-manager"
                    errorElement={<ErrorPage />}
                    element={<RmLeadLayout />}
                >
                    <Route index element={<CaseManagerDashboard />}></Route>
                    <Route
                        path="/case-manager/report"
                        element={<Reports />}
                    ></Route>
                    <Route
                        path="/case-manager/all-cases/:caseId"
                        element={<CmAllCases />}
                    ></Route>
                    <Route
                        path="/case-manager/all-cases"
                        element={<CmAllCases />}
                    ></Route>
                    <Route
                        path="/case-manager/verified-cases"
                        element={<CmAllCases />}
                    ></Route>
                    <Route
                        path="/case-manager/unverified-cases"
                        element={<CmAllCases />}
                    ></Route>
                    <Route
                        path="/case-manager/edit-case"
                        element={<EditCMCase />}
                    ></Route>
                    <Route
                        path="/case-manager/leads/all"
                        element={<CaseManagerLeads />}
                    ></Route>
                    <Route
                        path="/case-manager/leads/assigned"
                        element={<CaseManagerLeads />}
                    ></Route>
                    <Route
                        path="/case-manager/leads/reassigned"
                        element={<CaseManagerLeads />}
                    ></Route>
                    <Route
                        path="/case-manager/add-leads"
                        element={<AddCMLeads />}
                    ></Route>
                    <Route
                        path="/case-manager/leads/unassigned"
                        element={<CaseManagerLeads />}
                    ></Route>
                    <Route
                        path="/case-manager/entities"
                        element={<CMEntity />}
                    ></Route>
                    <Route
                        path="/case-manager/individual-entity"
                        element={<AddCMIndividualEntity />}
                    ></Route>
                    <Route
                        path="/case-manager/business-entity"
                        element={<AddCMBusinessEntity />}
                    ></Route>
                </Route>

                <Route
                    path="/schedule"
                    errorElement={<ErrorPage />}
                    element={<RmLeadLayout />}
                >
                    <Route index element={<Schedule />}></Route>
                    <Route
                        path="/schedule/add-schedule"
                        element={<AddSchedule />}
                    ></Route>
                </Route>
                <Route
                    path="/notification"
                    errorElement={<ErrorPage />}
                    element={<RmLeadLayout />}
                >
                    <Route index element={<Notification />}></Route>
                    <Route
                        path="/notification/add-notification"
                        element={<AddNotification />}
                    ></Route>
                </Route>
                <Route
                    path="/activity-log"
                    errorElement={<ErrorPage />}
                    element={<RmLeadLayout />}
                >
                    <Route index element={<ActivityLog />}></Route>
                </Route>
                <Route
                    path="/announcements-list"
                    errorElement={<ErrorPage />}
                    element={<RmLeadLayout />}
                >
                    <Route index element={<AnnouncementList />}></Route>
                </Route>
                <Route
                    path="/announcements-details"
                    errorElement={<ErrorPage />}
                    element={<RmLeadLayout />}
                >
                    <Route index element={<AnnouncementDetails />}></Route>
                </Route>
                <Route
                    path="/folder-structure"
                    errorElement={<ErrorPage />}
                    element={<RmLeadLayout />}
                >
                    <Route index element={<FolderStructure />}></Route>
                </Route>

                <Route
                    path="/product-list"
                    errorElement={<ErrorPage />}
                    element={<RmLeadLayout />}
                >
                    <Route index element={<ProductList />}></Route>
                </Route>
            </>
        )
    );
    return <RouterProvider router={router} />;
};
