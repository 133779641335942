export const getTeam = (reduxTeams, teamId) => {
    return reduxTeams.find((e) => e._id === teamId);
};

export const checkTeamLead = (team, userId) => {
    if (team?.teamLead?._id === userId) {
        return true;
    }
    return false;
};
