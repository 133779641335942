// import { useEffect, useState } from "react";
// import arrowIcon from "../../assets/images/pagination-arrow.svg";
// import "./pagination.scss";

// export default function Pagination({ data, count, setPage, page }) {
//     const [list, setList] = useState([]);

//     useEffect(() => {
//         setList(new Array(Math.ceil(data?.length / count) || 1).fill(""));
//         setPage(1);
//     }, [data?.length]);
//     useEffect(() => {
//         setList(new Array(Math.ceil(data?.length / count) || 1).fill(""));
//         setPage(1);
//     }, [count]);

//     return (
//         <div className="pagination-wrapper flex ml-auto content-justify-end">
//             <ul className="list-style-none flex">
//                 <li>
//                     <button
//                         className="picon prev"
//                         onClick={() => {
//                             if (page > 1) setPage(page - 1);
//                         }}
//                     >
//                         <img src={arrowIcon} alt="prevIcon" />
//                     </button>
//                 </li>
//                 {list?.map((e, i) => (
//                     <li
//                         onClick={() => {
//                             setPage(i + 1);
//                         }}
//                     >
//                         <button className={page === i + 1 && "active"}>
//                             {i + 1}
//                         </button>
//                     </li>
//                 ))}
//                 {list.length === 0 && (
//                     <li>
//                         <button className={"active"}>{1}</button>
//                     </li>
//                 )}
//                 <li>
//                     <button
//                         className="picon next"
//                         onClick={() => {
//                             if (page < list?.length) setPage(page + 1);
//                         }}
//                     >
//                         <img src={arrowIcon} alt="prevIcon" />
//                     </button>
//                 </li>
//             </ul>
//         </div>
//     );
// }
import { useEffect, useState } from "react";
import arrowIcon from "../../assets/images/pagination-arrow.svg";
import "./pagination.scss";

export default function Pagination({ data, count, setPage, page }) {
    const [list, setList] = useState([]);

    useEffect(() => {
        setList(new Array(Math.ceil(data?.length / count) || 1).fill(""));
        setPage(1);
    }, [data?.length]);

    useEffect(() => {
        setList(new Array(Math.ceil(data?.length / count) || 1).fill(""));
        setPage(1);
    }, [count]);

    const renderPagination = () => {
        let renderedList = [];

        if (list.length <= 5) {
            renderedList = list.map((e, i) => (
                <li key={i}>
                    <button
                        className={page === i + 1 ? "active" : ""}
                        onClick={() => setPage(i + 1)}
                    >
                        {i + 1}
                    </button>
                </li>
            ));
        } else {
            if (page <= 3) {
                renderedList = list.slice(0, 5).map((e, i) => (
                    <li key={i}>
                        <button
                            className={page === i + 1 ? "active" : ""}
                            onClick={() => setPage(i + 1)}
                        >
                            {i + 1}
                        </button>
                    </li>
                ));
                if (list.length > 5) {
                    renderedList.push(
                        <li key="ellipsis-end">
                            <span>...</span>
                        </li>
                    );
                    renderedList.push(
                        <li key={list.length - 1}>
                            <button onClick={() => setPage(list.length)}>
                                {list.length}
                            </button>
                        </li>
                    );
                }
            } else if (page >= list.length - 2) {
                renderedList.push(
                    <li key={1}>
                        <button onClick={() => setPage(1)}>1</button>
                    </li>
                );
                if (list.length > 5) {
                    renderedList.push(
                        <li key="ellipsis-start">
                            <span>...</span>
                        </li>
                    );
                    renderedList = renderedList.concat(
                        list.slice(list.length - 5, list.length).map((e, i) => (
                            <li key={i + list.length - 5}>
                                <button
                                    className={
                                        page === i + list.length - 4
                                            ? "active"
                                            : ""
                                    }
                                    onClick={() => setPage(i + list.length - 4)}
                                >
                                    {i + list.length - 4}
                                </button>
                            </li>
                        ))
                    );
                }
            } else {
                renderedList.push(
                    <li key={1}>
                        <button onClick={() => setPage(1)}>1</button>
                    </li>
                );
                renderedList.push(
                    <li key="ellipsis-middle-start">
                        <span>...</span>
                    </li>
                );
                renderedList = renderedList.concat(
                    list.slice(page - 3, page + 2).map((e, i) => (
                        <li key={i + page - 3}>
                            <button
                                className={
                                    page === i + page - 2 ? "active" : ""
                                }
                                onClick={() => setPage(i + page - 2)}
                            >
                                {i + page - 2}
                            </button>
                        </li>
                    ))
                );
                if (list.length - page > 2) {
                    renderedList.push(
                        <li key="ellipsis-middle-end">
                            <span>...</span>
                        </li>
                    );
                    renderedList.push(
                        <li key={list.length}>
                            <button onClick={() => setPage(list.length)}>
                                {list.length}
                            </button>
                        </li>
                    );
                }
            }
        }

        return renderedList;
    };

    return (
        <div className="pagination-wrapper flex ml-auto content-justify-end">
            <ul className="list-style-none flex">
                <li>
                    <button
                        className="picon prev"
                        onClick={() => {
                            if (page > 1) setPage(page - 1);
                        }}
                    >
                        <img src={arrowIcon} alt="prevIcon" />
                    </button>
                </li>
                {renderPagination()}
                <li>
                    <button
                        className="picon next"
                        onClick={() => {
                            if (page < list?.length) setPage(page + 1);
                        }}
                    >
                        <img src={arrowIcon} alt="nextIcon" />
                    </button>
                </li>
            </ul>
        </div>
    );
}
