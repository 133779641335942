import {
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel,
} from "react-accessible-accordion";
import userIcon from "../../../../../assets/images/usericon.svg";
import leadIcon from "../../../../../assets/images/lead-icon.svg";
import add from "../../../../../assets/images/add-icon.svg";
import view from "../../../../../assets/images/view.svg";
import update from "../../../../../assets/images/update.svg";
import del from "../../../../../assets/images/del.svg";
import arrow from "../../../../../assets/images/border-arrow.svg";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { PENDING, PROCESSING } from "../../../../../config/leadStatus";
import { Link, useNavigate } from "react-router-dom";
import { AlertModal } from "../../../../../components/modals/AlertModal";
import { toastify } from "../../../../../helpers/toast/toastify";
import { setLoading } from "../../../../../redux/loader/loadingSlice";
import { api } from "../../../../../helpers/apiHelper/requestHelper";
import { useDispatch } from "react-redux";
// import LeadDetailsModal from "../../leads/lead-details-modal/LeadDetailsModal";
import { AddCase } from "../../../../../components/modals/AddCase";
import { storeLeads } from "../../../../../redux/leads/leadsSlice";
import LeadDetailsModal from "../../../../rm-lead/leads/lead-details-modal/LeadDetailsModal";
import { useEffect } from "react";
import { shortFormatDate } from "../../../../../helpers/dataHelper/dateFormatter";

export default function AllLeadAccordions(props) {
    const reduxData = useSelector((state) => state);
    const [openAddModal, setOpenAddModal] = useState(false);
    const [selectedLead, setSelectedLead] = useState();
    const [openDetailModal, setOpenDetailModal] = useState(false);
    const [show, setShow] = useState(false);
    const [lead, setLead] = useState();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userRoles = useSelector((state) => state.userRoles);

    const getSubStatus = (status) => {
        if (status.toLowerCase() === "pending") {
            return PENDING;
        } else if (status.toLowerCase() === "processing") {
            return PROCESSING;
        }
        return status;
    };

    useEffect(() => {
        getLeads();
    }, []);

    const getLeads = async () => {
        if (localStorage.getItem("token")) {
            let response = await api("post", "leads/index", {
                assignedFromRole: reduxData?.userRoles?.currentRole,
            });

            if (response?.status) {
                dispatch(storeLeads(response?.data?.data?.reverse()));
            } else {
                toastify(response?.message, "error");
            }
        }
    };

    const addCase = async (data) => {
        console.log(data);

        dispatch(setLoading(true));

        const response = await api("post", "leads/register", {
            leadId: selectedLead?._id,
            productId: data.product,
        });
        dispatch(setLoading(false));

        if (response?.data?.status) {
            setOpenAddModal(false);
            toastify("Case Added Successfully", "success");
            getLeads();
        } else {
            toastify(response?.message, "error");
        }
    };

    const deleteLead = async (data) => {
        setShow(true);
        setLead(data);
    };

    const confirmAction = async () => {
        dispatch(setLoading(true));
        const response = await api("post", "leads/update", {
            isDeleted: true,
            id: lead._id,
        });
        dispatch(setLoading(false));
        if (response?.data?.status) {
            getLeads();
            toastify("Lead deleted successfully", "success");
            setShow(false);
        } else {
            toastify(response?.message, "error");
        }
    };

    return (
        <>
            {show && (
                <AlertModal
                    confirmAction={confirmAction}
                    setModalClick={() => setShow(false)}
                    confirmBtn={true}
                    modalTitle={[
                        <h2>
                            Do you really want to delete{" "}
                            {lead?.identifier || "N/A"}
                            <br /> Lead permanently?
                        </h2>,
                    ]}
                    modalDescription={[]}
                />
            )}
            {openAddModal && (
                <AddCase
                    setModalClick={() => {
                        setOpenAddModal(!openAddModal);
                    }}
                    addCase={addCase}
                />
            )}
            {openDetailModal && (
                <LeadDetailsModal
                    setModalClick={() => {
                        setOpenDetailModal(!openDetailModal);
                    }}
                    setOpenDetailModal={() => {
                        setOpenDetailModal(!openDetailModal);
                    }}
                    lead={selectedLead}
                />
            )}
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        {props?.filter === "all"
                            ? "All "
                            : props?.filter === "assigned"
                            ? "Assigned "
                            : props?.filter === "reassigned"
                            ? "Reassigned "
                            : "Unassigned "}
                        Leads
                        {props.arrow === true && (
                            <Link className="flex" to={props.arrowLink}>
                                <img src={arrow} alt="arrow" />
                            </Link>
                        )}
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <div className="responsive-table accordion-list w-100">
                        <table className="table">
                            <thead>
                                <tr
                                    className="no-shadow"
                                    style={{ background: "#f6f6f6" }}
                                >
                                    <td colSpan="9" height="16"></td>
                                </tr>
                                <tr>
                                    <th>Created Date</th>
                                    <th>Customer Name</th>
                                    <th>Company Name</th>
                                    <th>Contact No.</th>
                                    <th>Assign From</th>
                                    <th>Assign To</th>
                                    <th>Product Name</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {reduxData?.leads?.value
                                    ?.filter((e) => {
                                        if (
                                            props.filter === "assigned" &&
                                            e.assignedTo
                                        ) {
                                            return e;
                                        } else if (
                                            props.filter === "unassigned" &&
                                            !e.assignedTo
                                        ) {
                                            return e;
                                        } else if (
                                            props.filter === "reassigned" &&
                                            e.reassigned
                                        ) {
                                            if (props.user) {
                                                if (
                                                    e?.assignedTo?._id ===
                                                    props.user
                                                ) {
                                                    return e;
                                                }
                                            } else {
                                                return e;
                                            }
                                        } else if (props.filter === "all") {
                                            if (props.user) {
                                                if (
                                                    e?.assignedTo?._id ===
                                                    props.user
                                                ) {
                                                    return e;
                                                }
                                            } else {
                                                return e;
                                            }
                                        }
                                    })
                                    ?.map((data, index) => {
                                        if (index < 5) {
                                            return (
                                                <>
                                                    <tr className="no-shadow">
                                                        <td
                                                            colSpan="9"
                                                            height="16"
                                                        ></td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            {shortFormatDate(
                                                                data?.created_at
                                                            )}
                                                        </td>

                                                        <td>
                                                            {data?.entityType ===
                                                            "Business"
                                                                ? data
                                                                      ?.businessEntity
                                                                      ?.representative
                                                                      ?.firstName +
                                                                  " " +
                                                                  data
                                                                      ?.businessEntity
                                                                      ?.representative
                                                                      ?.lastName
                                                                : data
                                                                      ?.individualEntity
                                                                      ?.firstName +
                                                                  " " +
                                                                  data
                                                                      ?.individualEntity
                                                                      ?.lastName}
                                                        </td>

                                                        <td>
                                                            {data
                                                                ?.businessEntity
                                                                ?.name || "N/A"}
                                                        </td>

                                                        <td>
                                                            <span className="number">
                                                                {data?.entityType ===
                                                                "Business"
                                                                    ? data
                                                                          ?.businessEntity
                                                                          ?.representative
                                                                          ?.contact
                                                                    : data
                                                                          ?.individualEntity
                                                                          ?.contact}
                                                            </span>
                                                        </td>

                                                        <td>
                                                            {data?.assignedFrom
                                                                ?.username ||
                                                                "N/A"}
                                                        </td>

                                                        <td>
                                                            {data?.assignedTo
                                                                ?.username ||
                                                                "N/A"}
                                                        </td>
                                                        <td>
                                                            {data?.cases?.map(
                                                                (e) => (
                                                                    <div>
                                                                        {
                                                                            e
                                                                                ?.product
                                                                                ?.name
                                                                        }
                                                                    </div>
                                                                )
                                                            )}
                                                        </td>
                                                        <td>
                                                            <span
                                                                className={`status ${data?.status
                                                                    ?.toLowerCase()
                                                                    ?.split(" ")
                                                                    .join(
                                                                        "-"
                                                                    )}`}
                                                            >
                                                                {data?.status}
                                                            </span>
                                                            <small>
                                                                {" "}
                                                                {getSubStatus(
                                                                    data?.status
                                                                )}
                                                            </small>
                                                        </td>
                                                        <td>
                                                            <div className="flex items-center">
                                                                <button
                                                                    className="table-action add"
                                                                    onClick={() => {
                                                                        setOpenAddModal(
                                                                            true
                                                                        );
                                                                        setSelectedLead(
                                                                            data
                                                                        );
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={
                                                                            add
                                                                        }
                                                                        alt="add"
                                                                    />
                                                                    <span>
                                                                        <small>
                                                                            Add
                                                                            Case
                                                                        </small>
                                                                    </span>
                                                                </button>
                                                                <button
                                                                    className="table-action view"
                                                                    onClick={() => {
                                                                        setSelectedLead(
                                                                            data
                                                                        );
                                                                        setOpenDetailModal(
                                                                            true
                                                                        );
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={
                                                                            view
                                                                        }
                                                                        alt="view"
                                                                    />
                                                                    <span>
                                                                        <small>
                                                                            View
                                                                        </small>
                                                                    </span>
                                                                </button>
                                                                <button
                                                                    className="table-action update cursor-pointer"
                                                                    onClick={() => {
                                                                        if (
                                                                            userRoles?.currentRole ===
                                                                            "RM Member"
                                                                        ) {
                                                                            navigate(
                                                                                "/rm-member/add-leads",
                                                                                {
                                                                                    state: {
                                                                                        lead: data,
                                                                                    },
                                                                                }
                                                                            );
                                                                        } else if (
                                                                            userRoles?.currentRole ===
                                                                            "RM Team Leader"
                                                                        ) {
                                                                            navigate(
                                                                                "/rm-lead/add-leads",
                                                                                {
                                                                                    state: {
                                                                                        lead: data,
                                                                                    },
                                                                                }
                                                                            );
                                                                        } else if (
                                                                            userRoles?.currentRole ===
                                                                            "Lead Coordinator"
                                                                        ) {
                                                                            navigate(
                                                                                "/lead-coordinator/add-leads",
                                                                                {
                                                                                    state: {
                                                                                        lead: data,
                                                                                    },
                                                                                }
                                                                            );
                                                                        }
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={
                                                                            update
                                                                        }
                                                                        alt="update"
                                                                    />
                                                                    <span>
                                                                        <small>
                                                                            Update
                                                                        </small>
                                                                    </span>
                                                                </button>
                                                                <button
                                                                    className="table-action del"
                                                                    onClick={() =>
                                                                        deleteLead(
                                                                            data
                                                                        )
                                                                    }
                                                                >
                                                                    <img
                                                                        src={
                                                                            del
                                                                        }
                                                                        alt="del"
                                                                    />
                                                                    <span>
                                                                        <small>
                                                                            Delete
                                                                        </small>
                                                                    </span>
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </>
                                            );
                                        }
                                    })}
                            </tbody>
                        </table>
                    </div>
                </AccordionItemPanel>
            </AccordionItem>
        </>
    );
}
