import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import arrow from "../../../assets/images/diraction-arrow.svg";
import Pagination from "../../../components/pagination/Pagination";
import { api } from "../../../helpers/apiHelper/requestHelper";
import { toastify } from "../../../helpers/toast/toastify";
import { setLoading } from "../../../redux/loader/loadingSlice";
import {
    selectTeam,
    storeTeams,
    storeUnassignedMembers,
} from "../../../redux/teams/teamsSlice";

export default function UnassignedMembers() {
    const teams = useSelector((state) => state.allTeams);
    const dispatch = useDispatch();
    const [count, setCount] = useState(10);
    const [page, setPage] = useState(1);

    const handleAssign = async (id) => {
        if (!teams?.selectedTeam) {
            toastify("Please select a team to assign");
        } else {
            dispatch(setLoading(true));
            const response = await api("post", "teams/assign", {
                userId: id,
                teamId: teams?.selectedTeam?._id,
            });
            dispatch(setLoading(false));

            if (response?.data?.status) {
                const res = await api("get", "teams/index");
                console.log({ res });
                if (res?.status) {
                    dispatch(storeTeams(res?.data));
                    dispatch(
                        storeUnassignedMembers(res?.data?.unassignedUsers)
                    );
                    dispatch(selectTeam(response?.data?.data));
                } else {
                    toastify(res?.message, "error");
                }
            } else {
                toastify(response?.message, "error");
            }
        }
    };

    return (
        <div className="responsive-table w-100">
            <table className="table">
                <thead>
                    <tr className="no-shadow" style={{ background: "#f6f6f6" }}>
                        <td colSpan="5" height="16"></td>
                    </tr>
                    <tr>
                        <th>Unassigned Members</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {teams?.unassignedMembers
                        ?.slice(10 * page - 10, 10 * page)
                        ?.map((data, index) => {
                            return (
                                <>
                                    <tr className="no-shadow">
                                        <td colSpan="5" height="16"></td>
                                    </tr>
                                    <tr
                                        key={`member-${index}`}
                                        className="cursor-pointer"
                                    >
                                        <td>{data?.username}</td>
                                        <td>
                                            <div className="flex items-center">
                                                <button
                                                    onClick={() =>
                                                        handleAssign(data?._id)
                                                    }
                                                    className="table-action update"
                                                >
                                                    <img
                                                        src={arrow}
                                                        alt="Assign"
                                                    />{" "}
                                                    <span>
                                                        <small>Assign</small>
                                                    </span>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </>
                            );
                        })}
                </tbody>
            </table>
            <Pagination
                count={count}
                data={teams?.unassignedMembers}
                setPage={setPage}
                page={page}
            />
        </div>
    );
}
