import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DatePicker from "react-date-picker";
import TimePicker from "react-time-picker";
import Multiselect from "multiselect-react-dropdown";
import { toastify } from "../../../helpers/toast/toastify";
import { api } from "../../../helpers/apiHelper/requestHelper";
import { missingPopup } from "../../../helpers/dataHelper/missingData";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../redux/loader/loadingSlice";
import { useSelector } from "react-redux";
import { storeLeads } from "../../../redux/leads/leadsSlice";

export default function AddSchedule() {
    const { state } = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const reduxData = useSelector((state) => state);

    const [type, setType] = useState("Meeting");
    const [date, setDate] = useState();
    const [participants, setParticipants] = useState([]);
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [remarks, setRemarks] = useState("");
    const [title, setTitle] = useState("");
    const [lead, setLead] = useState();
    const [users, setUsers] = useState([]);

    useEffect(() => {
        let data = state?.schedule;
        console.log({ data });

        setDate(data?.startDate);
        setParticipants(
            data?.participants?.map((e) => {
                return e.user;
            })
        );
        setStartTime(data?.startTime);
        setEndTime(data?.endTime);
        setRemarks(data?.remarks);
        setTitle(data?.text);
        setLead(data?.lead);
    }, [state?.schedule]);

    const handleSave = async () => {
        if (!title) missingPopup("Title");
        else if (!date) missingPopup("Date");
        else if (participants.length === 0) missingPopup("Participants");
        else if (!startTime) missingPopup("Start Time");
        else if (!endTime) missingPopup("End Time");
        else if (!remarks) missingPopup("Remarks");
        else {
            let data = {
                title,
                date,
                startTime,
                endTime,
                participants: participants?.map((e) => {
                    return { user: e._id, acknowledged: false };
                }),
                remarks,
                lead,
                type,
            };

            if (state?.schedule) {
                dispatch(setLoading(true));
                const response = await api("post", "schedules/update", {
                    ...data,
                    id: state?.schedule?._id,
                });
                dispatch(setLoading(false));
                if (response?.data?.status) {
                    toastify("Schedule Updated Successfully!", "success");
                    navigate("/schedule");
                } else {
                    toastify(response?.message);
                }
            } else {
                dispatch(setLoading(true));
                const response = await api("post", "schedules/create", data);

                dispatch(setLoading(false));
                if (response?.data?.status) {
                    toastify("Schedule Added Successfully!", "success");
                    navigate("/schedule");
                } else {
                    toastify(response?.message);
                }
            }
        }
    };

    const getUsers = async () => {
        let response = await api("get", "users/usersList");
        if (response?.status) {
            setUsers(response?.data?.reverse());
        } else {
            toastify(response?.message, "error");
        }
    };

    const getName = (ld) => {
        if (ld?.entityType === "Individual") {
            return `${ld?.individualEntity?.firstName} ${ld?.individualEntity?.lastName}`;
        } else {
            return `${ld?.businessEntity?.name}`;
        }
    };

    const getLeads = async () => {
        let response = await api("post", "leads/index", {
            assignedFromRole: reduxData?.userRoles?.currentRole,
        });
        if (response?.status) {
            dispatch(storeLeads(response?.data?.data?.reverse()));
        } else {
            toastify(response?.message, "error");
        }
    };

    useEffect(() => {
        getUsers();
        getLeads();
    }, []);

    return (
        <>
            <h4 className="flex flex-wrap mini-title">
                <span onClick={() => navigate("/schedule")}>Scheduler </span>/
                Add Schedule{" "}
            </h4>
            <div className="card">
                <div className="card-body">
                    {/* <h2 className="flex items-center section-title">
                        Choose a Type
                    </h2> */}
                    <div className="edit-form-wrapper">
                        <ul className="list-style-none flex flex-wrap">
                            <li>
                                <div className="form-group">
                                    <label htmlFor="">Topic / Title *</label>
                                    <input
                                        type="text"
                                        className="form-control mandatory"
                                        placeholder="Topic / Title"
                                        value={title}
                                        onChange={(e) =>
                                            setTitle(e.target.value)
                                        }
                                    />
                                </div>
                            </li>
                            <li>
                                <div className="form-group">
                                    <label htmlFor="">Date *</label>
                                    <DatePicker
                                        onChange={(e) => {
                                            setDate(e);
                                        }}
                                        format="dd/MM/y"
                                        value={date}
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                        className="form-control mandatory"
                                    />
                                </div>
                            </li>
                            <li>
                                <div className="form-group">
                                    <label htmlFor="">Participant *</label>
                                    <Multiselect
                                        className="mandatory-multi"
                                        showCheckbox
                                        options={users}
                                        selectedValues={participants}
                                        onSelect={setParticipants}
                                        onRemove={setParticipants}
                                        displayValue={"username"}
                                    />
                                </div>
                            </li>
                            <li>
                                <ul className="list-style-none flex time-wrapper">
                                    <li>
                                        <div className="form-group">
                                            <label htmlFor="">
                                                Start Time *
                                            </label>
                                            <TimePicker
                                                // disableClock
                                                className="form-control mandatory"
                                                clockIcon={false}
                                                hourPlaceholder="hh"
                                                minutePlaceholder="mm"
                                                value={startTime}
                                                onChange={(e) =>
                                                    setStartTime(e)
                                                }
                                            />
                                        </div>
                                    </li>
                                    <li>
                                        <div className="form-group">
                                            <label htmlFor="">End Time *</label>
                                            <TimePicker
                                                // disableClock
                                                clockIcon={false}
                                                className="form-control mandatory"
                                                hourPlaceholder="hh"
                                                secondPlaceholder="sad"
                                                minutePlaceholder="mm"
                                                value={endTime}
                                                onChange={(e) => setEndTime(e)}
                                            />
                                        </div>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <div className="form-group">
                                    <label htmlFor="">Remarks *</label>
                                    <textarea
                                        className="form-control mandatory"
                                        placeholder="Remarks"
                                        cols="30"
                                        rows="10"
                                        value={remarks}
                                        onChange={(e) =>
                                            setRemarks(e.target.value)
                                        }
                                    ></textarea>
                                </div>
                            </li>
                            <li>
                                <div className="form-group">
                                    <label htmlFor="">Lead</label>
                                    <select
                                        className="form-control"
                                        value={lead}
                                        onChange={(e) => {
                                            setLead(e.target.value);
                                        }}
                                    >
                                        <option
                                            value=""
                                            style={{ display: "none" }}
                                        >
                                            Select Lead
                                        </option>
                                        {reduxData?.leads?.value?.map((e) => (
                                            <option value={e._id}>
                                                {e.identifier} - {getName(e)}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="flex items-start content-justify-center">
                <button
                    className="simple-btn cancel"
                    onClick={() => navigate(-1)}
                >
                    Cancel
                </button>
                <button className="simple-btn" onClick={() => handleSave()}>
                    Save
                </button>
            </div>
        </>
    );
}
