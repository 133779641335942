import { useState } from "react";
import InputElements from "./InputElements";
import "./onlineForm.scss";
import formIcon from "../../../assets/images/formIcon.svg";
import { InputModal } from "./InputModal";
import { missingPopup } from "../../../helpers/dataHelper/missingData";
import { api } from "../../../helpers/apiHelper/requestHelper";
import { toastify } from "../../../helpers/toast/toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useRef } from "react";
import Papa from "papaparse";
import { setLoading } from "../../../redux/loader/loadingSlice";
import { useDispatch } from "react-redux";
import axios from "axios";

export default function OnlineForm() {
    const [formFields, setFormFields] = useState([]);
    const [show, setShow] = useState(false);
    const [title, setTitle] = useState("");
    const [type, setType] = useState("");
    const [formName, setFormName] = useState("");
    const [status, setStatus] = useState(true);
    const [description, setDescription] = useState("");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { state } = useLocation();
    const fileInputRef = useRef(null);

    const config = axios.create({
        baseURL: process.env.REACT_APP_API_BASE_URL,
    });

    const uploadFile = async (file, id) => {
        dispatch(setLoading(true));
        try {
            let fd = new FormData();
            fd.append("file", file);
            fd.append("type", "Form Template");
            if (state?.template) {
                fd.append("formTemplate", state?.template?._id);
            }
            let response = await config.post("auth/uploadFile", fd);

            if (response?.data?.status) {
                let url = response?.data?.data;
                if (url) {
                    const foundObjectIndex = formFields.findIndex(
                        (obj) => obj.id === id
                    );
                    const newArray = [...formFields];
                    if (foundObjectIndex !== -1) {
                        newArray[foundObjectIndex].filename = file.name;
                        newArray[foundObjectIndex].url = url;
                        setFormFields(newArray);
                    }
                } else {
                    toastify("Unable to upload document.");
                }
            } else {
                toastify(response?.message);
            }
        } catch (error) {
            console.log({ error });
            toastify(error?.response?.data?.message);
        }

        dispatch(setLoading(false));
    };

    const removeAttachment = (id) => {
        const foundObjectIndex = formFields.findIndex((obj) => obj.id === id);
        const newArray = [...formFields];
        if (foundObjectIndex !== -1) {
            newArray[foundObjectIndex].filename = "";
            newArray[foundObjectIndex].url = "";
            setFormFields(newArray);
        }
    };

    const handleExport = (data) => {
        const flattenArrayValues = (value) => {
            if (Array.isArray(value)) {
                return value.map((v) => flattenArrayValues(v));
            }
            return value;
        };

        const jsonObject = (data || formFields).map((obj) =>
            Object.keys(obj).reduce((acc, key) => {
                if (Array.isArray(obj[key])) {
                    acc[key] = obj[key].map((v) => flattenArrayValues(v));
                } else if (typeof obj[key] === "object" && obj[key] !== null) {
                    acc[key] = handleExport([obj[key]]);
                } else {
                    acc[key] = obj[key];
                }
                return acc;
            }, {})
        );

        // let newJson = JSON.stringify(jsonObject);

        // console.log({ csv: newJson });

        let unparsedJson = Papa.unparse(jsonObject);

        const blob = new Blob([unparsedJson], {
            type: "text/csv;charset=utf-8;",
        });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", "data.csv");
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleImport = (event) => {
        const file = event.target.files[0];
        let _parsedCsv;
        Papa.parse(file, {
            header: true,
            complete: function (results) {
                _parsedCsv = results.data.map((e) => {
                    return { ...e, options: e?.options?.split(",") };
                });
                console.log(results);
                setFormFields(_parsedCsv);
            },
            error: function (errors) {
                console.log({ errors });
            },
        });
    };

    const addFormField = (type, heading) => {
        setTitle(heading);
        setType(type);
        setShow(true);
    };

    const submit = async () => {
        if (!formName) missingPopup("Form Name");
        else if (formFields.length === 0) missingPopup("Form Data");
        else {
            let data = {
                name: formName,
                description,
                form: formFields,
                isActive: status,
            };
            console.log({ data });

            if (state?.template) {
                dispatch(setLoading(true));
                const response = await api("post", "templates/update", {
                    ...data,
                    id: state?.template?._id,
                });
                dispatch(setLoading(false));

                if (response?.data?.status) {
                    toastify("Form Template Updated Successfully!", "success");
                    navigate("/form-template");
                } else {
                    toastify(response?.message);
                }
            } else {
                dispatch(setLoading(true));
                const response = await api("post", "templates/create", data);
                dispatch(setLoading(false));

                if (response?.data?.status) {
                    toastify("Form Template Added Successfully!", "success");
                    navigate("/form-template");
                } else {
                    toastify(response?.message);
                }
            }
        }
    };

    const confirm = (data) => {
        const newField = {
            id: formFields.length,
            type,
            label: data?.label || "",
            placeholder: data?.placeholder || "",
            text: data?.text || "",
            value: "",
            options: data?.options,
        };
        setFormFields([...formFields, newField]);
        setShow(false);
    };

    const deleteData = (id) => {
        let arr = [...formFields].filter((e) => e.id !== id);
        setFormFields(arr);
    };

    const populateTemplate = () => {
        let data = state?.template;
        setFormFields(data?.form);
        setFormName(data?.name);
        setDescription(data?.description);
        setStatus(data?.isActive);
    };

    useEffect(() => {
        if (state?.template) populateTemplate();
    }, [state?.template]);

    const move = (id, direction) => {
        let arr = [...formFields];
        if (direction === "down") {
            if (id < arr.length - 1) {
                let temp = arr[id];
                arr[id] = arr[id + 1];
                arr[id + 1] = temp;
                setFormFields(arr);
            }
        } else {
            if (id > 0) {
                let temp = arr[id];
                arr[id] = arr[id - 1];
                arr[id - 1] = temp;
                setFormFields(arr);
            }
        }
    };

    return (
        <>
            <div className="card">
                <div className="card-body">
                    <div className="edit-form-wrapper">
                        <ul className="list-style-none flex flex-wrap">
                            <li>
                                <div className="form-group">
                                    <label htmlFor="">Form Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Form Name"
                                        value={formName}
                                        onChange={(e) =>
                                            setFormName(e.target.value)
                                        }
                                        maxLength={50}
                                    />
                                </div>
                            </li>
                            <li>
                                <div className="form-group">
                                    <label htmlFor="">Status</label>
                                    <select
                                        value={status}
                                        onChange={(e) =>
                                            setStatus(e.target.value)
                                        }
                                        className="form-control"
                                    >
                                        <option value={true}>Active</option>
                                        <option value={false}>Inactive</option>
                                    </select>
                                </div>
                            </li>
                            <li className="w-100">
                                <div className="form-group">
                                    <label htmlFor="">Description</label>
                                    <textarea
                                        className="form-control"
                                        placeholder="Description"
                                        value={description}
                                        onChange={(e) =>
                                            setDescription(e.target.value)
                                        }
                                        maxLength={300}
                                    ></textarea>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="online-form">
                        <div className="form-online-box">
                            <h2>
                                <img src={formIcon} alt="formIcon" /> Form
                            </h2>
                            <div className="online-inner-form edit-form-wrapper">
                                <ul className="flex flex-wrap list-style-none">
                                    {formFields.map((data, index) => (
                                        <>
                                            <InputElements
                                                type={data?.type}
                                                placeholder={data?.placeholder}
                                                label={data?.label}
                                                text={data?.text}
                                                options={data?.options}
                                                id={data?.id}
                                                deleteData={deleteData}
                                                move={move}
                                                index={index}
                                                uploadFile={uploadFile}
                                                filename={data?.filename}
                                                removeAttachment={
                                                    removeAttachment
                                                }
                                            />
                                        </>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="form-button-list">
                            <ul className="list-style-none">
                                <li>
                                    <button
                                        onClick={() =>
                                            addFormField(
                                                "heading",
                                                "Add Heading"
                                            )
                                        }
                                    >
                                        Heading
                                    </button>
                                </li>
                                <li>
                                    <button
                                        onClick={() =>
                                            addFormField("input", "Add Input")
                                        }
                                    >
                                        Input
                                    </button>
                                </li>
                                <li>
                                    <button
                                        onClick={() =>
                                            addFormField(
                                                "textarea",
                                                "Add Textarea"
                                            )
                                        }
                                    >
                                        Textarea
                                    </button>
                                </li>

                                <li>
                                    <button
                                        onClick={() =>
                                            addFormField(
                                                "date",
                                                "Add Date Picker"
                                            )
                                        }
                                    >
                                        Date Picker
                                    </button>
                                </li>
                                <li>
                                    <button
                                        onClick={() =>
                                            addFormField(
                                                "file",
                                                "Add File Upload"
                                            )
                                        }
                                    >
                                        File Upload
                                    </button>
                                </li>
                                <li>
                                    <button
                                        onClick={() =>
                                            addFormField(
                                                "checkbox",
                                                "Add Checkbox"
                                            )
                                        }
                                    >
                                        Checkbox
                                    </button>
                                </li>
                                <li>
                                    <button
                                        onClick={() =>
                                            addFormField(
                                                "radio",
                                                "Add Radio Buttons"
                                            )
                                        }
                                    >
                                        Radio Buttons
                                    </button>
                                </li>
                                <li>
                                    <button
                                        onClick={() =>
                                            addFormField(
                                                "select",
                                                "Add Dropdown"
                                            )
                                        }
                                    >
                                        Dropdown
                                    </button>
                                </li>
                                {/* <li>
                                    <button
                                        onClick={() =>
                                            addFormField("button", "Add Button")
                                        }
                                    >
                                        Submit Button
                                    </button>
                                </li> */}
                            </ul>
                        </div>
                    </div>

                    <div className="flex">
                        <input
                            type="file"
                            accept=".csv"
                            ref={fileInputRef}
                            style={{ display: "none" }}
                            onChange={handleImport}
                        />

                        <button
                            className="simple-btn cancel"
                            onClick={() => fileInputRef.current.click()}
                        >
                            Import
                        </button>
                        <button
                            onClick={() => handleExport()}
                            className="simple-btn"
                        >
                            Export
                        </button>
                        <button onClick={submit} className="simple-btn">
                            Submit Form
                        </button>
                    </div>
                </div>
            </div>

            {show && (
                <InputModal
                    setFormFields={setFormFields}
                    closeModal={() => setShow(false)}
                    confirmAction={confirm}
                    type={type}
                    title={title}
                />
            )}
        </>
    );
}
