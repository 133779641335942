import {
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel,
} from "react-accessible-accordion";
import React, { useEffect, useRef, useState } from "react";
import caseIcon from "../../../assets/images/caseIcon.svg";
import pngFile from "../../../assets/images/pngFile.svg";
import fileIcon from "../../../assets/images/file-icon.svg";
import uploadIcon from "../../../assets/images/upload.svg";
import exportIcon from "../../../assets/images/export.svg";
import del from "../../../assets/images/cross_del.svg";
import { Link, useNavigate } from "react-router-dom";
import { UploadFile } from "../../../components/modals/UploadFile";
import { api } from "../../../helpers/apiHelper/requestHelper";
import { toastify } from "../../../helpers/toast/toastify";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../redux/loader/loadingSlice";
import { useSelector } from "react-redux";
import { setRoute } from "../../../redux/route/routeSlice";
import parse from "html-react-parser";
import { formatDate } from "../../../helpers/dataHelper/dateFormatter";
import axios from "axios";

export default function CaseDocument({
    product,
    selectedCase,
    submit,
    setSidebar,
}) {
    const navigate = useNavigate();
    const [openAddModal, setOpenAddModal] = useState(false);
    const [caseDocuments, setCaseDocuments] = useState([]);
    const [document, setDocument] = useState();
    const [comments, setComments] = useState([]);
    const [comment, setComment] = useState("");
    const [expanded, setExpanded] = useState(true);
    const dispatch = useDispatch();
    const reduxData = useSelector((state) => state);
    const [elements, setElements] = useState([]);
    const [attachments, setAttachments] = useState([]);
    const [allAttachments, setAllAttachments] = useState([]);
    const fileInputRef = useRef(null);

    const config = axios.create({
        baseURL: process.env.REACT_APP_API_BASE_URL,
    });

    const uploadFile = async (file) => {
        dispatch(setLoading(true));
        try {
            let fd = new FormData();
            fd.append("file", file);
            fd.append("type", "Advisory Comment");
            if (selectedCase) {
                fd.append("relatedCase", selectedCase?._id);
            }
            let response = await config.post("auth/uploadFile", fd);

            if (response?.data?.status) {
                let url = response?.data?.data;
                if (url) {
                    let _attachments = [...attachments];
                    let obj = {
                        url,
                        filename: file.name,
                    };
                    _attachments.push(obj);

                    setAttachments(_attachments);
                    fileInputRef.current.value = null;
                } else {
                    toastify("Unable to upload document.");
                }
            } else {
                toastify(response?.message);
            }
        } catch (error) {
            console.log({ error });
            toastify(error?.response?.data?.message);
        }

        dispatch(setLoading(false));
    };

    const getStatus = async (e) => {
        // return <h4 className={`cursor-pointer reject`}>{e?.name}</h4>;
        const response = await api("post", "forms/formStatus", {
            caseId: selectedCase?._id,
            formId: e._id,
        });
        if (response?.data?.data === "incomplete") {
            return <h4 className={`cursor-pointer reject`}>{e?.name}</h4>;
        } else {
            return <h4 className={`cursor-pointer`}>{e?.name}</h4>;
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const els = await Promise.all(
                    selectedCase?.product?.internalForms?.map(getStatus)
                );
                setElements(els);
            } catch (error) {
                console.error(error);
            }
        };

        const fetchDataFromProd = async () => {
            try {
                const els = await Promise.all(
                    product?.internalForms?.map(getStatus)
                );
                setElements(els);
            } catch (error) {
                console.error(error);
            }
        };

        if (selectedCase) {
            fetchData();
        } else {
            fetchDataFromProd();
        }
    }, [selectedCase, product]);

    useEffect(() => {
        if (selectedCase) {
            let atts = [];
            for (
                let index = 0;
                index < selectedCase?.comments?.length;
                index++
            ) {
                const element = selectedCase?.comments[index];
                atts.push(...element.attachments);
            }
            setAllAttachments(atts || []);
            setCaseDocuments(selectedCase?.caseDocuments);
            setComments(selectedCase?.comments || []);
        }
    }, [selectedCase]);

    const getClassName = (doc) => {
        let myDoc = caseDocuments?.find((e) => e.documentId === doc._id);
        if (myDoc) {
            if ((doc?.upload && !myDoc?.url) || (doc?.input && !myDoc?.input)) {
                return "reject";
            } else {
                return "";
            }
        } else {
            return "waiting";
        }
    };

    const handleFileUpload = async (imageFile) => {
        let myDoc = caseDocuments?.find((e) => e.documentId === document._id);

        dispatch(setLoading(true));
        let fd = new FormData();
        fd.append("file", imageFile);
        fd.append("documentId", document?._id);
        fd.append("filename", imageFile.name);
        fd.append("type", "Case Document");
        if (selectedCase) {
            fd.append("relatedCase", selectedCase?._id);
        }
        const response = await api("upload", "cases/uploadCaseDocument", fd);
        console.log({ response });

        dispatch(setLoading(false));

        if (response?.data?.status) {
            setOpenAddModal(false);
            const resData = response?.data?.data;
            let _caseDocuments = [...caseDocuments];
            let itemIndex = _caseDocuments.findIndex(
                (e) => e.documentId === resData?.documentId
            );
            if (itemIndex > -1) {
                let newDoc = {
                    documentId: _caseDocuments[itemIndex].documentId,
                    name: _caseDocuments[itemIndex].name,
                    _id: _caseDocuments[itemIndex]._id,
                    name: resData?.name,
                    url: resData?.url,
                    input: myDoc?.input,
                };
                _caseDocuments[itemIndex] = newDoc;
            } else {
                _caseDocuments.push({
                    name: resData?.name,
                    url: resData?.url,
                    documentId: resData?.documentId,
                    input: myDoc?.input,
                });
            }

            setCaseDocuments(_caseDocuments);
        } else {
            toastify(response?.message);
        }
    };

    const getButtonVisibility = (doc) => {
        let _caseDoc = caseDocuments.find((e) => e.documentId === doc?._id);
        if (_caseDoc?.url || !doc.upload) {
            return false;
        } else {
            return true;
        }
    };

    const getCaseDocument = (doc) => {
        let _caseDoc = caseDocuments.find((e) => e.documentId === doc?._id);
        if (_caseDoc && _caseDoc?.url) {
            return (
                <span>
                    <img src={fileIcon} alt="pdfIcon" />{" "}
                    <div className="file_name">{_caseDoc?.name}</div>
                    <div className="action_button_wrap">
                        <button
                            onClick={() => {
                                if (getDocLink(doc)) {
                                    window.open(getDocLink(doc), "_blank");
                                }
                            }}
                        >
                            <img src={exportIcon} alt="exportIcon" />
                        </button>
                        <img
                            className="cursor-pointer"
                            src={del}
                            alt="del"
                            onClick={() => {
                                let _caseDocuments = [...caseDocuments];
                                let itemIndex = _caseDocuments.findIndex(
                                    (e) => e.documentId === doc?._id
                                );
                                let currentDoc = {
                                    documentId:
                                        _caseDocuments[itemIndex].documentId,
                                    name: _caseDocuments[itemIndex].name,
                                    input: _caseDocuments[itemIndex].input,
                                    _id: _caseDocuments[itemIndex]._id,
                                };

                                _caseDocuments[itemIndex] = currentDoc;
                                setCaseDocuments(_caseDocuments);
                            }}
                        />
                    </div>
                </span>
            );
        }
    };

    const getDocLink = (doc) => {
        let _caseDoc = caseDocuments.find((e) => e.documentId === doc?._id);
        if (_caseDoc) {
            return _caseDoc?.url;
        } else {
            return null;
        }
    };

    const getInputField = (doc) => {
        if (doc?.input) {
            let _caseIndex = caseDocuments.findIndex(
                (e) => e.documentId === doc?._id
            );

            return (
                <>
                    <small>Input Field |</small>
                    <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        value={caseDocuments[_caseIndex]?.input}
                        onChange={(e) => {
                            let _caseIndex = caseDocuments.findIndex(
                                (e) => e.documentId === doc?._id
                            );
                            if (_caseIndex > -1) {
                                let _caseDocuments = [...caseDocuments];
                                _caseDocuments[_caseIndex] = {
                                    ..._caseDocuments[_caseIndex],
                                    input: e.target.value,
                                };
                                setCaseDocuments(_caseDocuments);
                            } else {
                                let _caseDocuments = [...caseDocuments];
                                _caseDocuments.push({
                                    documentId: doc?._id,
                                    input: e.target.value,
                                });
                                setCaseDocuments(_caseDocuments);
                            }
                        }}
                    />
                </>
            );
        }
    };

    return (
        <>
            <AccordionItem dangerouslySetExpanded={expanded}>
                <AccordionItemHeading onClick={() => setExpanded(!expanded)}>
                    <AccordionItemButton>Case Document</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <div className="document-wrapper flex">
                        <div className="cases-doc-list">
                            <ul className="list-style-none">
                                <li className="title">
                                    <h2>Internal Forms</h2>
                                </li>
                                {selectedCase?.product?.internalForms?.map(
                                    (e) => (
                                        <li
                                            className={`bordered-form`}
                                            onClick={async () => {
                                                if (
                                                    selectedCase &&
                                                    selectedCase?.status !==
                                                        "Completed" &&
                                                    selectedCase?.status !==
                                                        "Submitted" &&
                                                    selectedCase?.status !==
                                                        "Processing"
                                                ) {
                                                    window.open(
                                                        `${process.env.REACT_APP_BASE_URL}form/${e?._id}/${selectedCase?._id}`,
                                                        "_blank"
                                                    );
                                                }
                                            }}
                                        >
                                            {elements.find(
                                                (f) =>
                                                    f?.props?.children ===
                                                    e.name
                                            )}
                                        </li>
                                    )
                                )}

                                <li className="title">
                                    <h2>Required Documents</h2>
                                </li>
                                {product?.documents?.map((e) => (
                                    <li>
                                        <h4 className={getClassName(e)}>
                                            {e?.name}
                                            {e.url && (
                                                <img
                                                    src={caseIcon}
                                                    alt="caseIcon"
                                                    className="cursor-pointer"
                                                    onClick={() =>
                                                        window.open(
                                                            e.url,
                                                            "_blank"
                                                        )
                                                    }
                                                />
                                            )}
                                        </h4>
                                        <div className="caseinput-wrap">
                                            <div className="caseinput">
                                                {getInputField(e)}
                                            </div>
                                            <div className="flex column-direction case_input_des">
                                                {getCaseDocument(e)}
                                                {getButtonVisibility(e) && (
                                                    <section className="flex items-center files-upload">
                                                        <button
                                                            onClick={() => {
                                                                setDocument(e);
                                                                setOpenAddModal(
                                                                    true
                                                                );
                                                            }}
                                                        >
                                                            <img
                                                                src={uploadIcon}
                                                                alt="uploadIcon"
                                                            />{" "}
                                                            Upload your file
                                                        </button>
                                                    </section>
                                                )}
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="single-right-wrap flex column-direction">
                            <section className="comments-wrapper">
                                <strong className="flex items-center mb-20">
                                    Comment Advisory
                                    <span className="add-extra-list del ml-20 ">
                                        <label
                                            class="file-input-label"
                                            htmlFor={`file-input-i`}
                                        >
                                            <img src={uploadIcon} />
                                        </label>
                                        <input
                                            ref={fileInputRef}
                                            id={`file-input-i`}
                                            type="file"
                                            style={{
                                                display: "none",
                                            }}
                                            onChange={(e) => {
                                                if (e.target.files[0]) {
                                                    uploadFile(
                                                        e.target.files[0]
                                                    );
                                                }
                                            }}
                                        />
                                    </span>
                                </strong>
                                {attachments?.length > 0 && (
                                    <div
                                        className="cases-doc-list mt-20"
                                        style={{ width: "100%", padding: 0 }}
                                    >
                                        <ul className="p-0 m-0 mb-0">
                                            <div className="flex column-direction case_input_des ">
                                                {attachments?.map((e) => {
                                                    return (
                                                        <li className="p-0">
                                                            <span
                                                                style={{
                                                                    padding:
                                                                        "20px 40px 20px 20px",
                                                                }}
                                                            >
                                                                <img
                                                                    src={
                                                                        fileIcon
                                                                    }
                                                                    className="cursor-pointer"
                                                                    alt="pdfIcon"
                                                                    onClick={() => {
                                                                        window.open(
                                                                            e.url
                                                                        );
                                                                    }}
                                                                />
                                                                <div
                                                                    className="file_name cursor-pointer"
                                                                    onClick={() => {
                                                                        window.open(
                                                                            e.url
                                                                        );
                                                                    }}
                                                                >
                                                                    {
                                                                        e?.filename
                                                                    }
                                                                </div>
                                                                <div className="action_button_wrap">
                                                                    <img
                                                                        className="cursor-pointer"
                                                                        src={
                                                                            del
                                                                        }
                                                                        alt="del"
                                                                        onClick={() => {
                                                                            let _attachments =
                                                                                [
                                                                                    ...attachments,
                                                                                ];
                                                                            let newAttachments =
                                                                                _attachments.filter(
                                                                                    (
                                                                                        f
                                                                                    ) =>
                                                                                        f.url !==
                                                                                        e.url
                                                                                );

                                                                            setAttachments(
                                                                                newAttachments
                                                                            );
                                                                            fileInputRef.current.value =
                                                                                null;
                                                                        }}
                                                                    />
                                                                </div>
                                                            </span>
                                                        </li>
                                                    );
                                                })}
                                            </div>
                                        </ul>
                                    </div>
                                )}
                                <textarea
                                    className="form-control"
                                    placeholder="Write..."
                                    value={comment}
                                    onChange={(e) => setComment(e.target.value)}
                                ></textarea>
                                <button
                                    className="simple-btn"
                                    onClick={() => {
                                        if (comment !== "") {
                                            let _comments = [...comments];
                                            _comments.push({
                                                date: new Date(),
                                                comment,
                                                type: "Advisory",
                                                commentBy:
                                                    reduxData?.auth?.user,
                                                hide: true,
                                                attachments,
                                            });
                                            setComments(_comments);
                                            setComment("");
                                            setAttachments([]);
                                            let atts = [...allAttachments];
                                            atts.push(...attachments);
                                            setAllAttachments(atts);
                                        }
                                    }}
                                >
                                    Comment
                                </button>
                                {allAttachments?.length > 0 && (
                                    <div
                                        className="cases-doc-list mt-20"
                                        style={{ width: "100%", padding: 0 }}
                                    >
                                        <strong className="flex items-center mt-20 mb-20">
                                            Attachments
                                        </strong>
                                        <ul className="p-0 m-0 mb-0">
                                            <div className="flex column-direction case_input_des ">
                                                {allAttachments?.map((e) => {
                                                    return (
                                                        <li className="p-0">
                                                            <span
                                                                style={{
                                                                    padding:
                                                                        "20px 40px 20px 20px",
                                                                }}
                                                            >
                                                                <img
                                                                    src={
                                                                        fileIcon
                                                                    }
                                                                    className="cursor-pointer"
                                                                    alt="pdfIcon"
                                                                    onClick={() => {
                                                                        window.open(
                                                                            e.url
                                                                        );
                                                                    }}
                                                                />
                                                                <div
                                                                    className="file_name cursor-pointer"
                                                                    onClick={() => {
                                                                        window.open(
                                                                            e.url
                                                                        );
                                                                    }}
                                                                >
                                                                    {
                                                                        e?.filename
                                                                    }
                                                                </div>
                                                            </span>
                                                        </li>
                                                    );
                                                })}
                                            </div>
                                        </ul>
                                    </div>
                                )}
                            </section>
                            {comments.length > 0 && (
                                <section className="latest-comment">
                                    <h3>Latest Comment</h3>
                                    <ul className="list-style-none flex column-direction">
                                        {comments.map((data) => {
                                            return (
                                                <li>
                                                    <h6>
                                                        {data?.commentBy &&
                                                            ` ${
                                                                data?.commentBy
                                                                    ?.username ||
                                                                data?.commentBy
                                                            }`}
                                                    </h6>
                                                    <span>
                                                        {parse(
                                                            data.comment.replace(
                                                                /\n/g,
                                                                "<br />"
                                                            )
                                                        )}
                                                    </span>
                                                    {data?.attachments?.length >
                                                        0 && (
                                                        <div
                                                            className="cases-doc-list mt-20"
                                                            style={{
                                                                width: "100%",
                                                                padding: 0,
                                                            }}
                                                        >
                                                            <ul className="p-0 m-0 mb-0">
                                                                <div className="flex column-direction case_input_des ">
                                                                    {data?.attachments?.map(
                                                                        (e) => {
                                                                            return (
                                                                                <li className="p-0">
                                                                                    <span
                                                                                        style={{
                                                                                            padding:
                                                                                                "20px 40px 20px 20px",
                                                                                        }}
                                                                                    >
                                                                                        <img
                                                                                            src={
                                                                                                fileIcon
                                                                                            }
                                                                                            className="cursor-pointer"
                                                                                            alt="pdfIcon"
                                                                                            onClick={() => {
                                                                                                window.open(
                                                                                                    e.url
                                                                                                );
                                                                                            }}
                                                                                        />
                                                                                        <div
                                                                                            className="file_name cursor-pointer"
                                                                                            onClick={() => {
                                                                                                window.open(
                                                                                                    e.url
                                                                                                );
                                                                                            }}
                                                                                        >
                                                                                            {
                                                                                                e?.filename
                                                                                            }
                                                                                        </div>
                                                                                    </span>
                                                                                </li>
                                                                            );
                                                                        }
                                                                    )}
                                                                </div>
                                                            </ul>
                                                        </div>
                                                    )}
                                                    <small>
                                                        {formatDate(
                                                            new Date(data?.date)
                                                        )}
                                                    </small>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </section>
                            )}
                        </div>
                    </div>
                    <div className="flex items-center button-wrap fixed-button-wrap content-justify-center">
                        <button
                            className="cursor-pointer simple-btn cancel"
                            onClick={() => {
                                setSidebar();
                                navigate(-1);
                            }}
                        >
                            Cancel
                        </button>

                        <button
                            className="cursor-pointer simple-btn submit"
                            onClick={() => submit(caseDocuments, comments)}
                        >
                            Save
                        </button>
                    </div>
                </AccordionItemPanel>
            </AccordionItem>
            {openAddModal === true && (
                <UploadFile
                    submit={handleFileUpload}
                    setModalClick={() => setOpenAddModal(!openAddModal)}
                />
            )}
        </>
    );
}
