import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    value: [],
};

export const scheduleSlice = createSlice({
    name: "scheduleSlice",
    initialState,
    reducers: {
        setUserSchedule: (state, action) => {
            state.value = action.payload;
        },
    },
});

export const { setUserSchedule } = scheduleSlice.actions;

export default scheduleSlice.reducer;
