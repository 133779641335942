import userIcon from "../../../../assets/images/usericon.svg";
import leadIcon from "../../../../assets/images/lead-icon.svg";
import add from "../../../../assets/images/add-icon.svg";
import view from "../../../../assets/images/view.svg";
import update from "../../../../assets/images/update.svg";
import del from "../../../../assets/images/del.svg";
import React, { useEffect } from "react";
import Search from "../../../../components/search/Search";
import { AddCase } from "../../../../components/modals/AddCase";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { storeLeads } from "../../../../redux/leads/leadsSlice";
import { toastify } from "../../../../helpers/toast/toastify";
import { api } from "../../../../helpers/apiHelper/requestHelper";
import { useDispatch } from "react-redux";
import LeadDetailsModal from "../lead-details-modal/LeadDetailsModal";
import { PENDING, PROCESSING } from "../../../../config/leadStatus";
import { AlertModal } from "../../../../components/modals/AlertModal";
import { setLoading } from "../../../../redux/loader/loadingSlice";
import EntriesSelection from "../../../../components/pagination/EntriesSelection";
import Pagination from "../../../../components/pagination/Pagination";
import { setRoute } from "../../../../redux/route/routeSlice";
import { storeCases } from "../../../../redux/cases/casesSlice";
import { setUserNotifications } from "../../../../redux/notifications/notificationsSlice";
import sortIcon from "../../../../assets/images/sort.png";
import { sortTable } from "../../../../helpers/dataHelper/sorting";
import { shortFormatDate } from "../../../../helpers/dataHelper/dateFormatter";
import {
    APPROVED,
    COMPLETED,
    PENDING_1,
    PENDING_2,
    PROCESSING_1,
} from "../../../../config/casesStatus";
import calenderIcon from "../../../../assets/images/calender.svg";
import DatePicker from "react-date-picker";

export default function AllLeads() {
    const reduxData = useSelector((state) => state);
    const reduxLeads = useSelector((state) => state.leads.value);

    const [openAddModal, setOpenAddModal] = useState(false);
    const [filter, setFilter] = useState("all");
    const [filtered, setFiltered] = useState(reduxLeads || []);
    const [selectedLead, setSelectedLead] = useState();
    const [show, setShow] = useState(false);
    const [lead, setLead] = useState();
    const [openDetailModal, setOpenDetailModal] = useState(false);
    const [count, setCount] = useState(5);
    const [page, setPage] = useState(1);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const { state } = useLocation();

    useEffect(() => {
        let year = new Date().getFullYear();
        setStartDate(new Date(year, 0, 1));
        setEndDate(new Date());
    }, []);

    const compareDates = (a, b) => {
        let sDate = new Date(a);
        let eDate = new Date(b);
        if (
            sDate.getFullYear() === eDate.getFullYear() &&
            sDate.getMonth() === eDate.getMonth() &&
            sDate.getDate() === eDate.getDate()
        ) {
            return true;
        } else {
            return false;
        }
    };

    const filterDataByDate = (e) => {
        if (startDate) {
            if (
                new Date(e?.created_at) >= new Date(startDate) ||
                compareDates(e?.created_at, startDate)
            ) {
                if (endDate) {
                    if (
                        new Date(e?.created_at) <= new Date(endDate) ||
                        compareDates(e?.created_at, endDate)
                    ) {
                        return e;
                    }
                } else {
                    return e;
                }
            }
        } else if (endDate) {
            if (
                new Date(e?.created_at) <= new Date(endDate) ||
                compareDates(e?.created_at, endDate)
            ) {
                if (startDate) {
                    if (
                        new Date(e?.created_at) >= new Date(startDate) ||
                        compareDates(e?.created_at, startDate)
                    ) {
                        return e;
                    }
                } else {
                    return e;
                }
            }
        }
    };

    const getNotifications = async () => {
        let response = await api("post", "notifications/getNotificationsById");
        if (response?.status) {
            dispatch(setUserNotifications(response?.data?.data?.reverse()));
        } else {
            toastify(response?.message, "error");
        }
    };

    useEffect(() => {
        setFiltered(reduxLeads);
        getNotifications();
    }, [reduxLeads]);

    useEffect(() => {
        if (window.location.pathname) {
            let _data = reduxLeads;
            let data = [..._data]?.filter((e) => {
                if (
                    checkPerm(
                        reduxData?.userRoles?.currentRole,
                        window.location.pathname.split("/")[3],
                        e
                    )
                ) {
                    return e;
                }
            });
            setFiltered(data);
            setFilter(window.location.pathname.split("/")[3]);
        }
    }, [window.location.pathname, reduxLeads]);

    const getStartDate = async () => {
        let response = await api("get", "settings/getDateSettings");

        if (response?.status) {
            setStartDate(new Date(response?.data?.startDate));
        }
    };

    useEffect(() => {
        getLeads();
        getStartDate();
    }, []);

    const getLeads = async () => {
        let response = await api("post", "leads/index", {
            assignedFromRole: reduxData?.userRoles?.currentRole,
        });

        if (response?.status) {
            dispatch(storeLeads(response?.data?.data?.reverse()));
        } else {
            toastify(response?.message, "error");
        }
    };

    const checkTeamLead = () => {
        let found;
        let id = reduxData?.auth?.user?._id;
        reduxData?.allTeams?.value?.teams?.forEach((element) => {
            element?.members?.forEach((e) => {
                if (e?._id === id) {
                    found = element;
                }
            });
        });

        if (found && found?.teamLead?._id === id) {
            return true;
        } else {
            return false;
        }
    };

    const checkPerm = (role, url, data) => {
        if (
            (role === "RM Member" &&
                ((url === "assigned" &&
                    data?.assignedTo?._id === reduxData?.auth?.user?._id) ||
                    (url === "unassigned" &&
                        data?.assignedFrom?._id ===
                            reduxData?.auth?.user?._id) ||
                    url === "all" ||
                    (url === "reassigned" && data?.reassigned))) ||
            (role === "Case Manager" &&
                ((url === "assigned" && data?.assignedTo) ||
                    (url === "unassigned" &&
                        data?.assignedFrom?._id ===
                            reduxData?.auth?.user?._id) ||
                    url === "all" ||
                    (url === "reassigned" && data?.reassigned))) ||
            ((role === "RM Team Leader" || role === "Lead Coordinator") &&
                ((url === "assigned" && data?.assignedTo) ||
                    (url === "own" &&
                        data?.assignedFrom?._id ===
                            reduxData?.auth?.user?._id) ||
                    (url === "unassigned" && !data?.assignedTo) ||
                    url === "all" ||
                    (url === "reassigned" && data?.reassigned)))
        ) {
            return true;
        } else {
            return false;
        }
    };

    const getSubStatus = (status) => {
        if (status?.toLowerCase() === "pending") {
            return PENDING_1;
        } else if (status?.toLowerCase() === "submitted") {
            return PENDING_2;
        } else if (status?.toLowerCase() === "processing") {
            return PROCESSING_1;
        } else if (status?.toLowerCase() === "approved") {
            return APPROVED;
        } else if (status?.toLowerCase() === "completed") {
            return COMPLETED;
        } else {
            return null;
        }
    };

    const addCase = async (data) => {
        dispatch(setLoading(true));

        const response = await api("post", "leads/register", {
            leadId: selectedLead?._id,
            ...data,
        });
        dispatch(setLoading(false));

        if (response?.data?.status) {
            //SIDEBAR start
            //Remove Active class
            dispatch(setRoute("Cases"));
            let activeSpan = document.querySelector("span.active");
            if (activeSpan) {
                activeSpan.classList.remove("active");
            }
            //Add Active class
            let sidebar = document.querySelector(".sidebar-wrapper");
            const spanElements = sidebar.querySelectorAll("a");
            let targetSpan;
            spanElements.forEach((span) => {
                if (span.innerText === "Case") {
                    targetSpan = span;
                    return;
                }
            });
            if (targetSpan) {
                targetSpan.classList.toggle("active");
            }
            //SIDEBAR end
            navigate("/rm-lead/edit-case", {
                state: {
                    case: response?.data?.data?.case,
                },
            });
            setOpenAddModal(false);
            toastify("Case Added Successfully", "success");
            getLeads();
        } else {
            toastify(response?.message, "error");
        }
    };

    const deleteLead = async (data) => {
        setShow(true);
        setLead(data);
    };

    const confirmAction = async () => {
        dispatch(setLoading(true));
        const response = await api("post", "leads/update", {
            isDeleted: true,
            id: lead._id,
        });
        dispatch(setLoading(false));
        if (response?.data?.status) {
            getLeads();
            toastify("Lead deleted successfully", "success");
            setShow(false);
        } else {
            toastify(response?.message, "error");
        }
    };

    const getHeadings = () => {
        if (reduxData?.userRoles?.currentRole === "Lead Coordinator") {
            return (
                <>
                    <th>
                        Lead ID
                        <img
                            src={sortIcon}
                            className="sort-icon"
                            onClick={() =>
                                setFiltered(sortTable("Leads", filtered, "ID"))
                            }
                        />
                    </th>
                    <th>
                        Created Date
                        <img
                            src={sortIcon}
                            className="sort-icon"
                            onClick={() =>
                                setFiltered(
                                    sortTable("Leads", filtered, "Created Date")
                                )
                            }
                        />
                    </th>
                    <th>
                        Lead Type
                        <img
                            src={sortIcon}
                            className="sort-icon"
                            onClick={() =>
                                setFiltered(
                                    sortTable("Leads", filtered, "Lead Type")
                                )
                            }
                        />
                    </th>
                    <th>
                        Name
                        <img
                            src={sortIcon}
                            className="sort-icon"
                            onClick={() =>
                                setFiltered(
                                    sortTable("Leads", filtered, "Name")
                                )
                            }
                        />
                    </th>
                    <th>
                        Contact
                        <img
                            src={sortIcon}
                            className="sort-icon"
                            onClick={() =>
                                setFiltered(
                                    sortTable("Leads", filtered, "Contact")
                                )
                            }
                        />
                    </th>
                    <th>
                        Team
                        <img
                            src={sortIcon}
                            className="sort-icon"
                            onClick={() =>
                                setFiltered(
                                    sortTable("Leads", filtered, "Team")
                                )
                            }
                        />
                    </th>
                    {/* <th>Company Name</th> */}
                    {/* <th>Representative</th> */}
                    <th>
                        Assign To
                        <img
                            src={sortIcon}
                            className="sort-icon"
                            onClick={() =>
                                setFiltered(
                                    sortTable("Leads", filtered, "Assign To")
                                )
                            }
                        />
                    </th>
                    <th>
                        Entity Type
                        <img
                            src={sortIcon}
                            className="sort-icon"
                            onClick={() =>
                                setFiltered(
                                    sortTable(
                                        "Leads",
                                        filtered,
                                        "Entities Type"
                                    )
                                )
                            }
                        />
                    </th>

                    <th>
                        Status
                        <img
                            src={sortIcon}
                            className="sort-icon"
                            onClick={() =>
                                setFiltered(
                                    sortTable("Leads", filtered, "Status")
                                )
                            }
                        />
                    </th>
                    <th>Action</th>
                </>
            );
        } else {
            return (
                <>
                    <th>
                        ID
                        <img
                            src={sortIcon}
                            className="sort-icon"
                            onClick={() =>
                                setFiltered(sortTable("Leads", filtered, "ID"))
                            }
                        />
                    </th>
                    <th>
                        Created Date
                        <img
                            src={sortIcon}
                            className="sort-icon"
                            onClick={() =>
                                setFiltered(
                                    sortTable("Leads", filtered, "Created Date")
                                )
                            }
                        />
                    </th>
                    <th>
                        Lead Type
                        <img
                            src={sortIcon}
                            className="sort-icon"
                            onClick={() =>
                                setFiltered(
                                    sortTable("Leads", filtered, "Lead Type")
                                )
                            }
                        />
                    </th>
                    <th>
                        Entity Type
                        <img
                            src={sortIcon}
                            className="sort-icon"
                            onClick={() =>
                                setFiltered(
                                    sortTable(
                                        "Leads",
                                        filtered,
                                        "Entities Type"
                                    )
                                )
                            }
                        />
                    </th>
                    <th>
                        Name
                        <img
                            src={sortIcon}
                            className="sort-icon"
                            onClick={() =>
                                setFiltered(
                                    sortTable("Leads", filtered, "Name")
                                )
                            }
                        />
                    </th>
                    <th>
                        Contact No.
                        <img
                            src={sortIcon}
                            className="sort-icon"
                            onClick={() =>
                                setFiltered(
                                    sortTable("Leads", filtered, "Contact")
                                )
                            }
                        />
                    </th>
                    <th>
                        Assign From
                        <img
                            src={sortIcon}
                            className="sort-icon"
                            onClick={() =>
                                setFiltered(
                                    sortTable("Leads", filtered, "Assign From")
                                )
                            }
                        />
                    </th>
                    {reduxData?.userRoles?.currentRole === "RM Team Leader" &&
                        checkTeamLead() && (
                            <th>
                                Assign To
                                <img
                                    src={sortIcon}
                                    className="sort-icon"
                                    onClick={() =>
                                        setFiltered(
                                            sortTable(
                                                "Leads",
                                                filtered,
                                                "Assign To"
                                            )
                                        )
                                    }
                                />
                            </th>
                        )}
                    <th>
                        Status
                        <img
                            src={sortIcon}
                            className="sort-icon"
                            onClick={() =>
                                setFiltered(
                                    sortTable("Leads", filtered, "Status")
                                )
                            }
                        />
                    </th>
                    <th>Action</th>
                </>
            );
        }
    };

    const getCases = async () => {
        let response = await api("post", "cases/index", {
            role: reduxData?.userRoles?.currentRole,
        });

        if (response?.data?.status) {
            dispatch(storeCases(response?.data?.data));
        } else {
            toastify(response?.message, "error");
        }
    };

    const getTitle = () => {
        switch (filter) {
            case "assigned":
                return "Assigned";
            case "unassigned":
                if (
                    reduxData?.userRoles?.currentRole === "Lead Coordinator" ||
                    reduxData?.userRoles?.currentRole === "RM Team Leader"
                ) {
                    return "Unassigned";
                }
                return "Own";
            case "own":
                return "Own";
            default:
                return "All";
        }
    };

    const getTeamLead = (id) => {
        const users = [...reduxData?.users?.value];
        let user = users.find((e) => e._id === id);
        return user;
    };

    useEffect(() => {
        if (state?.isPrevious) {
            setStartDate(null);
            setEndDate(null);
        }
    }, [state?.isPrevious]);

    return (
        <>
            <div className="flex items-end mb-20">
                <h4 className="flex flex-wrap mini-title ">
                    <span
                        onClick={() =>
                            navigate(
                                "/" +
                                    window.location.pathname.split("/")[1] +
                                    "/leads/all"
                            )
                        }
                    >
                        Leads{" "}
                    </span>
                    / {getTitle()} Leads{" "}
                </h4>
                <div className="flex ml-auto">
                    <div>
                        <p>Start Date</p>
                        <DatePicker
                            onChange={(e) => setStartDate(e)}
                            maxDate={endDate}
                            format="dd/MM/y"
                            className="form-control"
                            value={startDate}
                            onKeyDown={(e) => {
                                e.preventDefault();
                            }}
                        />
                    </div>
                    <div className="ml-20">
                        <p>End Date</p>
                        <DatePicker
                            onChange={(e) => setEndDate(e)}
                            minDate={startDate}
                            format="dd/MM/y"
                            className="form-control"
                            value={endDate}
                            onKeyDown={(e) => {
                                e.preventDefault();
                            }}
                        />
                    </div>
                </div>
            </div>
            <Search
                userSearch={true}
                filedText="Search here"
                teamDropdown={false}
                filters={false}
                typeFilters={true}
                typeFiltersTitle="Lead Type"
                // dateFilters={true}
                addButton={true}
                addButtonText="Add Leads"
                buttonRoute={
                    reduxData?.userRoles?.currentRole === "RM Team Leader"
                        ? "/rm-lead/add-leads"
                        : reduxData?.userRoles?.currentRole === "RM Member"
                        ? "/rm-member/add-leads"
                        : reduxData?.userRoles?.currentRole ===
                          "Lead Coordinator"
                        ? "/lead-coordinator/add-leads"
                        : "/leads/add-leads"
                }
                extraClass="mega-search"
                statusFilter={true}
                setLeads={setFiltered}
                leads={reduxLeads}
                component={"Leads"}
                filter={filter}
                role={
                    reduxData?.userRoles?.currentRole === "Lead Coordinator"
                        ? "LC"
                        : reduxData?.userRoles?.currentRole === "RM Team Leader"
                        ? "RML"
                        : null
                }
            />
            {show && (
                <AlertModal
                    confirmAction={confirmAction}
                    setModalClick={() => setShow(false)}
                    confirmBtn={true}
                    modalTitle={[
                        <h2>
                            Do you really want to delete{" "}
                            {lead?.identifier || "N/A"}
                            <br /> Lead permanently?
                        </h2>,
                    ]}
                    modalDescription={[]}
                />
            )}
            <div className="responsive-table accordion-list w-100">
                <table className="table">
                    <thead>
                        <tr
                            className="no-shadow"
                            style={{ background: "#f6f6f6" }}
                        >
                            <td colSpan="10" height="16"></td>
                        </tr>
                        <tr>{getHeadings()}</tr>
                    </thead>

                    <tbody>
                        {filtered
                            ?.filter((e) => {
                                if (startDate || endDate) {
                                    return filterDataByDate(e);
                                } else {
                                    return e;
                                }
                            })
                            ?.slice(count * page - count, count * page)
                            ?.map((data) => {
                                return (
                                    <>
                                        <tr className="no-shadow">
                                            <td colSpan="10" height="16"></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span className="flex items-center">
                                                    <img
                                                        src={
                                                            data?.leadType ===
                                                            "Personal"
                                                                ? userIcon
                                                                : leadIcon
                                                        }
                                                        alt="userIcon"
                                                    />

                                                    <span>
                                                        {data?.identifier}
                                                    </span>
                                                </span>
                                            </td>
                                            <td>
                                                {shortFormatDate(
                                                    data?.created_at
                                                )}
                                            </td>
                                            <td>{data?.leadType}</td>
                                            {reduxData?.userRoles
                                                ?.currentRole ===
                                            "Lead Coordinator" ? (
                                                <>
                                                    <td>
                                                        {data?.entityType ===
                                                            "Business" && (
                                                            <div className="bold">
                                                                {
                                                                    data
                                                                        ?.businessEntity
                                                                        ?.name
                                                                }
                                                            </div>
                                                        )}
                                                        {data?.entityType ===
                                                        "Business"
                                                            ? data
                                                                  ?.businessEntity
                                                                  ?.representative
                                                                  ?.firstName +
                                                              " " +
                                                              data
                                                                  ?.businessEntity
                                                                  ?.representative
                                                                  ?.lastName
                                                            : data
                                                                  ?.individualEntity
                                                                  ?.firstName +
                                                              " " +
                                                              data
                                                                  ?.individualEntity
                                                                  ?.lastName}
                                                    </td>
                                                    <td>
                                                        {data?.businessEntity
                                                            ?.representative
                                                            ?.contact ||
                                                            data
                                                                ?.individualEntity
                                                                ?.contact ||
                                                            "N/A"}
                                                    </td>
                                                    <td>
                                                        {data?.team?.name ||
                                                            "N/A"}
                                                    </td>
                                                    <td>
                                                        <span className="number">
                                                            {data?.assignedTo
                                                                ?.username ||
                                                                "N/A"}
                                                        </span>
                                                    </td>
                                                    <td>{data?.entityType}</td>

                                                    {reduxData?.userRoles
                                                        ?.currentRole ===
                                                        "RM Team Leader" &&
                                                        checkTeamLead() && (
                                                            <td>
                                                                {data
                                                                    ?.assignedTo
                                                                    ?.username ||
                                                                    "N/A"}
                                                            </td>
                                                        )}
                                                    <td>
                                                        <span
                                                            className={`status ${data?.status
                                                                ?.toLowerCase()
                                                                ?.split(" ")
                                                                .join("-")}`}
                                                        >
                                                            {data?.status}
                                                        </span>
                                                        <small>
                                                            {getSubStatus(
                                                                data?.status
                                                            )}
                                                        </small>
                                                    </td>
                                                    <td>
                                                        <div className="flex items-center">
                                                            <button
                                                                className="table-action view"
                                                                onClick={() => {
                                                                    setSelectedLead(
                                                                        data
                                                                    );
                                                                    setOpenDetailModal(
                                                                        true
                                                                    );
                                                                }}
                                                            >
                                                                <img
                                                                    src={view}
                                                                    alt="view"
                                                                />
                                                                <span>
                                                                    <small>
                                                                        View
                                                                    </small>
                                                                </span>
                                                            </button>
                                                            <button
                                                                className="table-action update cursor-pointer"
                                                                onClick={() => {
                                                                    if (
                                                                        reduxData
                                                                            ?.userRoles
                                                                            ?.currentRole ===
                                                                        "RM Team Leader"
                                                                    ) {
                                                                        navigate(
                                                                            "/rm-lead/add-leads",
                                                                            {
                                                                                state: {
                                                                                    lead: data,
                                                                                },
                                                                            }
                                                                        );
                                                                    } else if (
                                                                        reduxData
                                                                            ?.userRoles
                                                                            ?.currentRole ===
                                                                        "RM Member"
                                                                    ) {
                                                                        navigate(
                                                                            "/rm-member/add-leads",
                                                                            {
                                                                                state: {
                                                                                    lead: data,
                                                                                },
                                                                            }
                                                                        );
                                                                    } else if (
                                                                        reduxData
                                                                            ?.userRoles
                                                                            ?.currentRole ===
                                                                        "Lead Coordinator"
                                                                    ) {
                                                                        navigate(
                                                                            "/lead-coordinator/add-leads",
                                                                            {
                                                                                state: {
                                                                                    lead: data,
                                                                                },
                                                                            }
                                                                        );
                                                                    }
                                                                }}
                                                            >
                                                                <img
                                                                    src={update}
                                                                    alt="update"
                                                                />
                                                                <span>
                                                                    <small>
                                                                        Update
                                                                    </small>
                                                                </span>
                                                            </button>
                                                            <button
                                                                className="table-action del"
                                                                onClick={() =>
                                                                    deleteLead(
                                                                        data
                                                                    )
                                                                }
                                                            >
                                                                <img
                                                                    src={del}
                                                                    alt="del"
                                                                />
                                                                <span>
                                                                    <small>
                                                                        Delete
                                                                    </small>
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </>
                                            ) : (
                                                <>
                                                    <td>{data?.entityType}</td>
                                                    <td>
                                                        {data?.entityType ===
                                                            "Business" && (
                                                            <div className="bold">
                                                                {
                                                                    data
                                                                        ?.businessEntity
                                                                        ?.name
                                                                }
                                                            </div>
                                                        )}
                                                        {data?.entityType ===
                                                        "Business"
                                                            ? data
                                                                  ?.businessEntity
                                                                  ?.representative
                                                                  ?.firstName
                                                            : data
                                                                  ?.individualEntity
                                                                  ?.firstName}
                                                    </td>
                                                    <td>
                                                        <span className="number">
                                                            {data
                                                                ?.businessEntity
                                                                ?.representative
                                                                ?.contact ||
                                                                data
                                                                    ?.individualEntity
                                                                    ?.contact ||
                                                                "N/A"}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        {(reduxData?.userRoles
                                                            ?.currentRole ===
                                                            "RM Member" &&
                                                        data?.assignedFromRole ===
                                                            "Lead Coordinator"
                                                            ? getTeamLead(
                                                                  data?.team
                                                                      ?.teamLead
                                                              )?.username
                                                            : data?.assignedFrom
                                                                  ?.username) ||
                                                            "N/A"}
                                                    </td>
                                                    {reduxData?.userRoles
                                                        ?.currentRole ===
                                                        "RM Team Leader" &&
                                                        checkTeamLead() && (
                                                            <td>
                                                                {data
                                                                    ?.assignedTo
                                                                    ?.username ||
                                                                    "N/A"}
                                                            </td>
                                                        )}
                                                    <td>
                                                        <span
                                                            className={`status ${data?.status
                                                                ?.toLowerCase()
                                                                ?.split(" ")
                                                                .join("-")}`}
                                                        >
                                                            {data?.status}
                                                        </span>
                                                        <small>
                                                            {getSubStatus(
                                                                data?.status
                                                            )}
                                                        </small>
                                                    </td>
                                                    <td>
                                                        <div className="flex items-center">
                                                            <button
                                                                className="table-action view"
                                                                onClick={() => {
                                                                    setSelectedLead(
                                                                        data
                                                                    );
                                                                    setOpenDetailModal(
                                                                        true
                                                                    );
                                                                }}
                                                            >
                                                                <img
                                                                    src={view}
                                                                    alt="view"
                                                                />
                                                                <span>
                                                                    <small>
                                                                        View
                                                                    </small>
                                                                </span>
                                                            </button>
                                                            <button
                                                                className="table-action add"
                                                                onClick={() => {
                                                                    setOpenAddModal(
                                                                        true
                                                                    );
                                                                    setSelectedLead(
                                                                        data
                                                                    );
                                                                }}
                                                            >
                                                                <img
                                                                    src={add}
                                                                    alt="add"
                                                                />
                                                                <span>
                                                                    <small>
                                                                        Add Case
                                                                    </small>
                                                                </span>
                                                            </button>
                                                            {reduxData
                                                                ?.userRoles
                                                                ?.currentRole ===
                                                                "Lead Coordinator" && (
                                                                <button
                                                                    className="table-action view"
                                                                    onClick={() => {
                                                                        setSelectedLead(
                                                                            data
                                                                        );
                                                                        setOpenDetailModal(
                                                                            true
                                                                        );
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={
                                                                            view
                                                                        }
                                                                        alt="view"
                                                                    />
                                                                    <span>
                                                                        <small>
                                                                            View
                                                                        </small>
                                                                    </span>
                                                                </button>
                                                            )}

                                                            <button
                                                                className="table-action update cursor-pointer"
                                                                onClick={() => {
                                                                    let currentRole =
                                                                        reduxData
                                                                            ?.userRoles
                                                                            ?.currentRole;
                                                                    if (
                                                                        currentRole ===
                                                                        "RM Team Leader"
                                                                    ) {
                                                                        navigate(
                                                                            "/rm-lead/add-leads",
                                                                            {
                                                                                state: {
                                                                                    lead: data,
                                                                                },
                                                                            }
                                                                        );
                                                                    } else if (
                                                                        currentRole ===
                                                                        "RM Member"
                                                                    ) {
                                                                        navigate(
                                                                            "/rm-member/add-leads",
                                                                            {
                                                                                state: {
                                                                                    lead: data,
                                                                                },
                                                                            }
                                                                        );
                                                                    } else if (
                                                                        currentRole ===
                                                                        "Lead Coordinator"
                                                                    ) {
                                                                        navigate(
                                                                            "/lead-coordinator/add-leads",
                                                                            {
                                                                                state: {
                                                                                    lead: data,
                                                                                },
                                                                            }
                                                                        );
                                                                    }
                                                                }}
                                                            >
                                                                <img
                                                                    src={update}
                                                                    alt="update"
                                                                />
                                                                <span>
                                                                    <small>
                                                                        Update
                                                                    </small>
                                                                </span>
                                                            </button>

                                                            <button
                                                                className="table-action del"
                                                                onClick={() =>
                                                                    deleteLead(
                                                                        data
                                                                    )
                                                                }
                                                            >
                                                                <img
                                                                    src={del}
                                                                    alt="del"
                                                                />
                                                                <span>
                                                                    <small>
                                                                        Delete
                                                                    </small>
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </>
                                            )}
                                        </tr>
                                    </>
                                );
                            })}
                    </tbody>
                </table>
                {/* {filter === "all" && ( */}
                <div className="flex pagination-combine">
                    <EntriesSelection
                        setCount={setCount}
                        page={page}
                        count={count}
                        data={filtered?.filter((e) => {
                            if (startDate || endDate) {
                                return filterDataByDate(e);
                            } else {
                                return e;
                            }
                        })}
                    />
                    <Pagination
                        count={count}
                        data={filtered?.filter((e) => {
                            if (startDate || endDate) {
                                return filterDataByDate(e);
                            } else {
                                return e;
                            }
                        })}
                        setPage={setPage}
                        page={page}
                    />
                </div>
                {/* )} */}
            </div>
            {openAddModal && (
                <AddCase
                    setModalClick={() => {
                        setOpenAddModal(!openAddModal);
                    }}
                    addCase={addCase}
                />
            )}
            {/* <LeadDetailsModal
                    setModalClick={() => {
                        setOpenDetailModal(!openDetailModal);
                        setSelectedCase();
                    }}
                    case={selectedCase}
                    setOpenDetailModal={setOpenDetailModal}
                    getCases={getCases}
                /> */}
            {openDetailModal && (
                <LeadDetailsModal
                    setModalClick={() => {
                        setOpenDetailModal(!openDetailModal);
                    }}
                    setOpenDetailModal={() => {
                        setOpenDetailModal(!openDetailModal);
                    }}
                    lead={selectedLead}
                    getLeads={getLeads}
                    getCases={getCases}
                />
            )}
        </>
    );
}
