import React, { useState } from "react";
import DatePicker from "react-date-picker";
import { useSelector } from "react-redux";
import AreaReportGenerator from "../../components/reports/AreaReportGenerator";
import BarChartGenerator from "../../components/reports/BarChartGenerator";
import { api } from "../../helpers/apiHelper/requestHelper";
import Members from "../../pages/rm-lead/rm-dashboard/members/Members";
import "./styles.scss";
import lowIcon from "../../assets/images/lowIcon.svg";
import userIcon from "../../assets/images/users.svg";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import ReportTables from "./ReportTables";
import { setLoading } from "../../redux/loader/loadingSlice";
import { useDispatch } from "react-redux";
import { formatCurrency } from "../../helpers/dataHelper/currentFormatter";

export default function RMLeadReports() {
    const reduxData = useSelector((state) => state);
    const [activeTab, setActiveTab] = useState("tab1");
    const [pendingRev, setPendingRev] = useState("0");
    const [ownRev, setOwnRev] = useState("0");
    const [memberList, setMemberList] = useState([]);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const dispatch = useDispatch();

    useEffect(() => {
        if (reduxData?.auth) {
            getMembersData();
            getStartDate();
        }
    }, [reduxData?.auth]);

    const getStartDate = async () => {
        let response = await api("get", "settings/getDateSettings");

        if (response?.status) {
            setStartDate(new Date(response?.data?.startDate));
        }
    };

    const getDataWithDate = async () => {
        dispatch(setLoading(true));
        const response = await api("post", "teams/getMembersByTeam", {
            id: reduxData?.auth?.user?.team?._id,
            startDate,
            endDate,
        });

        if (response?.data?.status) {
            setMemberList(response?.data?.data?.members);
            setPendingRev(response?.data?.data?.pendingCaseRevenue);
        }
        dispatch(setLoading(false));
    };

    useEffect(() => {
        let year = new Date().getFullYear();
        setStartDate(new Date(year, 0, 1));
        setEndDate(new Date());
    }, []);

    useEffect(() => {
        if (startDate && endDate) {
            getDataWithDate();
        }
    }, [startDate, endDate]);

    const getMembersData = async () => {
        const response = await api("post", "teams/getMembersByTeam", {
            id: reduxData?.auth?.user?.team?._id,
        });

        if (response?.data?.status) {
            setMemberList(response?.data?.data?.members);
            setPendingRev(response?.data?.data?.pendingCaseRevenue);
        }
    };

    const checkTeamLead = () => {
        let found;
        let id = reduxData?.auth?.user?._id;
        reduxData?.allTeams?.value?.teams?.forEach((element) => {
            element?.members?.forEach((e) => {
                if (e?._id === id) {
                    found = element;
                }
            });
        });

        if (found && found?.teamLead?._id === id) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <>
            <div className="rml-container">
                <div>Report</div>
                <div className="flex">
                    <div>
                        <p>Start Date</p>
                        <DatePicker
                            onChange={(date) => setStartDate(date)}
                            selectsStart
                            startDate={startDate}
                            endDate={endDate}
                            className="form-control"
                            onKeyDown={(e) => {
                                e.preventDefault();
                            }}
                            value={startDate}
                            format="dd/MM/y"
                        />
                    </div>
                    <div className="ml-20">
                        <p>End Date</p>
                        <DatePicker
                            onChange={(date) => setEndDate(date)}
                            selectsEnd
                            startDate={startDate}
                            endDate={endDate}
                            minDate={startDate}
                            value={endDate}
                            className="form-control"
                            onKeyDown={(e) => {
                                e.preventDefault();
                            }}
                            format="dd/MM/y"
                        />
                    </div>
                </div>
            </div>

            {checkTeamLead() && (
                <div className="div5">
                    {memberList?.length && (
                        <Members members={memberList} heading="All Members" />
                    )}
                </div>
            )}
            <ReportTables startDate={startDate} endDate={endDate} />
        </>
    );
}
