import { useEffect, useState } from "react";
import "./modals.scss";
import crossIcon from "../../assets/images/cross.svg";

export const PdpaModal = (props) => {
    const [docs, setDocs] = useState({
        internalForms: [],
        requiredDocuments: [],
    });

    useEffect(() => {
        setDocs({
            internalForms: props?.case?.product?.internalForms || [],
            requiredDocuments:
                props?.case?.product?.documents?.filter((e) => {
                    let found = props?.case?.caseDocuments?.find(
                        (f) => f.documentId === e._id
                    );
                    if (found) {
                        return e;
                    }
                }) || [],
        });
    }, [props.case]);

    return (
        <div className={`modal-wrapper`}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-body ">
                        <img
                            src={crossIcon}
                            className="cross-btn"
                            onClick={props.setShow}
                            alt="crossIcon"
                        />
                        <div className="pdpa-deletion">
                            <h4>PDPA Deletion</h4>
                            <div className="flex content-justify-center column-direction">
                                <div className="pdpa-divs">
                                    <div className="pad-left">
                                        <h4>Internal Forms</h4>
                                        {docs?.internalForms?.map((e, i) => (
                                            <div className="form-group">
                                                <label
                                                    className="check-box"
                                                    htmlFor={i + "if"}
                                                >
                                                    <input
                                                        id={i + "if"}
                                                        type="checkbox"
                                                        checked={
                                                            e.pdpa || false
                                                        }
                                                        onChange={(e) => {
                                                            let iForms = [
                                                                ...docs.internalForms,
                                                            ];
                                                            iForms[i] = {
                                                                ...iForms[i],
                                                                pdpa: e.target
                                                                    .checked,
                                                            };
                                                            setDocs({
                                                                internalForms:
                                                                    iForms,
                                                                requiredDocuments:
                                                                    docs.requiredDocuments,
                                                            });
                                                        }}
                                                    />
                                                    <span>{e.name}</span>
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="pad-left">
                                        <h4>Required Documents</h4>
                                        {docs?.requiredDocuments?.map(
                                            (e, i) => (
                                                <div className="form-group">
                                                    <label
                                                        className="check-box"
                                                        htmlFor={i + "rd"}
                                                    >
                                                        <input
                                                            id={i + "rd"}
                                                            type="checkbox"
                                                            checked={
                                                                e.pdpa || false
                                                            }
                                                            onChange={(e) => {
                                                                let rDocs = [
                                                                    ...docs.requiredDocuments,
                                                                ];
                                                                rDocs[i] = {
                                                                    ...rDocs[i],
                                                                    pdpa: e
                                                                        .target
                                                                        .checked,
                                                                };
                                                                setDocs({
                                                                    requiredDocuments:
                                                                        rDocs,
                                                                    internalForms:
                                                                        docs.internalForms,
                                                                });
                                                            }}
                                                        />
                                                        <span>{e?.name}</span>
                                                    </label>
                                                </div>
                                            )
                                        )}
                                    </div>
                                </div>
                                <button
                                    onClick={() => {
                                        let iForms = [
                                            ...docs.internalForms,
                                        ].filter((e) => e.pdpa);
                                        let rDocs = [
                                            ...docs.requiredDocuments,
                                        ].filter((e) => e.pdpa);

                                        props?.confirm({
                                            internalForms: iForms.map(
                                                (e) => e._id
                                            ),
                                            requiredDocuments: rDocs.map(
                                                (e) => e._id
                                            ),
                                            caseId: props?.case?._id,
                                        });
                                    }}
                                    className="simple-btn"
                                >
                                    PDPA Deletion
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
