import React from "react";
import { useSelector } from "react-redux";
import CaseManagerReports from "../../../components/reports/CaseManagerReports";
import LeadCoordinatorReports from "../../../components/reports/LeadCoordinatorReports";
import RMLeadReports from "../../../components/reports/RMLeadReports";
import RMMemberReports from "../../../components/reports/RMMemberReports";
import AdminReports from "../../../components/reports/AdminReports";

export default function Reports() {
    const role = useSelector((state) => state.userRoles.currentRole);

    return (
        <div>
            {role === "RM Team Leader" && <RMLeadReports />}
            {role === "RM Member" && <RMMemberReports />}
            {role === "Admin" && <AdminReports />}
            {role === "Case Manager" && <CaseManagerReports />}
            {role === "Lead Coordinator" && <LeadCoordinatorReports />}
        </div>
    );
}
