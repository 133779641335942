import { useEffect } from "react";
import "./modals.scss";

export const CashOutModal = (props) => {
    const numberRegex = new RegExp(`^((0|([1-9][0-9]*))([.]|[.][0-9]{1,2})?)?$`);

    // useEffect(() => {
    //     if (props?.amount) {
    //         props?.setCashOut({
    //             ...props?.cashOut,
    //             amount: props?.amount,
    //         });
    //     }
    // }, [props?.amount]);

    return (
        <div className={`modal-wrapper inner_modal`}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-body ">
                        <div className="modal-forms">
                            <ul className="list-style-none">
                                <li>
                                    <div className="form-group">
                                        <label>Cash Out Amount</label>

                                        <input
                                            value={props?.cashOut?.amount}
                                            className="form-control"
                                            placeholder="Enter Cash Out Amount"
                                            onChange={(ev) => {
                                                let value = ev.target.value;
                                                if (
                                                    numberRegex.test(value) ||
                                                    // &&
                                                    //     parseInt(value) <=
                                                    //         parseInt(
                                                    //             props?.cashOut
                                                    //                 ?.maxAmount
                                                    //         )
                                                    value === ""
                                                ) {
                                                    props?.setCashOut({
                                                        ...props?.cashOut,
                                                        amount: value,
                                                    });
                                                }
                                            }}
                                        />
                                    </div>
                                </li>
                                <li>
                                    <div className="flex content-justify-center">
                                        <button
                                            onClick={() => {
                                                props?.setCashOut({
                                                    ...props?.cashOut,
                                                    cashOutModal: false,
                                                });
                                                props?.setSelectedBank();
                                            }}
                                            className="modal-btn cancel"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            onClick={() => {
                                                props?.submitCashOut();
                                            }}
                                            className="modal-btn"
                                        >
                                            Confirm
                                        </button>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
