import view from "../../assets/images/view.svg";
import Search from "../../components/search/Search";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useState } from "react";
import EntriesSelection from "../../components/pagination/EntriesSelection";
import Pagination from "../../components/pagination/Pagination";
import { ProductDetail } from "../../components/modals/ProductDetail";

export default function ProductList() {
    const products = useSelector((state) => state?.productForms?.value);
    const [filtered, setFiltered] = useState([]);
    const [count, setCount] = useState(5);
    const [page, setPage] = useState(1);
    const [show, setShow] = useState(false);
    const [product, setProduct] = useState();

    useEffect(() => {
        setFiltered(products);
    }, [products]);

    return (
        <>
            <h4 className="flex flex-wrap mini-title">Products</h4>
            <Search
                userSearch={true}
                filedText="Search Product"
                teamDropdown={false}
                filters={false}
                setProducts={setFiltered}
                products={products}
                component={"Product"}
            />
            {show && (
                <ProductDetail close={() => setShow(false)} product={product} />
            )}
            <div className="responsive-table activity_wrap w-100">
                <table className="table">
                    <thead>
                        <tr
                            className="no-shadow"
                            style={{ background: "#f6f6f6" }}
                        >
                            <td colSpan="1" height="16"></td>
                        </tr>
                        <tr>
                            <th>Product Name</th>
                            {/* <th>Description</th>
                        <th>From Date</th>
                        <th>To Date</th>
                        <th>Ack Status</th>
                        <th>Action</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {filtered
                            ?.slice(count * page - count, count * page)
                            ?.map((data, index) => {
                                return (
                                    <>
                                        <tr className="no-shadow">
                                            <td colSpan="1" height="16"></td>
                                        </tr>
                                        <tr key={index}>
                                            <td
                                                onClick={() => {
                                                    setProduct(data);
                                                    setShow(true);
                                                }}
                                                className="cursor-pointer"
                                            >
                                                {data?.name}
                                            </td>
                                        </tr>
                                    </>
                                );
                            })}
                    </tbody>
                </table>
                <div className="flex pagination-combine">
                    <EntriesSelection
                        setCount={setCount}
                        page={page}
                        count={count}
                        data={filtered}
                    />
                    <Pagination
                        count={count}
                        data={filtered}
                        setPage={setPage}
                        page={page}
                    />
                </div>
            </div>
        </>
    );
}
