import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    folderStructure: null,
    folderModal: "",
};

export const folderStructureSlice = createSlice({
    name: "folderStructureSlice",
    initialState,
    reducers: {
        storeFolders: (state, action) => {
            state.folderStructure = action.payload;
        },
        setFolderModal: (state, action) => {
            state.folderModal = action.payload;
        },
    },
});

export const { storeFolders, setFolderModal } = folderStructureSlice.actions;

export default folderStructureSlice.reducer;
