import React, { useState } from "react";
import DatePicker from "react-date-picker";

const RenderElements = ({
    _id,
    id,
    type,
    value,
    placeholder,
    onChange,
    text,
    options,
    label,
    submit,
    selectedOptions,
    upload,
    disabled,
    filename,
    url,
}) => {
    return (
        <li className="w-100 flex items-start">
            <div className="form-group">
                {label && (
                    <label>
                        {label}
                        {url && (
                            <span
                                style={{
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                    marginLeft: 20,
                                }}
                                onClick={() => window.open(url)}
                            >
                                {filename}
                            </span>
                        )}
                    </label>
                )}

                {type === "heading" && (
                    <h2 className="builder-title mb-20">{text}</h2>
                )}

                {type === "textarea" && (
                    <textarea
                        className="form-control mb-20"
                        value={value}
                        onChange={(e) => onChange(_id, e.target.value)}
                        placeholder={placeholder}
                        disabled={disabled}
                    />
                )}

                {type === "input" && (
                    <input
                        type={type}
                        className="form-control mb-20"
                        value={value}
                        onChange={(e) => onChange(_id, e.target.value)}
                        placeholder={placeholder}
                        disabled={disabled}
                    />
                )}

                {type === "date" && (
                    <DatePicker
                        format="dd/MM/y"
                        className="form-control mb-20"
                        onChange={(e) => onChange(_id, e)}
                        value={value}
                        onKeyDown={(e) => {
                            e.preventDefault();
                        }}
                        disabled={disabled}
                    />
                )}

                {/* {type === "button" && (
                    <button className="simple-btn mb-20" onClick={submit}>
                        {text}
                    </button>
                )} */}

                {type === "file" && (
                    <div className="mb-20">
                        <div className="input-row">
                            <input
                                type="file"
                                name={_id}
                                id={_id}
                                class="inputfile"
                                onChange={(f) => {
                                    if (f.target.files[0]) {
                                        upload(f.target.files[0], _id);
                                    }
                                }}
                                disabled={disabled}
                            />

                            <label for={_id}>Choose a file</label>
                            {value && (
                                <button
                                    className="cursor-pointer upload-btn"
                                    target="_blank"
                                    onClick={() => window.open(value, "_blank")}
                                >
                                    Download File
                                </button>
                            )}
                        </div>
                        {/* <span>{value}</span> */}
                    </div>
                )}

                {type === "checkbox" && (
                    <div className="mb-20">
                        {options.map((e, i) => (
                            <label htmlFor={i + "-" + id} className="check-box">
                                <input
                                    id={i + "-" + id}
                                    type="checkbox"
                                    title={e}
                                    checked={selectedOptions.includes(e)}
                                    disabled={disabled}
                                    onChange={(f) => {
                                        if (f.target.checked) {
                                            let arr = [...selectedOptions];
                                            arr.push(e);
                                            onChange(_id, null, arr);
                                        } else {
                                            let arr = [
                                                ...selectedOptions,
                                            ].filter((el) => el !== e);
                                            onChange(_id, null, arr);
                                        }
                                    }}
                                />
                                <span>{e}</span>
                            </label>
                        ))}
                    </div>
                )}

                {type === "radio" && (
                    <div className="mb-20">
                        {options.map((e) => (
                            <label id="group1" className="check-radio ">
                                <input
                                    type="radio"
                                    name="group1"
                                    disabled={disabled}
                                    checked={selectedOptions[0] === e}
                                    onChange={(f) => {
                                        if (f.target.checked) {
                                            onChange(_id, null, [e]);
                                        } else {
                                            onChange(_id, null, []);
                                        }
                                    }}
                                />
                                <span>{e}</span>
                            </label>
                        ))}
                    </div>
                )}

                {type === "select" && (
                    <select
                        className="form-control field-margin  w-300 mb-20"
                        value={value}
                        onChange={(e) => onChange(_id, e.target.value)}
                        disabled={disabled}
                    >
                        <option value="" style={{ display: "none" }}>
                            Select
                        </option>

                        {options.map((e) => (
                            <option value={e}>{e}</option>
                        ))}
                    </select>
                )}
            </div>
        </li>
    );
};

export default RenderElements;
