import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { api } from "../../../../helpers/apiHelper/requestHelper";
import { toastify } from "../../../../helpers/toast/toastify";
import { setBusinessEntities } from "../../../../redux/businessEntities/bEntitySlice";
import { setIndividualEntities } from "../../../../redux/individualEntities/iEntitySlice";
import { storeTeams } from "../../../../redux/teams/teamsSlice";
import { missingPopup } from "../../../../helpers/dataHelper/missingData";
import { getLeadUsers } from "../../../../helpers/dataHelper/getCaseUsers";
import { setLoading } from "../../../../redux/loader/loadingSlice";

export default function AddLeads() {
    const { state } = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [leadType, setLeadType] = useState("Personal");
    const [entityType, setEntityType] = useState("Business");
    const [selectedEntity, setSelectedEntity] = useState();
    const [selectedMember, setSelectedMember] = useState();
    const [selectedTeam, setSelectedTeam] = useState();
    const [entities, setEntities] = useState([]);
    const reduxData = useSelector((state) => state);

    useEffect(() => {
        if (state?.redirectData) {
            let data = state?.redirectData;
            setLeadType(data?.leadType);
            setEntityType(data?.entityType);
            setSelectedTeam(data?.selectedTeam);
            setSelectedMember(data?.selectedMember);
            setSelectedEntity(data?.entity?._id);
        }
    }, [state?.redirectData]);

    const getEntity = () => {
        let ents = [];
        reduxData?.businessEntities?.value?.forEach((e) => {
            ents.push(e);
        });
        reduxData?.individualEntities?.value?.forEach((e) => {
            ents.push(e);
        });

        return ents?.find((e) => e._id === selectedEntity);
    };

    const getTeams = async () => {
        let response = await api("get", "teams/index");
        if (response?.status) {
            dispatch(storeTeams(response?.data));
        } else {
            toastify(response?.message, "error");
        }
    };

    const getBusinessEntities = async () => {
        let response = await api(
            "get",
            reduxData?.userRoles?.currentRole === "RM Member" ||
                reduxData?.userRoles?.currentRole === "RM Team Leader"
                ? "businessEntity/index"
                : "businessEntity/unfilteredIndex"
        );
        if (response?.status) {
            dispatch(setBusinessEntities(response?.data?.reverse()));
        } else {
            toastify(response?.message, "error");
        }
    };

    const getIndividualEntities = async () => {
        let response = await api(
            "get",
            reduxData?.userRoles.currentRole === "RM Member" ||
                reduxData?.userRoles.currentRole === "RM Team Leader"
                ? "individualEntity/index"
                : "individualEntity/unfilteredIndex"
        );
        if (response?.status) {
            dispatch(setIndividualEntities(response?.data?.reverse()));
        } else {
            toastify(response?.message, "error");
        }
    };

    const checkDisabled = () => {
        if (!state?.lead && reduxData?.auth?.user?.addCorporateLeads) {
            if (reduxData?.userRoles?.currentRole === "RM Team Leader") {
                return false;
            } else if (
                reduxData?.userRoles?.currentRole === "RM Member" &&
                reduxData?.auth?.user?.addCorporateLeads
            ) {
                return false;
            } else {
                if (
                    state?.lead?.assignedFrom?._id ===
                    reduxData?.auth?.user?._id
                ) {
                    return false;
                }
                if (reduxData?.userRoles?.currentRole === "Lead Coordinator") {
                    return true;
                }
                return true;
            }
        } else {
            // if (
            //     reduxData?.userRoles?.currentRole === "RM Member" &&
            //     reduxData?.auth?.user?.addCorporateLeads &&
            //     state?.lead?.assignedFrom?._id === reduxData?.auth?.user?._id
            // ) {
            //     return false;
            // }
            return true;
        }
    };

    const checkEnabled = () => {
        if (reduxData?.userRoles?.currentRole === "RM Team Leader") {
            return true;
        } else {
            if (
                state?.lead?.assignedFrom?._id === reduxData?.auth?.user?._id ||
                reduxData?.userRoles?.currentRole === "Lead Coordinator"
            ) {
                return true;
            }

            return false;
        }
    };

    useEffect(() => {
        if (
            reduxData?.userRoles?.currentRole === "RM Team Leader" &&
            ((!state?.lead && reduxData?.auth?.user?.team) ||
                (state?.lead &&
                    !state?.lead?.assignedTo &&
                    reduxData?.auth?.user?.team))
        ) {
            setSelectedTeam(
                reduxData?.allTeams?.value?.teams?.find(
                    (e) => e._id === reduxData?.auth?.user?.team?._id
                )
            );
        } else if (
            reduxData?.userRoles?.currentRole === "RM Member" &&
            reduxData?.auth?.user?.team
        ) {
            setSelectedTeam(
                reduxData?.allTeams?.value?.teams?.find(
                    (e) => e._id === reduxData?.auth?.user?.team?._id
                )
            );
        }
    }, [
        reduxData?.auth?.user?.team,
        state?.lead,
        reduxData?.allTeams?.value?.teams?.length,
    ]);

    useEffect(() => {
        if (
            reduxData?.allTeams?.value?.teams?.length &&
            state?.lead &&
            state?.lead?.team
        ) {
            setSelectedTeam(
                reduxData?.allTeams?.value?.teams?.find(
                    (e) => e._id === state?.lead?.team?._id
                )
            );
        }
    }, [reduxData?.allTeams?.value]);

    useEffect(() => {
        getTeams();
        getBusinessEntities();
        getIndividualEntities();
        if (reduxData?.userRoles?.currentRole === "Lead Coordinator") {
            setLeadType("Corporate");
        }
    }, []);

    useEffect(() => {
        if (state?.lead) {
            let data = state?.lead;
            setLeadType(data?.leadType);
            setEntityType(data?.entityType);
            if (data?.individualEntity) {
                setSelectedEntity(data?.individualEntity?._id);
            } else {
                setSelectedEntity(data?.businessEntity?._id);
            }
            if (data?.team?._id) {
                setSelectedTeam(
                    reduxData?.allTeams?.value?.teams?.find(
                        (e) => e._id === data?.team?._id
                    )
                );
            }
            setSelectedMember(data?.assignedTo?._id);
        }
    }, [state?.lead]);

    useEffect(() => {
        if (state?.entity) setSelectedEntity(state?.entity?._id);
    }, [state?.entity]);

    useEffect(() => {
        if (entityType === "Individual") {
            setEntities(reduxData?.individualEntities?.value);
        } else {
            setEntities(reduxData?.businessEntities?.value);
        }
        if (!state?.lead && !state?.redirectData) {
            setSelectedEntity("");
        }
    }, [entityType]);

    const submit = async () => {
        let data = {
            leadType,
            entityType,
            status: "Pending",

            logs: [
                {
                    date: new Date(),
                    operator: reduxData?.auth?.user?._id,
                    comment: "Lead created.",
                },
            ],
        };

        if (
            !(
                reduxData?.userRoles?.currentRole === "RM Team Leader" &&
                state?.lead &&
                state?.lead?.assignedFromRole === "Lead Coordinator"
            )
        ) {
            data = {
                ...data,
                assignedFrom: reduxData?.auth?.user?._id,
                assignedFromRole: reduxData?.userRoles?.currentRole,
            };
        }

        if (selectedMember) {
            if (
                state?.lead &&
                state?.lead?.assignedTo &&
                state?.lead?.assignedTo?._id !== selectedMember
            ) {
                data = { ...data, reassigned: true };
            }
            data = { ...data, assignedTo: selectedMember };
        }

        if (selectedTeam) {
            data = { ...data, team: selectedTeam._id };
            // if (
            //     !selectedMember &&
            //     reduxData?.userRoles?.currentRole !== "RM Team Leader"
            // ) {
            //     data = { ...data, assignedTo: selectedTeam?.teamLead?._id };
            // }
        }
        if (state?.lead && !selectedTeam) {
            data = { ...data, team: null, assignedTo: null };
        }

        if (
            reduxData?.userRoles?.currentRole === "RM Member" &&
            reduxData?.auth?.user?.team &&
            (!reduxData?.auth?.user?.reAssignLeads || !selectedMember) &&
            !state?.lead
        ) {
            data = { ...data, assignedTo: reduxData?.auth?.user?._id };
        }

        if (entityType === "Business") {
            data = { ...data, businessEntity: selectedEntity };
        } else {
            data = { ...data, individualEntity: selectedEntity };
        }
        if (!leadType) missingPopup("Lead Type");
        else if (!entityType) missingPopup("Entity Type");
        else if (!selectedEntity) missingPopup("Entity");
        else {
            dispatch(setLoading(true));
            if (state?.lead) {
                const response = await api("post", "leads/update", {
                    ...data,
                    id: state?.lead?._id,
                });
                if (response?.data?.status) {
                    let allUsers = getLeadUsers(response?.data?.data);
                    if (!allUsers.includes(reduxData?.auth?.user?._id)) {
                        allUsers.push(reduxData?.auth?.user?._id);
                    }
                    await api("post", "activity/create", {
                        activity: `${response?.data?.data?.identifier} - Lead has been updated.`,
                        users: allUsers,
                    });
                    dispatch(setLoading(false));

                    navigate(-1);
                    toastify("Lead Updated Successfully", "success");
                } else {
                    toastify(response?.message, "error");
                    dispatch(setLoading(false));
                }
            } else {
                const response = await api("post", "leads/create", data);
                dispatch(setLoading(false));

                if (response?.data?.status) {
                    navigate("/case-manager/leads/all");
                    toastify("Lead Added Successfully", "success");
                } else {
                    toastify(response?.message, "error");
                }
            }
            dispatch(setLoading(false));
        }
    };

    const checkTeamLead = (id) => {
        let found;
        reduxData?.allTeams?.value?.teams?.forEach((element) => {
            element?.members?.forEach((e) => {
                if (e?._id === id) {
                    found = element;
                }
            });
        });

        if (found && found?.teamLead?._id === id) {
            return " ( Team Leader )";
        }
    };

    return (
        <>
            <h4 className="flex flex-wrap items-center mini-title">
                <span onClick={() => navigate("/case-manager/leads/all")}>
                    Leads /{" "}
                </span>{" "}
                {state?.lead ? "Edit" : "Add"} Lead{" "}
            </h4>
            {state?.lead && (
                <h5 className="flex flex-wrap userid">
                    ID: <span>{state?.lead?.identifier}</span>
                </h5>
            )}
            <div className="card">
                <div className="card-body">
                    <div className="edit-form-wrapper">
                        <ul className="list-style-none flex flex-wrap">
                            <li>
                                <div className="form-group">
                                    <label htmlFor="">Lead Type *</label>
                                    {reduxData?.userRoles?.currentRole ===
                                    "Lead Coordinator" ? (
                                        <select
                                            disabled={
                                                !reduxData?.auth?.user
                                                    ?.addCorporateLeads
                                            }
                                            className="form-control mandatory"
                                            onChange={(e) =>
                                                setLeadType(e.target.value)
                                            }
                                            value={leadType}
                                        >
                                            <option value="Corporate">
                                                Corporate
                                            </option>
                                        </select>
                                    ) : (
                                        <select
                                            disabled={checkDisabled()}
                                            className="form-control mandatory"
                                            onChange={(e) =>
                                                setLeadType(e.target.value)
                                            }
                                            value={leadType}
                                        >
                                            <option value="Personal">
                                                Personal
                                            </option>
                                            <option value="Corporate">
                                                Corporate
                                            </option>
                                        </select>
                                    )}
                                </div>
                            </li>
                            <li>
                                <div className="form-group">
                                    <label htmlFor="">Entity Type *</label>
                                    <select
                                        className="form-control mandatory"
                                        onChange={(e) =>
                                            setEntityType(e.target.value)
                                        }
                                        disabled={
                                            (reduxData?.userRoles
                                                ?.currentRole ===
                                                "RM Team Leader" &&
                                                state?.lead) ||
                                            (state?.lead &&
                                                reduxData?.userRoles
                                                    ?.currentRole ===
                                                    "Lead Coordinator") ||
                                            (state?.lead &&
                                                reduxData?.userRoles
                                                    ?.currentRole ===
                                                    "RM Member")
                                        }
                                        value={entityType}
                                    >
                                        <option value="Individual">
                                            Individual
                                        </option>
                                        <option value="Business">
                                            Business
                                        </option>
                                    </select>
                                </div>
                            </li>
                            <li>
                                <div className="form-group">
                                    <label htmlFor="">List Of Entities *</label>
                                    <select
                                        onChange={(e) =>
                                            setSelectedEntity(e.target.value)
                                        }
                                        disabled={
                                            (reduxData?.userRoles
                                                ?.currentRole ===
                                                "RM Team Leader" &&
                                                state?.lead) ||
                                            (state?.lead &&
                                                reduxData?.userRoles
                                                    ?.currentRole ===
                                                    "Lead Coordinator") ||
                                            (state?.lead &&
                                                reduxData?.userRoles
                                                    ?.currentRole ===
                                                    "RM Member")
                                        }
                                        value={selectedEntity}
                                        className="form-control mandatory"
                                    >
                                        <option
                                            value=""
                                            style={{ display: "none" }}
                                        >
                                            Select Entity
                                        </option>
                                        {(entityType === "Business"
                                            ? reduxData?.businessEntities?.value
                                            : reduxData?.individualEntities
                                                  ?.value
                                        )?.map((e) => (
                                            <option value={e?._id}>
                                                {e?.name ||
                                                    e?.firstName +
                                                        " " +
                                                        e?.lastName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </li>
                            <li className="flex items-center">
                                {selectedEntity && (
                                    <button
                                        onClick={() => {
                                            if (entityType === "Business") {
                                                navigate(
                                                    "/case-manager/business-entity",
                                                    {
                                                        state: {
                                                            entity: getEntity(),
                                                            redirectData: {
                                                                leadType,
                                                                entityType,
                                                                selectedMember,
                                                                selectedTeam,
                                                            },
                                                        },
                                                    }
                                                );
                                            } else {
                                                navigate(
                                                    "/case-manager/individual-entity",
                                                    {
                                                        state: {
                                                            entity: getEntity(),
                                                            redirectData: {
                                                                leadType,
                                                                entityType,
                                                                selectedMember,
                                                                selectedTeam,
                                                            },
                                                        },
                                                    }
                                                );
                                            }
                                        }}
                                        className="simple-btn cancel add-entity-btn cursor-pointer"
                                    >
                                        Edit Entity
                                    </button>
                                )}
                                <div
                                    onClick={() => {
                                        if (entityType === "Business") {
                                            navigate(
                                                "/case-manager/business-entity",
                                                {
                                                    state: {
                                                        navigate_lead: true,
                                                    },
                                                }
                                            );
                                        } else {
                                            navigate(
                                                "/case-manager/individual-entity",
                                                {
                                                    state: {
                                                        navigate_lead: true,
                                                    },
                                                }
                                            );
                                        }
                                    }}
                                    className="simple-btn add-entity-btn cursor-pointer"
                                >
                                    Add Entity
                                </div>
                            </li>
                            <li>
                                <div className="form-group">
                                    <label className="unflex" htmlFor="">
                                        Team
                                        <small>Assign to</small>
                                    </label>
                                    <select
                                        className="form-control"
                                        onChange={(e) => {
                                            if (
                                                e.target.value === "unassigned"
                                            ) {
                                                setSelectedTeam("");
                                            } else {
                                                setSelectedTeam(
                                                    JSON.parse(e.target.value)
                                                );
                                                setSelectedMember();
                                            }
                                        }}
                                        value={
                                            selectedTeam === "unassigned" ||
                                            JSON.stringify(selectedTeam)
                                        }
                                    >
                                        <option style={{ display: "none" }}>
                                            Select Team
                                        </option>

                                        <option value={"unassigned"}>
                                            Unassigned
                                        </option>
                                        {reduxData?.allTeams?.value?.teams?.map(
                                            (e) => (
                                                <option
                                                    value={JSON.stringify(e)}
                                                >
                                                    {e?.name}
                                                </option>
                                            )
                                        )}
                                    </select>
                                </div>
                            </li>

                            <li>
                                <div className="form-group">
                                    <label htmlFor="" className="unflex">
                                        Team Member <small>Assign to</small>
                                    </label>
                                    <select
                                        className="form-control"
                                        onChange={(e) =>
                                            setSelectedMember(e.target.value)
                                        }
                                        value={selectedMember}
                                        disabled={
                                            reduxData?.userRoles
                                                ?.currentRole === "RM Member" &&
                                            !reduxData?.auth?.user
                                                ?.reAssignLeads
                                        }
                                    >
                                        <option
                                            style={{ display: "none" }}
                                            value=""
                                        >
                                            Select Member
                                        </option>

                                        {reduxData?.allTeams?.value?.teams
                                            ?.find(
                                                (e) =>
                                                    e._id === selectedTeam?._id
                                            )
                                            ?.members?.map((e) => (
                                                <option value={e?._id}>
                                                    {e?.username}
                                                    {checkTeamLead(e._id)}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="flex items-start content-justify-center">
                <button
                    className="simple-btn cancel"
                    onClick={() => {
                        navigate("/case-manager/leads/all");
                        // navigate(-1);
                    }}
                >
                    Cancel
                </button>
                <button
                    className="simple-btn submit"
                    disabled={
                        reduxData?.userRoles?.currentRole === "RM Member" &&
                        state?.lead &&
                        state?.lead?.assignedFrom?._id !==
                            reduxData?.auth?.user?._id &&
                        !reduxData?.auth?.user?.reAssignLeads
                    }
                    onClick={submit}
                >
                    Submit
                </button>
            </div>
        </>
    );
}
