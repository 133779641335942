export const convertBytesToMbsKbs = (bytesValue) => {
    const megabytes = bytesValue / (1024 * 1024);
    const kilobytes = bytesValue / 1024;

    if (megabytes < 1) {
        return kilobytes.toFixed(2) + " KB";
    } else {
        return megabytes.toFixed(2) + " MB";
    }
};
