import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    value: [],
};

export const notificationsSlice = createSlice({
    name: "notificationsSlice",
    initialState,
    reducers: {
        setUserNotifications: (state, action) => {
            state.value = action.payload;
        },
    },
});

export const { setUserNotifications } = notificationsSlice.actions;

export default notificationsSlice.reducer;
