import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    value: [],
};

export const leadsSlice = createSlice({
    name: "leadsSlice",
    initialState,
    reducers: {
        storeLeads: (state, action) => {
            state.value = action.payload;
        },
    },
});

export const { storeLeads } = leadsSlice.actions;

export default leadsSlice.reducer;
