import "./modals.scss";
import crossIcon from "../../assets/images/cross.svg";
import del from "../../assets/images/trash.svg";
import add from "../../assets/images/add-icon.svg";
import Multiselect from "multiselect-react-dropdown";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { missingPopup } from "../../helpers/dataHelper/missingData";
import { api } from "../../helpers/apiHelper/requestHelper";
import { toastify } from "../../helpers/toast/toastify";
import { useEffect } from "react";
import { setLoading } from "../../redux/loader/loadingSlice";
import addIcon from "../../assets/images/add.svg";
import uploadIcon from "../../assets/images/upload.svg";
import axios from "axios";

const config = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
});

export const AddProduct = (props) => {
    const [items, setItems] = useState([]);
    const [isActive, setIsActive] = useState(true);
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [price, setPrice] = useState(0);
    const [advisoryFee, setAdvisoryFee] = useState("fixed");
    const [docs, setDocs] = useState([
        { name: "", input: false, upload: false, pdpa: true, url: "" },
    ]);
    const dispatch = useDispatch();
    const numberRegex = new RegExp(`^((0|([1-9][0-9]*))([.]|[.][0-9]{1,2})?)?$`);
    const percentageRegex = new RegExp(`^[+-]?\d+(\.\d+)?(?!\.\d+)$`);
    const fileInputRef = useRef(null);

    const internalForms = useSelector((state) => state.internalForms.value);

    const handleSelect = (selectedList) => {
        setItems(selectedList);
    };

    const handleRemove = (selectedList) => {
        setItems(selectedList);
    };

    const uploadFile = async (file, index) => {
        dispatch(setLoading(true));
        try {
            let fd = new FormData();
            fd.append("file", file);
            fd.append("type", "Product Required Documentation");
            if (props?.product) {
                fd.append("product", props?.product?._id);
            }
            let response = await config.post("auth/uploadFile", fd);

            if (response?.data?.status) {
                let url = response?.data?.data;
                if (url) {
                    let _docs = [...docs];
                    _docs[index].url = url;
                    _docs[index].filename = file.name;
                    setDocs(_docs);
                } else {
                    toastify("Unable to upload document.");
                }
            } else {
                toastify(response?.message);
            }
        } catch (error) {
            console.log({ error });
            toastify(error?.response?.data?.message);
        }

        dispatch(setLoading(false));
    };

    const setAmount = (e) => {
        let value = e.target.value;
        if (value !== "00") {
            if (advisoryFee === "percentage") {
                if (
                    (numberRegex.test(value) &&
                        parseFloat(value) <= 100 &&
                        parseFloat(value) >= 0) ||
                    value === ""
                ) {
                    if (
                        (value.length === 3 &&
                            value.charAt(1) === "." &&
                            value.charAt(2) === ".") ||
                        (value.length === 2 &&
                            value.charAt(0) === "0" &&
                            value.charAt(1) !== "0" &&
                            value.charAt(1) !== ".") ||
                        value?.match(/\./g)?.length === 2
                    ) {
                        return;
                    } else {
                        setPrice(value);
                    }
                }
            } else {
                if (numberRegex.test(value) || value === "") {
                    setPrice(value);
                }
            }
        }
    };

    const submit = async () => {
        if (!name) missingPopup("Product Name");
        else if (!description) missingPopup("Product Specifications");
        else if (!price) missingPopup("Advisory Fee");
        else if (!(price && /^[+-]?\d+(\.\d+)?$/.test(price)))
            toastify("Invalid price.");
        else if (docs?.find((e) => !e.name))
            toastify("Documentation Name cannot be empty");
        else {
            let data = {
                name,
                description,
                documents: docs.filter((e) => e.name !== ""),
                internalForms: items.map((e) => e._id),
                isActive,
                price,
                advisoryFee,
            };
            if (props.product) {
                dispatch(setLoading(true));
                const response = await api("post", "products/update", {
                    ...data,
                    id: props.product?._id,
                });
                dispatch(setLoading(false));

                if (response?.data?.status) {
                    toastify("Product Updated Successfully!", "success");
                    props.setModalClick();
                    props.populateData();
                    resetData();
                } else {
                    toastify(response?.message);
                }
            } else {
                dispatch(setLoading(true));
                const response = await api("post", "products/create", data);
                dispatch(setLoading(false));

                if (response?.data?.status) {
                    toastify("Product Added Successfully!", "success");
                    props.setModalClick();
                    props.populateData();
                    resetData();
                } else {
                    toastify(response?.message);
                }
            }
        }
    };

    const populateData = () => {
        let data = props.product;
        setName(data?.name);
        setDescription(data?.description);
        setDocs(data?.documents);
        setIsActive(data?.isActive);
        setItems(data?.internalForms);
        setPrice(data?.price);
        setAdvisoryFee(data?.advisoryFee);
    };

    useEffect(() => {
        if (props.product) populateData();
    }, [props.product]);

    const resetData = () => {
        setName("");
        setDescription("");
        setPrice(0);
        setAdvisoryFee("fixed");
        setDocs([
            { name: "", input: false, upload: false, pdpa: true, url: "" },
        ]);
        setIsActive(true);
        setItems([]);
        props.setProduct();
    };

    return (
        <div className={`modal-wrapper add-product-form`}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-body">
                        <img
                            src={crossIcon}
                            className="cross-btn"
                            onClick={() => {
                                resetData();
                                props.setModalClick();
                            }}
                            alt="crossIcon"
                        />
                        <div className="modal-forms">
                            <h2>{props.product ? "Edit" : "Add"} Product </h2>
                            <ul className="list-style-none">
                                <li>
                                    <div className="form-group">
                                        <label>Product Name *</label>
                                        <input
                                            type="text"
                                            placeholder="Product name"
                                            className="form-control mandatory"
                                            value={name}
                                            onChange={(e) =>
                                                setName(e.target.value)
                                            }
                                        />
                                    </div>
                                </li>
                                <li>
                                    <div className="form-group">
                                        <label htmlFor="">
                                            Product Specification *
                                        </label>
                                        <textarea
                                            placeholder="Description"
                                            className="form-control mandatory"
                                            value={description}
                                            onChange={(e) =>
                                                setDescription(e.target.value)
                                            }
                                        />
                                    </div>
                                </li>
                                <li>
                                    <div className="form-group">
                                        <label>Advisory Fee *</label>

                                        <label
                                            id="advisory"
                                            className="check-radio"
                                        >
                                            <input
                                                type="radio"
                                                name="advisory"
                                                checked={
                                                    advisoryFee === "fixed"
                                                }
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        setAdvisoryFee("fixed");
                                                        setPrice(0);
                                                    }
                                                }}
                                            />
                                            <span>Fixed</span>
                                        </label>
                                        <label
                                            id="advisory"
                                            className="check-radio"
                                        >
                                            <input
                                                type="radio"
                                                name="advisory"
                                                checked={
                                                    advisoryFee === "percentage"
                                                }
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        setAdvisoryFee(
                                                            "percentage"
                                                        );
                                                        setPrice(0);
                                                    }
                                                }}
                                            />
                                            <span>Percentage</span>
                                        </label>
                                        <input
                                            type="text"
                                            placeholder="Enter Value"
                                            className="form-control mandatory"
                                            value={price}
                                            onChange={setAmount}
                                            // maxLength={10}
                                        />
                                    </div>
                                </li>
                                <li>
                                    <div className="form-group">
                                        <label htmlFor="">Internal Form</label>
                                        <Multiselect
                                            isObject={true}
                                            showCheckbox
                                            options={internalForms?.filter(
                                                (e) => e.isActive
                                            )}
                                            selectedValues={items}
                                            onSelect={handleSelect}
                                            onRemove={handleRemove}
                                            displayValue="name"
                                        />
                                    </div>
                                </li>
                                <li>
                                    <div className="form-group">
                                        <label htmlFor="">Status *</label>
                                        <select
                                            className="form-control mandatory"
                                            value={isActive}
                                            onChange={(e) =>
                                                setIsActive(e.target.value)
                                            }
                                        >
                                            <option value={true}>Active</option>
                                            <option value={false}>
                                                Inactive
                                            </option>
                                        </select>
                                    </div>
                                </li>
                                <li>
                                    <div className="form-group">
                                        <label htmlFor="">
                                            Required documentation
                                        </label>
                                    </div>
                                    <button
                                        className="add-list-wrapper mb-20"
                                        onClick={() => {
                                            let _docs = [...docs];
                                            _docs.push({
                                                name: "",
                                                input: false,
                                                upload: false,
                                                pdpa: true,
                                                url: "",
                                            });
                                            setDocs(_docs);
                                        }}
                                    >
                                        <img src={addIcon} alt="addIcon" /> Add
                                        New Requirement Documentation
                                    </button>
                                    {docs.map((e, i) => (
                                        <>
                                            <section className="form-group flex product-form-list items-center">
                                                <input
                                                    id={i + "1"}
                                                    type="text"
                                                    placeholder="Documentation Name"
                                                    className="form-control"
                                                    value={e.name}
                                                    onChange={(e) => {
                                                        let _docs = [...docs];
                                                        _docs[i].name =
                                                            e.target.value;
                                                        setDocs(_docs);
                                                    }}
                                                />

                                                <div className="flex flex-wrap switches-area">
                                                    <ul className="list-style-none flex">
                                                        <li className="w-33">
                                                            <span>Input</span>
                                                            <label
                                                                htmlFor={
                                                                    "2" + i
                                                                }
                                                                className="switch-wrap"
                                                            >
                                                                <input
                                                                    id={"2" + i}
                                                                    checked={
                                                                        e.input
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        let _docs =
                                                                            [
                                                                                ...docs,
                                                                            ];
                                                                        _docs[
                                                                            i
                                                                        ].input =
                                                                            e.target.checked;
                                                                        setDocs(
                                                                            _docs
                                                                        );
                                                                    }}
                                                                    type="checkbox"
                                                                />
                                                                <span></span>
                                                            </label>
                                                        </li>
                                                        <li className="w-33">
                                                            <span>Upload</span>
                                                            <label
                                                                htmlFor={
                                                                    "3" + i
                                                                }
                                                                className="switch-wrap"
                                                            >
                                                                <input
                                                                    id={"3" + i}
                                                                    checked={
                                                                        e.upload
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        let _docs =
                                                                            [
                                                                                ...docs,
                                                                            ];
                                                                        _docs[
                                                                            i
                                                                        ].upload =
                                                                            e.target.checked;
                                                                        setDocs(
                                                                            _docs
                                                                        );
                                                                    }}
                                                                    type="checkbox"
                                                                />
                                                                <span></span>
                                                            </label>
                                                        </li>
                                                        <li className="w-33">
                                                            <span>PDPA</span>
                                                            <label
                                                                htmlFor={
                                                                    "4" + i
                                                                }
                                                                className="switch-wrap"
                                                            >
                                                                <input
                                                                    id={"4" + i}
                                                                    checked={
                                                                        e.pdpa
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        let _docs =
                                                                            [
                                                                                ...docs,
                                                                            ];
                                                                        _docs[
                                                                            i
                                                                        ].pdpa =
                                                                            e.target.checked;
                                                                        setDocs(
                                                                            _docs
                                                                        );
                                                                    }}
                                                                    type="checkbox"
                                                                />
                                                                <span></span>
                                                            </label>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <span className="flex items-center add-extra-list del">
                                                    <label
                                                        class="file-input-label"
                                                        htmlFor={`file-input-${i}`}
                                                    >
                                                        <img src={uploadIcon} />
                                                    </label>
                                                    <input
                                                        ref={fileInputRef}
                                                        id={`file-input-${i}`}
                                                        type="file"
                                                        style={{
                                                            display: "none",
                                                        }}
                                                        onChange={(e) => {
                                                            if (
                                                                e.target
                                                                    .files[0]
                                                            ) {
                                                                uploadFile(
                                                                    e.target
                                                                        .files[0],
                                                                    i
                                                                );
                                                            }
                                                        }}
                                                    />
                                                </span>

                                                <span className="flex items-center add-extra-list del">
                                                    <button
                                                        onClick={() => {
                                                            let _docs = [
                                                                ...docs,
                                                            ].filter(
                                                                (el, index) =>
                                                                    i !== index
                                                            );
                                                            setDocs(_docs);
                                                        }}
                                                    >
                                                        <img
                                                            src={del}
                                                            alt="del"
                                                        />
                                                    </button>
                                                </span>
                                            </section>
                                            {e.url && (
                                                <div className="flex items-center">
                                                    <div className="me-10 flex items-center">
                                                        <img
                                                            src={crossIcon}
                                                            className="cursor-pointer"
                                                            style={{
                                                                width: 20,
                                                            }}
                                                            onClick={() => {
                                                                fileInputRef.current.value =
                                                                    null;

                                                                let _docs = [
                                                                    ...docs,
                                                                ]?.map(
                                                                    (ele) => {
                                                                        if (
                                                                            ele.url ===
                                                                            e.url
                                                                        ) {
                                                                            return {
                                                                                ...ele,
                                                                                url: "",
                                                                            };
                                                                        } else {
                                                                            return {
                                                                                ...ele,
                                                                            };
                                                                        }
                                                                    }
                                                                );
                                                                setDocs(_docs);
                                                            }}
                                                        />
                                                    </div>
                                                    <div
                                                        className="bold underline cursor-pointer"
                                                        onClick={() => {
                                                            window.open(
                                                                e.url,
                                                                "_blank",
                                                                "noopener noreferrer"
                                                            );
                                                        }}
                                                    >
                                                        {e?.filename ||
                                                            "Document Attached"}
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    ))}
                                </li>
                                <li className="flex content-justify-center button-wrapper">
                                    <button
                                        onClick={() => {
                                            resetData();
                                            props.setModalClick();
                                        }}
                                        className="simple-btn cancel"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="simple-btn"
                                        onClick={submit}
                                    >
                                        Submit
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
