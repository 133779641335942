import "./modals.scss";
import crossIcon from "../../assets/images/cross.svg";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { missingPopup } from "../../helpers/dataHelper/missingData";

export const AddCase = (props) => {
    const [product, setProduct] = useState("");
    const [loanAmount, setLoanAmount] = useState("");
    const reduxData = useSelector((state) => state);
    const numberRegex = new RegExp(`^((0|([1-9][0-9]*))([.]|[.][0-9]{1,2})?)?$`);

    useEffect(() => {
        if (props?.case) {
            setProduct(props?.case?.product);
            setLoanAmount(props?.case?.loanAmount);
        }
    }, [props.case]);

    const submit = () => {
        if (!product) missingPopup("Product");
        else {
            const data = {
                productId: product?._id,
                loanAmount,
            };
            if (props?.case) props?.editCase(data);
            else props?.addCase(data);
        }
    };

    return (
        <div className={`modal-wrapper add-product-form`}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-body">
                        <img
                            src={crossIcon}
                            className="cross-btn"
                            onClick={props.setModalClick}
                            alt="crossIcon"
                        />
                        <div className="modal-forms">
                            <h2>Add Case</h2>
                            <ul className="list-style-none">
                                <li>
                                    <div className="form-group">
                                        <label>Case Product</label>
                                        <select
                                            className="form-control"
                                            value={JSON.stringify(product)}
                                            onChange={(e) => {
                                                setProduct(
                                                    JSON.parse(e.target.value)
                                                );
                                            }}
                                        >
                                            <option
                                                value=""
                                                style={{ display: "none" }}
                                            >
                                                Select Product
                                            </option>
                                            {reduxData?.productForms?.value?.map(
                                                (e) => (
                                                    <option
                                                        value={JSON.stringify(
                                                            e
                                                        )}
                                                    >
                                                        {e.name}
                                                    </option>
                                                )
                                            )}
                                        </select>
                                    </div>
                                </li>
                                <li>
                                    <div className="form-group">
                                        <label htmlFor="">
                                            Product Specification
                                        </label>
                                        <textarea
                                            placeholder="Description"
                                            className="form-control"
                                            disabled
                                            value={product?.description}
                                        />
                                    </div>
                                </li>
                                <li>
                                    <div className="form-group">
                                        <label htmlFor="">Loan Amount</label>
                                        <input
                                            placeholder="Input Amount"
                                            className="form-control"
                                            value={loanAmount}
                                            onChange={(e) => {
                                                if (numberRegex.test(e.target.value) ) {
                                                    setLoanAmount(e.target.value);
                                                }
                                            }}
                                            onBlur={() => {
                                                if(loanAmount?.slice(-1) === "."){
                                                    setLoanAmount(loanAmount.slice(0, -1));
                                                }
                                            }}
                                        />
                                    </div>
                                </li>
                                <li>
                                    <div className="form-group">
                                        <label htmlFor="">
                                            Required Documents
                                        </label>
                                        <section className="docs-list">
                                            {product?.documents?.map((e) => (
                                                <span>{e.name}</span>
                                            ))}
                                        </section>
                                    </div>
                                </li>
                                <li className="flex content-justify-center button-wrapper">
                                    <button
                                        onClick={props.setModalClick}
                                        className="simple-btn cancel"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="simple-btn"
                                        onClick={submit}
                                    >
                                        Save
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
