import { useState } from "react";
import { useSelector } from "react-redux";
import "./modals.scss";

export const BankModal = (props) => {
    const [bank, setBank] = useState();
    const reduxData = useSelector((state) => state);

    return (
        <div className={`modal-wrapper inner_modal`}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-body ">
                        <div className="modal-forms">
                            <ul className="list-style-none">
                                <li>
                                    <div className="form-group">
                                        <label>Select a bank to apply</label>
                                        <select
                                            className="form-control"
                                            value={JSON.stringify(bank)}
                                            onChange={(e) => {
                                                setBank(
                                                    JSON.parse(e.target.value)
                                                );
                                            }}
                                        >
                                            <option
                                                value=""
                                                style={{ display: "none" }}
                                            >
                                                Select Bank
                                            </option>
                                            {reduxData?.banks?.value?.map(
                                                (e) => (
                                                    <option
                                                        value={JSON.stringify(
                                                            e
                                                        )}
                                                    >
                                                        {e.name}
                                                    </option>
                                                )
                                            )}
                                        </select>
                                    </div>
                                </li>
                                <li>
                                    <div className="flex content-justify-center">
                                        <button
                                            onClick={() => props.setShow(false)}
                                            className="modal-btn cancel"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            onClick={() => {
                                                props.confirmAction(bank);
                                                setBank();
                                            }}
                                            className="modal-btn"
                                        >
                                            Confirm
                                        </button>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
