import addIcon from "../../../../assets/images/add-icon.svg";
import delIcon from "../../../../assets/images/del.svg";
import { toastify } from "../../../../helpers/toast/toastify";

export default function AddressMultipleFields({
    inputFields,
    setInputFields,
    hideIcon,
    hideDeleteIcon = false,
    address,
    disabled,
}) {
    const addInputField = () => {
        let adds = inputFields.map((e) => e.address);
        if (!adds?.includes("")) {
            setInputFields([
                ...inputFields,
                {
                    address: "",
                },
            ]);
        }
    };
    const removeInputFields = (index) => {
        if (hideIcon) return;
        const rows = [...inputFields];
        rows.splice(index, 1);
        setInputFields(rows);
    };
    const handleChange = (index, event) => {
        const { name, value } = event.target;
        const list = [...inputFields];
        list[index] = value;
        setInputFields(list);
    };

    return (
        <ul className="list-style-none flex flex-wrap multi-field-wrapper">
            {/* <button className="add-address-btn" onClick={addInputField}>
                <img src={addIcon} alt="addIcon" />
            </button> */}
            {inputFields.map((data, index) => {
                return (
                    <li>
                        <div className="form-group multi-field">
                            <label htmlFor="">
                                Address {index + 2}
                                {index === inputFields.length - 1 && (
                                    <button
                                        disabled={disabled}
                                        className="add-address-btn"
                                        onClick={() => {
                                            if (
                                                inputFields[
                                                    inputFields.length - 1
                                                ] !== "" &&
                                                address !== ""
                                            ) {
                                                setInputFields([
                                                    ...inputFields,
                                                    "",
                                                ]);
                                            } else {
                                                toastify(
                                                    `Please fill address ${
                                                        inputFields.length + 1
                                                    } to add more address.`,
                                                    "error"
                                                );
                                            }
                                        }}
                                    >
                                        <img src={addIcon} alt="addIcon" />
                                    </button>
                                )}
                            </label>
                            <input
                                type="text"
                                onChange={(evnt) => handleChange(index, evnt)}
                                value={data}
                                name="address"
                                className="form-control"
                                placeholder={`Address ${index + 2}`}
                                disabled={disabled}
                            />
                            {!hideDeleteIcon && (
                                <button
                                    className="del-field-btn"
                                    onClick={() => removeInputFields(index)}
                                >
                                    <img src={delIcon} alt="delIcon" />
                                </button>
                            )}
                        </div>
                    </li>
                );
            })}
        </ul>
    );
}
