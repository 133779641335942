import "./modals.scss";
import crossIcon from "../../assets/images/cross.svg";
import del from "../../assets/images/del.svg";
import edit from "../../assets/images/update.svg";
import { useEffect, useState } from "react";
import { api } from "../../helpers/apiHelper/requestHelper";
import { toastify } from "../../helpers/toast/toastify";
import passwordIcon from "../../assets/images/password-eye.png";
import { useNavigate } from "react-router-dom";
import { AlertModal } from "./AlertModal";
import { setLoading } from "../../redux/loader/loadingSlice";
import { useDispatch } from "react-redux";
import { shortFormatDate } from "../../helpers/dataHelper/dateFormatter";

export const UserDetailModal = (props) => {
    const [password, setPassword] = useState("********");
    const [show, setShow] = useState(false);
    const [openAlertModal, setOpenAlertModal] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        const getPassword = async () => {
            dispatch(setLoading(true));
            const response = await api("post", "users/viewPassword", {
                username: props?.user?.username,
            });
            dispatch(setLoading(false));
            if (response?.data?.status) {
                setPassword(response?.data?.data);
            } else {
                toastify("Unable to get password", "error");
            }
        };
        if (show) {
            getPassword();
        } else {
            setPassword("********");
        }
    }, [show]);

    const confirmAction = async () => {
        dispatch(setLoading(true));
        const response = await api("post", "users/updateUser", {
            isDeleted: true,
            id: props?.user._id,
        });
        dispatch(setLoading(false));
        if (response?.data?.status) {
            setOpenAlertModal(false);
            props.turnOff(false);
            props.refresh();
            toastify("User deleted successfully", "success");
            navigate("/users");
        } else {
            toastify(response?.message, "error");
        }
    };

    return (
        <div className={`modal-wrapper detail-wrapper`}>
            {openAlertModal && (
                <AlertModal
                    confirmAction={confirmAction}
                    setModalClick={() => setOpenAlertModal(false)}
                    confirmBtn={true}
                    modalTitle={[
                        <h2>
                            Do you really want to delete{" "}
                            {"@" + props?.user?.username || "N/A"} <br />
                            permanently?
                        </h2>,
                    ]}
                    modalDescription={[]}
                />
            )}
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-body">
                        <img
                            src={crossIcon}
                            className="cross-btn"
                            onClick={props.setModalClick}
                            alt="crossIcon"
                        />
                        <div className="user-detail-modal flex column-direction">
                            <h2>User Details</h2>
                            <div className="user-detail-wrapper flex">
                                <div className="modal-left-section flex column-direction">
                                    <section>
                                        <h3>Personal Information</h3>
                                        <ul className="list-style-none">
                                            <li>
                                                <h4>Username</h4>{" "}
                                                <span>
                                                    {props.user.username}
                                                </span>
                                            </li>
                                            <li>
                                                <h4>Email</h4>{" "}
                                                <span>{props.user.email}</span>
                                            </li>
                                            <li>
                                                <h4>Gender</h4>{" "}
                                                <span>
                                                    {" "}
                                                    {props.user.gender}
                                                </span>
                                            </li>
                                            <li>
                                                <h4>Date of Birth</h4>{" "}
                                                <span>
                                                    {props?.user?.dob
                                                        ? shortFormatDate(
                                                              props.user.dob
                                                          )
                                                        : ""}
                                                </span>
                                            </li>
                                            <li>
                                                <h4>Account Password</h4>
                                                <span>
                                                    {/* {props.user.password} */}
                                                    {password}
                                                    <img
                                                        className="cursor-pointer m3"
                                                        src={passwordIcon}
                                                        onClick={() =>
                                                            setShow(!show)
                                                        }
                                                        alt="password"
                                                    />
                                                </span>
                                            </li>
                                        </ul>
                                    </section>
                                    <section>
                                        <h3>Roles</h3>
                                        <ul className="list-style-none">
                                            {props.user.roles.map((e) => (
                                                <li>
                                                    <h4>Role</h4>
                                                    <span>{e}</span>
                                                </li>
                                            ))}
                                            {/* <li>
                                                <h4>Under to</h4>{" "}
                                                <span>Non Under</span>
                                            </li>
                                            <li>
                                                <h4>Status</h4>{" "}
                                                <span>Active</span>
                                            </li> */}
                                        </ul>
                                    </section>
                                </div>
                                <div className="modal-right-section flex column-direction">
                                    <section>
                                        <h3>Contact & Address Information</h3>
                                        <ul className="list-style-none">
                                            <li>
                                                <h4>Contact No.</h4>
                                                <span>
                                                    {props.user.contact}
                                                </span>
                                            </li>
                                            <li>
                                                <h4>Country</h4>
                                                <span>
                                                    {props.user.country}
                                                </span>
                                            </li>
                                            <li>
                                                <h4>State/Region</h4>
                                                <span>{props.user.state}</span>
                                            </li>
                                            <li>
                                                <h4>City</h4>
                                                <span>{props.user.city}</span>
                                            </li>
                                            <li>
                                                <h4>Postal Code</h4>
                                                <span>
                                                    {props.user.postalCode}
                                                </span>
                                            </li>
                                            {props.user.addresses?.map(
                                                (e, i) => {
                                                    <li>
                                                        <h4>Address {i + 1}</h4>{" "}
                                                        <span>{e}</span>
                                                    </li>;
                                                }
                                            )}
                                        </ul>
                                    </section>
                                </div>
                            </div>
                            <div className="flex content-justify-start">
                                <button
                                    onClick={() => {
                                        setOpenAlertModal(true);
                                    }}
                                    className="modal-btn cancel"
                                >
                                    <img src={del} alt="del" /> Delete
                                </button>
                                {props.confirmBtn === true ? (
                                    <button
                                        onClick={() => {
                                            navigate("/edit-user-details", {
                                                state: {
                                                    user: props.user,
                                                },
                                            });
                                        }}
                                        className="modal-btn"
                                    >
                                        <img src={edit} alt="edit" /> Edit
                                    </button>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
