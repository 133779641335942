import Multiselect from "multiselect-react-dropdown";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { api } from "../../helpers/apiHelper/requestHelper";
import { missingPopup } from "../../helpers/dataHelper/missingData";
import { toastify } from "../../helpers/toast/toastify";
import { storeCases } from "../../redux/cases/casesSlice";
import "./modals.scss";

export const DocumentModal = (props) => {
    return (
        <div className={`modal-wrapper`}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-body ">
                        <div className="modal-forms">
                            <ul className="list-style-none">
                                <li>
                                    <div className="form-group">
                                        <label>
                                            Select documents to send to the
                                            client
                                        </label>
                                        <Multiselect
                                            isObject={true}
                                            showCheckbox
                                            options={props?.case?.caseDocuments?.filter(
                                                (e) => e.url
                                            )}
                                            selectedValues={props?.documents}
                                            onSelect={(e) =>
                                                props?.setDocuments(e)
                                            }
                                            onRemove={(e) =>
                                                props?.setDocuments(e)
                                            }
                                            displayValue="name"
                                        />
                                        {/* <select
                                            className="form-control"
                                            value={documents}
                                            onChange={(e) => {
                                                setDocuments(e);
                                            }}
                                        >
                                            <option
                                                value=""
                                                style={{ display: "none" }}
                                            >
                                                Select Document
                                            </option>
                                            {props?.case?.caseDocuments
                                                ?.filter((e) => e.url)
                                                ?.map((e) => (
                                                    <option value={e.url}>
                                                        {e.name}
                                                    </option>
                                                ))}
                                        </select> */}
                                    </div>
                                </li>
                                <li>
                                    <div className="flex content-justify-center">
                                        <button
                                            onClick={() => {
                                                props.setShow(false);
                                                props?.setDocuments([]);
                                            }}
                                            className="modal-btn cancel"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            onClick={() => {
                                                if (
                                                    props?.documents?.length ===
                                                    0
                                                )
                                                    missingPopup("Document");
                                                else props?.confirm();
                                            }}
                                            className="modal-btn"
                                        >
                                            Confirm
                                        </button>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
