import RmStatistics from "./rm-statistics/RmStatistics";
import Members from "./members/Members";
import LeadCaseAccordion from "./accordion-listing/LeadCaseAccordion";
import { api } from "../../../helpers/apiHelper/requestHelper";
import { storeUsers } from "../../../redux/users/usersSlice";
import { storeLeads } from "../../../redux/leads/leadsSlice";
import { storeCases } from "../../../redux/cases/casesSlice";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { toastify } from "../../../helpers/toast/toastify";
import { setProductForms } from "../../../redux/productForms/productsSlice";
import calenderIcon from "../../../assets/images/calender.svg";
import { useSelector } from "react-redux";
import DatePicker from "react-date-picker";
import { setLoading } from "../../../redux/loader/loadingSlice";
import { filterDataByDate } from "../../../helpers/filterHelper/dateFilter";
import { useNavigate } from "react-router-dom";
import { setUserAnnouncements } from "../../../redux/announcements/announcementSlice";
import { setRoute } from "../../../redux/route/routeSlice";

export default function RmDashboard() {
    const dispatch = useDispatch();
    const [date, setDate] = useState();
    const reduxData = useSelector((state) => state);
    const navigate = useNavigate();

    const getLeads = async () => {
        let response = await api("post", "leads/index", {
            assignedFromRole: reduxData?.userRoles?.currentRole,
        });

        if (response?.status) {
            dispatch(storeLeads(response?.data?.data?.reverse()));
        } else {
            toastify(response?.message, "error");
        }
    };

    const getCases = async () => {
        let response = await api("post", "cases/index", {
            role: reduxData?.userRoles?.currentRole,
        });

        if (response?.data?.status) {
            dispatch(storeCases(response?.data?.data?.reverse()));
        } else {
            toastify(response?.message, "error");
        }
    };

    useEffect(() => {
        if (date) {
            filterData();
        } else {
            if (localStorage.getItem("token")) {
                getLeads();
                getCases();
            }
        }
    }, [date]);

    useEffect(() => {
        dispatch(setRoute("Dashboard"));

        if (!reduxData?.auth?.user) {
            navigate("/login");
        } else {
            dispatch(setLoading(false));
            if (reduxData?.userRoles?.currentRole === "RM Team Leader") {
                navigate("/rm-lead");
            } else if (reduxData?.userRoles?.currentRole === "RM Member") {
                navigate("/rm-member");
            } else if (
                reduxData?.userRoles?.currentRole === "Lead Coordinator"
            ) {
                navigate("/lead-coordinator");
            } else if (reduxData?.userRoles?.currentRole === "Case Manager") {
                navigate("/case-manager");
            } else {
                navigate("/");
            }
        }
    }, [reduxData?.auth?.user]);

    const filterData = async () => {
        dispatch(setLoading(true));

        let { filteredLeads, filteredCases } = await filterDataByDate(
            date,
            false,
            reduxData?.userRoles?.currentRole
        );

        dispatch(storeLeads(filteredLeads));
        dispatch(storeCases(filteredCases));

        dispatch(setLoading(false));
    };

    return (
        <>
            <h2 className="flex items-center section-title">
                RM Member
                <div className="search-filter single-sort ml-auto">
                    <img src={calenderIcon} alt="filterIcon" />
                    <DatePicker
                        format="dd/MM/y"
                        className="form-control"
                        onChange={(e) => {
                            setDate(e);
                        }}
                        value={date}
                        onKeyDown={(e) => {
                            e.preventDefault();
                        }}
                    />
                </div>
            </h2>

            <RmStatistics />
            {/* <LeadCaseAccordion /> */}
        </>
    );
}
